import { PRODUCTS_COLLECTION } from "../../core/constants";
import Firebase from "../../core/firebase";
import ProductDto from "../dtos/ProductDto";

export interface IProductRemoteDatasource {
	getProducts(): Promise<ProductDto[]>;
}

export default class ProductRemoteDatasource
	implements IProductRemoteDatasource
{
	async getProducts(): Promise<ProductDto[]> {
		const firebase = Firebase.GetInstance();
		const productsCollection = await firebase.firestore
			.collection(PRODUCTS_COLLECTION)
			.orderBy("order")
			.get();
		return Promise.all(
			productsCollection.docs.map((item) => ProductDto.fromFirestore(item))
		);
	}
}
