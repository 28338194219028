import { useHistory, useParams } from "react-router";
import {
  useClientDispatch,
  useClientState,
} from "../../../../../../../../../../application/client";
import ClientActions from "../../../../../../../../../../application/client/actions";
import { useInjection } from "../../../../../../../../../../core/dependency_injection";
import GoalPlan from "../../../../../../../../../../domain/client/models/GoalPlan";
import Goal from "../../../../../../../../../../domain/note/models/Goal";
import { EnhancedTable } from "../../../../../../../../../core/components/Table";
import { Column } from "../../../../../../../../../core/components/Table/EnhancedTable";
import { RouteParams } from "../../../../../../../../../core/constants";
// import useStyles from "../../styles";

export default function Goals() {
  const { id } = useParams<RouteParams>();
  const history = useHistory();
  // const classes = useStyles();
  // const { goals } = useNoteState();
  const { selectedClient } = useClientState();
  const clientDispatch = useClientDispatch();
  const clientActions = useInjection<ClientActions>("ClientActions");

  const columns: Column[] = [
    {
      id: "startDate",
      label: "Start Date",
      minWidth: 170,
      align: "left",
      format: (value: Date) => value.toLocaleDateString("en-US"),
    },
    {
      id: "endDate",
      label: "End Date",
      minWidth: 100,
      align: "left",
      format: (value: Date) => value.toLocaleDateString("en"),
    },
    {
      id: "goals",
      label: "Goals & Objectives",
      minWidth: 170,
      align: "left",
      format: (value: Goal[]) => `${value.length}`,
    },
  ];

  return (
    <div>
      {selectedClient.goalPlans?.length === 0 ? (
        <div>No registered plans </div>
      ) : (
        <EnhancedTable
          data={selectedClient.goalPlans ?? []}
          columns={columns}
          rowsPerPage={[5, 10, 50]}
          onClickCallback={(value: GoalPlan) => {
            // console.log(`onClick item:`, contractor);
            clientActions.setGoalPlan(clientDispatch, value);
            history.push(`/app/clients/${id}/goals-objectives/${value.id}`);
            // noteActions.setEditNote(noteDispatch, note);
            // history.push(`/app/clients/${id}/progress-note`);
          }}
        />
      )}
    </div>
  );
}
