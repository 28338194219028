import { Typography } from "@material-ui/core";
// import PropTypes from "prop-types";
import useStyles from "./styles";

export default function InfoDataItem(props: any) {
  const classes = useStyles();

  const { label, value } = props;

  return (
    <div className={classes.root}>
      <Typography className={classes.label}>{label}</Typography>
      <Typography className={classes.value}>{value}</Typography>
    </div>
  );
}

// InfoDataItem.propTypes = {
//   label: PropTypes.string.isRequired,
//   value: PropTypes.string.isRequired,
// };
