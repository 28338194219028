import {
	SET_LOADING,
	SET_SENDING_PASSWORD,
	SET_SENDING_VERIFY,
	SET_SHOW_PLAN,
	SET_SUBMITING,
	SET_UPDATING,
	SET_USER,
	SIGN_FAILURE,
	SIGN_OUT_SUCCESS,
	UPDATE_USER,
	UserDispatch,
} from "./misc";
import { User } from "../../domain/user/models/User";
import { EnqueueSnackbar } from "../../domain/core/utils";
import { IUserRepository } from "../../domain/user/iuser.repository";
import Company from "../../domain/user/models/Company";

export default class UserActions {
	private userRepository: IUserRepository;

	constructor(userRepository: IUserRepository) {
		this.userRepository = userRepository;
	}

	public async sendEmail(
		dispatch: UserDispatch,
		enqueueSnackbar: EnqueueSnackbar
	) {
		dispatch({ type: SET_SENDING_VERIFY, payload: true });
		const result = await this.userRepository.sendValidateEmail();
		if (result.isLeft) {
			enqueueSnackbar(result.getError(), { variant: "error" });
			dispatch({ type: SET_SENDING_VERIFY, payload: false });
			// dispatch({ type: SET_ACTIVATE_MESSAGE, payload: "Something went wrong" });
			return;
		}
		enqueueSnackbar(
			"We send you an email to validate your account. Check your inbox and follow the intructions",
			{ variant: "success" }
		);
		dispatch({ type: SET_SENDING_VERIFY, payload: false });
	}

	public async sendResetPasswordEmail(
		dispatch: UserDispatch,
		email: string,
		enqueueSnackbar: EnqueueSnackbar
	) {
		dispatch({ type: SET_SENDING_PASSWORD, payload: true });
		const result = await this.userRepository.sendResetPasswordEmail(email);
		if (result.isLeft) {
			enqueueSnackbar(result.getError(), { variant: "error" });
			dispatch({ type: SET_SENDING_PASSWORD, payload: false });
			return;
		}
		enqueueSnackbar(
			"We send you a password reset email. Check your inbox and follow the intructions",
			{
				variant: "success",
			}
		);
		dispatch({ type: SET_SENDING_PASSWORD, payload: false });
	}

	public async updateUser(
		dispatch: UserDispatch,
		user: User,
		enqueueSnackbar: EnqueueSnackbar
	) {
		dispatch({ type: SET_UPDATING, payload: true });
		const result = await this.userRepository.update(user);
		if (result.isLeft) {
			enqueueSnackbar(result.getError(), { variant: "error" });
			dispatch({ type: SET_UPDATING, payload: false });
			return;
		}
		dispatch({ type: UPDATE_USER, payload: user });
		dispatch({ type: SET_UPDATING, payload: false });
	}

	public async checkAuthenticated(dispatch: any) {
		dispatch({ type: SET_LOADING, payload: true });
		const result = await this.userRepository.getAuthenticatedUser();
		// console.log(`checkAuthenticated result`, result);
		if (result.isLeft) {
			dispatch({ type: SIGN_FAILURE });
			return;
		}
		const user: User = result.getValue();
		dispatch({ type: SET_USER, payload: user });
		dispatch({ type: SET_LOADING, payload: false });
	}

	public async signUp(
		dispatch: UserDispatch,
		name: string,
		email: string,
		password: string,
		company: Company,
		history: any,
		enqueueSnackbar: EnqueueSnackbar
	) {
		const user = new User({ name: name, email: email, company: company });
		if (user.isValid()) {
			dispatch({ type: SET_SUBMITING, payload: true });
			const result = await this.userRepository.signUp(user, password);
			if (result.isLeft) {
				dispatch({ type: SIGN_FAILURE });
				dispatch({ type: SET_SUBMITING, payload: false });
				enqueueSnackbar(result.getError(), { variant: "error" });
				return;
			}
			const emailResult = await this.userRepository.sendValidateEmail();
			if (emailResult.isLeft) {
				enqueueSnackbar(emailResult.getError(), { variant: "error" });
			} else {
				enqueueSnackbar(
					"We send you an email to validate your account. Check your inbox and follow the intructions",
					{ variant: "success" }
				);
			}

			const newUser: User = result.getValue();
			history.replace("/app/dashboard");
			dispatch({ type: SET_USER, payload: newUser });
			dispatch({ type: SET_SUBMITING, payload: false });
		} else {
			enqueueSnackbar("Form invalid", { variant: "error" });
		}
	}

	public async signIn(
		dispatch: UserDispatch,
		email: string,
		password: string,
		history: any,
		enqueueSnackbar: EnqueueSnackbar
	) {
		dispatch({ type: SET_SUBMITING, payload: true });
		const result = await this.userRepository.signIn(email, password);
		// console.log(`result`, result);
		if (result.isLeft) {
			dispatch({ type: SIGN_FAILURE });
			dispatch({ type: SET_SUBMITING, payload: false });
			enqueueSnackbar(result.getError(), { variant: "error" });
			return;
		}
		const user: User = result.getValue();
		if (!user.emailVerified) {
			const emailResult = await this.userRepository.sendValidateEmail();
			if (emailResult.isLeft) {
				enqueueSnackbar(emailResult.getError(), { variant: "error" });
			} else {
				enqueueSnackbar(
					"We send you an email to validate your account. Check your inbox and follow the intructions",
					{ variant: "success" }
				);
			}
		}
		dispatch({ type: SET_SUBMITING, payload: false });
		history.replace("/app/dashboard");
		dispatch({ type: SET_USER, payload: user });
	}

	// public async register(
	// 	dispatch: UserDispatch,
	// 	user: User,
	// 	history: any,
	// 	enqueueSnackbar: EnqueueSnackbar
	// ) {
	// 	if (user.isValid()) {
	// 		dispatch({ type: SET_SUBMITING, payload: true });
	// 		const result = await this.userRepository.register(user, "");
	// 		if (result.isLeft) {
	// 			dispatch({ type: SIGN_FAILURE });
	// 			dispatch({ type: SET_SUBMITING, payload: false });
	// 			enqueueSnackbar(result.getError(), { variant: "error" });
	// 			return;
	// 		}
	// 		const newUser: User = result.getValue();
	// 		history.replace("/app/dashboard");
	// 		dispatch({ type: SET_USER, payload: newUser });
	// 		dispatch({ type: SET_SUBMITING, payload: false });
	// 	} else {
	// 		enqueueSnackbar("Form invalid", { variant: "error" });
	// 	}
	// }

	// public async sendSignLink(
	// 	dispatch: UserDispatch,
	// 	email: string,
	// 	enqueueSnackbar: EnqueueSnackbar
	// ) {
	// 	dispatch({ type: SET_SENDING_VERIFY, payload: true });
	// 	const result = await this.userRepository.sendSignLink(email);
	// 	if (result.isLeft) {
	// 		enqueueSnackbar(result.getError(), { variant: "error" });
	// 		dispatch({ type: SET_SENDING_VERIFY, payload: false });
	// 		// dispatch({ type: SET_ACTIVATE_MESSAGE, payload: "Something went wrong" });
	// 		return;
	// 	}
	// 	dispatch({ type: SET_SENDING_VERIFY, payload: false });
	// 	dispatch({ type: SET_SIGN_EMAIL_SENDED, payload: true });
	// }

	// public async signInWithLink(
	// 	dispatch: UserDispatch,
	// 	email: string,
	// 	history: any,
	// 	enqueueSnackbar: EnqueueSnackbar
	// ) {
	// 	dispatch({ type: SET_SUBMITING, payload: true });
	// 	const result = await this.userRepository.signInWithLink(email);
	// 	if (result.isLeft) {
	// 		// dispatch({ type: SIGN_FAILURE });
	// 		dispatch({ type: SET_SUBMITING, payload: false });
	// 		history.push("/auth/sign");
	// 		enqueueSnackbar(result.getError(), { variant: "error" });
	// 		return;
	// 	}
	// 	const newUser: User = result.getValue();
	// 	if (newUser.name && newUser.name !== "") {
	// 		history.replace("/app/dashboard");
	// 		dispatch({ type: SET_USER, payload: newUser });
	// 	} else {
	// 		dispatch({
	// 			type: SET_FILL_PROFILE,
	// 			payload: {
	// 				id: newUser.id ?? "",
	// 				emailVerified: newUser.emailVerified ?? false,
	// 				value: true,
	// 			},
	// 		});
	// 	}
	// 	dispatch({ type: SET_SUBMITING, payload: false });
	// }

	public async signOut(dispatch: UserDispatch, history: any) {
		await this.userRepository.signOut();
		dispatch({ type: SIGN_OUT_SUCCESS });
		history.replace("/");
	}

	public async goToCheckout(
		dispatch: UserDispatch,
		user: User,
		priceId: string,
		enqueueSnackbar: EnqueueSnackbar
	) {
		dispatch({ type: SET_SUBMITING, payload: true });
		const result = await this.userRepository.goToCheckout(user, priceId);
		if (result.isLeft) {
			enqueueSnackbar(result.getError(), { variant: "error" });
			dispatch({ type: SET_SUBMITING, payload: false });
			return;
		}
		dispatch({ type: SET_SUBMITING, payload: false });
	}

	public async goToCustomerPortal(
		dispatch: UserDispatch,
		customerId: string,
		enqueueSnackbar: EnqueueSnackbar
	) {
		dispatch({ type: SET_SUBMITING, payload: true });
		const result = await this.userRepository.goToCustomerPortal(customerId);
		if (result.isLeft) {
			enqueueSnackbar(result.getError(), { variant: "error" });
			dispatch({ type: SET_SUBMITING, payload: false });
			return;
		}
		dispatch({ type: SET_SUBMITING, payload: false });
	}

	public setShowPlans(dispatch: UserDispatch, value: boolean) {
		dispatch({ type: SET_SHOW_PLAN, payload: value });
	}

	public handleSubscriptionStatus(
		dispatch: UserDispatch,
		user: User,
		enqueueSnackbar: EnqueueSnackbar,
		successCallback: () => void
	): void {
		if (!user.emailVerified) {
			enqueueSnackbar(
				"You need to activate your account. Please check your email inbox.",
				{
					variant: "error",
				}
			);
			return;
		} else if (user.subscription && !user.subscription.isActive()) {
			enqueueSnackbar("Something is wrong with your subscription.", {
				variant: "error",
			});
			return;
		}
		successCallback();
	}
}
