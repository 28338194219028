import { container, InjectionToken } from "tsyringe";
import ClientActions from "../../application/client/actions";
import InputFormActions from "../../application/core/inputForm/actions";
import ThemeActions from "../../application/core/theme/actions";
import GroupActions from "../../application/group/actions";
import NoteActions from "../../application/note/actions";
import ProductActions from "../../application/product/actions";
import UserActions from "../../application/user/actions";
import { IClientRepository } from "../../domain/client/iclient.repository";
import { IGroupRepository } from "../../domain/group/igroup.repository";
import { INoteRepository } from "../../domain/note/inote.repository";
import { IProductRepository } from "../../domain/product/iproduct.repository";
import { IUserRepository } from "../../domain/user/iuser.repository";
import { registerClientDependencies } from "./client_dependencies";
import { registerGroupDependencies } from "./group_dependencies";
import { registerNoteDependencies } from "./note_dependencies";
import { registerProductDependencies } from "./product_dependencies";
import { registerUserDependencies } from "./user_dependencies";

export function sl<T>(dependency: InjectionToken<T>): T {
	return container.resolve<T>(dependency);
}

export function registerDependencies() {
	registerUserDependencies();
	registerClientDependencies();
	registerNoteDependencies();
	registerGroupDependencies();
	registerProductDependencies();

	container.register<ThemeActions>("ThemeActions", {
		useValue: new ThemeActions(),
	});

	container.register<UserActions>("UserActions", {
		useValue: new UserActions(sl<IUserRepository>("IUserRepository")),
	});

	container.register<NoteActions>("NoteActions", {
		useValue: new NoteActions(
			sl<INoteRepository>("INoteRepository"),
			sl<IGroupRepository>("IGroupRepository"),
			sl<IClientRepository>("IClientRepository")
		),
	});

	container.register<ClientActions>("ClientActions", {
		useValue: new ClientActions(
			sl<IClientRepository>("IClientRepository"),
			sl<INoteRepository>("INoteRepository")
		),
	});

	container.register<GroupActions>("GroupActions", {
		useValue: new GroupActions(sl<IGroupRepository>("IGroupRepository")),
	});

	container.register<ProductActions>("ProductActions", {
		useValue: new ProductActions(sl<IProductRepository>("IProductRepository")),
	});

	container.register<InputFormActions>("InputFormActions", {
		useValue: new InputFormActions(),
	});
}
