import GroupPlan from "../../domain/group/models/GroupPlan";
import SessionGroup from "../../domain/group/models/SessionGroup";
import GroupTip, { GroupObject } from "../../domain/note/models/GroupTip";
import {
	SET_FETCHING,
	SET_SUBMITING,
	SET_DELETING,
	SET_GROUPS,
	GroupAction,
	GroupState,
	SET_GROUP,
	SET_GROUP_NAME,
	SET_GROUP_CLIENTS,
	ADD_GROUP,
	UPDATE_GROUP,
	DELETE_GROUP,
	SET_GROUP_PLAN_DATE,
	SET_GROUP_PLAN_INTERVENTION,
	ADD_GROUP_PLAN,
	SET_GROUP_PLAN,
	UPDATE_GROUP_PLAN,
	DELETE_GROUP_PLAN,
	DELETE_GROUP_CLIENT,
	SET_GROUP_PLAN_TIP_CATEGORY,
	SET_GROUP_PLAN_TIP_TITLE,
} from "./misc";

export default function reducer(state: GroupState, action: GroupAction) {
	var groupTips;
	var groupPlans;
	var group: SessionGroup;
	var groups: SessionGroup[];
	var groupTip;
	switch (action.type) {
		case SET_FETCHING:
			return { ...state, isFetching: action.payload } as GroupState;
		case SET_DELETING:
			return { ...state, isDeleting: action.payload } as GroupState;
		case SET_SUBMITING:
			return { ...state, isSubmiting: action.payload } as GroupState;
		case ADD_GROUP:
			return {
				...state,
				sessionGroups: state.sessionGroups
					? [...state.sessionGroups, action.payload]
					: [action.payload],
			} as GroupState;
		case ADD_GROUP_PLAN:
			groupPlans = [...state.group.groupPlans, action.payload];
			groupPlans.sort((a, b) => b.date.getTime() - a.date.getTime());
			group = new SessionGroup({
				...state.group,
				groupPlans: groupPlans,
			});
			groups = state.sessionGroups ?? [];
			const groupIndex = groups.findIndex(
				(item) => item.name === state.group.name
			);
			groups[groupIndex] = group;
			return {
				...state,
				group: group,
				sessionGroups: groups,
			} as GroupState;
		case UPDATE_GROUP_PLAN:
			groupPlans = state.group.groupPlans;
			var planIndex = groupPlans.findIndex(
				(item) => item.id === action.payload.id
			);
			groupPlans[planIndex] = action.payload;
			return {
				...state,
				group: {
					...state.group,
					groupPlans: groupPlans,
				},
			} as GroupState;
		case DELETE_GROUP_PLAN:
			// const { groupId, planId } = action.payload;
			groupPlans = state.group.groupPlans.filter(
				(item) => item.id !== action.payload
			);
			group = new SessionGroup({
				...state.group,
				groupPlans: groupPlans,
			});
			groups = state.sessionGroups ?? [];
			const gIndex = groups.findIndex((item) => item.name === state.group.name);
			groups[gIndex] = group;
			return {
				...state,
				// sessionGroups: state.sessionGroups?.filter(item=>item.)
				group: group,
				sessionGroups: groups,
			} as GroupState;
		case UPDATE_GROUP:
			group = action.payload;
			groups = state.sessionGroups ?? [];
			var index = groups.findIndex((item) => item.id === group.id);
			groups[index] = group;
			return {
				...state,
				sessionGroups: groups,
			} as GroupState;
		case DELETE_GROUP_CLIENT:
			groups = state.sessionGroups ?? [];
			groups = groups.map((item) => {
				const clients = item.clients.filter(
					(client) => client.id !== action.payload
				);
				return new SessionGroup({ ...item, clients: clients });
			});
			return {
				...state,
				sessionGroups: [...groups],
			} as GroupState;
		case DELETE_GROUP:
			return {
				...state,
				sessionGroups: state.sessionGroups?.filter(
					(item) => item.id !== action.payload
				),
			} as GroupState;
		case SET_GROUPS:
			return {
				...state,
				sessionGroups: action.payload ? [...action.payload] : undefined,
			} as GroupState;
		case SET_GROUP:
			return {
				...state,
				group: new SessionGroup({ ...action.payload }),
			} as GroupState;
		case SET_GROUP_NAME:
			return {
				...state,
				group: new SessionGroup({
					...state.group,
					name: action.payload,
				}),
			} as GroupState;
		case SET_GROUP_CLIENTS:
			return {
				...state,
				group: new SessionGroup({ ...state.group, clients: action.payload }),
			} as GroupState;
		case SET_GROUP_PLAN_DATE:
			return {
				...state,
				groupPlan: new GroupPlan({ ...state.groupPlan, date: action.payload }),
			} as GroupState;
		case SET_GROUP_PLAN_TIP_CATEGORY:
			groupTips = state.groupPlan.groupTips;
			groupTip = action.payload.clean
				? GroupTip.empty()
				: groupTips[action.payload.index];
			groupTip.changeIsNewCategory(action.payload.isNew);
			groupTip.category = action.payload.value;
			groupTips[action.payload.index] = groupTip;
			// console.log(`SET_NOTE_GOAL_OBJECTIVE noteGoals`, noteGoals);
			return {
				...state,
				groupPlan: new GroupPlan({ ...state.groupPlan, groupTips: groupTips }),
			} as GroupState;
		case SET_GROUP_PLAN_TIP_TITLE:
			groupTips = state.groupPlan.groupTips;
			// groupTip = action.payload.groupTip;
			groupTip = new GroupTip({
				...groupTips[action.payload.index],
				title: action.payload.title,
				selectedIntervention: action.payload.clean
					? GroupObject.Empty()
					: groupTips[action.payload.index].selectedIntervention,
				interventions: action.payload.interventions,
			});
			groupTip.changeIsNewTitle(action.payload.isNew);
			groupTips[action.payload.index] = groupTip;
			// console.log(`SET_NOTE_GOAL_OBJECTIVE noteGoals`, noteGoals);
			return {
				...state,
				groupPlan: new GroupPlan({ ...state.groupPlan, groupTips: groupTips }),
			} as GroupState;
		case SET_GROUP_PLAN_INTERVENTION:
			groupTips = state.groupPlan.groupTips;
			groupTip = groupTips[action.payload.index];
			groupTip.selectedIntervention = action.payload.value;
			groupTip.changeIsNewIntervention(action.payload.isNew);
			groupTips[action.payload.index] = groupTip;
			// console.log(`SET_NOTE_GOAL_OBJECTIVE noteGoals`, noteGoals);
			return {
				...state,
				groupPlan: new GroupPlan({ ...state.groupPlan, groupTips: groupTips }),
			} as GroupState;
		case SET_GROUP_PLAN:
			return {
				...state,
				groupPlan: new GroupPlan({
					...action.payload,
					groupTips: [...action.payload.groupTips],
				}),
			} as GroupState;
		default: {
			throw new Error(`Unhandled action type: ${action}`);
		}
	}
}
