import { People, Save } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { useHistory, useParams } from "react-router";
import { useClientDispatch, useClientState } from "../../../../../../../../application/client";
import ClientActions from "../../../../../../../../application/client/actions";
import { useNoteDispatch } from "../../../../../../../../application/note";
import NoteActions from "../../../../../../../../application/note/actions";
import { useInjection } from "../../../../../../../../core/dependency_injection";
import { RouteParams } from "../../../../../../../core/constants";
import PanelHeader, {
	PanelHeaderAction,
	PanelHeaderBreadcrumbsRoute,
} from "../../../../../../components/PanelHeader";
import useFindClientById from "../../../../hooks/useFindClientById";
import GoalPlanForm from "../../components/GoalPlanForm";

export default function GoalPlanCreate() {
	const { id } = useParams<RouteParams>();
	const history = useHistory();

	const { selectedClient, goalPlan, isCreating } = useClientState();
	const clientDispatch = useClientDispatch();
	const clientActions = useInjection<ClientActions>("ClientActions");

	const noteDispatch = useNoteDispatch();
	const noteActions = useInjection<NoteActions>("NoteActions");

	const { enqueueSnackbar } = useSnackbar();

	useFindClientById(id);

	const panelActions: PanelHeaderAction[] = [
		{
			label: "Save",
			icon: Save,
			isLoading: isCreating,
			onClick: async () => {
				if (selectedClient.id) {
					if (goalPlan.isValid()) {
						noteActions.setUserGoals(noteDispatch, goalPlan.getNewGoals(), enqueueSnackbar);
						await clientActions.createGoalPlan(
							clientDispatch,
							selectedClient.id,
							goalPlan,
							history,
							enqueueSnackbar
						);
					} else {
						enqueueSnackbar("Form invalid", { variant: "error" });
					}
				}
			},
		},
	];

	const breadcrumbsRoutes: PanelHeaderBreadcrumbsRoute[] = [
		{
			label: "Clients",
			icon: People,
			to: "/app/clients",
		},
		{
			label: `${selectedClient.name}`,
			to: `/app/clients/${selectedClient.id}`,
			// icon: People,
		},
		{
			label: "Create Treatment Plan",
		},
	];
	return (
		<>
			<PanelHeader breadcrumbsRoutes={breadcrumbsRoutes} actions={panelActions} />
			<GoalPlanForm />
		</>
	);
}
