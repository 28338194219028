import { IClientRepository } from "../../domain/client/iclient.repository";
import Client from "../../domain/client/models/Client";
import GoalPlan from "../../domain/client/models/GoalPlan";
import { Result } from "../../domain/core/Result";
import { IClientRemoteDatasource } from "./datasources/remote";
import { ClientDto } from "./dtos/ClientDto";
import { GoalPlanDto } from "./dtos/GoalPlanDto";

export default class ClientRepository implements IClientRepository {
  private remoteDatasource: IClientRemoteDatasource;

  public constructor(remoteDatasource: IClientRemoteDatasource) {
    this.remoteDatasource = remoteDatasource;
  }
  async getGoalPlanByDate(clientId: string, date: Date): Promise<Result<GoalPlan>> {
    try {
      const dto = await this.remoteDatasource.getGoalPlanByDate(clientId, date);
      return Result.rigth(dto.toDomain());
    } catch (error: any) {
      console.log(`error`, error);
      return Result.left(error.message);
    }
  }
  async updateGoalPlan(clientId: string, goalPlan: GoalPlan): Promise<Result<void>> {
    try {
      // console.log(`client`, client);
      await this.remoteDatasource.updateGoalPlan(clientId, GoalPlanDto.fromDomain(goalPlan));
      return Result.rigth("Update successfully");
    } catch (error: any) {
      console.log(`error`, error);
      return Result.left(error.message);
    }
  }
  async deleteGoalPlan(clientId: string, goalPlanId: string): Promise<Result<void>> {
    try {
      // console.log(`client`, client);
      await this.remoteDatasource.deleteGoalPlan(clientId, goalPlanId);
      return Result.rigth("Delete successfully");
    } catch (error: any) {
      console.log(`error`, error);
      return Result.left(error.message);
    }
  }

  async createGoalPlan(clientId: string, goalPlan: GoalPlan): Promise<Result<GoalPlan>> {
    try {
      // console.log(`client`, client);
      const dto = await this.remoteDatasource.createGoalPlan(
        clientId,
        GoalPlanDto.fromDomain(goalPlan)
      );
      // console.log(`clientDto`, clientDto);
      return Result.rigth(dto.toDomain());
    } catch (error: any) {
      console.log(`error`, error);
      return Result.left(error.message);
    }
  }

  async getClients(): Promise<Result<Client[]>> {
    try {
      const clientDtos = await this.remoteDatasource.getClients();
      // console.log(`clientDto`, clientDtos);
      return Result.rigth<Client[]>(clientDtos.map((item) => item.toDomain()));
    } catch (error: any) {
      console.log(`error`, error);
      return Result.left(error.message);
    }
  }
  async create(client: Client): Promise<Result<Client>> {
    try {
      // console.log(`client`, client);
      const clientDto = await this.remoteDatasource.create(ClientDto.fromDomain(client));
      // console.log(`clientDto`, clientDto);
      return Result.rigth<Client>(clientDto.toDomain());
    } catch (error: any) {
      console.log(`error`, error);
      return Result.left(error.message);
    }
  }
  async update(client: Client): Promise<Result<void>> {
    try {
      // console.log(`client`, client);
      await this.remoteDatasource.update(ClientDto.fromDomain(client));
      return Result.rigth("Update successfully");
    } catch (error: any) {
      console.log(`error`, error);
      return Result.left(error.message);
    }
  }
  async delete(id: string): Promise<Result<void>> {
    try {
      await this.remoteDatasource.delete(id);
      return Result.rigth("Client deleted successfully");
    } catch (error: any) {
      console.log(`error`, error);
      return Result.left(error.message);
    }
  }
}
