import React from "react";
import { initialValues, NoteDispatch } from "./misc";
import reducer from "./reducer";

var NoteStateContext = React.createContext(initialValues);
var NoteDispatchContext = React.createContext<NoteDispatch>(() => null);

function NoteProvider({ children }: any) {
  var [state, dispatch] = React.useReducer(reducer, initialValues);
  return (
    <NoteStateContext.Provider value={state}>
      <NoteDispatchContext.Provider value={dispatch}>
        {children}
      </NoteDispatchContext.Provider>
    </NoteStateContext.Provider>
  );
}

function useNoteState() {
  var context = React.useContext(NoteStateContext);
  if (context === undefined) {
    throw new Error("useNoteState must be used within a NoteProvider");
  }
  return context;
}

function useNoteDispatch() {
  var context = React.useContext(NoteDispatchContext);
  if (context === undefined) {
    throw new Error("useNoteDispatch must be used within a NoteProvider");
  }
  return context;
}

export { NoteProvider, useNoteState, useNoteDispatch };
