import { makeStyles } from "@material-ui/core";
export default makeStyles((theme) => ({
	root: {
		// backgroundColor: theme.palette.background.paper,
		// backgroundColor: "grey",
		borderRadius: "10px",
		padding: "20px",
		width: "100%",
		marginTop: "10px",
	},
	accordionRoot: {
		// backgroundColor: "transparent",
		boxShadow: "none",
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
}));
