import {
	CloudDownload,
	Edit,
	Close,
	Visibility,
	CloudDownloadOutlined,
} from "@material-ui/icons";
import { useHistory, useParams } from "react-router";
import { useClientState } from "../../../../../../../../../../application/client";
import {
	useNoteDispatch,
	useNoteState,
} from "../../../../../../../../../../application/note";
import NoteActions from "../../../../../../../../../../application/note/actions";
import { useInjection } from "../../../../../../../../../../core/dependency_injection";
import Note from "../../../../../../../../../../domain/note/models/Note";
import { EnhancedTable } from "../../../../../../../../../core/components/Table";
import { Column } from "../../../../../../../../../core/components/Table/EnhancedTable";
import { RouteParams } from "../../../../../../../../../core/constants";
import useOpenable from "../../../../../../../../../core/hooks/useOpenable";
import NotePreview from "../../../../../Note/components/NotePreview";
import {
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
} from "@material-ui/core";
import { getDocument } from "../../../../../../../../core/helpers";
import {
	useUserDispatch,
	useUserState,
} from "../../../../../../../../../../application/user";
import { useSnackbar } from "notistack";
import UserActions from "../../../../../../../../../../application/user/actions";

export default function Notes() {
	const { id } = useParams<RouteParams>();

	const history = useHistory();

	const { user } = useUserState();
	const userDispatch = useUserDispatch();
	const userActions = useInjection<UserActions>("UserActions");

	const { selectedClient } = useClientState();

	const { note } = useNoteState();
	const noteDispatch = useNoteDispatch();
	const noteActions = useInjection<NoteActions>("NoteActions");

	const [open, handleOpen, handleClose] = useOpenable();

	const { enqueueSnackbar } = useSnackbar();

	const columns: Column[] = [
		{
			id: "startDate",
			label: "Service Date",
			minWidth: 100,
			align: "left",
			format: (value: Date) => value.toLocaleDateString("en-US"),
		},
		{
			id: "startDate",
			label: "Begin",
			minWidth: 100,
			align: "left",
			format: (value: Date) => value.toLocaleTimeString("en"),
		},
		{
			id: "endDate",
			label: "End",
			minWidth: 100,
			align: "left",
			format: (value: Date) => value.toLocaleTimeString("en"),
		},
		{
			id: "totalUnits",
			label: "Total Units",
			minWidth: 100,
			align: "left",
		},
		{
			label: "Preview",
			minWidth: 10,
			align: "center",
			icon: <Visibility />,
			onButtonClick: (note: Note) => {
				userActions.handleSubscriptionStatus(
					userDispatch,
					user,
					enqueueSnackbar,
					() => {
						noteActions.setEditNote(noteDispatch, note);
						handleOpen();
					}
				);
			},
		},
		{
			label: "Download",
			minWidth: 10,
			align: "center",
			icon: <CloudDownload />,
			onButtonClick: (note: Note) => {
				userActions.handleSubscriptionStatus(
					userDispatch,
					user,
					enqueueSnackbar,
					() => {
						getDocument(user, note);
					}
				);
			},
		},
		{
			label: "Edit",
			minWidth: 10,
			align: "center",
			icon: <Edit />,
			onButtonClick: (note: Note) => {
				userActions.handleSubscriptionStatus(
					userDispatch,
					user,
					enqueueSnackbar,
					() => {
						noteActions.setEditNote(noteDispatch, note);
						history.push(`/app/clients/${id}/progress-note/${note.id}`);
					}
				);
			},
		},
	];

	return (
		<div>
			{selectedClient.notes?.length === 0 ? (
				<div>No registered notes </div>
			) : (
				<EnhancedTable
					data={selectedClient.notes ?? []}
					columns={columns}
					dense={true}
					rowsPerPage={[5, 10, 50]}
					// onClickCallback={(note: Note) => {
					// 	// console.log(`onClick item:`, contractor);
					// 	// clientActions.setSelectedClient(clientDispatch, client);
					// 	// history.push(`/app/clients/${client.id}`);
					// 	noteActions.setEditNote(noteDispatch, note);
					// 	history.push(`/app/clients/${id}/progress-note/${note.id}`);
					// }}
				/>
			)}
			{/* <NoteDialog open={open} handleClose={handleClose} content={<NotePreview />} /> */}
			{/* <div style={{ display: "none" }}>
				<div ref={componentRef}>
					<NotePreview />
				</div>
			</div> */}
			<Dialog
				open={open}
				onClose={handleClose}
				scroll={"paper"}
				aria-labelledby="scroll-dialog-title"
				aria-describedby="scroll-dialog-description"
				maxWidth="md"
			>
				<DialogTitle
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-end",
					}}
				>
					<IconButton
						style={{
							marginRight: "10px",
						}}
						onClick={() => {
							getDocument(user, note);
						}}
					>
						<CloudDownloadOutlined />
					</IconButton>
					<IconButton onClick={handleClose}>
						<Close />
					</IconButton>
				</DialogTitle>
				<DialogContent dividers>
					<NotePreview />
				</DialogContent>
				{/* <DialogActions>
				<Button onClick={handleClose} color="primary">
					Back
				</Button>
			</DialogActions> */}
			</Dialog>
		</div>
	);
}
