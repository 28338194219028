import { makeStyles } from "@material-ui/core";
export default makeStyles((theme) => ({
	root: {
		padding: "20px 24vw",
		[theme.breakpoints.down("sm")]: {
			padding: "20px",
		},
	},
	paragraph: {
		padding: "20px",
	},
	marginTop: {
		marginTop: "10px",
	},
}));
