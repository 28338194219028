import { useSnackbar } from "notistack";
import React from "react";
import { Route, Switch } from "react-router";
import { useGroupDispatch, useGroupState } from "../../../../application/group";
import GroupActions from "../../../../application/group/actions";
import { useInjection } from "../../../../core/dependency_injection";
import { PanelCircularProgress } from "../../../core/components/Progress";
import GroupCreate from "./pages/Group/GroupCreate";
import GroupDetails from "./pages/Group/GroupDetails";
import GroupPlan from "./pages/GroupPlan";
import GroupsPage from "./pages/Group";
import GroupPlanCreate from "./pages/GroupPlan/GroupPlanCreate";
import GroupPlanEdit from "./pages/GroupPlan/GroupPlanEdit";

export default function Groups() {
  const { sessionGroups, isFetching } = useGroupState();
  const groupDispatch = useGroupDispatch();
  const groupActions = useInjection<GroupActions>("GroupActions");

  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    if (!sessionGroups) {
      groupActions.getAllSessionGroups(groupDispatch, enqueueSnackbar);
    } // eslint-disable-next-line
  }, []);

  return !isFetching ? (
    <Switch>
      <Route exact path="/app/groups/create" component={GroupCreate} />
      <Route path="/app/groups/:id/group-plans/create" component={GroupPlanCreate} />
      <Route path="/app/groups/:id/group-plans/:planId" component={GroupPlanEdit} />
      <Route path="/app/groups/:id/group-plans" component={GroupPlan} />
      <Route path="/app/groups/:id" component={GroupDetails} />
      <Route path="/app/groups" component={GroupsPage} />
    </Switch>
  ) : (
    <PanelCircularProgress />
  );
}
