import { Card } from "@material-ui/core";
import { Add, Delete, Edit, People } from "@material-ui/icons";
import EditClientModalContent from "../../components/EditClientModalContent";
import useOpenable from "../../../../../core/hooks/useOpenable";
import { useHistory, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useInjection } from "../../../../../../core/dependency_injection/ioc.react";
import PanelHeader, {
	PanelHeaderAction,
	PanelHeaderBreadcrumbsRoute,
} from "../../../../components/PanelHeader";
import WrappedModal from "../../../../../core/components/Modal";
import {
	useClientDispatch,
	useClientState,
} from "../../../../../../application/client";
import ClientActions from "../../../../../../application/client/actions";
import Client from "../../../../../../domain/client/models/Client";
import { PanelCircularProgress } from "../../../../../core/components/Progress";
import NoteActions from "../../../../../../application/note/actions";
import { useNoteDispatch } from "../../../../../../application/note";
import ClientHighlighs from "./components/ClientHighlighs";
import ClientTabs from "./components/ClientTabs";
import {
	useUserDispatch,
	useUserState,
} from "../../../../../../application/user";
import Company from "../../../../../../domain/user/models/Company";
import { RouteParams } from "../../../../../core/constants";
import { ConfirmationDialog } from "../../../../../core/components/Dialog";
import useFindClientById from "../../hooks/useFindClientById";
import GoalPlan from "../../../../../../domain/client/models/GoalPlan";
import { useGroupDispatch } from "../../../../../../application/group";
import UserActions from "../../../../../../application/user/actions";
import { newNoteDate } from "../../../../../../core/helpers";

export default function ClientDetails(props: any) {
	// let { path, url } = useRouteMatch();
	const { id } = useParams<RouteParams>();
	const history = useHistory();

	// const { selectedContractor, contractors, isSubmiting } = useContractorState();
	// const contractorDispatch = useContractorDispatch();

	// const contractorActions =
	//   useInjection<ContractorActions>("ContractorActions");
	const { user } = useUserState();
	const userDispatch = useUserDispatch();
	const userActions = useInjection<UserActions>("UserActions");

	const { selectedClient, isFetching, isDeleting } = useClientState();
	const clientDispatch = useClientDispatch();
	const clientActions = useInjection<ClientActions>("ClientActions");

	const noteDispatch = useNoteDispatch();
	const noteActions = useInjection<NoteActions>("NoteActions");

	const groupDispatch = useGroupDispatch();

	const [open, handleOpen, handleClose] = useOpenable();
	const [openDialog, handleOpenDialog, handleCloseDialog] = useOpenable();
	// const [openEditDocument, handleOpenEditDocument, handleCloseEditDocument] =
	//   useOpenable();

	const { enqueueSnackbar } = useSnackbar();

	// console.log(`selectedContractor`, selectedContractor);

	useFindClientById(id);
	// useEffect(() => {
	//   if (clients) {
	//     const client = clients.find((item) => item.id === id);
	//     clientActions.setSelectedClient(clientDispatch, client ?? Client.empty());
	//   } // eslint-disable-next-line
	// }, [clients]);

	// document.title = `PSR Notes | Contractors ${
	//   selectedContractor?.name ? "| " + selectedContractor?.name : ""
	// }`;

	// console.log(`selectedClient`, selectedClient);

	const panelActions: PanelHeaderAction[] = [
		{
			label: "Add note...",
			icon: Add,
			onClick: () => {
				userActions.handleSubscriptionStatus(
					userDispatch,
					user,
					enqueueSnackbar,
					() => {
						noteActions.initNote(noteDispatch, {
							client: selectedClient,
							company: user.company ?? Company.empty(),
							date: newNoteDate(),
						});
						history.push(`/app/clients/${id}/progress-note/create`);
					}
				);
			},
		},
		{
			label: "Add Treatment Plan...",
			icon: Add,
			onClick: () => {
				userActions.handleSubscriptionStatus(
					userDispatch,
					user,
					enqueueSnackbar,
					() => {
						clientActions.setGoalPlan(clientDispatch, GoalPlan.empty());
						history.push(`/app/clients/${id}/goals-objectives/create`);
					}
				);
			},
		},
		{
			label: "Update...",
			icon: Edit,
			onClick: () => {
				userActions.handleSubscriptionStatus(
					userDispatch,
					user,
					enqueueSnackbar,
					() => {
						handleOpen();
					}
				);
			},
		},
		{
			label: "Delete...",
			isLoading: isDeleting,
			color: "danger",
			icon: Delete,
			onClick: () => {
				userActions.handleSubscriptionStatus(
					userDispatch,
					user,
					enqueueSnackbar,
					() => {
						handleOpenDialog();
					}
				);
			},
		},
	];

	const breadcrumbsRoutes: PanelHeaderBreadcrumbsRoute[] = [
		{
			label: "Clients",
			icon: People,
			to: "/app/clients",
		},
		{
			label: `${selectedClient.name}`,
			// icon: People,
		},
	];

	return !isFetching ? (
		<>
			<PanelHeader
				breadcrumbsRoutes={breadcrumbsRoutes}
				actions={panelActions}
			/>
			<Card
				style={{
					position: "relative",
					overflow: "visible",
					marginTop: "10px",
				}}
			>
				<ClientHighlighs />
				<ClientTabs />
			</Card>
			<WrappedModal open={open} handleClose={handleClose}>
				<EditClientModalContent
					isEditing
					onSuccessCallback={(client: Client) => {
						// console.log(`contractor`, contractor);
						clientActions.update(
							clientDispatch,
							client,
							enqueueSnackbar,
							handleClose
						);
					}}
				/>
			</WrappedModal>
			<ConfirmationDialog
				open={openDialog}
				handleClose={handleCloseDialog}
				title="Delete this client?"
				content="Are you sure do you want to delete this client."
				onSuccessCallback={async () => {
					handleCloseDialog();
					clientActions.delete(
						clientDispatch,
						groupDispatch,
						selectedClient.id ?? "",
						enqueueSnackbar,
						history
					);
				}}
			/>
		</>
	) : (
		<PanelCircularProgress />
	);
}
