import Note from "../../../domain/note/models/Note";
import firebase from "firebase";
import Client from "../../../domain/client/models/Client";
import { GoalDto } from "./GoalDto";
import { CompanyDto } from "../../user/dtos/CompanyDto";
import { TreatmentDto } from "./TreatmentDto";
import { GroupTipDto } from "./GroupTipDto";
import { UpdateOfProgressDto } from "./UpdateOfProgressDto";

export interface INoteDtoProps {
	id?: string;
	case: string;
	clientId?: string;
	serviceCode: string;
	setting: string;
	diagnosis: string;
	clientName: string;
	totalUnits: number;
	startDate: any;
	endDate: any;
	goals: any[];
	company: CompanyDto;
	treatments: TreatmentDto[];
	groupTips: GroupTipDto[];
	updateOfProgress: UpdateOfProgressDto;
}

export class NoteDto {
	id?: string;
	clientId?: string;
	case: string;
	serviceCode: string;
	setting: string;
	diagnosis: string;
	clientName: string;
	totalUnits: number;
	startDate: Date;
	endDate: Date;
	company: CompanyDto;
	goals: GoalDto[];
	treatments: TreatmentDto[];
	groupTips: GroupTipDto[];
	updateOfProgress: UpdateOfProgressDto;

	constructor(props: INoteDtoProps) {
		this.id = props.id;
		this.clientId = props.clientId;
		// this.client = new ClientDto({ ...props.client });
		this.clientName = props.clientName;
		this.setting = props.setting;
		this.serviceCode = props.serviceCode;
		this.case = props.case;
		this.diagnosis = props.diagnosis;
		this.totalUnits = props.totalUnits;
		this.startDate = props.startDate;
		this.endDate = props.endDate;
		this.company = new CompanyDto({ ...props.company });
		this.goals = props.goals?.map((item) => new GoalDto({ ...item }));
		this.treatments = props.treatments?.map(
			(item) => new TreatmentDto({ ...item })
		);
		this.groupTips = props.groupTips?.map(
			(item) => new GroupTipDto({ ...item })
		);
		this.updateOfProgress = new UpdateOfProgressDto({
			...props.updateOfProgress,
		});
	}

	public static fromDomain(user: Note): NoteDto {
		return new NoteDto({
			id: user.id,
			clientId: user.client.id,
			// client: ClientDto.fromDomain(user.client),
			clientName: user.client.name,
			setting: user.client.setting,
			diagnosis: user.client.diagnosis,
			serviceCode: user.client.serviceCode,
			case: user.client.case,
			totalUnits: user.totalUnits,
			startDate: user.startDate,
			endDate: user.endDate,
			company: CompanyDto.fromDomain(user.company),
			goals: user.goals.map((item) => GoalDto.fromDomain(item)),
			treatments: user.treatments.map((item) => TreatmentDto.fromDomain(item)),
			groupTips: user.groupTips.map((item) => GroupTipDto.fromDomain(item)),
			updateOfProgress: UpdateOfProgressDto.fromDomain(user.updateOfProgress),
		});
	}

	public static fromFirestore(
		doc: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
	): NoteDto {
		const data = doc.data();
		return new NoteDto({
			id: doc.id,
			case: data?.case,
			clientId: data?.clientId,
			setting: data?.setting,
			diagnosis: data?.diagnosis,
			serviceCode: data?.serviceCode,
			clientName: data?.clientName,
			totalUnits: data?.totalUnits,
			startDate: data?.startDate.toDate(),
			endDate: data?.endDate.toDate(),
			company: data?.company,
			goals: data?.goals.map((item: any) => new GoalDto({ ...item })),
			treatments: data?.treatments?.map(
				(item: any) => new TreatmentDto({ ...item })
			),
			groupTips: data?.groupTips?.map(
				(item: any) => new GroupTipDto({ ...item })
			),
			updateOfProgress: UpdateOfProgressDto.fromJson(data?.updateOfProgress),
		});
	}

	public toJson(): any {
		return {
			clientName: this.clientName,
			clientId: this.clientId,
			case: this.case,
			setting: this.setting,
			serviceCode: this.serviceCode,
			diagnosis: this.diagnosis,
			totalUnits: this.totalUnits,
			startDate: this.startDate,
			endDate: this.endDate,
			company: this.company.toJson(),
			goals: this.goals.map((item) => item.toJson()),
			treatments: this.treatments.map((item) => item.toNoteJson()),
			groupTips: this.groupTips.map((item) => item.toJson()),
			updateOfProgress: this.updateOfProgress.toJson(),
		};
	}

	public toDomain(): Note {
		return new Note({
			id: this.id,
			client: new Client({
				id: this.clientId,
				case: this.case,
				setting: this.setting,
				name: this.clientName,
				serviceCode: this.serviceCode,
				diagnosis: this.diagnosis,
			}),
			totalUnits: this.totalUnits,
			startDate: this.startDate,
			endDate: this.endDate,
			company: this.company.toDomain(),
			goals: this.goals?.map((item) => item.toDomain()),
			treatments: this.treatments?.map((item) => item.toDomain()),
			groupTips: this.groupTips?.map((item) => item.toDomain()),
			updateOfProgress: this.updateOfProgress.toDomain(),
		});
	}
}
