import { Route, Switch } from "react-router";
// import { useNoteState } from "../../../../../../application/note";
// import { PanelCircularProgress } from "../../../../../core/components/Progress";
import NoteCreate from "./pages/NoteCreate";
import NoteEdit from "./pages/NoteEdit";

export default function Note() {
  // const { isFetching } = useNoteState();

  return (
    <div>
      <Switch>
        <Route path="/app/clients/:id/progress-note/create" component={NoteCreate} />
        <Route path="/app/clients/:id/progress-note/:noteId" component={NoteEdit} />
      </Switch>
    </div>
    // ) : (
    //   <PanelCircularProgress />
  );
}
