import { SET_FETCHING, SET_PRODUCTS } from "./misc";
import { EnqueueSnackbar } from "../../domain/core/utils";
import { IProductRepository } from "../../domain/product/iproduct.repository";

export default class ProductActions {
	private productRepository: IProductRepository;

	constructor(ProductRepository: IProductRepository) {
		this.productRepository = ProductRepository;
	}

	public async getProducts(dispatch: any, enqueueSnackbar: EnqueueSnackbar) {
		dispatch({ type: SET_FETCHING, payload: true });
		const result = await this.productRepository.getProducts();
		// console.log(`checkAuthenticated result`, result);
		if (result.isLeft) {
			enqueueSnackbar(result.getError(), { variant: "error" });
			dispatch({ type: SET_FETCHING, payload: false });
			return;
		}
		const products = result.getValue();
		dispatch({ type: SET_PRODUCTS, payload: products });
		dispatch({ type: SET_FETCHING, payload: false });
	}
}
