import SessionGroup from "../../../domain/group/models/SessionGroup";
// import firebase from "firebase";
import { ClientDto } from "../../client/dtos/ClientDto";
import { GroupPlanDto } from "./GroupPlanDto";

export interface ISessionGroupDtoProps {
  id?: string;
  name: string;
  clients: ClientDto[];
  groupPlans: GroupPlanDto[];
}

export class SessionGroupDto {
  id?: string;
  name: string;
  clients: ClientDto[];
  groupPlans: GroupPlanDto[];

  constructor(props: ISessionGroupDtoProps) {
    this.id = props.id;
    this.name = props.name;
    this.clients = props.clients;
    this.groupPlans = props.groupPlans;
  }

  public static fromDomain(model: SessionGroup): SessionGroupDto {
    // console.log(`fromDomain client.goals`, client.goals);
    return new SessionGroupDto({
      id: model.id,
      name: model.name,
      clients: model.clients.map((item) => ClientDto.fromDomain(item)),
      groupPlans: model.groupPlans.map((item) => GroupPlanDto.fromDomain(item)),
    });
  }

  // public static async fromFirestore(
  //   doc: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
  // ): Promise<SessionGroupDto> {
  //   const data = doc.data();
  //   // console.log(`data`, data);
  //   return new SessionGroupDto({
  //     id: doc.id,
  //     name: data?.name,
  //     clients: data?.clients,
  //   });
  // }

  public toJson(): any {
    return {
      name: this.name,
      clients: this.clients.map((item) => item.id),
    };
  }

  public toDomain(): SessionGroup {
    return new SessionGroup({
      id: this.id,
      name: this.name,
      clients: this.clients.map((item) => item.toDomain()),
      groupPlans: this.groupPlans.map((item) => item.toDomain()),
    });
  }
}
