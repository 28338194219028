import React from "react";
import Client from "../../domain/client/models/Client";
import GroupPlan from "../../domain/group/models/GroupPlan";
import SessionGroup from "../../domain/group/models/SessionGroup";
import { GroupObject } from "../../domain/note/models/GroupTip";

export const SET_FETCHING = "SET_FETCHING";
export const SET_SUBMITING = "SET_SUBMITING";
export const SET_DELETING = "SET_DELETING";
export const SET_GROUPS = "SET_GROUPS";
export const ADD_GROUP = "ADD_GROUP";
export const UPDATE_GROUP = "UPDATE_GROUP";
export const DELETE_GROUP = "DELETE_GROUP";
export const DELETE_GROUP_CLIENT = "DELETE_GROUP_CLIENT";
export const SET_GROUP = "SET_GROUP";
export const SET_GROUP_NAME = "SET_GROUP_NAME";
export const SET_GROUP_CLIENTS = "SET_GROUP_CLIENTS";
export const SET_GROUP_PLAN = "SET_GROUP_PLAN";
export const SET_GROUP_PLAN_DATE = "SET_GROUP_PLAN_DATE";
export const SET_GROUP_PLAN_TIP_TITLE = "SET_GROUP_PLAN_TIP_TITLE";
export const SET_GROUP_PLAN_TIP_CATEGORY = "SET_GROUP_PLAN_TIP_CATEGORY";
export const SET_GROUP_PLAN_INTERVENTION = "SET_GROUP_PLAN_INTERVENTION";
export const ADD_GROUP_PLAN = "ADD_GROUP_PLAN";
export const UPDATE_GROUP_PLAN = "UPDATE_GROUP_PLAN";
export const DELETE_GROUP_PLAN = "DELETE_GROUP_PLAN";

export const initialValues: GroupState = {
	sessionGroups: undefined,
	group: SessionGroup.empty(),
	groupPlan: GroupPlan.empty(),
	isFetching: false,
	isSubmiting: false,
	isDeleting: false,
};

export type GroupDispatch = React.Dispatch<GroupAction>;

export type GroupState = {
	sessionGroups: SessionGroup[] | undefined;
	group: SessionGroup;
	groupPlan: GroupPlan;
	isFetching: boolean;
	isSubmiting: boolean;
	isDeleting: boolean;
};

export type SetGroupPlanTipTitle = {
	index: number;
	title: string;
	interventions: GroupObject[];
	isNew: boolean;
	clean: boolean;
};

export type SetGroupPlanTipCategory = {
	index: number;
	value: string;
	isNew: boolean;
	clean: boolean;
};

export type SetGroupPlanIntervention = {
	index: number;
	value: GroupObject;
	isNew: boolean;
};

export type GroupAction =
	| {
			type: typeof DELETE_GROUP_CLIENT;
			payload: string;
	  }
	| {
			type: typeof DELETE_GROUP_PLAN;
			payload: string;
	  }
	| {
			type: typeof UPDATE_GROUP_PLAN;
			payload: GroupPlan;
	  }
	| {
			type: typeof SET_GROUP_PLAN;
			payload: GroupPlan;
	  }
	| {
			type: typeof ADD_GROUP_PLAN;
			payload: GroupPlan;
	  }
	| {
			type: typeof SET_GROUP_PLAN_INTERVENTION;
			payload: SetGroupPlanIntervention;
	  }
	| {
			type: typeof SET_GROUP_PLAN_TIP_TITLE;
			payload: SetGroupPlanTipTitle;
	  }
	| {
			type: typeof SET_GROUP_PLAN_TIP_CATEGORY;
			payload: SetGroupPlanTipCategory;
	  }
	| {
			type: typeof SET_GROUP_PLAN_DATE;
			payload: Date;
	  }
	| {
			type: typeof SET_FETCHING;
			payload: boolean;
	  }
	| {
			type: typeof SET_SUBMITING;
			payload: boolean;
	  }
	| {
			type: typeof SET_DELETING;
			payload: boolean;
	  }
	| {
			type: typeof SET_GROUP;
			payload: SessionGroup;
	  }
	| {
			type: typeof ADD_GROUP;
			payload: SessionGroup;
	  }
	| {
			type: typeof UPDATE_GROUP;
			payload: SessionGroup;
	  }
	| {
			type: typeof DELETE_GROUP;
			payload: string;
	  }
	| {
			type: typeof SET_GROUP_NAME;
			payload: string;
	  }
	| {
			type: typeof SET_GROUP_CLIENTS;
			payload: Client[];
	  }
	| {
			type: typeof SET_GROUPS;
			payload: SessionGroup[] | undefined;
	  };
