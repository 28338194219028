import { makeStyles } from "@material-ui/core";
export default makeStyles((theme) => ({
	buttonsContainer: {
		display: "flex",
		justifyContent: "flex-end",
		margin: "20px 10px",
		[theme.breakpoints.down("sm")]: {
			// flexDirection: "column",
			justifyContent: "center",
			margin: "50px 10px",
		},
	},
	divider: {
		width: "2px",
		backgroundColor: "black",
		margin: "5px 8px",
	},
	progress: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		height: "70vh",
	},
	plansContainer: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-evenly",
		alignItems: "center",
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column",
			justifyContent: "center",
		},
	},
}));
