import { Group, Save } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { useHistory, useParams } from "react-router";
import { useGroupDispatch, useGroupState } from "../../../../../../../application/group";
import GroupActions from "../../../../../../../application/group/actions";
import { useNoteDispatch } from "../../../../../../../application/note";
import NoteActions from "../../../../../../../application/note/actions";
import { useInjection } from "../../../../../../../core/dependency_injection";
import { RouteParams } from "../../../../../../core/constants";
import PanelHeader, {
	PanelHeaderAction,
	PanelHeaderBreadcrumbsRoute,
} from "../../../../../components/PanelHeader";
import GroupPlanForm from "../../../components/GroupPlanForm";
import useFindGroupById from "../../../hooks/useFindGroupById";

export default function GroupPlanCreate() {
	const { id } = useParams<RouteParams>();
	const history = useHistory();

	const { group, groupPlan, isSubmiting } = useGroupState();
	const groupDispatch = useGroupDispatch();
	const groupActions = useInjection<GroupActions>("GroupActions");

	const noteDispatch = useNoteDispatch();
	const noteActions = useInjection<NoteActions>("NoteActions");

	const { enqueueSnackbar } = useSnackbar();

	useFindGroupById(id);

	const breadcrumbsRoutes: PanelHeaderBreadcrumbsRoute[] = [
		{
			label: "Groups",
			icon: Group,
			to: "/app/groups",
		},
		{
			label: `${group.name}`,
			to: `/app/groups/${group.id}`,
			// icon: People,
		},
		{
			label: `Group Titles`,
			to: `/app/groups/${group.id}/group-plans`,
		},
		{
			label: `Create`,
		},
	];

	const panelActions: PanelHeaderAction[] = [
		{
			label: "Save",
			icon: Save,
			isLoading: isSubmiting,
			onClick: () => {
				if (group.id) {
					if (groupPlan.isValid()) {
						noteActions.setUserGroupTips(
							noteDispatch,
							groupPlan.getNewGroupTips(),
							enqueueSnackbar
						);
						groupActions.createGroupPlan(
							groupDispatch,
							group.id,
							groupPlan,
							history,
							enqueueSnackbar
						);
					} else {
						enqueueSnackbar("Form invalid", { variant: "error" });
					}
				}
			},
		},
	];

	return (
		<div>
			<PanelHeader breadcrumbsRoutes={breadcrumbsRoutes} actions={panelActions} />
			<GroupPlanForm />
		</div>
	);
}
