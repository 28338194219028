import { ThemeOptions } from "@material-ui/core";

export const primary = "#26A137";
export const secondary = "#0B2449";
// export const primary = "#009688";
// export const secondary = "#22273d";
// export const primary = "#0081c3";
// export const secondary = "#83ad2d";
// export const primary = "#83ad2d";
// export const secondary = "#0081c3";

export const common: ThemeOptions = {
	typography: {
		fontFamily: '"Nunito", sans-serif',
		h1: {
			fontSize: "4rem",
		},
		h2: {
			fontSize: "3rem",
		},
		h3: {
			fontSize: "2rem",
		},
		h4: {
			fontSize: "1.5rem",
		},
		h5: {
			fontSize: "1.285rem",
		},
		h6: {
			fontSize: "1.142rem",
		},
	},
	// props: {
	// MuiTextField: {
	// 	size: "small",
	// },
	// MuiSelect: {
	// 	MenuProps: {
	// 		classes: {
	// 			paper: classNames({
	// 				maxWidth: "200px",
	// 				overflowWrap: "break-word",
	// 			}),
	// 		},
	// 	},
	// },
	// },
	overrides: {
		MuiMenu: {
			paper: {
				maxWidth: "200px",
				overflowWrap: "normal",
			},
		},
		MuiMenuItem: {
			root: {
				whiteSpace: "unset",
				wordBreak: "normal",
			},
		},
		MuiButton: {
			root: {
				borderRadius: "20px",
				textTransform: "none",
			},
		},
		MuiCard: {
			root: {
				borderRadius: "10px",
			},
		},
	},
};
