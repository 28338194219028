type PriceProps = {
	id: string;
	active: boolean;
	currency: string;
	description?: string | null;
	interval: string;
	interval_count: number;
	trial_period_days: number;
	type: string;
	unit_amount: number;
};

export default class Price {
	id: string;
	active: boolean;
	currency: string;
	description?: string | null;
	interval: string;
	interval_count: number;
	trial_period_days: number;
	type: string;
	unit_amount: number;

	constructor(props: PriceProps) {
		this.id = props.id;
		this.active = props.active;
		this.currency = props.currency;
		this.interval = props.interval;
		this.interval_count = props.interval_count;
		this.trial_period_days = props.trial_period_days;
		this.type = props.type;
		this.unit_amount = props.unit_amount;
	}

	public static Empty(): Price {
		return new Price({
			id: "",
			active: false,
			currency: "",
			interval: "",
			interval_count: -1,
			trial_period_days: -1,
			type: "",
			unit_amount: -1,
		});
	}
}
