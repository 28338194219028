import React, { useEffect } from "react";
import {
	KeyboardDatePicker,
	KeyboardTimePicker,
	MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { Card, Grid, TextField } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
	useNoteDispatch,
	useNoteState,
} from "../../../../../../../../../../application/note";
import useStyles from "../../styles";
import NoteActions from "../../../../../../../../../../application/note/actions";
import { useInjection } from "../../../../../../../../../../core/dependency_injection";
import { getTotalUnits } from "../../../../../../../../core/helpers";

export default function SessionInfoAccordionContent() {
	const classes = useStyles();

	const { note } = useNoteState();
	const noteDispatch = useNoteDispatch();
	const noteActions = useInjection<NoteActions>("NoteActions");

	useEffect(() => {
		const units = getTotalUnits(note.startDate, note.endDate);
		noteActions.setNoteTotalUnits(noteDispatch, units > 0 ? units : 0); // eslint-disable-next-line
	}, [note.startDate, note.endDate]);

	return (
		<Card className={classes.card}>
			<Grid container>
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<Grid item container justifyContent="flex-start" lg={6} xs={12}>
						<KeyboardDatePicker
							variant="inline"
							inputVariant="filled"
							className={classes.item}
							format="MM/dd/yyyy"
							margin="normal"
							id="date-picker-inline"
							label="Service Date"
							value={note.startDate}
							onChange={(date) => {
								noteActions.setNoteDate(noteDispatch, date as Date);
							}}
							KeyboardButtonProps={{
								"aria-label": "change date",
							}}
						/>
					</Grid>
					<Grid item container justifyContent="flex-start" lg={6} xs={12}>
						<KeyboardTimePicker
							margin="normal"
							id="time-picker"
							variant="inline"
							inputVariant="filled"
							className={classes.item}
							label="Begin"
							value={note.startDate}
							onChange={(date) => {
								noteActions.setNoteStartDate(noteDispatch, date as Date);
							}}
							KeyboardButtonProps={{
								"aria-label": "change time",
							}}
						/>
					</Grid>
					<Grid item container justifyContent="flex-start" lg={6} xs={12}>
						<TextField
							type="number"
							className={classes.item}
							variant="filled"
							margin="normal"
							label="Total Units"
							value={note.totalUnits.toString()}
							onChange={(e) => {
								var value: number = parseInt(e.target.value);
								if (isNaN(value)) {
									value = 0;
								}
								noteActions.setNoteTotalUnits(noteDispatch, value);
							}}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					</Grid>
					<Grid item container justifyContent="flex-start" lg={6} xs={12}>
						<KeyboardTimePicker
							margin="normal"
							variant="inline"
							inputVariant="filled"
							className={classes.item}
							id="time-picker"
							label="End"
							value={note.endDate}
							onChange={(date) => {
								noteActions.setNoteEndDate(noteDispatch, date as Date);
							}}
							KeyboardButtonProps={{
								"aria-label": "change time",
							}}
						/>
					</Grid>
				</MuiPickersUtilsProvider>
			</Grid>
		</Card>
	);
}
