import { useEffect, useState } from "react";
import { makeStyles, TextField, Typography } from "@material-ui/core";
// import Proptypes from "prop-types";
// import { OWNER_ROLE } from "../../../../../../core/constants";
import WrappedButton from "../../../../../core/components/Button";
import Company from "../../../../../../domain/user/models/Company";
import { useUserState } from "../../../../../../application/user";
import { useSnackbar } from "notistack";
import { User } from "../../../../../../domain/user/models/User";
import { ButtonCircularProgress } from "../../../../../core/components/Progress";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		padding: "30px 10px",
		maxWidth: "30vw",
		[theme.breakpoints.down("sm")]: {
			maxWidth: "90vw",
		},
	},
	content: {
		width: "80%",
		[theme.breakpoints.down("sm")]: {
			width: "90%",
		},
	},
	textField: {
		marginTop: "15px",
	},
	buttonContainer: {
		marginTop: "30px",
		// width: "100%",
		// width: "80%",
	},
	chips: {
		display: "flex",
		flexWrap: "wrap",
	},
	button: {
		width: "100%",
	},
	margin: {
		marginTop: "20px",
	},
}));

// const selectLanguages = ["English", "Spanish"];

export default function EditProfileModalContent(props: any) {
	const classes = useStyles();

	const { onSuccessCallback } = props;

	const { user, isUpdating } = useUserState();

	const { enqueueSnackbar } = useSnackbar();

	const [name, setName] = useState("");
	const [company, setCompany] = useState<Company>(Company.empty());

	useEffect(() => {
		if (user) {
			setName(user.name);
			if (user.company) {
				setCompany(user.company);
			}
		}
	}, [user]);

	const onButtonClick = () => {
		const newUser = new User({
			...user,
			name: name,
			email: user.email,
			company: company,
		});
		if (newUser.isValid()) {
			onSuccessCallback(newUser);
		} else {
			enqueueSnackbar("Form invalid", { variant: "error" });
		}
	};

	return (
		<div className={classes.root}>
			<div className={classes.content}>
				<Typography variant="h3">Edit your profile</Typography>
				{/* <div className={classes.inputsContainer}> */}
				<TextField
					className={classes.textField}
					fullWidth
					label="Name"
					value={name}
					onChange={(e) => setName(e.target.value)}
				/>
				<Typography className={classes.margin} variant="h5">
					Your Company:
				</Typography>
				<TextField
					className={classes.textField}
					fullWidth
					label="Name"
					value={company.name}
					onChange={(e) =>
						setCompany(new Company({ ...company, name: e.target.value }))
					}
				/>
				<TextField
					className={classes.textField}
					fullWidth
					label="Address"
					value={company.address}
					onChange={(e) =>
						setCompany(new Company({ ...company, address: e.target.value }))
					}
				/>
				<div className={classes.buttonContainer}>
					<WrappedButton
						className={classes.button}
						disabled={isUpdating}
						onClick={onButtonClick}
					>
						Update
						{isUpdating && <ButtonCircularProgress />}
					</WrappedButton>
				</div>
			</div>
		</div>
	);
}

// EditProfileModalContent.propTypes = {
//   onSuccessCallback: Proptypes.func.isRequired,
// };
