import { Typography, useMediaQuery, useTheme } from "@material-ui/core";
import useStyles from "./styles";

export default function HorizontalItem(props: {
	title: string;
	img: any;
	content: string;
	imgLeft?: boolean;
}) {
	const { title, content, img, imgLeft = false } = props;
	const classes = useStyles();
	const theme = useTheme();
	const onMobile = useMediaQuery(theme.breakpoints.down("sm"));

	const Image = (
		<div className={classes.leftContainer}>
			<img src={img} alt="Helper" width="60%" height="100%" />
		</div>
	);

	const Content = (
		<div className={classes.rightContainer}>
			<div className={classes.titleContainer}>
				<Typography variant="h3" className={classes.title}>
					{title}
				</Typography>
			</div>
			<div className={classes.content}>
				<Typography>{content}</Typography>
			</div>
		</div>
	);

	return (
		<div className={classes.root}>
			{imgLeft || onMobile ? (
				<>
					{Image} {Content}
				</>
			) : (
				<>
					{Content} {Image}
				</>
			)}
		</div>
	);
}
