import React from "react";
import { Themes } from "../../../domain/core/utils";

export const SET_DARK_MODE = "SET_DARK_MODE";
export const SET_THEME = "SET_THEME";

const themeSaved = localStorage.getItem("theme");
const dark =
	themeSaved === Themes.System
		? window.matchMedia("(prefers-color-scheme: dark)").matches
		: themeSaved === Themes.Dark;

export const initialValues: ThemeState = {
	isDark: dark,
	selectedTheme:
		themeSaved === Themes.Dark
			? Themes.Dark
			: themeSaved === Themes.Light
			? Themes.Light
			: Themes.System,
};

export type ThemeDispatch = React.Dispatch<ThemeAction>;

export type ThemeState = {
	isDark: boolean;
	selectedTheme: Themes;
};

export type ThemeAction =
	| {
			type: typeof SET_DARK_MODE;
			payload: boolean;
	  }
	| {
			type: typeof SET_THEME;
			payload: Themes;
	  };
