import { Typography } from "@material-ui/core";
import useStyles from "./styles";

export default function Terms() {
	document.title = "PSR Notes | Terms of Services";
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Typography variant="h3">TERMS OF SERVICE</Typography>
			<div className={classes.paragraph}>
				<Typography className={classes.marginTop}>
					The following terms and conditions by PSRnotes (“PSRnotes,” “we,” or
					“us”), apply to the PSRnotes.com website, and any related
					applications, online services and mobile applications provided by
					PSRnotes (collectively, the “Services”). These Terms of Service
					together with our Privacy Policy (collectively, this “Agreement”)
					govern your use of the Services.
				</Typography>
				<Typography className={classes.marginTop}>
					The Agreement applies to any registered or a non-registered patient or
					other user of the Services (“User”), including a doctor, therapist, or
					other healthcare specialist, professional or provider using the
					Services in connection with a healthcare-related practice or other
					organization (“Service Provider”).
				</Typography>
				<Typography className={classes.marginTop}>
					BY USING OR OTHERWISE ACCESSING THE SERVICES, AND/OR BY REGISTERING
					WITH US, YOU AGREE TO THIS AGREEMENT. IF YOU DO NOT AGREE, YOU CANNOT
					USE THE SERVICES.
				</Typography>
				<Typography className={classes.marginTop}>
					PLEASE SEE SECTIONS 9 AND 10 BELOW REGARDING YOUR LEGAL RIGHTS IN ANY
					DISPUTE INVOLVING OUR WEBSITES, SERVICES, TERMS OF SERVICE OR PRIVACY
					POLICY.
				</Typography>
			</div>
			<Typography variant="h4" className={classes.marginTop}>
				1. INTRODUCTION
			</Typography>
			<div className={classes.paragraph}>
				<Typography variant="h5" className={classes.marginTop}>
					1.1. About The Services
				</Typography>
				<div className={classes.paragraph}>
					<Typography className={classes.marginTop}>
						The Services include User scheduling, reminders, to-do lists,
						provision of patient records and billing information, and related
						features that can be used by Users and Service Providers. We are not
						responsible for content, information, guidance, or other materials
						that are provided to you through the Services by Service Providers,
						including responses to your questions or healthcare advice by
						medical professionals. Such information is provided to Users from a
						Service Provider pursuant to a separate doctor-patient or other
						relationship directly between those parties.
					</Typography>
				</div>
				<Typography variant="h5" className={classes.marginTop}>
					1.2. Effective date and modifications
				</Typography>
				<div className={classes.paragraph}>
					<Typography className={classes.marginTop}>
						The Services include User scheduling, reminders, to-do lists,
						provision of patient records and billing information, and related
						features that can be used by Users and Service Providers. We are not
						responsible for content, information, guidance, or other materials
						that are provided to you through the Services by Service Providers,
						including responses to your questions or healthcare advice by
						medical professionals. Such information is provided to Users from a
						Service Provider pursuant to a separate doctor-patient or other
						relationship directly between those parties.
					</Typography>
				</div>
			</div>
			<Typography variant="h4" className={classes.marginTop}>
				2. USE OF THE SERVICES AND YOUR ACCOUNT
			</Typography>
			<div className={classes.paragraph}>
				<Typography variant="h5" className={classes.marginTop}>
					2.1. Permitted Users
				</Typography>
				<div className={classes.paragraph}>
					<Typography className={classes.marginTop}>
						By using the Services or registering an Account, you represent that
						you are at least 18 years of age, or the legal age of majority where
						you reside if that jurisdiction has an older age of majority, and
						you have the legal authority to enter into this Agreement. If you
						are the parent, legal guardian, or duly authorized personal
						representative of an individual, you may use the Services on behalf
						of this individual. If you are consenting on behalf of an entity,
						you represent and warrant that you are duly authorized to act on
						behalf of the entity and accept this Agreement on behalf of the
						entity. You further agree that as a condition to accessing the
						Services, you will submit to account verification as reasonably
						required by PSRnotes, and provide only true and accurate
						identification documentation to PSRnotes (or its third party service
						providers) to verify your age and other Account-related information.
					</Typography>
					<Typography className={classes.marginTop}>
						By using the Services, you represent and warrant that you have the
						right, authority, and capacity to enter into this Agreement and will
						abide by all of the terms and conditions herein.
					</Typography>
				</div>
				<Typography variant="h5" className={classes.marginTop}>
					2.2. Establishing an Account and Using the Services
				</Typography>
				<div className={classes.paragraph}>
					<Typography className={classes.marginTop}>
						Portions of the Services are viewable without registering with us,
						but to actively use the primary Services or have us process certain
						personal and health information, you may be required to register for
						the Services (an “Account”) and affirmatively accept the terms of
						this Agreement, including the Privacy Policy.
					</Typography>
					<Typography className={classes.marginTop}>
						Only one person may use each Account. You agree to provide accurate,
						current and complete information about yourself or the individual
						you represent (as described above) when using the Services, such as
						in the registration form and with provided account management tools
						to update Account information. You may be required to choose an
						Account name to identify yourself to PSRnotes in connection with
						your Account.
					</Typography>
				</div>
				<Typography variant="h5" className={classes.marginTop}>
					2.3. Responsibility for Use of Account
				</Typography>
				<div className={classes.paragraph}>
					<Typography className={classes.marginTop}>
						You are responsible for all activities conducted through your
						Account. In the event that fraud, illegality or other conduct that
						violates this Agreement is discovered or reported (whether by you or
						someone else) in connection with your Account, we may suspend or
						terminate your Account as described in Section 11.
					</Typography>
				</div>
				<Typography variant="h5" className={classes.marginTop}>
					2.4. Selection and Use of Account Password
				</Typography>
				<div className={classes.paragraph}>
					<Typography className={classes.marginTop}>
						You are responsible for providing an Account password, maintaining
						its confidentiality, and any harm resulting from your disclosure of
						the password to any other person. At no time should you respond to
						an online request for a password other than the log-on process for
						the Services.
					</Typography>
				</div>
				<Typography variant="h5" className={classes.marginTop}>
					2.5. Fees and Billing for Service Providers
				</Typography>
				<div className={classes.paragraph}>
					<Typography className={classes.marginTop}>
						For Service Providers using the Services: PSRnotes provides certain
						aspects of the Services to you pursuant to fees and other conditions
						set forth herein or on our website. We may add new services for
						additional fees and charges, or prospectively amend fees and charges
						for existing services. You acknowledge that it is your
						responsibility to ensure payment in full for all paid aspects of the
						Services, and to ensure that your credit or debit cards or other
						payment instruments accepted by PSRnotes continue to be valid and
						sufficient for such purposes.
					</Typography>
				</div>
				<Typography variant="h5" className={classes.marginTop}>
					2.6. Modification of Service
				</Typography>
				<div className={classes.paragraph}>
					<Typography className={classes.marginTop}>
						We may add, modify, or eliminate aspect(s), features or
						functionality of the Services from time to time for the purposes of
						compliance with applicable laws and regulations, to effect
						improvements in security and functionality, to correct errors, or
						for other commercially-reasonable purposes.
					</Typography>
				</div>
			</div>
			<Typography variant="h4" className={classes.marginTop}>
				3. USER DATA AND INTELLECTUAL PROPERTY RIGHTS
			</Typography>
			<div className={classes.paragraph}>
				<Typography variant="h5" className={classes.marginTop}>
					3.1. Your Rights and Obligations with Respect to Your User Data
				</Typography>
				<div className={classes.paragraph}>
					<Typography className={classes.marginTop}>
						Any information that you upload or submit to us in connection with
						the Services or your Account, including but not limited to Protected
						Health Information (defined below), is referred to in this Agreement
						as “User Data”. You retain all Intellectual Property Rights in your
						User Data, subject to the rights, licenses, and other terms of this
						Agreement. “Intellectual Property Rights” means copyrights,
						trademarks, service marks, trade dress, publicity rights, database
						rights, patent rights, and other intellectual property rights or
						proprietary rights recognized by law.
					</Typography>
					<Typography className={classes.marginTop}>
						You affirm, represent, and warrant that you own or have all
						necessary Intellectual Property Rights, licenses, consents, and
						permissions to use—and authorize PSRnotes to use—the User Data you
						provide in the manner described by this Agreement.
					</Typography>
					<Typography className={classes.marginTop}>
						You agree that by providing any User Data in connection with the
						Services or your Account, you are granting PSRnotes a non-exclusive,
						worldwide, royalty-free, sublicenseable, and transferable license to
						use, reproduce, distribute, prepare derivative works of, and display
						the User Data non-publicly and internally to PSRnotes for the
						purposes of providing the Services. You agree that the license
						includes the right to copy, analyze and use any of your User Data as
						PSRnotes may deem necessary or desirable for troubleshooting,
						debugging, testing, supporting, enhancing or developing services in
						connection with the Services and future improvements to the
						Services. The license granted in this section is referred to as the
						“Services Data License.” You also acknowledge that the Services Data
						License granted to PSRnotes with respect to your User Data will
						survive the termination of your Account to permit PSRnotes to retain
						server copies with User Data, including for back-up, debugging,
						testing and record retention and legal purposes.
					</Typography>
					<Typography className={classes.marginTop}>
						If you send us feedback, ideas, suggestions, or other materials in
						regard to the Services (other than your User Data), you waive any
						potential Intellectual Property Rights and agree that we are free to
						use it for any purposes whatsoever, including, without limitation,
						developing and marketing products and services, without any
						liability or payment of any kind to you.
					</Typography>
				</div>
				<Typography variant="h5" className={classes.marginTop}>
					3.2. Protected Health Information, User Data and Use of Your
					Information
				</Typography>
				<div className={classes.paragraph}>
					<Typography className={classes.marginTop}>
						PSRnotes will make no use of “Protected Health Information” (“PHI,”
						as defined by law at 45 C.F.R. § 160.103) except as permitted by
						this Agreement and applicable law, including but not limited to the
						Health Insurance Portability and Accountability Act of 1996, and any
						implementing regulations, as amended (“HIPAA”). With respect to all
						User Data, including PHI, PSRnotes will comply with the terms of its
						Privacy Policy,PSRnotes will make commercially reasonable efforts to
						maintain the Services in a manner that includes appropriate
						administrative, technical and physical security measures designed to
						protect the confidentiality, availability and integrity of User
						Data, including PHI as required by HIPAA.
					</Typography>
				</div>
				<Typography variant="h5" className={classes.marginTop}>
					3.3. PSRnotes’ Rights
				</Typography>
				<div className={classes.paragraph}>
					<Typography className={classes.marginTop}>
						Except for User Data, PSRnotes (or its licensors) own all rights,
						title, and interest to the Intellectual Property Rights in the
						Services, including the software, content, data, websites, and
						servers. PSRnotes owns all Intellectual Property Rights in and to
						its trademarks, service marks, trade names, logos, domain names,
						tag-lines, and trade dress.
					</Typography>
				</div>
				<Typography variant="h5" className={classes.marginTop}>
					3.4. Limited License
				</Typography>
				<div className={classes.paragraph}>
					<Typography className={classes.marginTop}>
						PSRnotes hereby grants you a non-exclusive, non-transferable,
						non-sublicenseable, limited, revocable license to access and use the
						Services while you agree to and comply with this Agreement. You may
						not: (i) enable any person or entity not so authorized by PSRnotes
						to use or access the Services; (ii) attempt to copy, create
						derivative works, or infringe any Intellectual Property Rights in
						the Services; (iii) use the Services for another party or as
						otherwise prohibited herein; (iv) alter or modify the Services; (v)
						transfer your Account or the Services to anyone else; or (vi)
						attempt to translate, disassemble, decompile, reverse assemble,
						reverse engineer all or any part of the Services or otherwise
						attempt to derive the source code for the Services.
					</Typography>
				</div>
				<Typography variant="h5" className={classes.marginTop}>
					3.5. Third Party Rights
				</Typography>
				<div className={classes.paragraph}>
					<Typography className={classes.marginTop}>
						You agree that you will not upload, publish, or submit to the
						Services any User Data that is subject to the Intellectual Property
						Rights or other proprietary rights of another party, including any
						trade secret or privacy rights, unless you have permission to convey
						to us all license rights required under this Agreement. You agree
						that PSRnotes will have no liability for, and you agree to defend,
						indemnify, and hold PSRnotes harmless for, any claims, losses or
						damages arising out of or in connection with your unlawful or
						otherwise improper use or provision of any User Data in connection
						with the Services.
					</Typography>
				</div>
			</div>
			<Typography variant="h4" className={classes.marginTop}>
				4. HEALTH-RELATED INFORMATION
			</Typography>
			<div className={classes.paragraph}>
				<Typography variant="h5" className={classes.marginTop}>
					4.1. We Do Not Provide Medical Advice or Services
				</Typography>
				<div className={classes.paragraph}>
					<Typography className={classes.marginTop}>
						Our Services are intended to foster enhanced communications and
						coordination between our Users and Service Providers, including
						healthcare professionals. PSRnotes itself does not provide
						professional medical advice, diagnosis, or treatment.
					</Typography>
					<Typography className={classes.marginTop}>
						PSRNOTES IS NOT RESPONSIBLE FOR HEALTH-RELATED INFORMATION PROVIDED
						TO YOU THROUGH THE SERVICES COMES FROM INDEPENDENT SERVICE
						PROVIDERS, SUCH AS MEDICAL PROFESSIONALS AND ORGANIZATIONS,
						INCLUDING ANSWERS TO ANY HEALTH-RELATED QUESTION YOU MAY SUBMIT
						THROUGH THE SERVICES. ANY INFORMATION THAT PSRNOTES ITSELF MAY
						PROVIDE IN CONNECTION WITH THE SERVICES IS FOR INFORMATIONAL
						PURPOSES ONLY, AND IS NOT INTENDED AS A SUBSTITUTE FOR, NOR DOES IT
						REPLACE, PROFESSIONAL MEDICAL ADVICE, DIAGNOSIS, OR TREATMENT.
						NOTHING STATED OR POSTED ON THE SERVICES MORE GENERALLY BY PSRNOTES
						IS INTENDED TO BE, AND MUST NOT BE TAKEN TO BE, THE PRACTICE OF
						MEDICINE, DENTISTRY, NURSING, OR OTHER PROFESSIONAL HEALTHCARE
						ADVICE, OR THE PROVISION OF MEDICAL CARE.
					</Typography>
					<Typography className={classes.marginTop}>
						DO NOT DISREGARD, AVOID OR DELAY OBTAINING MEDICAL ADVICE FROM A
						QUALIFIED HEALTHCARE PROVIDER. DO NOT USE THE SERVICES FOR MEDICAL
						EMERGENCY NEEDS. IF YOU EXPERIENCE A MEDICAL EMERGENCY, IMMEDIATELY
						CALL A HEALTH CARE PROFESSIONAL AND 911. YOUR USE OF INFORMATION
						PROVIDED THROUGH THE SERVICES IS SOLELY AT YOUR OWN RISK.
					</Typography>
					<Typography className={classes.marginTop}>
						We do not recommend or endorse any specific Service Providers,
						tests, procedures, opinions, or other information that may appear
						through the Services. If you rely on any information provided
						through the Services, you do so solely at your own risk.
					</Typography>
					<Typography className={classes.marginTop}>
						Our Services are provided to Users through PSRnotes.com free of
						charge. Usual, customary and any other charges for any medical or
						related services rendered by Service Providers will apply and will
						be entirely your responsibility (including through your insurance).
						You must resolve any dispute between you or any Service Provider
						directly with the Service Provider, even if scheduling or other
						interactions were facilitated by our Services.
					</Typography>
				</div>
				<Typography variant="h5" className={classes.marginTop}>
					4.2. No Doctor Patient Relationship
				</Typography>
				<div className={classes.paragraph}>
					<Typography className={classes.marginTop}>
						NO LICENSED MEDICAL PROFESSIONAL/PATIENT RELATIONSHIP IS CREATED BY
						USING INFORMATION PROVIDED BY, OR THROUGH THE USE OF, THE SERVICES
						OR THROUGH ANY OTHER COMMUNICATIONS FROM US. WE MAKE NO GUARANTEES,
						REPRESENTATIONS OR WARRANTIES, WHETHER EXPRESSED OR IMPLIED, WITH
						RESPECT TO PROFESSIONAL QUALIFICATIONS, EXPERTISE, QUALITY OF WORK,
						PRICE OR COST INFORMATION OR OTHER INFORMATION THROUGH THE SERVICES.
						FURTHERMORE, WE DO NOT IN ANY WAY ENDORSE OR RECOMMEND ANY
						INDIVIDUAL LISTED OR ACCESSIBLE THROUGH THE SERVICES. IN NO EVENT
						SHALL WE BE LIABLE TO YOU OR ANYONE ELSE FOR ANY DECISION MADE OR
						ACTION TAKEN BY YOU IN RELIANCE ON ANY SUCH INFORMATION.
					</Typography>
					<Typography className={classes.marginTop}>
						We have no control over, and cannot guarantee the availability of
						any Service Provider at any particular time. We will not be liable
						for cancelled or otherwise unfulfilled appointments, or any injury
						resulting therefrom, or for any other injury resulting or arising
						from or related to the use of the Services whatsoever.
					</Typography>
					<Typography className={classes.marginTop}>
						You are strongly advised to perform your own investigation prior to
						selecting a Service Provider by making confirming telephone calls to
						the appropriate licensing or certification authorities to verify
						listed credentials and education, and to further verify information
						about a particular Service Provider by confirming with the Service
						Provider’s office, your current Service Provider, the medical
						association relative to the Service Provider’s specialty and your
						state medical board.
					</Typography>
				</div>
				<Typography variant="h5" className={classes.marginTop}>
					4.3. Authorization And Acknowledgement; Important Information About
					Service Provider Relationships And Lists
				</Typography>
				<div className={classes.paragraph}>
					<Typography className={classes.marginTop}>
						In connection with using the Services to locate and schedule
						appointments with Service Providers, you understand that YOU ARE
						ULTIMATELY RESPONSIBLE FOR CHOOSING YOUR OWN SERVICE PROVIDER.
					</Typography>
					<Typography className={classes.marginTop}>
						Service Providers listed or accessible through the Services with
						whom you may book appointments enter into contracts with us and such
						Service Providers generally pay us a fee in order to be listed
						and/or marketed through the Services. We may provide you with lists
						and/or profile previews of Service Providers who may be suitable to
						deliver the healthcare that you are seeking based on information
						that you provide to us (such as insurance information, proximity to
						your geographical location, and specialty of the Service Provider).
						In an effort to aid in the discovery of Service Providers and enable
						the maximum choice and diversity of Service Providers who
						participate in the Services, these lists and/or profile previews may
						also be based on other criteria (including, for example, Service
						Provider availability, past selections by and/or ratings of Service
						Providers by you or by other of our users, and past experience of
						our users with Service Providers); but we (a) do not recommend or
						endorse any Service Providers, (b) do not make any representations
						or warranties with respect to these Service Providers or the quality
						of the healthcare services they may provide, and (c) do not receive
						any additional fees from Service Providers for featuring them (i.e.,
						higher or better placement on lists) through the Services.
					</Typography>
					<Typography className={classes.marginTop}>
						IN CONNECTION WITH THE SERVICES, YOU MAY AFFIRMATIVELY DIRECT AND
						AUTHORIZE US TO SHARE YOUR INFORMATION WITH ONE OR MORE SPECIFIED
						SERVICE PROVIDER(S), INCLUDING YOUR PERSONAL INFORMATION AND
						PROTECTED HEALTH INFORMATION.
					</Typography>
				</div>
				<Typography variant="h5" className={classes.marginTop}>
					4.4. Editorial Control
				</Typography>
				<div className={classes.paragraph}>
					<Typography className={classes.marginTop}>
						We make the Services available as a service to Users, including
						Service Providers, for the purposes of providing an informative and
						educational resource. We may, but have no obligation to, have
						information posted through the Services reviewed by our editorial
						personnel. It is important to note, however, that the timeliness,
						accuracy, and completeness of any or all such information is not
						guaranteed. Neither the authors, editorial personnel, nor any other
						party involved in the preparation or publication of information
						through the Services can assure you that the information contained
						herein is in every respect accurate or complete, and we and they are
						not responsible for any errors or omissions or for the results
						obtained from the use of such information.
					</Typography>
					<Typography className={classes.marginTop}>
						You are encouraged to independently confirm the information provided
						through the Services with other sources and to seek the advice of a
						qualified Service Provider.
					</Typography>
				</div>
				<Typography variant="h5" className={classes.marginTop}>
					4.5. Service Provider Information
				</Typography>
				<div className={classes.paragraph}>
					<Typography className={classes.marginTop}>
						Service Provider and other information that may be made available
						through the Services is intended for general reference purposes
						only. Service Provider information available through the Services
						may be provided by the Service Provider and/or multiple other data
						sources not validated by the Service Provider. Information may
						become out of date, incomplete or inaccurate. The Services do not
						provide any advice, qualification or certification about any
						particular Service Provider. You are encouraged to independently
						verify any such information.
					</Typography>
				</div>
				<Typography variant="h5" className={classes.marginTop}>
					4.6. Procedures/Products/Services
				</Typography>
				<div className={classes.paragraph}>
					<Typography className={classes.marginTop}>
						The procedures, products, services and devices discussed and/or
						marketed through the Services are not applicable to all individuals,
						patients or clinical situations. We make no claims as to the safety,
						appropriateness, or effectiveness of any procedures, products,
						services or devices. Any products and/or services represented
						through the Services by advertisers, sponsors, and other
						participants of the Services, either paid or unpaid, are presented
						for your awareness and do not necessarily imply safety or
						appropriateness for any particular individual or prediction of
						effectiveness, outcome or success.
					</Typography>
				</div>
			</div>
			<Typography variant="h4" className={classes.marginTop}>
				5. USER RESPONSIBILITIES
			</Typography>
			<div className={classes.paragraph}>
				<Typography variant="h5" className={classes.marginTop}>
					5.1. Prohibited Uses of the Services
				</Typography>
				<div className={classes.paragraph}>
					<Typography className={classes.marginTop}>
						You are responsible for all use of the Services and for all use of
						your Account credentials, including use by others to whom you have
						given your credentials in violation of this Agreement.
					</Typography>
					<ul>
						<li>You may only use the Services for lawful purposes.</li>
						<li>
							Use of the Services is limited to personal, non-commercial
							purposes except if you are registered with us as a Service
							Provider.
						</li>
						<li>
							You may not use the Services in any manner that could damage,
							disable, overburden, or impair our servers or networks, or
							interfere with any other party’s use and enjoyment of the
							Services.
						</li>
						<li>
							You may not attempt to gain unauthorized access to any of the
							Services, user accounts, or computer systems or networks, through
							hacking, password mining or any other means.
						</li>
						<li>
							You may not post, display or transmit User Data that violates any
							applicable law, the rights of any third party, or is defamatory,
							indecent, discriminatory or otherwise inappropriate.
						</li>
					</ul>
				</div>
				<Typography variant="h5" className={classes.marginTop}>
					5.2. Security of the Services
				</Typography>
				<div className={classes.paragraph}>
					<Typography className={classes.marginTop}>
						Without limiting any of the foregoing, you may not (and may not to
						allow any third party to):
					</Typography>
					<ul>
						<li>
							Copy, modify, adapt, translate, or reverse engineer any portion of
							the Services, its content or materials;
						</li>
						<li>
							Remove any copyright, trademark or other proprietary rights
							notices contained in or on the Services or in or on any content or
							other material obtained via the Services;
						</li>
						<li>
							Use, without permission, any robot, spider, site search/retrieval
							application, or other automated device, process or means to
							access, retrieve or index any portion of the Services;
						</li>
						<li>
							Access or retrieve any portion of the Services for purposes of
							constructing or populating a searchable database of reviews or
							other information related to the healthcare industry or Service
							Providers;
						</li>
						<li>
							Reformat or frame any portion of the web pages that are part of
							the Services;
						</li>
						<li>
							Fraudulently misuse the Services, including scheduling an
							appointment with a Service Provider which you do not intend to
							keep;
						</li>
						<li>
							Create user accounts by automated means or under false or
							fraudulent pretenses;
						</li>
						<li>
							Collect or store personal data about other users in connection
							with the prohibited activities described in this paragraph;
						</li>
						<li>
							Accumulate or index, directly or indirectly, any portion of the
							Services (including, without limitation, Service Provider or
							practice information, appointment availability, and price
							information) for any purpose whatsoever; or
						</li>
						<li>
							Use any means, including software means, to conduct web scraping
							of any portion of the Services.
						</li>
					</ul>
				</div>
				<Typography variant="h5" className={classes.marginTop}>
					5.3. Violation of terms
				</Typography>
				<div className={classes.paragraph}>
					<Typography className={classes.marginTop}>
						Any violation by you of the terms of this section may result in
						immediate suspension or termination of your Account without any
						refund or other compensation. In addition, we may take any measures
						deemed appropriate, including legal action and technical remedies,
						to respond to or prevent violation of this provision and enforce
						these terms.
					</Typography>
				</div>
				<Typography variant="h5" className={classes.marginTop}>
					5.4. No Spam
				</Typography>
				<div className={classes.paragraph}>
					<Typography className={classes.marginTop}>
						You may not use contact information provided by our users or Service
						Providers, or harvest such information for the purpose of sending,
						or to facilitate the sending of, unsolicited bulk communications,
						such as SPAM. You may not allow others to use your account to
						violate the terms of this section. We may terminate your Account or
						access to the Services immediately and take other legal action if
						you or anyone using your credentials violates these provisions.
					</Typography>
				</div>
			</div>
			<Typography variant="h4" className={classes.marginTop}>
				6. RESPONSIBILITIES FOR USERS WHO ARE SERVICE PROVIDERS
			</Typography>
			<div className={classes.paragraph}>
				<Typography className={classes.marginTop}>
					In addition to the responsibilities in the foregoing section, if you
					are a Service Provider or other person or entity in the healthcare or
					medical industries, you acknowledge and agree that:
				</Typography>
				<div className={classes.paragraph}>
					<Typography className={classes.marginTop}>
						1. You will not use the Services to view, access or otherwise use,
						directly or indirectly, price, availability, or other information of
						another Service Provider for any purpose other than your own
						personal, non-commercial use, such as a patient or prospective
						patient with that Service Provider, or in connection with assisting
						a patient or prospective patient with a referral;
					</Typography>
					<Typography className={classes.marginTop}>
						2. You will not use the Services to establish, attempt to establish,
						or enforce, directly or indirectly, any agreement or coordination of
						the prices charged for any product or service; the kinds,
						frequencies or amounts of any product or service offered; or the
						customer or customer categories for any product or service, or
						otherwise engage or attempt to engage in price fixing, output
						restriction, or customer or market allocation; and
					</Typography>
					<Typography className={classes.marginTop}>
						3. You will not use the Services, directly or indirectly, to engage
						in any anti-competitive, deceptive or unfair practices, or otherwise
						violate applicable antitrust, competition or consumer protection
						laws, or regulations.
					</Typography>
					<Typography className={classes.marginTop}>
						4. You are responsible for setting your configurable security
						settings in alignment with HIPAA requirements including security
						monitoring, reporting and notification of security incidents to
						TherapyNotes, network connectivity redundancy (as needed), disaster
						recovery in accordance with HIPAA requirements, and administering
						end user access to limit access appropriately.
					</Typography>
				</div>
			</div>
			<Typography variant="h4" className={classes.marginTop}>
				7. NO RESPONSIBILITY FOR ACTS OF OMISSIONS OF THIRD PARTY WEBSITES
			</Typography>
			<div className={classes.paragraph}>
				<Typography className={classes.marginTop}>
					The Services may contain links to or otherwise support connections to
					third-party websites or other online services that are not owned or
					controlled by PSRnotes. Portions of the Services may also be embedded
					within third party websites. PSRnotes is not responsible or liable for
					the content, policies, or practices of any third-party websites or
					online services. Please consult any applicable terms of use and
					privacy policies provided by the third party for such websites or
					online services.
				</Typography>
			</div>
			<Typography variant="h4" className={classes.marginTop}>
				8. INTERRUPTION OF SERVICES OR USER DATA LOSS
			</Typography>
			<div className={classes.paragraph}>
				<Typography className={classes.marginTop}>
					PSRnotes may on occasion need to interrupt the Services with or
					without prior notice to protect the integrity or functionality of the
					Services. PSRnotes will not be liable for any interruption of the
					Services (whether intentional or not), and you will not be entitled to
					any refunds of fees or other compensation for an interruption of
					Services. Likewise, in the event of loss of any User Data, we will not
					be liable for any purported damage or harm arising therefrom.
				</Typography>
			</div>
			<Typography variant="h4" className={classes.marginTop}>
				9. LEGAL LIABILITY AND RELEASES
			</Typography>
			<div className={classes.paragraph}>
				<Typography variant="h5" className={classes.marginTop}>
					9.1. Releases
				</Typography>
				<div className={classes.paragraph}>
					<Typography className={classes.marginTop}>
						You agree not to hold PSRnotes liable for the content, actions, or
						inactions of other users of the Services or of other third parties.
						As a condition of access to the Services, you release PSRnotes (and
						its officers, directors, shareholders, agents, subsidiaries, and
						employees) from claims, demands, losses, liabilities and damages
						(actual and consequential) of every kind and nature, known and
						unknown, arising out of or in any way connected with any dispute you
						have or claim to have with one or more other users of the Services
						or with other third parties, including whether or not PSRnotes
						becomes involved in any resolution or attempted resolution of the
						dispute.
					</Typography>
					<Typography className={classes.marginTop}>
						If you are a California resident, you waive California Civil Code
						Section 1542, which says: “A general release does not extend to
						claims which the creditor does not know or suspect to exist in his
						favor at the time of executing the release, which if known by him
						must have materially affected his settlement with the debtor.” If
						you are a resident of another jurisdiction, you waive any comparable
						statute or doctrine.
					</Typography>
				</div>
				<Typography variant="h5" className={classes.marginTop}>
					9.2. Disclaimer of Other Express and Implied Warranties
				</Typography>
				<div className={classes.paragraph}>
					<Typography className={classes.marginTop}>
						PSRNOTES PROVIDES THE SERVICES (INCLUDING WITHOUT LIMITATION ANY
						SOFTWARE, WEBSITES, SERVERS, ONLINE SERVICES, YOUR ACCOUNT, AND USER
						DATA) STRICTLY ON AN “AS IS” BASIS, AND HEREBY EXPRESSLY DISCLAIMS
						ALL WARRANTIES OR CONDITIONS OF ANY KIND, WRITTEN OR ORAL, EXPRESS,
						IMPLIED OR STATUTORY, INCLUDING WITHOUT LIMITATION ANY IMPLIED
						WARRANTY OF TITLE, NONINFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A
						PARTICULAR PURPOSE. NO VALUE, EITHER EXPRESS OR IMPLIED, IS
						GUARANTEED OR WARRANTED WITH RESPECT TO ANY SERVICES OR CONTENT.
						NOTWITHSTANDING ANY INTELLECTUAL PROPERTY RIGHTS YOU MAY HAVE IN
						YOUR USER DATA OR ANY EXPENDITURE ON YOUR PART, PSRNOTES AND YOU
						EXPRESSLY DISCLAIM ANY COMPENSABLE VALUE RELATING TO OR ATTRIBUTABLE
						TO ANY DATA RELATING TO YOUR ACCOUNT RESIDING ON PSRNOTES’ SERVERS.
						YOU ASSUME ALL RISK OF LOSS FROM USING THE SERVICES ON THIS BASIS.
						WE DO NOT GUARANTEE THAT THE RESULTS THAT MAY BE OBTAINED FROM THE
						USE OF THE SERVICES WILL BE EFFECTIVE, RELIABLE OR ACCURATE OR WILL
						MEET YOUR REQUIREMENTS. WE DO NOT GUARANTEE THAT YOU WILL BE ABLE TO
						ACCESS OR USE THE SERVICES (EITHER DIRECTLY OR THROUGH THIRD-PARTY
						NETWORKS) AT TIMES OR LOCATIONS OF YOUR CHOOSING. WE ARE NOT
						RESPONSIBLE FOR THE ACCURACY, RELIABILITY, TIMELINESS OR
						COMPLETENESS OF INFORMATION PROVIDED BY USERS OF THE SERVICES OR ANY
						OTHER DATA OR INFORMATION PROVIDED OR RECEIVED THROUGH THE SERVICES.
						EXCEPT AS EXPRESSLY SET FORTH HEREIN, WE MAKE NO WARRANTIES ABOUT
						THE INFORMATION SYSTEMS, SOFTWARE AND FUNCTIONS MADE ACCESSIBLE
						THROUGH THE SERVICES OR ANY OTHER SECURITY ASSOCIATED WITH THE
						TRANSMISSION OF SENSITIVE INFORMATION. WE DO NOT WARRANT THAT THE
						SERVICES WILL OPERATE ERROR-FREE, BUG-FREE OR FREE FROM DEFECTS,
						THAT LOSS OF DATA WILL NOT OCCUR, OR THAT THE SERVICES ARE FREE OF
						COMPUTER VIRUSES, CONTAMINANTS OR OTHER HARMFUL ITEMS.
					</Typography>
					<Typography className={classes.marginTop}>
						PSRnotes engages in commercially reasonable efforts to protect the
						confidentiality, availability and integrity of the Services, but it
						cannot guarantee continuous, error-free, secure and virus-free
						operation at all times, and you understand that you shall not be
						entitled to refunds or other compensation based on PSRnotes’ failure
						to provide any of the foregoing other than as explicitly provided in
						this Agreement. Some jurisdictions do not allow the disclaimer of
						implied warranties, and to that extent, the foregoing disclaimer may
						not apply to you. To the extent applicable (i.e., for Service
						Providers), PSRnotes does not guarantee that by mere use of the
						Services you will be in compliance with HIPAA, and you understand
						and agree that you are responsible for maintaining any other
						administrative, technical and physical measures required to maintain
						appropriate information security with respect to PHI you create,
						receive, maintain, and/or transmit and to otherwise comply with
						HIPAA.
					</Typography>
				</div>
				<Typography variant="h5" className={classes.marginTop}>
					9.3. Limitation of Liability
				</Typography>
				<div className={classes.paragraph}>
					<Typography className={classes.marginTop}>
						YOUR SOLE AND EXCLUSIVE REMEDY FOR ANY DISPUTE WITH US IS THE
						CANCELLATION OF YOUR REGISTRATION OR ACCOUNT. IN NO EVENT SHALL OUR
						TOTAL CUMULATIVE LIABILITY TO YOU FOR ANY AND ALL CLAIMS RELATING TO
						OR ARISING OUT OF YOUR USE OF THE SERVICES, REGARDLESS OF THE FORM
						OF ACTION, EXCEED THE GREATER OF THE TOTAL AMOUNT OF FEES, IF ANY,
						THAT YOU PAID FOR THE SERVICES.
					</Typography>
					<Typography className={classes.marginTop}>
						IN NO EVENT SHALL PSRNOTES OR ANY OF ITS DIRECTORS, OFFICERS,
						EMPLOYEES, SHAREHOLDERS, SUBSIDIARIES, AGENTS OR LICENSORS BE LIABLE
						TO YOU OR TO ANY THIRD PARTY FOR ANY SPECIAL, INCIDENTAL, INDIRECT,
						CONSEQUENTIAL, RELIANCE, PUNITIVE OR EXEMPLARY DAMAGES OR
						DISGORGEMENT OR COMPARABLE EQUITABLE REMEDY, INCLUDING WITHOUT
						LIMITATION ANY DAMAGES FOR LOST DATA OR LOST PROFITS, ARISING
						(WHETHER IN CONTRACT, TORT, STRICT LIABILITY OR OTHERWISE) OUT OF OR
						IN CONNECTION WITH THE SERVICES (INCLUDING ITS MODIFICATION OR
						TERMINATION), PSRNOTES SOFTWARE, WEBSITES, SERVERS, YOUR ACCOUNT
						(INCLUDING ITS TERMINATION OR SUSPENSION) OR THIS AGREEMENT, WHETHER
						OR NOT PSRNOTES MAY HAVE BEEN ADVISED THAT ANY SUCH DAMAGES MIGHT OR
						COULD OCCUR AND NOTWITHSTANDING THE FAILURE OF ESSENTIAL PURPOSE OF
						ANY REMEDY. Some jurisdictions do not allow the foregoing
						limitations of liability, so to the extent that any such limitation
						is found to be impermissible, such limitation may not apply to you.
					</Typography>
				</div>
				<Typography variant="h5" className={classes.marginTop}>
					9.4. New Jersey Residents
				</Typography>
				<div className={classes.paragraph}>
					<Typography className={classes.marginTop}>
						Notwithstanding any term herein, this Agreement does not limit your
						rights or our obligations under any applicable statute or law to the
						contrary, including types and amounts of recovery; nor do they
						excuse us from any duty to avoid causing harm by means of gross
						negligence, recklessness, or intentional misconduct; nor do they
						disclaim our duty of care to our invitees; nor do they require you
						to defend and indemnify us in the event that any loss is caused by
						our negligence.
					</Typography>
				</div>
				<Typography variant="h5" className={classes.marginTop}>
					9.5. Indemnification
				</Typography>
				<div className={classes.paragraph}>
					<Typography className={classes.marginTop}>
						At PSRnotes’ request, you agree to defend, indemnify and hold
						harmless PSRnotes, its officers, directors, shareholders, employees,
						subsidiaries, and agents from all damages, liabilities, claims and
						expenses, including without limitation attorneys’ fees and costs,
						arising from: (i) any breach or alleged breach by you of this
						Agreement, including without limitation your representations and
						warranties relating to your User Data; or (ii) your acts, omissions
						or use of the Services, including without limitation your negligent,
						willful or illegal conduct. We reserve the right to assume the
						exclusive defense and control of any matter otherwise subject to
						indemnification by you, and in such case, you agree to cooperate
						with our defense of such claim.
					</Typography>
				</div>
				<Typography variant="h5" className={classes.marginTop}>
					9.6. Legal Relationship Between You and PSRnotes; No Third Party
					Beneficiaries
				</Typography>
				<div className={classes.paragraph}>
					<Typography className={classes.marginTop}>
						You acknowledge that your participation in the Services, including
						your creation or uploading of User Data to the Services, does not
						make you a PSRnotes employee or agent, and that you do not expect to
						be, and will not be, compensated by PSRnotes for such activities,
						and you will make no claim inconsistent with these acknowledgements.
						In addition, no agency, partnership, joint venture, franchise
						relationship is intended or created by this Agreement. There are no
						third party beneficiaries, intended or implied, under this
						Agreement.
					</Typography>
				</div>
			</div>
			<Typography variant="h4" className={classes.marginTop}>
				10. DISPUTE RESOLUTION AND ARBITRATION
			</Typography>
			<div className={classes.paragraph}>
				<Typography className={classes.marginTop}>
					In the event of a dispute between you and PSRnotes, other than with
					respect to claims for injunctive relief, the dispute will be resolved
					by binding arbitration pursuant to the rules of the American
					Arbitration Association Commercial Arbitration Rules. The place of the
					arbitration shall be in Miami-Dade, Florida. Notwithstanding the
					foregoing, either party may seek strictly injunctive or other
					non-monetary equitable relief to protect or enforce its Intellectual
					Property Rights in court. In the event that there is any dispute
					between you and PSRnotes that is determined not to be subject to
					arbitration, exclusive jurisdiction and venue shall be in state or
					federal court in the City and County of Miami, Florida.
				</Typography>
				<Typography className={classes.marginTop}>
					PURSUANT TO THIS AGREEMENT, YOU WAIVE ANY RIGHTS TO BRING, AND AGREE
					NOT TO PARTICIPATE IN, ANY CLASS ACTION, CONSOLIDATED, MULTI-DISTRICT
					OR COLLECTIVE ACTION, OR PRIVATE ATTORNEY GENERAL ACTION. Unless both
					you and PSRnotes agree, no arbitrator or judge may consolidate more
					than one person’s claims or otherwise preside over any form of a
					representative or class proceeding.
				</Typography>
				<Typography className={classes.marginTop}>
					You agree that this Agreement and the relationship between you and
					PSRnotes shall be governed by the laws of the Commonwealth of Florida
					without regard to conflict of law principles or the United Nations
					Convention on the International Sale of Goods.
				</Typography>
			</div>
			<Typography variant="h4" className={classes.marginTop}>
				11. TERMINATION
			</Typography>
			<div className={classes.paragraph}>
				<Typography variant="h5" className={classes.marginTop}>
					11.1. Termination by You
				</Typography>
				<div className={classes.paragraph}>
					<Typography className={classes.marginTop}>
						You may terminate this Agreement by closing your Account at any time
						for any reason. PSRnotes shall have no further obligation or
						liability to you under this Agreement or otherwise, except as
						required by law (e.g., HIPAA).
					</Typography>
				</div>
				<Typography variant="h5" className={classes.marginTop}>
					11.2. Termination by PSRnotes
				</Typography>
				<div className={classes.paragraph}>
					<Typography className={classes.marginTop}>
						PSRnotes may suspend or terminate your Account for breach if you
						violate this Agreement or any other policies posted on the Services.
						Upon termination, you will remain liable for any unpaid amounts owed
						by you to PSRnotes.
					</Typography>
					<Typography className={classes.marginTop}>
						We may suspend or terminate your Account if we determine in our
						discretion that such action is necessary or advisable to comply with
						legal requirements or protect the rights or interests of PSRnotes or
						any third party. In such event, you will not be entitled to
						compensation for such suspension or termination, and you acknowledge
						PSRnotes will have no liability to you in connection with such
						suspension or termination. We may also cancel or suspend your
						registration for any other reason, including inactivity for an
						extended period, but will attempt to notify you in advance of such
						cancellation or suspension.
					</Typography>
					<Typography className={classes.marginTop}>
						Upon termination of your Account, all licenses granted by PSRnotes
						to use the Services will automatically terminate.
					</Typography>
				</div>
			</div>
			<Typography variant="h4" className={classes.marginTop}>
				12. INTERNATIONAL CONSIDERATIONS
			</Typography>
			<div className={classes.paragraph}>
				<Typography className={classes.marginTop}>
					PSRnotes’ Services are based in the United States. PSRnotes makes no
					representation that any aspect of the Services is appropriate or
					available for use outside of the United States. Those who access the
					Services from other locations are responsible for compliance with
					applicable local laws. The Services may be subject to applicable
					export laws and restrictions.
				</Typography>
			</div>
			<Typography variant="h4" className={classes.marginTop}>
				13. ASSIGNMENT OF AGREEMENT AND ACCOUNT
			</Typography>
			<div className={classes.paragraph}>
				<Typography className={classes.marginTop}>
					You may not assign this Agreement or your Account without the prior
					written consent of PSRnotes. You may not transfer or sublicense any
					licenses granted by PSRnotes in this Agreement without the prior
					written consent of PSRnotes.
				</Typography>
				<Typography className={classes.marginTop}>
					PSRnotes may assign this Agreement (in whole or in part, and all
					related rights, licenses, benefits and obligations, without
					restriction, including the right to sublicense any rights and licenses
					under this Agreement) to any parent, subsidiary, or any affiliated
					company, or as part of the sale to, merger with, or other change in
					control of our company to another entity. We will use reasonable
					efforts to notify you regarding any such change of ownership.
				</Typography>
			</div>
			<Typography variant="h4" className={classes.marginTop}>
				14. INTEGRATION, INTERPRETATION OF SECTION HEADINGS AND SEVERABILITY
			</Typography>
			<div className={classes.paragraph}>
				<Typography className={classes.marginTop}>
					This Agreement (including anything incorporated by reference) sets
					forth the entire agreement and understanding between you and PSRnotes
					with respect to the subject matter hereof and supersedes any prior or
					contemporaneous agreements or understandings. This Agreement may not
					be modified except as provided in Section 1 or by mutual written
					agreement between you and PSRnotes that is signed by a duly authorized
					representative of both parties and expressly references amendment of
					this Agreement. No other written, oral or electronic communications
					will modify or supplement this Agreement, and you agree not to make
					any claims inconsistent with this understanding or in reliance on
					communications not part of this Agreement.
				</Typography>
				<Typography className={classes.marginTop}>
					The section headings used herein, including descriptive summary
					sentences at the start of each section, is for convenience only and
					shall not affect the interpretation of this Agreement.
				</Typography>
				<Typography className={classes.marginTop}>
					If any provision of this Agreement shall be held by a court of
					competent jurisdiction to be unlawful, void, or unenforceable, then in
					such jurisdiction that provision shall be deemed severable from these
					terms and shall not affect the validity and enforceability of the
					remaining provisions.
				</Typography>
				<Typography className={classes.marginTop}>
					No waiver of by us of any term or condition set out in this Agreement
					shall be deemed a further or continuing waiver of such term or
					condition or a waiver of any other term or condition, and any failure
					by us to assert a right or provision under this Agreement shall not
					constitute a waiver of such right or provision.
				</Typography>
			</div>
			<Typography variant="h4" className={classes.marginTop}>
				15. NOTICES AND QUESTIONS FOR PSRnotes
			</Typography>
			<div className={classes.paragraph}>
				<Typography className={classes.marginTop}>
					PSRnotes may provide notice to you and obtain consent from you (1)
					through the Services; (2) by electronic mail at the electronic mail
					address associated with your Account; and/or (3) by written mail
					communication to you at the address associated with your Account.
				</Typography>
				<Typography className={classes.marginTop}>
					Please direct all notices, questions or concerns regarding this
					Agreement or the Services to us at support@psrnotes.com
				</Typography>
			</div>
			<Typography variant="h4" className={classes.marginTop}>
				16. Generally
			</Typography>
			<div className={classes.paragraph}>
				<Typography className={classes.marginTop}>
					PSRnotes will respond to allegations of copyright violations in
					accordance with the Digital Millennium Copyright Act (“DMCA”). The
					DMCA provides a process for a copyright owner to give notification to
					an online service provider concerning alleged copyright infringement.
					When a valid DMCA notification is received, the service provider
					responds under this process by taking down the offending content. On
					taking down content under the DMCA, we will take reasonable steps to
					contact the owner of the removed content so that a
					counter-notification may be filed. On receiving a valid
					counter-notification, we generally restore the content in question,
					unless we receive notice from the notification provider that a legal
					action has been filed seeking a court order to restrain the alleged
					infringer from engaging in the infringing activity.
				</Typography>
				<div className={classes.paragraph}>
					<Typography variant="h5" className={classes.marginTop}>
						16.1. Filing a Notification
					</Typography>
					<div className={classes.paragraph}>
						<Typography className={classes.marginTop}>
							A written notification must be made. This can be done either by
							fax or written letter (regular mail or courier). Emails will not
							be accepted unless a prior arrangement has been made. The
							notification must:
						</Typography>
						<ul>
							<li>
								Identify in sufficient detail the copyrighted work that you
								believe has been infringed upon (i.e., describe the work that
								you own).
							</li>
							<li>
								Identify the item that you claim is infringing on your
								copyright.
							</li>
							<li>
								Provide a reasonably sufficient method of contacting you; phone
								number and email address would be preferred.
							</li>
							<li>
								Include the following statement: "I have good faith belief that
								the use of the copyrighted materials described above and
								maintained by the Services is not authorized by the copyright
								owner, its agent, or by protection of law."
							</li>
							<li>
								Include the following statement: "I swear, under penalty of
								perjury, that the information in the notification is accurate
								and that I am the copyright owner or am authorized to act on
								behalf of the owner of an exclusive right that is allegedly
								infringed."
							</li>
							<li>Sign the paper.</li>
						</ul>
						<Typography className={classes.marginTop}>
							Please note: The DMCA provides that you may be liable for damages
							(including costs and attorneys fees) if you falsely claim that an
							item is infringing your copyrights. We recommend contacting an
							attorney if you are unsure whether an object is protected by
							copyright laws.
						</Typography>
						<Typography className={classes.marginTop}>
							Send the written document to the designated Copyright Agent at
							PSRnotes: support@psrnotes.com
						</Typography>
					</div>
					<Typography variant="h5" className={classes.marginTop}>
						16.2. Filing a counter-notification
					</Typography>
					<div className={classes.paragraph}>
						<ul>
							<li>List the items that were removed by PSRnotes.</li>
							<li>
								Provide your name, address, telephone number, email address (if
								available).
							</li>
							<li>
								State that you consent to the jurisdiction of Federal District
								Court for the judicial district in which you reside (or Miami,
								Florida if your address is outside of the United States).
							</li>
							<li>
								State that you will accept service of process from the person
								who provided notification to us of the alleged infringement or
								an agent of such person.
							</li>
							<li>
								State the following: "I swear, under penalty of perjury, that I
								have a good faith belief that the material identified above was
								removed or disabled as a result of a mistake or
								misidentification of the material to be removed or disabled."
							</li>
							<li>Sign the paper.</li>
						</ul>
						<Typography className={classes.marginTop}>
							Send the written document to the designated Copyright Agent at
							PSRnotes: support@psrnotes.com
						</Typography>
					</div>
				</div>
			</div>
		</div>
	);
}
