import {
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Toolbar,
	Typography,
	withStyles,
} from "@material-ui/core";
import { useState } from "react";
// import PropTypes from "prop-types";
import useStyles from "./styles";

const StyledTableCell = withStyles((theme) => ({
	head: {
		// backgroundColor: `rgba(${hexToRgb(theme.palette.primary.main)}, 1)`,
		backgroundColor: theme.palette.background.paper,
		// color: theme.palette.common.white,
	},
	// body: {
	//   fontSize: 14,
	// },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
	// root: {
	//   "&:nth-of-type(odd)": {
	//     backgroundColor: theme.palette.action.hover,
	//   },
	// },
	hover: {
		cursor: "pointer",
	},
}))(TableRow);

export type Column = {
	id?: string;
	property?: string;
	label: string;
	minWidth?: number;
	align?: string;
	format?: (value: any) => string;
	icon?: any;
	onButtonClick?: (value: any) => void;
};

type EnhancedTableProps = {
	dense?: boolean;
	columns: Column[];
	data: any[];
	rowsPerPage?: number[];
	title?: string;
	onClickCallback?: (value: any) => void;
};

const EnhancedTable = (props: EnhancedTableProps) => {
	const { dense, columns, data, rowsPerPage, title, onClickCallback } = props;

	const classes = useStyles();

	const [page, setPage] = useState(0);
	const [selectedRowsPerPage, setSelectedRowsPerPage] = useState(
		rowsPerPage ? rowsPerPage[0] : 10
	);

	const handleChangePage = (event: any, newPage: any) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: any) => {
		setSelectedRowsPerPage(+event.target.value);
		setPage(0);
	};

	return (
		<Paper className={classes.table}>
			{title && (
				<Toolbar className={classes.toolbar}>
					<Typography className={classes.title}>{title}</Typography>
				</Toolbar>
			)}
			<TableContainer className={classes.tableContainer}>
				<Table
					size={dense ? "small" : "medium"}
					stickyHeader
					aria-label="sticky table"
				>
					<TableHead>
						<TableRow>
							{columns.map((column: any, index: any) => (
								<StyledTableCell
									key={index}
									align={column.align}
									style={{ minWidth: column.minWidth ?? 170 }}
								>
									{column.label}
								</StyledTableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{data
							.slice(
								page * selectedRowsPerPage,
								page * selectedRowsPerPage + selectedRowsPerPage
							)
							.map((row: any, index: any) => {
								return (
									<StyledTableRow
										hover={!!onClickCallback}
										role="checkbox"
										tabIndex={-1}
										key={index}
										onClick={
											onClickCallback
												? () => {
														onClickCallback(row);
												  }
												: undefined
										}
									>
										{columns.map((column: any, index: any) => {
											if (column.icon) {
												return (
													<TableCell
														key={index}
														align={column.align ?? "center"}
													>
														{/* <IconButton varriant="outlined">{column.buttonLabel ?? ""}</IconButton> */}
														<IconButton
															color="primary"
															onClick={(e) => column.onButtonClick(row)}
															style={{ margin: 0, padding: 10 }}
														>
															{column.icon}
														</IconButton>
													</TableCell>
												);
											}
											const value = column.property
												? row[column.id][column.property]
												: row[column.id];
											return (
												<TableCell key={index} align={column.align ?? "center"}>
													{column.format ? column.format(value) : value}
												</TableCell>
											);
										})}
									</StyledTableRow>
								);
							})}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={rowsPerPage ?? [10, 25, 100]}
				component="div"
				count={data.length}
				rowsPerPage={selectedRowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		</Paper>
	);
};

EnhancedTable.defaultProps = {
	dense: false,
};

export default EnhancedTable;
