import React from "react";
import { initialValues, ProductDispatch } from "./misc";
import reducer from "./reducer";

var ProductStateContext = React.createContext(initialValues);
var ProductDispatchContext = React.createContext<ProductDispatch>(() => null);

function ProductProvider({ children }: any) {
	var [state, dispatch] = React.useReducer(reducer, initialValues);
	return (
		<ProductStateContext.Provider value={state}>
			<ProductDispatchContext.Provider value={dispatch}>
				{children}
			</ProductDispatchContext.Provider>
		</ProductStateContext.Provider>
	);
}

function useProductState() {
	var context = React.useContext(ProductStateContext);
	if (context === undefined) {
		throw new Error("useProductState must be used within a ProductProvider");
	}
	return context;
}

function useProductDispatch() {
	var context = React.useContext(ProductDispatchContext);
	if (context === undefined) {
		throw new Error("useProductDispatch must be used within a ProductProvider");
	}
	return context;
}

export { ProductProvider, useProductState, useProductDispatch };
