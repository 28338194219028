import {
	CLIENTS_COLLECTION,
	CUSTOMERS_COLLECTION,
	GOALS_COLLECTION,
	GROUPSTIPS_COLLECTION,
	NOTES_COLLECTION,
	TREATMENTS_COLLECTION,
} from "../../core/constants";
// import { goals, groupTips } from "../../core/constants";
import Firebase from "../../core/firebase";
import { GoalDto } from "../dtos/GoalDto";
import { GroupObjectDto, GroupTipDto } from "../dtos/GroupTipDto";
import { NoteDto } from "../dtos/NoteDto";
import { ObjectiveDto } from "../dtos/ObjectiveDto";
import { TreatmentDto } from "../dtos/TreatmentDto";

export interface INoteRemoteDatasource {
	getGoals(): Promise<GoalDto[]>;
	getTreatments(): Promise<TreatmentDto[]>;
	getGroupTips(): Promise<GroupTipDto[]>;
	create(note: NoteDto): Promise<NoteDto>;
	update(note: NoteDto): Promise<void>;
	delete(clientId: string, noteId: string): Promise<void>;
	setUserGoals(goals: GoalDto[]): Promise<void>;
	getUserGoals(): Promise<GoalDto[]>;
	setUserTreatments(treatments: TreatmentDto[]): Promise<void>;
	getUserTreatments(): Promise<TreatmentDto[]>;
	setUserGroupTips(groupTips: GroupTipDto[]): Promise<void>;
	getUserGroupTips(): Promise<GroupTipDto[]>;
}

export default class NoteRemoteDatasource implements INoteRemoteDatasource {
	async setUserGroupTips(groupTips: GroupTipDto[]): Promise<void> {
		const firebase = Firebase.GetInstance();
		const groupTipsCollectionRef = firebase.firestore
			.collection(CUSTOMERS_COLLECTION)
			.doc(firebase.auth.currentUser?.uid)
			.collection(GROUPSTIPS_COLLECTION);
		groupTips.forEach(async (item) => {
			const snapshot = await groupTipsCollectionRef.where("title", "==", item.title).get();
			var newGroupTip: GroupTipDto = new GroupTipDto({
				...item,
				interventions: item.selectedIntervention
					? [new GroupObjectDto({ ...item.selectedIntervention })]
					: [],
				participations: item.selectedParticipation ? [item.selectedParticipation] : [],
			});
			if (snapshot.docs.length !== 0) {
				const docRef = snapshot.docs[0].ref;
				const data = snapshot.docs[0].data();
				if (data) {
					if (data.interventions && newGroupTip.interventions) {
						var interventions = [
							...data.interventions.map((item: any) => new GroupObjectDto({ ...item })),
							...newGroupTip.interventions,
						];
						interventions = interventions.filter(
							(v, i, a) => a.findIndex((e) => e.label === v.label) === i
						);
						newGroupTip.interventions = interventions;
						// docRef.set(newGroupTip.toRecentJson(), { merge: true });
					}
					if (data.participations && newGroupTip.participations) {
						var participations = [...data.participations, ...newGroupTip.participations];
						participations = participations.filter((v, i, a) => a.findIndex((e) => e === v) === i);
						newGroupTip.participations = participations;
						// docRef.set(newGroupTip.toRecentJson(), { merge: true });
					}
				}
				docRef.set(newGroupTip.toRecentJson(), { merge: true });
			} else {
				groupTipsCollectionRef.add(newGroupTip.toRecentJson());
			}
		});
	}

	async getUserGroupTips(): Promise<GroupTipDto[]> {
		const firebase = Firebase.GetInstance();
		const groupTipsCollection = await firebase.firestore
			.collection(CUSTOMERS_COLLECTION)
			.doc(firebase.auth.currentUser?.uid)
			.collection(GROUPSTIPS_COLLECTION)
			.orderBy("title")
			.get();
		return groupTipsCollection.docs.map((item) => GroupTipDto.fromFirestore(item));
	}

	async getUserTreatments(): Promise<TreatmentDto[]> {
		const firebase = Firebase.GetInstance();
		const treatmentsCollection = await firebase.firestore
			.collection(CUSTOMERS_COLLECTION)
			.doc(firebase.auth.currentUser?.uid)
			.collection(TREATMENTS_COLLECTION)
			.get();
		return treatmentsCollection.docs.map((item) => TreatmentDto.fromFirestore(item));
	}

	async setUserTreatments(treatments: TreatmentDto[]): Promise<void> {
		const firebase = Firebase.GetInstance();
		const treatmentsCollectionRef = firebase.firestore
			.collection(CUSTOMERS_COLLECTION)
			.doc(firebase.auth.currentUser?.uid)
			.collection(TREATMENTS_COLLECTION);
		treatments.forEach(async (t) => {
			const snapshot = await treatmentsCollectionRef.where("question", "==", t.question).get();
			const nTreatment = new TreatmentDto({
				question: t.question,
				answers: t.selectedAnswer ? t.selectedAnswer : [],
			});
			if (snapshot.docs.length !== 0) {
				const docRef = snapshot.docs[0].ref;
				const data = snapshot.docs[0].data();
				if (data && data.answers && nTreatment.answers) {
					var answers: string[] = [...data.answers, ...nTreatment.answers];
					answers = answers.filter((v, i, a) => a.findIndex((e) => e === v) === i);
					// const newT = new TreatmentDto({ question: t.question, answers: answers });
					nTreatment.answers = answers;
				}
				docRef.set(nTreatment.toJson(), { merge: true });
			} else {
				treatmentsCollectionRef.add(nTreatment.toJson());
			}
		});
	}

	async getUserGoals(): Promise<GoalDto[]> {
		const firebase = Firebase.GetInstance();
		const goalsCollection = await firebase.firestore
			.collection(CUSTOMERS_COLLECTION)
			.doc(firebase.auth.currentUser?.uid)
			.collection(GOALS_COLLECTION)
			.orderBy("name")
			.get();
		return goalsCollection.docs.map((item) => GoalDto.fromFirestore(item));
	}

	async setUserGoals(goals: GoalDto[]): Promise<void> {
		const firebase = Firebase.GetInstance();
		const goalsRef = firebase.firestore
			.collection(CUSTOMERS_COLLECTION)
			.doc(firebase.auth.currentUser?.uid)
			.collection(GOALS_COLLECTION);
		goals.forEach(async (item) => {
			const items = await goalsRef
				.where("category", "==", item.category)
				.where("name", "==", item.name)
				.get();

			if (items.docs.length === 0) {
				await goalsRef.add(item.toJson());
			} else {
				items.docs.forEach(async (doc) => {
					const ref = goalsRef.doc(doc.id);
					const data = (await ref.get()).data();
					var objectives: ObjectiveDto[] = [];
					if (data) {
						objectives = [...data?.objectives, ...item.objectives.map((o) => o.toJson())];
						objectives = objectives.filter(
							(v, i, a) => a.findIndex((e) => e.label === v.label) === i
						);
					} else {
						objectives = item.objectives;
					}
					ref.set({ objectives: objectives }, { merge: true });
				});
			}
		});
	}
	async delete(clientId: string, noteId: string): Promise<void> {
		const firebase = Firebase.GetInstance();
		await firebase.firestore
			.collection(CUSTOMERS_COLLECTION)
			.doc(firebase.auth.currentUser?.uid)
			.collection(CLIENTS_COLLECTION)
			.doc(clientId)
			.collection(NOTES_COLLECTION)
			.doc(noteId)
			.delete();
	}
	async update(note: NoteDto): Promise<void> {
		const firebase = Firebase.GetInstance();
		await firebase.firestore
			.collection(CUSTOMERS_COLLECTION)
			.doc(firebase.auth.currentUser?.uid)
			.collection(CLIENTS_COLLECTION)
			.doc(note.clientId)
			.collection(NOTES_COLLECTION)
			.doc(note.id)
			.set(note.toJson(), { merge: true });
		// const doc = await docRef.get();
		// return NoteDto.fromFirestore(doc);
	}

	async create(note: NoteDto): Promise<NoteDto> {
		const firebase = Firebase.GetInstance();
		const docRef = await firebase.firestore
			.collection(CUSTOMERS_COLLECTION)
			.doc(firebase.auth.currentUser?.uid)
			.collection(CLIENTS_COLLECTION)
			.doc(note.clientId)
			.collection(NOTES_COLLECTION)
			.add(note.toJson());
		const doc = await docRef.get();
		return NoteDto.fromFirestore(doc);
	}

	async getTreatments(): Promise<TreatmentDto[]> {
		const firebase = Firebase.GetInstance();
		const treatmentsCollection = await firebase.firestore
			.collection(TREATMENTS_COLLECTION)
			.orderBy("order")
			.get();
		// if (treatmentsCollection.empty) {
		//   treatments.forEach(async (item) => {
		//     await firebase.firestore.collection(TREATMENTS_COLLECTION).add(item);
		//   });
		// }
		return treatmentsCollection.docs.map((item) => TreatmentDto.fromFirestore(item));
	}

	async getGoals(): Promise<GoalDto[]> {
		const firebase = Firebase.GetInstance();
		const goalsCollection = await firebase.firestore
			.collection(GOALS_COLLECTION)
			.orderBy("name")
			.get();
		// if (goalsCollection.empty) {
		//   goals.forEach(async (item) => {
		//     await firebase.firestore.collection(GOALS_COLLECTION).add(item);
		//   });
		// }
		return goalsCollection.docs.map((item) => GoalDto.fromFirestore(item));
	}

	async getGroupTips(): Promise<GroupTipDto[]> {
		const firebase = Firebase.GetInstance();
		const groupTipsCollection = await firebase.firestore
			.collection(GROUPSTIPS_COLLECTION)
			.orderBy("title")
			.get();
		// if (groupTipsCollection.empty) {
		// 	groupTips.forEach(async (item) => {
		// 		await firebase.firestore.collection(GROUPSTIPS_COLLECTION).add(item);
		// 	});
		// }
		return groupTipsCollection.docs.map((item) => GroupTipDto.fromFirestore(item));
	}
}
