import { useState } from "react";
import { Paper, Tab, Tabs } from "@material-ui/core";
import useStyles from "./styles";
import PersonalInformation from "./components/PersonalInformation";
import Subscription from "./components/Subscription";

const TabPanel = (props: any) => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <div style={{ padding: "20px" }}>{children}</div>}
		</div>
	);
};

export default function ProfileTabs() {
	const classes = useStyles();
	const [value, setValue] = useState(0);

	const handleChange = (event: any, newValue: any) => {
		setValue(newValue);
	};

	return (
		<Paper square className={classes.root}>
			<Tabs
				value={value}
				indicatorColor="primary"
				textColor="primary"
				onChange={handleChange}
				variant="scrollable"
				scrollButtons="auto"
				aria-label="disabled tabs example"
			>
				<Tab label="Personal Information" />
				<Tab label="Subscription" />
			</Tabs>
			<TabPanel value={value} index={0}>
				<PersonalInformation />
			</TabPanel>
			<TabPanel value={value} index={1}>
				<Subscription />
			</TabPanel>
		</Paper>
	);
}
