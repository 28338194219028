import { Delete, Edit, People } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { useHistory, useParams } from "react-router";
import { useClientDispatch, useClientState } from "../../../../../../../../application/client";
import ClientActions from "../../../../../../../../application/client/actions";
import { useInjection } from "../../../../../../../../core/dependency_injection";
import PanelHeader, {
	PanelHeaderAction,
	PanelHeaderBreadcrumbsRoute,
} from "../../../../../../components/PanelHeader";
import useFindClientById from "../../../../hooks/useFindClientById";
import GoalPlanForm from "../../components/GoalPlanForm";
import { ConfirmationDialog } from "../../../../../../../core/components/Dialog";
import useOpenable from "../../../../../../../core/hooks/useOpenable";
import GoalPlan from "../../../../../../../../domain/client/models/GoalPlan";
import { useNoteDispatch } from "../../../../../../../../application/note";
import NoteActions from "../../../../../../../../application/note/actions";
import { useEffect } from "react";

export default function GoalPlanEdit() {
	const { id, goalPlanId } = useParams<{ id: string; goalPlanId: string }>();
	const history = useHistory();

	const { selectedClient, goalPlan, isDeleting, isCreating } = useClientState();
	const clientDispatch = useClientDispatch();
	const clientActions = useInjection<ClientActions>("ClientActions");

	const noteDispatch = useNoteDispatch();
	const noteActions = useInjection<NoteActions>("NoteActions");

	const { enqueueSnackbar } = useSnackbar();

	const [open, handleOpen, handleClose] = useOpenable();

	useFindClientById(id);

	useEffect(() => {
		if (!goalPlan.id) {
			const currentGoalPlan = selectedClient.goalPlans?.find((item) => item.id === goalPlanId);
			clientActions.setGoalPlan(clientDispatch, currentGoalPlan ?? GoalPlan.empty());
		} // eslint-disable-next-line
	}, [selectedClient]);

	const panelActions: PanelHeaderAction[] = [
		{
			label: "Update",
			icon: Edit,
			isLoading: isCreating,
			onClick: async () => {
				if (selectedClient.id) {
					if (goalPlan.isValid()) {
						noteActions.setUserGoals(noteDispatch, goalPlan.getNewGoals(), enqueueSnackbar);
						await clientActions.updateGoalPlan(
							clientDispatch,
							selectedClient.id,
							goalPlan,
							history,
							enqueueSnackbar
						);
					} else {
						enqueueSnackbar("Form invalid", { variant: "error" });
					}
				}
			},
		},
		{
			label: "Delete",
			icon: Delete,
			isLoading: isDeleting,
			color: "danger",
			onClick: async () => {
				handleOpen();
			},
		},
	];

	const breadcrumbsRoutes: PanelHeaderBreadcrumbsRoute[] = [
		{
			label: "Clients",
			icon: People,
			to: "/app/clients",
		},
		{
			label: `${selectedClient.name}`,
			to: `/app/clients/${selectedClient.id}`,
			// icon: People,
		},
		{
			label: `Treatment Plan ${goalPlan.startDate.toLocaleDateString(
				"en"
			)}-${goalPlan.endDate.toLocaleDateString("en")}`,
		},
	];
	
	return (
		<>
			<PanelHeader breadcrumbsRoutes={breadcrumbsRoutes} actions={panelActions} />
			<GoalPlanForm />
			<ConfirmationDialog
				open={open}
				handleClose={handleClose}
				title="Delete this treatment plan?"
				content="Are you sure do you want to delete this treatment plan."
				onSuccessCallback={async () => {
					handleClose();
					if (selectedClient.id && goalPlan.id) {
						await clientActions.deleteGoalPlan(
							clientDispatch,
							selectedClient.id,
							goalPlan.id,
							history,
							enqueueSnackbar
						);
					}
				}}
			/>
		</>
	);
}
