import { makeStyles } from "@material-ui/core";
export default makeStyles((theme) => ({
	root: {
		marginTop: "100px",
		minHeight: "100vh",
	},
	flexCenter: {
		display: "flex",
		justifyContent: "center",
		marginTop: "10px",
		textAlign: "center",
	},
	marginTop: {
		marginTop: "60px",
	},
	items: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-around",
		margin: "60px 0px",
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column",
			justifyContent: "center",
		},
	},
}));
