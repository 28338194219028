import { makeStyles } from "@material-ui/core";
export default makeStyles((theme) => ({
	root: {
		marginTop: "20px",
		borderRadius: "10px",
	},
	bold: {
		fontWeight: "bold",
	},
}));
