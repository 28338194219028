import { Add, Group } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import React from "react";
import { useHistory, useParams } from "react-router";
import {
	useGroupDispatch,
	useGroupState,
} from "../../../../../../application/group";
import GroupActions from "../../../../../../application/group/actions";
import {
	useUserDispatch,
	useUserState,
} from "../../../../../../application/user";
import UserActions from "../../../../../../application/user/actions";
import { useInjection } from "../../../../../../core/dependency_injection";
import GroupPlan from "../../../../../../domain/group/models/GroupPlan";
import { EnhancedTable } from "../../../../../core/components/Table";
import { RouteParams } from "../../../../../core/constants";
import PanelHeader, {
	PanelHeaderAction,
	PanelHeaderBreadcrumbsRoute,
} from "../../../../components/PanelHeader";
import useFindGroupById from "../../hooks/useFindGroupById";

export default function GroupPlanPage() {
	const { id } = useParams<RouteParams>();
	const history = useHistory();

	const { user } = useUserState();
	const userDispatch = useUserDispatch();
	const userActions = useInjection<UserActions>("UserActions");

	const { group } = useGroupState();
	const groupDispatch = useGroupDispatch();
	const groupActions = useInjection<GroupActions>("GroupActions");

	const { enqueueSnackbar } = useSnackbar();

	useFindGroupById(id);

	const breadcrumbsRoutes: PanelHeaderBreadcrumbsRoute[] = [
		{
			label: "Groups",
			icon: Group,
			to: "/app/groups",
		},
		{
			label: `${group.name}`,
			to: `/app/groups/${group.id}`,
			// icon: People,
		},
		{
			label: `Group Titles`,
		},
	];

	const panelActions: PanelHeaderAction[] = [
		{
			label: "Add...",
			icon: Add,
			onClick: () => {
				userActions.handleSubscriptionStatus(
					userDispatch,
					user,
					enqueueSnackbar,
					() => {
						groupActions.setGroupPlan(groupDispatch, GroupPlan.empty());
						history.push(`/app/groups/${group.id}/group-plans/create`);
					}
				);
			},
		},
	];

	const columns = [
		{
			id: "date",
			label: "Date",
			minWidth: 170,
			align: "left",
			format: (value: Date) => value.toLocaleDateString("en"),
		},
		{
			id: "groupTips",
			label: "Group Titles",
			minWidth: 170,
			align: "left",
			format: (value: any) => value.length,
		},
	];

	// React.useEffect(() => {
	//   if (group.name === "") {
	//     const currentGroup = sessionGroups.find((item) => item.id === id);
	//     groupActions.setGroup(
	//       groupDispatch,
	//       currentGroup ?? SessionGroup.empty()
	//     );
	//   } // eslint-disable-next-line
	// }, [group, sessionGroups]);

	return (
		<div>
			<PanelHeader
				breadcrumbsRoutes={breadcrumbsRoutes}
				actions={panelActions}
			/>
			{group.groupPlans.length === 0 ? (
				<div>No registered groups titles</div>
			) : (
				<EnhancedTable
					data={group.groupPlans}
					columns={columns}
					onClickCallback={(groupPlan: GroupPlan) => {
						userActions.handleSubscriptionStatus(
							userDispatch,
							user,
							enqueueSnackbar,
							() => {
								groupActions.setGroupPlan(groupDispatch, groupPlan);
								history.push(
									`/app/groups/${group.id}/group-plans/${groupPlan.id}`
								);
							}
						);
						// console.log(`onClickCallback group:`, group);
						
					}}
				/>
			)}
		</div>
	);
}
