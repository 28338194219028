import { useState } from "react";
import {
	Button,
	Card,
	CardContent,
	TextField,
	Typography,
	FormControl,
	Input,
	InputLabel,
	InputAdornment,
	IconButton,
} from "@material-ui/core";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useUserDispatch, useUserState } from "../../../application/user";
import useStyles from "./styles";
import logoBlue from "../../core/assets/images/logo.png";
// import logoWhite from "../../core/assets/images/logo-white1.png";
import { useSnackbar } from "notistack";
import { useInjection } from "../../../core/dependency_injection/ioc.react";
import UserActions from "../../../application/user/actions";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import Company from "../../../domain/user/models/Company";
import { ButtonCircularProgress } from "../../core/components/Progress";

export default function SignUp() {
	document.title = "PSR Notes | Sign Up";

	const classes = useStyles();
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();

	// const { isDark } = useThemeState();

	const { isSubmiting } = useUserState();
	const userDispatch = useUserDispatch();

	const userActions = useInjection<UserActions>("UserActions");

	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	// const [company, setCompany] = useState<Company>(Company.empty());
	const [showPassword, setShowPassword] = useState(false);

	// const logo = isDark ? logoWhite : logoBlue;
	const logo = logoBlue;

	// console.log(`name`, name);
	// console.log(`email`, email);
	// console.log(`password`, password);
	// console.log(`company`, company);

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleMouseDownPassword = (event: any) => {
		event.preventDefault();
	};

	// useEffect(() => {
	//   // TODO: Check if the user is authenticated and if it's fetch his data form backend
	//   // checkAuthenticated(userDispatch);
	//   userActions.checkAuthenticated(userDispatch);
	//   // eslint-disable-next-line
	// }, []);

	return (
		<div className={classes.root}>
			<Card className={classes.card}>
				<CardContent className={classes.cardContent}>
					{/* <Typography variant="h4"> */}
					<div className={classes.logoContainer}>
						<Link className={classes.logolink} to="/">
							<img
								src={logo}
								alt="PSR Notes"
								className={classes.logo}
								onClick={() => {
									history.replace("/");
								}}
							/>
						</Link>
					</div>
					{/* </Typography> */}
					<Typography className={classes.subtitle} variant="h6">
						Try PSR Notes and explore all the tools and services we offer you.
					</Typography>
					<form
						className={classes.form}
						onSubmit={(e) => {
							e.preventDefault();
							userActions.signUp(
								userDispatch,
								name,
								email,
								password,
								Company.empty(),
								history,
								enqueueSnackbar
							);
						}}
					>
						<TextField
							className={classes.margin}
							fullWidth
							label="Your Name"
							value={name}
							onChange={(e) => setName(e.target.value)}
						/>
						<TextField
							className={classes.margin}
							fullWidth
							label="Email address"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
						{/* <TextField
            className={classes.margin}
            fullWidth
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          /> */}
						<FormControl fullWidth className={classes.margin}>
							<InputLabel htmlFor="standard-adornment-password">
								Password
							</InputLabel>
							<Input
								id="standard-adornment-password"
								type={showPassword ? "text" : "password"}
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								endAdornment={
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={handleClickShowPassword}
											onMouseDown={handleMouseDownPassword}
										>
											{showPassword ? <Visibility /> : <VisibilityOff />}
										</IconButton>
									</InputAdornment>
								}
							/>
						</FormControl>
						{/* <div style={{ width: "100%", marginTop: "20px" }}>
							<Typography variant="h6">Your Company</Typography>
						</div>
						<TextField
							// className={classes.margin}
							fullWidth
							label="Name"
							helperText="Optional"
							placeholder="Example: My Company, Inc."
							value={company.name}
							onChange={(e) =>
								setCompany(new Company({ ...company, name: e.target.value }))
							}
						/>
						<TextField
							// className={classes.margin}
							fullWidth
							label="Address"
							helperText="Optional"
							value={company.address}
							onChange={(e) =>
								setCompany(new Company({ ...company, address: e.target.value }))
							}
						/> */}
						<Button
							type="submit"
							fullWidth
							className={classes.button}
							variant="contained"
							disabled={isSubmiting}
							color="primary"
							// onClick={(e) => {
							// 	e.preventDefault();
							// 	userActions.signUp(
							// 		userDispatch,
							// 		name,
							// 		email,
							// 		password,
							// 		company,
							// 		history,
							// 		enqueueSnackbar
							// 	);
							// }}
						>
							Sign Up
							{isSubmiting && <ButtonCircularProgress />}
						</Button>
						<div style={{ marginTop: "10px", textAlign: "center" }}>
							<Typography variant="body2">
								By signing up, I agree PSR Notes{" "}
								<Link
									className={classes.textLink}
									to="/terms"
									target="_blank"
									rel="noreferrer"
								>
									Terms of Services
								</Link>{" "}
								and{" "}
								<Link
									className={classes.textLink}
									to="/privacy"
									target="_blank"
									rel="noreferrer"
								>
									Privacy Policy
								</Link>
							</Typography>
						</div>
						<Typography className={classes.margin} variant="body1">
							Do you have an account?{" "}
							<Link className={classes.link} to="/auth/sign-in">
								Sign in now
							</Link>
						</Typography>
					</form>
				</CardContent>
			</Card>
		</div>
	);
}
