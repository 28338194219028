import { useEffect, useState } from "react";
import { makeStyles, TextField, Typography } from "@material-ui/core";
import WrappedButton from "../../../../../core/components/Button";
// import Proptypes from "prop-types";
import { useSnackbar } from "notistack";
import Client from "../../../../../../domain/client/models/Client";
import { useClientState } from "../../../../../../application/client";
import { ButtonCircularProgress } from "../../../../../core/components/Progress";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		padding: "30px 10px",
		maxWidth: "30vw",
		[theme.breakpoints.down("sm")]: {
			maxWidth: "90vw",
		},
	},
	content: {
		width: "80%",
		[theme.breakpoints.down("sm")]: {
			width: "90%",
		},
	},
	textField: {
		marginTop: "15px",
	},
	buttonContainer: {
		marginTop: "30px",
		// width: "100%",
		// width: "80%",
	},
	chips: {
		display: "flex",
		flexWrap: "wrap",
	},
	button: {
		width: "100%",
	},
}));

// const selectLanguages = ["English", "Spanish"];

type EditClientModalContentProps = {
	isEditing?: boolean;
	onSuccessCallback: (client: Client) => void;
};

export default function EditClientModalContent(
	props: EditClientModalContentProps
) {
	const classes = useStyles();

	const { isEditing, onSuccessCallback } = props;

	// const { user } = useUserState();

	const { isCreating, selectedClient } = useClientState();

	const { enqueueSnackbar } = useSnackbar();

	const [client, setClient] = useState<Client>(Client.empty());

	useEffect(() => {
		if (isEditing && selectedClient) {
			setClient(selectedClient);
		}
	}, [isEditing, selectedClient]);

	const onButtonClick = () => {
		if (client.isValid()) {
			onSuccessCallback(client);
		} else {
			enqueueSnackbar("Form invalid", { variant: "error" });
		}
	};

	// console.log(`EditClientModalContent client`, client);

	return (
		<div className={classes.root}>
			<div className={classes.content}>
				<Typography variant="h3">
					{isEditing ? "Edit Client" : "Add Client"}
				</Typography>
				<TextField
					className={classes.textField}
					fullWidth
					label="Name"
					value={client.name}
					onChange={(e) =>
						setClient(new Client({ ...client, name: e.target.value }))
					}
				/>
				{/* <TextField
					className={classes.textField}
					fullWidth
					label="Email"
					helperText="Optional"
					value={client.email}
					onChange={(e) =>
						setClient(new Client({ ...client, email: e.target.value }))
					}
				/>
				<TextField
					className={classes.textField}
					fullWidth
					label="Phone"
					helperText="Optional"
					value={client.phone}
					onChange={(e) =>
						setClient(new Client({ ...client, phone: e.target.value }))
					}
				/> */}
				<TextField
					className={classes.textField}
					fullWidth
					label="Service Code"
					value={client.serviceCode}
					onChange={(e) =>
						setClient(new Client({ ...client, serviceCode: e.target.value }))
					}
				/>
				<TextField
					className={classes.textField}
					fullWidth
					label="Case #"
					value={client.case}
					onChange={(e) =>
						setClient(new Client({ ...client, case: e.target.value }))
					}
				/>
				<TextField
					className={classes.textField}
					fullWidth
					label="Setting"
					value={client.setting}
					onChange={(e) =>
						setClient(new Client({ ...client, setting: e.target.value }))
					}
				/>
				<TextField
					className={classes.textField}
					fullWidth
					label="Diagnosis"
					value={client.diagnosis}
					onChange={(e) =>
						setClient(new Client({ ...client, diagnosis: e.target.value }))
					}
				/>
				<div className={classes.buttonContainer}>
					<WrappedButton
						className={classes.button}
						disabled={isCreating}
						onClick={onButtonClick}
					>
						{isEditing ? "Update" : "Add"}
						{isCreating && <ButtonCircularProgress />}
					</WrappedButton>
				</div>
			</div>
		</div>
	);
}

// EditContractorModalContent.propTypes = {
//   isEditing: Proptypes.bool,
//   onSuccessCallback: Proptypes.func.isRequired,
// };
