import {
	CLIENTS_COLLECTION,
	CUSTOMERS_COLLECTION,
	GROUP_PLANS_COLLECTION,
	GROUPS_COLLECTION,
} from "../../core/constants";
import { ClientDto } from "../../client/dtos/ClientDto";
import Firebase from "../../core/firebase";
import { GroupPlanDto } from "../dtos/GroupPlanDto";
import { SessionGroupDto } from "../dtos/SessionGroupDto";
import firebase from "firebase";
export interface IGroupRemoteDatasource {
	getAllSessionGroups(): Promise<SessionGroupDto[]>;
	create(group: SessionGroupDto): Promise<SessionGroupDto>;
	delete(id: string): Promise<void>;
	update(group: SessionGroupDto): Promise<void>;
	getGroupPlanByDate(groupId: string, date: Date): Promise<GroupPlanDto>;
	createGroupPlan(clientId: string, groupPlan: GroupPlanDto): Promise<GroupPlanDto>;
	updateGroupPlan(groupId: string, groupPlan: GroupPlanDto): Promise<void>;
	deleteGroupPlan(groupId: string, planId: string): Promise<void>;
}

export default class GroupRemoteDatasource implements IGroupRemoteDatasource {
	async getGroupPlanByDate(clientId: string, date: Date): Promise<GroupPlanDto> {
		const firebaseInstance = Firebase.GetInstance();
		const clients = await firebaseInstance.firestore
			.collection(CUSTOMERS_COLLECTION)
			.doc(firebaseInstance.auth.currentUser?.uid)
			.collection(GROUPS_COLLECTION)
			.where("clients", "array-contains", clientId)
			.get();
		const clientRef = clients.docs[0].ref;
		const groupPlans = await clientRef
			.collection(GROUP_PLANS_COLLECTION)
			.where(
				"date",
				"==",
				firebase.firestore.Timestamp.fromDate(
					new Date(date.getFullYear(), date.getMonth(), date.getDate())
				)
			)
			.get();

		return GroupPlanDto.fromFirestore(groupPlans.docs[0]);
	}

	async deleteGroupPlan(groupId: string, planId: string): Promise<void> {
		const firebase = Firebase.GetInstance();
		await firebase.firestore
			.collection(CUSTOMERS_COLLECTION)
			.doc(firebase.auth.currentUser?.uid)
			.collection(GROUPS_COLLECTION)
			.doc(groupId)
			.collection(GROUP_PLANS_COLLECTION)
			.doc(planId)
			.delete();
	}

	async updateGroupPlan(groupId: string, groupPlan: GroupPlanDto): Promise<void> {
		const firebase = Firebase.GetInstance();
		await firebase.firestore
			.collection(CUSTOMERS_COLLECTION)
			.doc(firebase.auth.currentUser?.uid)
			.collection(GROUPS_COLLECTION)
			.doc(groupId)
			.collection(GROUP_PLANS_COLLECTION)
			.doc(groupPlan.id)
			.set(groupPlan.toJson(), { merge: true });
	}

	async createGroupPlan(groupId: string, groupPlan: GroupPlanDto): Promise<GroupPlanDto> {
		const firebase = Firebase.GetInstance();
		const docRef = await firebase.firestore
			.collection(CUSTOMERS_COLLECTION)
			.doc(firebase.auth.currentUser?.uid)
			.collection(GROUPS_COLLECTION)
			.doc(groupId)
			.collection(GROUP_PLANS_COLLECTION)
			.add(groupPlan.toJson());
		const doc = await docRef.get();
		return new GroupPlanDto({
			...groupPlan,
			id: doc.id,
		});
	}

	async getAllSessionGroups(): Promise<SessionGroupDto[]> {
		const firebase = Firebase.GetInstance();
		const groups = await firebase.firestore
			.collection(CUSTOMERS_COLLECTION)
			.doc(firebase.auth.currentUser?.uid)
			.collection(GROUPS_COLLECTION)
			.get();
		return Promise.all(
			groups.docs.map(async (doc) => {
				var clientIds: string[] = doc.data().clients;
				var clients: ClientDto[] = await Promise.all(
					clientIds.map(async (id) => {
						const client = await firebase.firestore
							.collection(CUSTOMERS_COLLECTION)
							.doc(firebase.auth.currentUser?.uid)
							.collection(CLIENTS_COLLECTION)
							.doc(id)
							.get();
						const dto = await ClientDto.fromFirestore(client);
						return dto;
					})
				);
				var plans = await doc.ref.collection(GROUP_PLANS_COLLECTION).orderBy("date", "desc").get();
				return new SessionGroupDto({
					id: doc.id,
					name: doc.data().name,
					clients: clients,
					groupPlans: plans.docs.map((item) => GroupPlanDto.fromFirestore(item)),
				});
			})
		);
	}
	async create(group: SessionGroupDto): Promise<SessionGroupDto> {
		const firebase = Firebase.GetInstance();
		const docRef = await firebase.firestore
			.collection(CUSTOMERS_COLLECTION)
			.doc(firebase.auth.currentUser?.uid)
			.collection(GROUPS_COLLECTION)
			.add(group.toJson());
		const doc = await docRef.get();
		return new SessionGroupDto({
			...group,
			id: doc.id,
		});
	}

	async update(group: SessionGroupDto): Promise<void> {
		const firebase = Firebase.GetInstance();
		await firebase.firestore
			.collection(CUSTOMERS_COLLECTION)
			.doc(firebase.auth.currentUser?.uid)
			.collection(GROUPS_COLLECTION)
			.doc(group.id)
			.set(group.toJson(), { merge: true });
	}

	async delete(id: string): Promise<void> {
		const firebase = Firebase.GetInstance();
		const docRef = firebase.firestore
			.collection(CUSTOMERS_COLLECTION)
			.doc(firebase.auth.currentUser?.uid)
			.collection(GROUPS_COLLECTION)
			.doc(id);
		const groupPlansCollectionRef = docRef.collection(GROUP_PLANS_COLLECTION);
		await firebase.deleteQueryBatch(groupPlansCollectionRef);
		await docRef.delete();
	}
}
