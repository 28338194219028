import { makeStyles } from "@material-ui/core";
export default makeStyles((theme) => ({
	root: {
		display: "flex",
		flexDirection: "column",
		maxWidth: "30vw",
		[theme.breakpoints.down("sm")]: {
			maxWidth: "100%",
			padding: "20px",
		},
	},
	titleContainer: {
		display: "flex",
		flexDirection: "row",
		marginBottom: "16px",
	},
	icon: {
		color: theme.palette.primary.main,
		marginRight: "10px",
	},
}));
