import { makeStyles } from "@material-ui/core";
export default makeStyles((theme) => ({
  root: {
    marginBottom: "20px",
  },
  label: {
    fontWeight: "bold",
  },
  value: {
    // fontSize: "14px",
  },
}));
