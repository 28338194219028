import { Typography } from "@material-ui/core";
import useStyles from "./styles";

export default function Privacy() {
	document.title = "PSR Notes | Privacy Policy";

	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Typography variant="h3">PRIVACY POLICY</Typography>
			<div className={classes.paragraph}>
				<Typography className={classes.marginTop}>
					PSRnotes , (“PSRnotes,” “we,” or “us”) is committed to protecting your
					privacy and the privacy of your Personal Information. This Privacy
					Policy explains the use of information collected via the PSRnotes.com
					and website, and related applications, online services and mobile
					applications provided by PSRnotes (collectively, the “Services”). Our
					Terms of Service together with this Privacy Policy (collectively, the
					“Agreement”) govern your use of the Services.
				</Typography>
				<Typography className={classes.marginTop}>
					The Agreement applies to any registered or a non-registered patient or
					other user of the Services (“User”), including a doctor, therapist, or
					other healthcare specialist, professional or provider using the
					Services in connection with a healthcare-related practice or other
					organization (“Service Provider”).
				</Typography>
				<Typography className={classes.marginTop}>
					BY USING OR OTHERWISE ACCESSING THE SERVICES, AND/OR BY REGISTERING
					WITH US, YOU AGREE TO THIS AGREEMENT. IF YOU DO NOT AGREE, YOU CANNOT
					USE THE SERVICES.
				</Typography>
				<Typography className={classes.marginTop}>
					PLEASE SEE SECTIONS 9 AND 10 OF OUR TERMS OF SERVICE REGARDING YOUR
					LEGAL RIGHTS IN ANY DISPUTE INVOLVING OUR WEBSITES, SERVICES, TERMS OF
					SERVICE OR PRIVACY POLICY.
				</Typography>
			</div>
			<Typography variant="h4" className={classes.marginTop}>
				1. INTRODUCTION
			</Typography>
			<div className={classes.paragraph}>
				<Typography variant="h5" className={classes.marginTop}>
					1.1. About the Privacy Policy
				</Typography>
				<div className={classes.paragraph}>
					<Typography className={classes.marginTop}>
						As further described below, we collect and maintain a range of data
						in order to provide Services to you and maintain safe and secure
						Services. Access to your Personal Information (described below) is
						limited to (a) PSRnotes employees and subcontractors who help with
						our business operations (such as to host our website platform,
						provide payment, billing or similar services, or perform anti-fraud
						functions), and (b) Service Providers with whom you elect to share
						information (such as a doctor).
					</Typography>
				</div>
				<Typography variant="h5" className={classes.marginTop}>
					1.2. Effective Date and Modifications
				</Typography>
				<div className={classes.paragraph}>
					<Typography className={classes.marginTop}>
						This Privacy Policy is effective as of 9/15/2020.
					</Typography>
					<Typography className={classes.marginTop}>
						This Privacy Policy may be revised or updated by PSRnotes from time
						to time in its sole discretion. Where appropriate, PSRnotes will
						provide notice to you as provided in the Terms of Service. By
						continuing to access or use the Services after the effective date of
						any such change, you agree to be bound by the modified Privacy
						Policy.
					</Typography>
				</div>
			</div>
			<Typography variant="h4" className={classes.marginTop}>
				2. INFORMATION WE COLLECT
			</Typography>
			<div className={classes.paragraph}>
				<Typography className={classes.marginTop}>
					In this Privacy Policy, the term “Personal Information” means any
					information that can readily be associated with a particular
					individual. As described below, Personal Information can include name
					and contact information, technical information, and health-related
					information known as “Protected Health Information” or “PHI.”
				</Typography>
				<Typography className={classes.marginTop}>
					We collect a range of Personal Information and usage statistics to
					maintain a high-quality user experience and deliver superior customer
					service. We request some information directly from you during
					registration. We gather other pieces of data indirectly from website
					traffic, your computer hardware and Internet connection, or your
					activities within the Services, communications, and usage.
				</Typography>
				<Typography variant="h5" className={classes.marginTop}>
					2.1. Information You Voluntarily Provide
				</Typography>
				<div className={classes.paragraph}>
					<Typography className={classes.marginTop}>
						2.1.1. If you register with any Services we offer, we may collect
						your username and password, contact information (e.g., name, email
						address, address, and phone number), and payment information (credit
						card, etc.).
					</Typography>
					<Typography className={classes.marginTop}>
						2.1.2. We also collect other information you provide to the Services
						and the transactions you perform (e.g., Account information and
						preferences, content, and other materials). Note that certain
						content that you may provide (such as picture files) could contain
						associated metadata information about the content.
					</Typography>
					<Typography className={classes.marginTop}>
						2.1.3. We will generally store communications between you and
						PSRnotes.
					</Typography>
				</div>
				<Typography variant="h5" className={classes.marginTop}>
					2.2. Information That Is Passively Collected
				</Typography>
				<div className={classes.paragraph}>
					<Typography className={classes.marginTop}>
						2.2.1. We collect traffic and analytics information through the
						Services such as your IP address, mobile device identifier, browser
						information, site usage information, use of different features and
						functionality, and other information that assists us in monitoring
						and improving the Services. Our Services may maintain log files that
						contain similar information regarding use of the Services (or links
						in communications).
					</Typography>
					<Typography className={classes.marginTop}>
						2.2.2. We may collect certain types of information through
						“cookies,” which are described in Section 8 below.
					</Typography>
					<Typography className={classes.marginTop}>
						2.2.3. In some circumstances we may receive information about you,
						including Personal Information, from third parties such as a Service
						Provider. If we do so, this Privacy Policy governs any combined
						information that we maintain in a personally identifiable format.
					</Typography>
				</div>
				<Typography variant="h5" className={classes.marginTop}>
					2.3. HIPAA and Protected Health Information
				</Typography>
				<div className={classes.paragraph}>
					<Typography className={classes.marginTop}>
						Under a federal law called the Health Insurance Portability and
						Accountability Act (“HIPAA”) and subsequent laws and regulations,
						when PSRnotes receives certain health-related information about you,
						that information may be classified as “Protected Health Information”
						(“PHI”) under the law. HIPAA provides specific protections for the
						privacy and security of your PHI and restricts how it may be used
						and disclosed. Notwithstanding any statements about the use of your
						Personal Information more generally in this Privacy Policy, PSRnotes
						will only use and disclose your PHI as authorized by you and/or your
						Service Provider(s) in accordance with applicable law.
					</Typography>
					<Typography className={classes.marginTop}>
						To that end, before any PHI that you provide is shared with a
						Service Provider, you may be asked to affirmatively authorize
						PSRnotes to do so. By providing such authorization, you are
						permitting PSRnotes to release your Personal Information, including
						PHI, to those specified Service Provider(s) pursuant to HIPAA. Your
						authorization is entirely voluntary, but without authorization,
						PSRnotes will not be able to share your information with selected
						Service Providers.
					</Typography>
				</div>
			</div>
			<Typography variant="h4" className={classes.marginTop}>
				3. HOW WE USE YOUR INFORMATION
			</Typography>
			<div className={classes.paragraph}>
				<Typography className={classes.marginTop}>
					We use Personal Information for legitimate business purposes as
					allowed by applicable law, such as to:
				</Typography>
				<ol>
					<li>
						Provide you with the Services, including your interactions with
						Service Providers and customer support;
					</li>
					<li>Comply with third party contracts;</li>
					<li>
						Help you efficiently access your information after you sign in;
					</li>
					<li>
						Remember information so you will not have to re-enter it during your
						visit or the next time you visit the Services;
					</li>
					<li>
						Provide personalized content and information to you and others,
						which could include online ads or other forms of marketing;
					</li>
					<li>
						Provide, improve, test, and monitor the effectiveness of the
						Services;
					</li>
					<li>Develop and test new products and features;</li>
					<li>
						Monitor metrics such as total number of visitors, traffic, and
						demographic patterns; or
					</li>
					<li>Diagnose or fix technology problems.</li>
				</ol>
			</div>
			<Typography variant="h4" className={classes.marginTop}>
				4. SHARING OF YOUR INFORMATION
			</Typography>
			<div className={classes.paragraph}>
				<Typography className={classes.marginTop}>
					We are not in the business of selling Personal Information about our
					users to third parties without your consent. Parties with whom we may
					share your information are:
				</Typography>
				<div className={classes.paragraph}>
					<Typography variant="h5" className={classes.marginTop}>
						4.1. Our Contractors
					</Typography>
					<div className={classes.paragraph}>
						<Typography className={classes.marginTop}>
							We may either employ or contract with third party companies for
							the purposes of operating our Services, enhancing our Services, or
							fulfilling your requests (such as hosting online platforms,
							anti-fraud functions, billing, collections, registration, customer
							support, or email delivery). In order for these third party
							companies to carry out their services, your personal Information
							and other data collected may be shared with these third party
							companies, but our contracts with these third parties prohibit
							them from using any of your Personal Information for purposes
							unrelated to the services they are providing to us.
						</Typography>
					</div>
					<Typography variant="h5" className={classes.marginTop}>
						4.2. Selected Service Provider(s)
					</Typography>
					<div className={classes.paragraph}>
						<Typography className={classes.marginTop}>
							We may share Personal Information (including PHI) with selected
							Service Provider(s) where we are authorized to do so as described
							above.
						</Typography>
					</div>
					<Typography variant="h5" className={classes.marginTop}>
						4.3. Government, Courts, and Law Enforcement
					</Typography>
					<div className={classes.paragraph}>
						<Typography className={classes.marginTop}>
							We may access, preserve, and share information about you, without
							consent, to government or law enforcement officials or private
							parties as we in good faith believe necessary or appropriate to
							respond to claims and legal process (including, but not limited
							to, subpoenas), to comply with applicable laws, to protect the
							property and rights of PSRnotes, you or a third party, to protect
							the safety of the public or any person, or to prevent or stop
							activity we may consider to pose a risk of being, or is illegal,
							unethical or legally actionable. This may include responding to
							legal requests from jurisdictions outside of the United States
							where we have a good faith belief that the response is required by
							law in that jurisdiction, affects users in that jurisdiction, and
							is consistent with internationally recognized standards.
						</Typography>
						<Typography className={classes.marginTop}>
							In the event that we receive a subpoena or other order issued by
							or under authority of a court of competent jurisdiction compelling
							the disclosure of any PHI, (unless prohibited by law) we will
							attempt to notify you or the Service Provider with whom you have a
							relationship of the subpoena or order prior to disclosing the
							information in order to provide an opportunity to intervene or
							otherwise prevent the disclosure. A Service Provider shall be
							responsible for any fees and expenses reasonably incurred by
							PSRnotes in responding to a subpoena seeking records of that
							Service Provider, its personnel, or its patients.
						</Typography>
						<Typography className={classes.marginTop}>
							We may also access, preserve and share information when we have a
							good faith belief it is necessary to: detect, prevent and address
							fraud and other illegal activity; to protect ourselves, you and
							others, including as part of investigations; and to prevent death
							or imminent bodily harm. Information we receive about you may be
							accessed, processed and retained for an extended period of time
							when it is the subject of a legal request or obligation,
							governmental investigation, or investigations concerning possible
							violations of our terms or policies, or otherwise to prevent harm.
						</Typography>
					</div>
					<Typography variant="h5" className={classes.marginTop}>
						4.4. Parties with Whom You May Choose to Share Your Information
					</Typography>
					<div className={classes.paragraph}>
						<Typography className={classes.marginTop}>
							If you voluntarily share or submit any information to be shared
							with other parties or the public, or link it to any social media
							platforms or the public, that information may become available to
							those parties or the public.
						</Typography>
					</div>
					<Typography variant="h5" className={classes.marginTop}>
						4.5. Using and Sharing Anonymized Data
					</Typography>
					<div className={classes.paragraph}>
						<Typography className={classes.marginTop}>
							We may de-identify and anonymize Personal Information so it is no
							longer associated with any individual(s) in order to use it for
							other purposes, such as aggregating the data for statistical
							analysis.
						</Typography>
					</div>
				</div>
			</div>
			<Typography variant="h4" className={classes.marginTop}>
				5. PROTECTION OF YOUR INFORMATION
			</Typography>
			<div className={classes.paragraph}>
				<Typography className={classes.marginTop}>
					By registering for and using our Services, you consent to the transfer
					of your Personal Information to anywhere where we, our contractors, or
					Service Providers maintain facilities for the use of your information
					as described in this Privacy Policy.
				</Typography>
				<Typography className={classes.marginTop}>
					Although we implement reasonable administrative, physical and
					electronic security measures designed to protect your Personal
					Information from unauthorized access, we cannot ensure the security of
					any information you transmit to or guarantee that this information
					will not be accessed, disclosed, altered, or destroyed. We will make
					any legally required disclosures of any breach of the security,
					confidentiality, or integrity of your Personal Information. To the
					extent the law of your jurisdiction allows for notification of a
					breach via e-mail or conspicuous posting through the Services, you
					agree to accept notice in that form.
				</Typography>
			</div>
			<Typography variant="h4" className={classes.marginTop}>
				6. YOUR CHOICES ABOUT YOUR INFORMATION
			</Typography>
			<div className={classes.paragraph}>
				<Typography className={classes.marginTop}>
					You may be able to modify certain features in your Account profile
					page. In addition, please note the following:
				</Typography>
				<ol>
					<li>
						If we offer any communications by text message, you may opt-out of
						receiving text messages by replying “STOP” to any text message
						received.
					</li>
					<li>
						In all promotional emails, you will be given the opportunity to
						opt-out of receiving such messages in the future by clicking on the
						link at the bottom of the email that says “unsubscribe.” It may take
						up to ten (10) business days for us to process your opt-out request.
					</li>
					<li>
						Even if you have unsubscribed from receiving promotional emails from
						us, we may send you other types of important email communications
						without offering you the opportunity to opt-out of receiving them,
						such as announcements about the Services and administrative notices
						(e.g., account verification, payment confirmations, technical and
						security notices).
					</li>
				</ol>
			</div>
			<Typography variant="h4" className={classes.marginTop}>
				7. RETENTION OF DATA
			</Typography>
			<div className={classes.paragraph}>
				<Typography className={classes.marginTop}>
					Information we collect may be retained indefinitely, but we may elect
					to only retain such information to the extent necessary for business
					and legal purposes. If you cease being a user, we may retain your
					Personal Information in the event you wish to rejoin the Services, and
					for anti-fraud and other purposes.
				</Typography>
			</div>
			<Typography variant="h4" className={classes.marginTop}>
				8. USE OF COOKIES
			</Typography>
			<div className={classes.paragraph}>
				<Typography className={classes.marginTop}>
					A “cookie” is a small string of data which often includes an anonymous
					unique identifier sent to your browser from a website’s computers, and
					stored on your computer’s hard drive. Each website can send its own
					cookie to your browser if your browser’s preferences allow it, but (to
					protect your privacy) your browser only permits a website to access
					the cookies it has already sent to you, not the cookies sent to you by
					other sites. These “cookies” and other similar technologies like
					pixels, web beacons (also known as “clear GIFs”), and local storage
					may be used to collect information about how you use the Services and
					provide features to you.
				</Typography>
				<Typography className={classes.marginTop}>
					You can configure your browser to accept all cookies, reject all
					cookies, or notify you when a cookie is set. (Each browser is
					different, so check the “Help” menu of your browser to learn how to
					change your cookie preferences.) However, if you reject all cookies,
					you may not be able to use our Services.
				</Typography>
				<Typography className={classes.marginTop}>
					PSRnotes and third parties acting on our behalf may use cookies for a
					number of purposes relating to the Services including to:
				</Typography>
				<ol>
					<li>
						Access your Account information when you "log in" to our Services;
					</li>
					<li>Keep track of your website session data; and</li>
					<li>
						Keep track of certain information that is then aggregated (i.e., not
						connected to your Personal Information), such as how you use our
						Services, what web pages you visit, and the frequency and length of
						those visits.
					</li>
				</ol>
			</div>
			<Typography variant="h4" className={classes.marginTop}>
				9. THIRD PARTY ADVERTISEMENTS
			</Typography>
			<div className={classes.paragraph}>
				<Typography className={classes.marginTop}>
					PSRnotes may participate in ad platforms operated by third parties.
					These companies may collect and use certain anonymous information
					about your use of the Services. We do not permit these companies to
					collect any Personal Information about you, such as your name,
					address, or email address; however, they may obtain your IP address or
					set and use cookies, web beacons, pixels, or other technologies to
					collect anonymous information about your use of the Services.
				</Typography>
			</div>
			<Typography variant="h4" className={classes.marginTop}>
				10. CHANGE OF CONTROL
			</Typography>
			<div className={classes.paragraph}>
				<Typography className={classes.marginTop}>
					We may share Personal Information with companies under common control
					(such as a parent company or subsidiary) to be used in a manner
					consistent with this Privacy Policy. If part or all of PSRnotes or our
					assets is transferred to another organization, your Personal
					Information may be among the items transferred. The recipient will
					have to honor the commitments we have made in this Privacy Policy.
				</Typography>
			</div>
			<Typography variant="h4" className={classes.marginTop}>
				11. AMENDMENT OF THIS POLICY
			</Typography>
			<div className={classes.paragraph}>
				<Typography className={classes.marginTop}>
					We may need to update our Privacy Policy periodically to reflect
					changes in the types of information we collect, the means we use to
					collect information, or our usage of collected information. We will
					make reasonable efforts to alert you to any material changes when they
					occur. Changes will generally take effect immediately upon posting to
					our Services.
				</Typography>
			</div>
			<Typography variant="h4" className={classes.marginTop}>
				12. CHILDREN’S PRIVACY
			</Typography>
			<div className={classes.paragraph}>
				<Typography className={classes.marginTop}>
					We do not knowingly collect or solicit any information from anyone
					under the age of 13 or knowingly allow such persons to register for
					the Services. The Services and their content are not directed at
					children under the age of 13. In the event that we learn that we have
					collected Personal Information from a child under age 13 without
					parental consent, we will delete that information as quickly as
					possible. If you believe that we might have any information from a
					child under 13, please contact us.
				</Typography>
			</div>
			<Typography variant="h4" className={classes.marginTop}>
				13. HOW WE RESPOND TO DO NOT TRACK SIGNALS
			</Typography>
			<div className={classes.paragraph}>
				<Typography className={classes.marginTop}>
					We do not track our users over time and across third party websites to
					provide targeted advertising. We do not respond to Do Not Track (DNT)
					signals and handle all Personal Information consistent with our Terms
					of Service and this Privacy Policy.
				</Typography>
			</div>
			<Typography variant="h4" className={classes.marginTop}>
				14. OTHER WEB SITES AND SERVICES
			</Typography>
			<div className={classes.paragraph}>
				<Typography className={classes.marginTop}>
					The Services may be embedded within, or contain links to, other
					websites or services that we do not own or operate. We are not
					responsible for the practices employed by such websites or services,
					including the information or content contained within them. Your use
					of any third-party website or service is subject to that third party’s
					own rules and policies, not ours.
				</Typography>
				<Typography className={classes.marginTop}>
					You agree that we are not responsible for and do not have control over
					any third parties that you authorize to access your Personal
					Information (which authorization may include PHI). If you are using a
					third-party website or service and you allow them to access your
					Personal Information you do so at your own risk.
				</Typography>
			</div>
			<Typography variant="h4" className={classes.marginTop}>
				15. INTERNATIONAL CONSIDERATIONS
			</Typography>
			<div className={classes.paragraph}>
				<Typography className={classes.marginTop}>
					We have developed data practices designed to assure information is
					appropriately protected but we cannot always know where Personal
					Information may be accessed or processed. While our Services are
					maintained and operated within the United States, we may transfer
					Personal Information outside of the United States if necessary. As
					described above, we may employ contractors to perform functions on our
					behalf, and if Personal Information is accessible to a third party or
					to our employees outside of the United States, we will seek assurances
					that such information is safeguarded adequately, in accordance with
					this Privacy Policy and applicable laws.
				</Typography>
				<Typography className={classes.marginTop}>
					Our Services are directed to United States residents. If you are
					visiting from the European Union or other regions with laws governing
					data collection and use, please note that you are transferring your
					Personal Information, potentially including sensitive data, to
					countries like the United States that may not provide the same level
					of legal protection that is available in your country. By providing
					your Personal Information, you consent to any transfer and processing
					in accordance with this Privacy Policy.
				</Typography>
			</div>
			<Typography variant="h4" className={classes.marginTop}>
				16. HOW TO CONTACT US
			</Typography>
			<div className={classes.paragraph}>
				<Typography className={classes.marginTop}>
					Please direct questions or concerns about this Privacy Policy or the
					Services to us at support@psrnotes.com.
				</Typography>
			</div>
		</div>
	);
}
