import { Result } from "../../domain/core/Result";
import { INoteRepository } from "../../domain/note/inote.repository";
import Goal from "../../domain/note/models/Goal";
import GroupTip from "../../domain/note/models/GroupTip";
import Note from "../../domain/note/models/Note";
import Treatment from "../../domain/note/models/Treatment";
import { INoteRemoteDatasource } from "./datasources/remote";
import { GoalDto } from "./dtos/GoalDto";
import { GroupTipDto } from "./dtos/GroupTipDto";
import { NoteDto } from "./dtos/NoteDto";
import { TreatmentDto } from "./dtos/TreatmentDto";

export default class NoteRepository implements INoteRepository {
	private remoteDatasource: INoteRemoteDatasource;

	public constructor(remoteDatasource: INoteRemoteDatasource) {
		this.remoteDatasource = remoteDatasource;
	}
	async setUserGroupTips(groupTips: GroupTip[]): Promise<Result<void>> {
		try {
			await this.remoteDatasource.setUserGroupTips(
				groupTips.map((item) => GroupTipDto.fromDomain(item))
			);
			// console.log(`signDto.toDomain()`, signDto);
			return Result.rigth("Treatments saved successfully");
		} catch (error: any) {
			return Result.left(error.message);
		}
	}
	async getUserGroupTips(): Promise<Result<GroupTip[]>> {
		try {
			const dtos = await this.remoteDatasource.getUserGroupTips();
			return Result.rigth(dtos.map((item) => item.toDomain()));
		} catch (error: any) {
			console.log(`error`, error);
			return Result.left(error.message);
		}
	}
	async getUserTreatments(): Promise<Result<Treatment[]>> {
		try {
			const dtos = await this.remoteDatasource.getUserTreatments();
			return Result.rigth(dtos.map((item) => item.toDomain()));
		} catch (error: any) {
			console.log(`error`, error);
			return Result.left(error.message);
		}
	}
	async setUserTreatments(treatments: Treatment[]): Promise<Result<void>> {
		try {
			await this.remoteDatasource.setUserTreatments(
				treatments.map((item) => TreatmentDto.fromDomain(item))
			);
			// console.log(`signDto.toDomain()`, signDto);
			return Result.rigth("Treatments saved successfully");
		} catch (error: any) {
			return Result.left(error.message);
		}
	}
	async getUserGoals(): Promise<Result<Goal[]>> {
		try {
			const dtos = await this.remoteDatasource.getUserGoals();
			return Result.rigth<Goal[]>(dtos.map((item) => item.toDomain()));
		} catch (error: any) {
			console.log(`error`, error);
			return Result.left(error.message);
		}
	}

	async setUserGoals(goals: Goal[]): Promise<Result<void>> {
		try {
			await this.remoteDatasource.setUserGoals(goals.map((item) => GoalDto.fromDomain(item)));
			// console.log(`signDto.toDomain()`, signDto);
			return Result.rigth("Goals saved successfully");
		} catch (error: any) {
			return Result.left(error.message);
		}
	}

	async delete(clientId: string, noteId: string): Promise<Result<void>> {
		try {
			await this.remoteDatasource.delete(clientId, noteId);
			return Result.rigth("Delete successfully");
		} catch (error: any) {
			console.log(`error`, error);
			return Result.left(error.message);
		}
	}

	async update(note: Note): Promise<Result<void>> {
		try {
			await this.remoteDatasource.update(NoteDto.fromDomain(note));
			return Result.rigth("Update successfully");
		} catch (error: any) {
			console.log(`error`, error);
			return Result.left(error.message);
		}
	}

	async create(note: Note): Promise<Result<Note>> {
		try {
			const dto = await this.remoteDatasource.create(NoteDto.fromDomain(note));
			// console.log(`dto`, dto);
			return Result.rigth(dto.toDomain());
		} catch (error: any) {
			console.log(`error`, error);
			return Result.left(error.message);
		}
	}

	async getTreatments(): Promise<Result<Treatment[]>> {
		try {
			const dtos = await this.remoteDatasource.getTreatments();
			// console.log(`dtos`, dtos);
			return Result.rigth<Treatment[]>(dtos.map((item) => item.toDomain()));
		} catch (error: any) {
			console.log(`error`, error);
			return Result.left(error.message);
		}
	}

	async getGoals(): Promise<Result<Goal[]>> {
		try {
			const dtos = await this.remoteDatasource.getGoals();
			return Result.rigth<Goal[]>(dtos.map((item) => item.toDomain()));
		} catch (error: any) {
			console.log(`error`, error);
			return Result.left(error.message);
		}
	}

	async getGroupTips(): Promise<Result<GroupTip[]>> {
		try {
			const dtos = await this.remoteDatasource.getGroupTips();
			return Result.rigth<GroupTip[]>(dtos.map((item) => item.toDomain()));
		} catch (error: any) {
			console.log(`error`, error);
			return Result.left(error.message);
		}
	}
}
