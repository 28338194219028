import React from "react";
import Client from "../../domain/client/models/Client";
import GoalPlan from "../../domain/client/models/GoalPlan";
import Note from "../../domain/note/models/Note";
import Objective from "../../domain/note/models/Objective";

export const SET_CREATING = "SET_CREATING";
export const ADD_CLIENT = "ADD_CLIENT";
export const SET_FETCHING = "SET_FETCHING";
export const SET_CLIENTS = "SET_CLIENTS";
export const SET_SELECTED_CLIENT = "SET_SELECTED_CLIENT";
export const UPDATE_CLIENT = "UPDATE_CLIENT";
export const SET_DELETING = "SET_DELETING";
export const DELETE_CLIENT = "DELETE_CLIENT";
export const ADD_NOTE = "ADD_NOTE";
export const UPDATE_NOTE = "UPDATE_NOTE";
export const DELETE_NOTE = "DELETE_NOTE";
export const SET_SAVING_NOTE = "SET_SAVING_NOTE";
export const SET_DELETE_NOTE = "SET_DELETE_NOTE";
export const SET_GOAL_PLAN = "SET_GOAL_PLAN";
export const SET_GOAL_PLAN_START_DATE = "SET_GOAL_PLAN_START_DATE";
export const SET_GOAL_PLAN_END_DATE = "SET_GOAL_PLAN_END_DATE";
export const SET_GOAL_PLAN_NAME = "SET_GOAL_PLAN_NAME";
export const SET_GOAL_PLAN_CATEGORY = "SET_GOAL_PLAN_CATEGORY";
export const SET_GOAL_PLAN_OBJECTIVE = "SET_GOAL_PLAN_OBJECTIVE";
export const ADD_GOAL_PLAN = "ADD_GOAL_PLAN";
export const UPDATE_GOAL_PLAN = "UPDATE_GOAL_PLAN";
export const DELETE_GOAL_PLAN = "DELETE_GOAL_PLAN";

export const initialValues: ClientState = {
	clients: undefined,
	selectedClient: Client.empty(),
	goalPlan: GoalPlan.empty(),
	isCreating: false,
	isFetching: false,
	isDeleting: false,
	isSaving: false,
};

export type ClientDispatch = React.Dispatch<ClientAction>;

export type ClientState = {
	clients: Client[] | undefined;
	selectedClient: Client;
	goalPlan: GoalPlan;
	isCreating: boolean;
	isFetching: boolean;
	isDeleting: boolean;
	isSaving: boolean;
};

export type SetGoalPlanNamePayload = {
	index: number;
	name: string;
	isNew: boolean;
};

export type SetGoalPlanCategoryPayload = {
	index: number;
	value: string;
	isNew: boolean;
};

export type SetGoalPlanObjectivePayload = {
	goalIndex: number;
	objectiveIndex: number;
	objective: Objective;
};

export type ClientAction =
	| {
			type: typeof SET_GOAL_PLAN_CATEGORY;
			payload: SetGoalPlanCategoryPayload;
	  }
	| {
			type: typeof DELETE_GOAL_PLAN;
			payload: string;
	  }
	| {
			type: typeof UPDATE_GOAL_PLAN;
			payload: GoalPlan;
	  }
	| {
			type: typeof ADD_GOAL_PLAN;
			payload: {
				goalPlan: GoalPlan;
				clientId: string;
			};
	  }
	| {
			type: typeof SET_GOAL_PLAN_OBJECTIVE;
			payload: SetGoalPlanObjectivePayload;
	  }
	| {
			type: typeof SET_GOAL_PLAN_NAME;
			payload: SetGoalPlanNamePayload;
	  }
	| {
			type: typeof SET_GOAL_PLAN_START_DATE;
			payload: Date;
	  }
	| {
			type: typeof SET_GOAL_PLAN_END_DATE;
			payload: Date;
	  }
	| {
			type: typeof SET_GOAL_PLAN;
			payload: GoalPlan;
	  }
	| {
			type: typeof ADD_NOTE;
			payload: Note;
	  }
	| {
			type: typeof UPDATE_NOTE;
			payload: Note;
	  }
	| {
			type: typeof DELETE_NOTE;
			payload: { clientId: string; noteId: string };
	  }
	| {
			type: typeof ADD_CLIENT;
			payload: Client;
	  }
	| {
			type: typeof SET_SELECTED_CLIENT;
			payload: Client;
	  }
	| {
			type: typeof SET_CLIENTS;
			payload: Client[];
	  }
	| {
			type: typeof SET_CREATING;
			payload: boolean;
	  }
	| {
			type: typeof UPDATE_CLIENT;
			payload: Client;
	  }
	| {
			type: typeof DELETE_CLIENT;
			payload: string;
	  }
	| {
			type: typeof SET_DELETING;
			payload: boolean;
	  }
	| {
			type: typeof SET_SAVING_NOTE;
			payload: boolean;
	  }
	| {
			type: typeof SET_DELETE_NOTE;
			payload: boolean;
	  }
	| {
			type: typeof SET_FETCHING;
			payload: boolean;
	  };
