import {
	AccountCircle,
	CardMembership,
	CreditCard,
	Edit,
} from "@material-ui/icons";
import EditProfileModalContent from "./components/EditProfileModalContent";
import { useSnackbar } from "notistack";
import { useUserDispatch, useUserState } from "../../../../application/user";
import { useInjection } from "../../../../core/dependency_injection";
import UserActions from "../../../../application/user/actions";
import useOpenable from "../../../core/hooks/useOpenable";
import PanelHeader, { PanelHeaderAction } from "../../components/PanelHeader";
import WrappedModal from "../../../core/components/Modal";
import { User } from "../../../../domain/user/models/User";
import ProfileHighlighs from "./components/ProfileHighlighs";
import { Card } from "@material-ui/core";
import ProfileTabs from "./components/ProfileTabs";

export default function Profile() {
	document.title = "PSR Notes | Profile";

	const { user, isSubmiting } = useUserState();
	const userDispatch = useUserDispatch();
	const userActions = useInjection<UserActions>("UserActions");

	const [open, handleOpen, handleClose] = useOpenable();

	const { enqueueSnackbar } = useSnackbar();

	// console.log(`user`, user);

	const panelActions: PanelHeaderAction[] = [
		{
			label: user.subscription ? "Manage Billing..." : "Subscribe...",
			color: user.subscription ? "primary" : "secondary",
			icon: user.subscription ? CreditCard : CardMembership,
			isLoading: isSubmiting,
			onClick: () => {
				if (user.emailVerified) {
					if (user.subscription) {
						userActions.goToCustomerPortal(
							userDispatch,
							user.stripeId ?? "",
							enqueueSnackbar
						);
					} else {
						userActions.setShowPlans(userDispatch, true);
					}
				} else {
					enqueueSnackbar(
						"You need to activate your account to subscribe. Please check your email inbox.",
						{ variant: "error" }
					);
				}
				// handleOpen();
			},
		},
		{
			label: "Edit...",
			icon: Edit,
			onClick: () => {
				handleOpen();
			},
		},
	];

	return (
		<>
			<div>
				<PanelHeader
					icon={<AccountCircle />}
					title="Profile"
					actions={panelActions}
				/>
				<Card
					style={{
						position: "relative",
						overflow: "visible",
						marginTop: "10px",
						borderRadius: "10px",
					}}
				>
					<ProfileHighlighs />
					<ProfileTabs />
				</Card>
			</div>
			<WrappedModal open={open} handleClose={handleClose}>
				<EditProfileModalContent
					onSuccessCallback={async (user: User) => {
						// console.log(`profile user`, user);
						await userActions.updateUser(userDispatch, user, enqueueSnackbar);
						handleClose();
					}}
				/>
			</WrappedModal>
		</>
	);
}
