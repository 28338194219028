import { Button, Typography } from "@material-ui/core";
import React from "react";
import {
	useUserDispatch,
	useUserState,
} from "../../../../../../application/user";
import useStyles from "./styles";
import profile from "../../../../../core/assets/images/profile.png";
import ProfileAvatar from "../../../../components/ProfileAvatar";
import { HighlightOff, VerifiedUser } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import UserActions from "../../../../../../application/user/actions";
import { useInjection } from "../../../../../../core/dependency_injection";
import { ButtonCircularProgress } from "../../../../../core/components/Progress";

export default function ProfileHighlighs() {
	const classes = useStyles();

	const { user, isSendingVerify, isSendingPassword } = useUserState();

	const userDispatch = useUserDispatch();

	const userActions = useInjection<UserActions>("UserActions");

	const { enqueueSnackbar } = useSnackbar();

	return (
		<div className={classes.root}>
			<ProfileAvatar imageSrc={profile} />
			<div className={classes.infoContainer}>
				<Typography>{user.name}</Typography>
				<div className={classes.email}>
					<Typography>{user.email}</Typography>
					<div className={classes.verified}>
						{user.emailVerified ? (
							<VerifiedUser color="primary" />
						) : (
							<HighlightOff color="error" />
						)}
						<Typography className={classes.emailVerifiedText}>
							{user.emailVerified ? "Email verified" : "Email not verified"}
						</Typography>
					</div>
				</div>
				<div>
					{!user.emailVerified && (
						<Button
							variant="outlined"
							color="primary"
							onClick={() => {
								userActions.sendEmail(userDispatch, enqueueSnackbar);
							}}
						>
							Verify Email
							{isSendingVerify && <ButtonCircularProgress />}
						</Button>
					)}
					<Button
						variant="outlined"
						color="primary"
						style={{ marginLeft: "10px" }}
						onClick={() => {
							userActions.sendResetPasswordEmail(
								userDispatch,
								user.email ?? "",
								enqueueSnackbar
							);
						}}
					>
						Reset Password
						{isSendingPassword && <ButtonCircularProgress />}
					</Button>
				</div>
			</div>
		</div>
	);
}
