import { Grid } from "@material-ui/core";
import { People, Save } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import {
	useClientDispatch,
	useClientState,
} from "../../../../../../../../application/client";
import ClientActions from "../../../../../../../../application/client/actions";
import { useGroupDispatch } from "../../../../../../../../application/group";
import {
	useNoteDispatch,
	useNoteState,
} from "../../../../../../../../application/note";
import NoteActions from "../../../../../../../../application/note/actions";
import {
	useUserDispatch,
	useUserState,
} from "../../../../../../../../application/user";
import UserActions from "../../../../../../../../application/user/actions";
import { useInjection } from "../../../../../../../../core/dependency_injection";
import WrappedButton from "../../../../../../../core/components/Button";
import { ButtonCircularProgress } from "../../../../../../../core/components/Progress";
import PanelHeader, {
	PanelHeaderAction,
	PanelHeaderBreadcrumbsRoute,
} from "../../../../../../components/PanelHeader";
import NoteForm from "../../components/NoteForm";

export default function NoteCreate() {
	const history = useHistory();

	const { user } = useUserState();
	const userDispatch = useUserDispatch();
	const userActions = useInjection<UserActions>("UserActions");

	const { note, isGoalsNew, goalsEndDate, isFetching, isFetchingClientInfo } =
		useNoteState();
	const noteDispatch = useNoteDispatch();
	const noteActions = useInjection<NoteActions>("NoteActions");

	const { isSaving, clients } = useClientState();
	const clientDispatch = useClientDispatch();
	const clientActions = useInjection<ClientActions>("ClientActions");

	const groupDispatch = useGroupDispatch();

	const { enqueueSnackbar } = useSnackbar();

	const onSave = async () => {
		if (!note.isValid()) {
			enqueueSnackbar("The note is invalid", { variant: "error" });
			return;
		}
		if (clients && !note.client.id) {
			const clientIndex = clients.findIndex(
				(client) => client.case === note.client.case
			);
			if (clientIndex !== -1) {
				note.client.id = clients[clientIndex].id;
			}
		}
		if (!note.client.id && !user.canAddClient(clients?.length ?? 0)) {
			enqueueSnackbar(
				"You reached the limit of clients. Upgrade your subscrition in order to add more clients",
				{
					variant: "error",
				}
			);
			userActions.setShowPlans(userDispatch, true);
			return;
		}
		noteActions.setUserGoals(noteDispatch, note.getNewGoals(), enqueueSnackbar);
		noteActions.setUserTreatments(
			noteDispatch,
			note.getNewTreatments(),
			enqueueSnackbar
		);
		noteActions.setUserGroupTips(
			noteDispatch,
			note.getNewGroupTips(),
			enqueueSnackbar
		);
		await clientActions.saveNote(
			clientDispatch,
			groupDispatch,
			note,
			isGoalsNew,
			goalsEndDate,
			history,
			enqueueSnackbar
		);
	};

	const panelActions: PanelHeaderAction[] = [
		{
			label: "Save",
			icon: Save,
			isLoading: isSaving,
			onClick: onSave,
		},
	];

	const breadcrumbsRoutes: PanelHeaderBreadcrumbsRoute[] = [
		{
			label: "Clients",
			icon: People,
			to: "/app/clients",
		},
		{
			label: `${note.client.name || "New"}`,
			to: note.client.id ? `/app/clients/${note.client.id}` : undefined,
			// icon: People,
		},
		{
			label: "Create Progress Note",
		},
	];
	return (
		<>
			<PanelHeader
				breadcrumbsRoutes={breadcrumbsRoutes}
				actions={panelActions}
			/>
			<NoteForm />
			{!isFetching && !isFetchingClientInfo && (
				<Grid container justifyContent="flex-end">
					<WrappedButton
						size="small"
						disabled={isSaving}
						startIcon={<Save />}
						onClick={onSave}
						style={{ marginLeft: "10px" }}
					>
						Save
						{isSaving && <ButtonCircularProgress />}
					</WrappedButton>
				</Grid>
			)}
		</>
		// ) : (
		//   <PanelCircularProgress />
	);
}
