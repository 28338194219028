import Objective from "./Objective";

type GoalProps = {
	id?: string;
	category: string;
	name: string;
	objectives: Objective[];
	isNew?: boolean[];
};

export default class Goal {
	id?: string;
	category: string;
	name: string;
	objectives: Objective[];

	private isNew: boolean[];

	constructor(props: GoalProps) {
		this.id = props.id;
		this.category = props.category;
		this.name = props.name;
		this.objectives = props.objectives;
		this.isNew = props.isNew ?? [false, false];
	}

	public static empty(): Goal {
		return new Goal({
			id: "",
			name: "",
			category: "",
			objectives: [Objective.empty(), Objective.empty()],
		});
	}

	public isValid(): boolean {
		return (
			this.name !== "" && this.category !== "" && this.objectives.every((item) => item.isValid())
		);
	}

	public checkIsNew(): boolean {
		return this.objectives.some((item) => item.isNew) || this.isNew.some((value) => value);
	}

	public changeIsNewCategory(value: boolean): void {
		if (this.isNew) {
			this.isNew[0] = value;
		}
	}

	public changeIsNewName(value: boolean): void {
		if (this.isNew) {
			this.isNew[1] = value;
		}
	}
}
