import Company from "./Company";
import Subscription from "./Subscription";

interface UserProps {
	id?: string;
	name: string;
	email: string;
	stripeId?: string;
	freeTrial?: boolean;
	emailVerified?: boolean;
	company?: Company;
	subscription?: Subscription;
}

export class User {
	id?: string;
	name: string;
	email: string;
	stripeId?: string;
	freeTrial?: boolean;
	emailVerified?: boolean;
	company?: Company;
	subscription?: Subscription;

	constructor(props: UserProps) {
		this.id = props.id;
		this.email = props.email.toLowerCase().replace(" ", "");
		this.name = props.name;
		this.stripeId = props.stripeId;
		this.freeTrial = props.freeTrial;
		this.emailVerified = props.emailVerified;
		this.company = props.company;
		this.subscription = props.subscription;
	}

	public static empty(): User {
		return new User({
			name: "",
			email: "",
		});
	}

	public isValid(): boolean {
		return this.name !== "" && this.email !== "";
	}

	public canAddClient(currentNumberOfClients: number): boolean {
		if (this.subscription) {
			return (
				(this.subscription.product.name === "Standard" &&
					currentNumberOfClients < 5) ||
				(this.subscription.product.name === "Pro" &&
					currentNumberOfClients < 30) ||
				this.subscription.product.name === "Unlimited"
			);
		} else {
			return currentNumberOfClients < 1;
		}
		// if (!this.subscription && currentNumberOfClients < 1) {
		// 	return true;
		// } else if (
		// 	this.subscription?.product.name === "Standard" &&
		// 	currentNumberOfClients < 5
		// ) {
		// 	return true;
		// } else if (
		// 	this.subscription?.product.name === "Pro" &&
		// 	currentNumberOfClients < 30
		// ) {
		// 	return true;
		// } else if (this.subscription?.product.name === "Unlimited") {
		// 	return true;
		// }
		// return false;
	}

	// public isActiveSubscription(): { error: boolean; message?: string } {
	// 	if (!this.subscription) {
	// 		return {
	// 			error: true,
	// 			message: "You need to subscribe to one of our plans.",
	// 		};
	// 	} else if (!this.subscription.isActive()) {
	// 		return {
	// 			error: true,
	// 			message: "Something is wrong with your subscription.",
	// 		};
	// 	}
	// 	return { error: false };
	// }
}
