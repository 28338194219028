export type GroupObjectProps = {
	label: string;
	value: string;
};
export class GroupObject {
	label: string;
	value: string;

	constructor(props: GroupObjectProps) {
		this.label = props.label;
		this.value = props.value;
	}

	public static Empty(): GroupObject {
		return new GroupObject({ label: "", value: "" });
	}

	public isValid(): boolean {
		return !!this.label && this.label !== "" && !!this.value && this.value !== "";
	}
}

type GroupTipProps = {
	id?: string;
	category: string;
	title: string;
	interventions?: GroupObject[];
	selectedIntervention?: GroupObject;
	participations?: string[];
	selectedParticipation?: string;
	isNew?: boolean[];
};

export default class GroupTip {
	id?: string;
	category: string;
	title: string;
	interventions?: GroupObject[];
	selectedIntervention?: GroupObject;
	participations?: string[];
	selectedParticipation?: string;

	private isNew: boolean[];

	constructor(props: GroupTipProps) {
		this.id = props.id;
		this.title = props.title;
		this.category = props.category;
		this.interventions = props.interventions;
		this.selectedIntervention = props.selectedIntervention;
		this.participations = props.participations;
		this.selectedParticipation = props.selectedParticipation;
		this.isNew = props.isNew ?? [false, false, false, false];
	}

	public static empty(): GroupTip {
		return new GroupTip({
			title: "",
			category: "",
			interventions: [],
			selectedIntervention: new GroupObject({ label: "", value: "" }),
			participations: [],
			selectedParticipation: "",
			isNew: [false, false, false, false],
		});
	}

	public isValid(): boolean {
		return (
			this.title !== "" &&
			this.category !== "" &&
			!!this.selectedIntervention &&
			this.selectedIntervention.isValid() &&
			!!this.selectedParticipation &&
			this.selectedParticipation !== ""
		);
	}

	public isValidPlan(): boolean {
		return (
			this.title !== "" &&
			this.category !== "" &&
			!!this.selectedIntervention &&
			this.selectedIntervention.isValid()
		);
	}

	public checkIsNew(): boolean {
		return this.isNew.some((value) => value);
	}

	public changeIsNewCategory(value: boolean): void {
		this.isNew[0] = value;
	}

	public changeIsNewTitle(value: boolean): void {
		this.isNew[1] = value;
	}
	public changeIsNewIntervention(value: boolean): void {
		this.isNew[2] = value;
	}

	public changeIsNewParticipation(value: boolean): void {
		this.isNew[3] = value;
	}
}
