import defaultTheme from "./default";
import {
	createTheme,
	responsiveFontSizes,
	ThemeProvider as Provider,
} from "@material-ui/core/styles";
import {
	useThemeDispatch,
	useThemeState,
} from "../../../application/core/theme";
import { useInjection } from "../../../core/dependency_injection";
import ThemeActions from "../../../application/core/theme/actions";
import { Themes } from "../../../domain/core/utils";
import { useEffect } from "react";
import darkTheme from "./dark";
import { common } from "./misc";

export const themes = {
	default: responsiveFontSizes(createTheme({ ...defaultTheme, ...common })),
	dark: responsiveFontSizes(createTheme({ ...darkTheme, ...common })),
};

export default function ThemeProvider(props: any) {
	const { isDark, selectedTheme } = useThemeState();
	const themeDispatch = useThemeDispatch();
	const themeActions = useInjection<ThemeActions>("ThemeActions");

	const setTheme = (e: MediaQueryListEvent) => {
		if (selectedTheme === Themes.System) {
			themeActions.setDarkMode(themeDispatch, e.matches);
		}
	};

	useEffect(() => {
		if (selectedTheme === Themes.System) {
			window
				.matchMedia("(prefers-color-scheme: dark)")
				.addEventListener("change", setTheme);
			// window.matchMedia("(prefers-color-scheme: dark)").onchange((e) => null);
		} else {
			window
				.matchMedia("(prefers-color-scheme: dark)")
				.removeEventListener("change", setTheme);
		}
		return () => {
			window
				.matchMedia("(prefers-color-scheme: dark)")
				.removeEventListener("change", setTheme);
		};
		// eslint-disable-next-line
	}, [selectedTheme]);

	return (
		<Provider theme={isDark ? themes.dark : themes.default}>
			{props.children}
		</Provider>
	);
}
