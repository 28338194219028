import { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import {
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	Typography,
} from "@material-ui/core";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import useStyles from "./styles";
// import { getRoutes } from "../../core/helpers";
import ErrorPage from "../Error";
import routes from "./core/routes";
import { AppContextProvider } from "../../application";
import Plans from "../core/components/Plans";
import { useUserDispatch, useUserState } from "../../application/user";
import { useInjection } from "../../core/dependency_injection";
import UserActions from "../../application/user/actions";
import { Close } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import Firebase from "../../infrastructure/core/firebase";
import ModalPlayer from "./components/ModalPlayer";

export default function App() {
	const classes = useStyles();

	// const { user } = useUserState();
	const { user, showPlans, isSubmiting } = useUserState();
	const userDispatch = useUserDispatch();
	const userActions = useInjection<UserActions>("UserActions");

	const [mobileOpen, setMobileOpen] = useState(false);

	const startVideo = localStorage.getItem("start-video");
	// const isOpen = null;

	// const [open, handleOpen, handleClose] = useOpenable();

	const { enqueueSnackbar } = useSnackbar();

	const handleClose = () => {
		userActions.setShowPlans(userDispatch, false);
	};

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	useEffect(() => {
		Firebase.GetInstance()
			.firestore.collection("customers")
			.doc(Firebase.GetInstance().auth.currentUser?.uid)
			.onSnapshot(
				(docSnapshot) => {
					// console.log("Received doc snapshot:", docSnapshot.data());
					// ...
					const data = docSnapshot.data();
					if (
						user?.subscription?.status !== data?.subscription?.status ||
						user.stripeId !== data?.stripeId
					) {
						userActions.checkAuthenticated(userDispatch);
					}
				},
				(err) => {
					console.log(`Encountered error: ${err}`);
				}
			);
		// eslint-disable-next-line
	}, []);

	// const routes = getRoutes(user?.role);

	const switchRoutes = (
		<Switch>
			{routes.map((prop, key) => {
				return (
					<Route
						path={prop.layout + prop.path}
						component={prop.component}
						key={key}
					/>
				);
			})}
			<Route path="*" component={ErrorPage} />
		</Switch>
	);

	return (
		<AppContextProvider>
			<div className={classes.wrapper}>
				<Sidebar open={mobileOpen} handleDrawerToggle={handleDrawerToggle} />
				<div className={classes.mainPanel}>
					<Navbar handleDrawerToggle={handleDrawerToggle} />
					<div className={classes.content}>{switchRoutes}</div>
				</div>
			</div>
			<Dialog open={showPlans} onClose={handleClose} fullScreen>
				<DialogTitle>
					<IconButton onClick={handleClose}>
						<Close />
					</IconButton>
					<Typography style={{ display: "inline", marginLeft: "5px" }}>
						|
					</Typography>
					<Typography style={{ display: "inline", marginLeft: "20px" }}>
						Pricing
					</Typography>
				</DialogTitle>
				<DialogContent dividers>
					<Plans
						buttonLabel={user.freeTrial ? "Try for free" : "Continue"}
						isLoading={isSubmiting}
						onButtonClick={(price) => {
							if (user.stripeId) {
								userActions.goToCheckout(
									userDispatch,
									user,
									price.id,
									enqueueSnackbar
								);
							}
						}}
					/>
				</DialogContent>
			</Dialog>
			<ModalPlayer isOpen={startVideo !== "viewed"} />
		</AppContextProvider>
	);
}
