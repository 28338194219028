import React from "react";
import Product from "../../domain/product/models/Product";

export const SET_FETCHING = "SET_FETCHING";
export const SET_PRODUCTS = "SET_PRODUCTS";

export const initialValues: ProductState = {
	isFetchingProducts: false,
	products: [],
};

export type ProductDispatch = React.Dispatch<ProductAction>;

export type ProductState = {
	isFetchingProducts: boolean;
	products: Product[];
};

export type ProductAction =
	| {
			type: typeof SET_FETCHING;
			payload: boolean;
	  }
	| {
			type: typeof SET_PRODUCTS;
			payload: Product[];
	  };
