import { container } from "tsyringe";
import { INoteRepository } from "../../domain/note/inote.repository";
import NoteRemoteDatasource, {
  INoteRemoteDatasource,
} from "../../infrastructure/note/datasources/remote";
import NoteRepository from "../../infrastructure/note/note.repository";
import { sl } from "./register_dependencies";

export function registerNoteDependencies() {
  container.register<INoteRemoteDatasource>("INoteRemoteDatasource", {
    useClass: NoteRemoteDatasource,
  });

  container.register<INoteRepository>("INoteRepository", {
    useValue: new NoteRepository(
      sl<INoteRemoteDatasource>("INoteRemoteDatasource")
    ),
  });

  
}
