import Treatment from "../../../domain/note/models/Treatment";
import firebase from "firebase";

export interface ITreatmentDtoProps {
	id?: string;
	question: string;
	selectedAnswer?: string[];
	order?: number;
	answers?: string[];
}

export class TreatmentDto {
	id?: string;
	question: string;
	selectedAnswer?: string[];
	order?: number;
	answers?: string[];

	constructor(props: ITreatmentDtoProps) {
		this.id = props.id;
		this.question = props.question;
		this.selectedAnswer = props.selectedAnswer;
		this.answers = props.answers;
		this.order = props.order;
	}

	public static fromDomain(user: Treatment): TreatmentDto {
		return new TreatmentDto({
			id: user.id,
			answers: user.answers,
			order: user.order,
			question: user.question,
			selectedAnswer: user.selectedAnswer,
		});
	}

	public static fromFirestore(
		doc: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
	): TreatmentDto {
		const data = doc.data();
		return new TreatmentDto({
			id: doc.id,
			answers: data?.answers,
			selectedAnswer: data?.selectedAnswer,
			order: data?.order,
			question: data?.question,
		});
	}

	public toJson(): any {
		return {
			question: this.question,
			answers: this.answers,
		};
	}

	public toNoteJson(): any {
		return {
			question: this.question,
			selectedAnswer: this.selectedAnswer,
		};
	}

	public toDomain(): Treatment {
		return new Treatment({
			id: this.id,
			order: this.order,
			selectedAnswer: this.selectedAnswer,
			question: this.question,
			answers: this.answers,
		});
	}
}
