import { Button, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import useStyles from "./styles";
export default function TryBanner() {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<div className={classes.container}>
				<Typography variant="h2" className={classes.white}>
					Try PSRnotes for your practice
				</Typography>
			</div>
			{/* <div className={classes.container}>
				<Typography variant="h5" className={classes.white}>
					14-day free trial
				</Typography>
			</div> */}
			<div className={classes.buttonContainer}>
				<Button
					component={Link}
					to="/auth/sign-up"
					variant="contained"
					size="large"
				>
					Get Started
				</Button>
			</div>
		</div>
	);
}
