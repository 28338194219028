import Goal from "../../../domain/note/models/Goal";
import firebase from "firebase";
import { ObjectiveDto } from "./ObjectiveDto";

export interface IGoalDtoProps {
  id?: string;
  name: string;
  category: string;
  objectives: any[];
}

export class GoalDto {
  id?: string;
  name: string;
  category: string;
  objectives: ObjectiveDto[];

  constructor(props: IGoalDtoProps) {
    this.id = props.id;
    this.name = props.name;
    this.category = props.category;
    this.objectives = props.objectives.map((item) => new ObjectiveDto({ ...item }));
  }

  public static fromDomain(user: Goal): GoalDto {
    return new GoalDto({
      id: user.id,
      name: user.name,
      category: user.category,
      objectives: user.objectives.map((item) => ObjectiveDto.fromDomain(item)),
    });
  }

  public static fromFirestore(
    doc: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
  ): GoalDto {
    const data = doc.data();
    return new GoalDto({
      id: doc.id,
      name: data?.name,
      category: data?.category,
      objectives: data?.objectives.map((item: any) => ObjectiveDto.fromJson(item)),
    });
  }

  public toJson(): any {
    return {
      name: this.name,
      category: this.category,
      objectives: this.objectives.map((item) => item.toJson()),
    };
  }

  public toDomain(): Goal {
    return new Goal({
      id: this.id,
      name: this.name,
      category: this.category,
      objectives: this.objectives.map((item) => item.toDomain()),
    });
  }
}
