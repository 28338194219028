import { Result } from "../../domain/core/Result";
import { IUserRepository } from "../../domain/user/iuser.repository";
import { User } from "../../domain/user/models/User";
import { IUserRemoteDatasource } from "./datasources/remote";
import { UserDto } from "./dtos/UserDto";

export default class UserRepository implements IUserRepository {
	private remoteDatasource: IUserRemoteDatasource;

	public constructor(remoteDatasource: IUserRemoteDatasource) {
		this.remoteDatasource = remoteDatasource;
	}
	// async sendSignLink(email: string): Promise<Result<void>> {
	// 	try {
	// 		await this.remoteDatasource.sendSignWithLink(email);
	// 		localStorage.setItem("singLinkEmail", email);
	// 		return Result.rigth("");
	// 	} catch (error: any) {
	// 		console.log(`error`, error);
	// 		return Result.left(error.message);
	// 	}
	// }
	// async signInWithLink(email: string): Promise<Result<User>> {
	// 	try {
	// 		const dto = await this.remoteDatasource.signInWithLink(email);
	// 		return Result.rigth<User>(dto.toDomain());
	// 	} catch (error: any) {
	// 		return Result.left(error.message);
	// 	}
	// }
	async goToCheckout(user: User, priceId: string): Promise<Result<void>> {
		try {
			await this.remoteDatasource.goToCheckout(
				UserDto.fromDomain(user),
				priceId
			);
			return Result.rigth("");
		} catch (error: any) {
			console.log(`error`, error);
			return Result.left(error.message);
		}
	}
	async goToCustomerPortal(customerId: string): Promise<Result<void>> {
		try {
			await this.remoteDatasource.goToCustomerPortal(customerId);
			return Result.rigth("");
		} catch (error: any) {
			console.log(`error`, error);
			return Result.left(error.message);
		}
	}

	async signIn(email: string, password: string): Promise<Result<User>> {
		try {
			const signDto = await this.remoteDatasource.signIn(email, password);
			// console.log(`signDto.toDomain()`, signDto);
			return Result.rigth<User>(signDto.toDomain());
		} catch (error: any) {
			return Result.left(error.message);
		}
	}

	// async register(user: User, password: string): Promise<Result<User>> {
	// 	try {
	// 		const signDto = await this.remoteDatasource.register(
	// 			UserDto.fromDomain(user),
	// 			password
	// 		);
	// 		return Result.rigth<User>(signDto.toDomain());
	// 	} catch (error: any) {
	// 		return Result.left(error.message);
	// 	}
	// }

	async signUp(user: User, password: string): Promise<Result<User>> {
		try {
			const signDto = await this.remoteDatasource.signUp(
				UserDto.fromDomain(user),
				password
			);
			return Result.rigth<User>(signDto.toDomain());
		} catch (error: any) {
			return Result.left(error.message);
		}
	}

	async update(user: User): Promise<Result<void>> {
		try {
			const userDto = UserDto.fromDomain(user);
			await this.remoteDatasource.update(userDto);
			return Result.rigth("Update successfully");
		} catch (error: any) {
			return Result.left(error.message);
		}
	}

	async getAuthenticatedUser(): Promise<Result<User>> {
		try {
			const userDto = await this.remoteDatasource.getAuthenticatedUser();
			// console.log(`userDto`, userDto);
			return Result.rigth<User>(userDto.toDomain());
		} catch (error: any) {
			return Result.left(error.message);
		}
	}

	async sendValidateEmail(): Promise<Result<void>> {
		try {
			await this.remoteDatasource.sendValidateEmail();
			return Result.rigth("Validate account email send successfully");
		} catch (error: any) {
			return Result.left(error.message);
		}
	}

	async sendResetPasswordEmail(email: string): Promise<Result<void>> {
		try {
			await this.remoteDatasource.sendResetPasswordEmail(email);
			return Result.rigth("Reset password email send successfully");
		} catch (error: any) {
			return Result.left(error.message);
		}
	}

	async signOut(): Promise<Result<void>> {
		try {
			await this.remoteDatasource.signOut();
			return Result.rigth("Reset password email send successfully");
		} catch (error: any) {
			return Result.left(error.message);
		}
	}
}
