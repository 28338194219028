// import tinycolor from "tinycolor2";

import { ThemeOptions } from "@material-ui/core";
import { primary, secondary } from "./misc";

// const warning = warningColor[0];
// const success = successColor[0];
// const info = infoColor[0];

// const lightenRate = 7.5;
// const darkenRate = 15;

const darkTheme: ThemeOptions = {
	palette: {
		type: "dark",
		primary: {
			main: primary,
			contrastText: "#FFFFFF",
		},
		secondary: {
			main: secondary,
		},
	},
};

export default darkTheme;
