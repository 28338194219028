import Subscription from "../../../domain/user/models/Subscription";
import PriceDto from "../../product/dtos/PriceDto";
import ProductDto from "../../product/dtos/ProductDto";
import firebase from "firebase/app";

export default class SubscriptionDto {
	id: string;
	status: string;
	product: ProductDto;
	price: PriceDto;
	cancel_at_period_end: boolean;
	current_period_start: firebase.firestore.Timestamp;
	current_period_end: firebase.firestore.Timestamp;
	created: firebase.firestore.Timestamp;
	cancel_at: firebase.firestore.Timestamp | null;
	canceled_at: firebase.firestore.Timestamp | null;
	ended_at: firebase.firestore.Timestamp | null;
	trial_start: firebase.firestore.Timestamp | null;
	trial_end: firebase.firestore.Timestamp | null;

	constructor(props: {
		id: string;
		status: string;
		product: ProductDto;
		price: PriceDto;
		cancel_at_period_end: boolean;
		current_period_start: firebase.firestore.Timestamp;
		current_period_end: firebase.firestore.Timestamp;
		created: firebase.firestore.Timestamp;
		cancel_at: firebase.firestore.Timestamp | null;
		canceled_at: firebase.firestore.Timestamp | null;
		ended_at: firebase.firestore.Timestamp | null;
		trial_start: firebase.firestore.Timestamp | null;
		trial_end: firebase.firestore.Timestamp | null;
	}) {
		this.id = props.id;
		this.status = props.status;
		this.product = new ProductDto({ ...props.product });
		this.price = new PriceDto({ ...props.price });
		this.cancel_at_period_end = props.cancel_at_period_end;
		this.cancel_at = props.cancel_at;
		this.canceled_at = props.canceled_at;
		this.current_period_start = props.current_period_start;
		this.current_period_end = props.current_period_end;
		this.created = props.created;
		this.ended_at = props.ended_at;
		this.trial_start = props.trial_start;
		this.trial_end = props.trial_end;
	}

	public static fromDomain(model: Subscription): SubscriptionDto {
		return new SubscriptionDto({
			id: model.id,
			status: model.status,
			product: ProductDto.fromDomain(model.product),
			price: PriceDto.fromDomain(model.price),
			cancel_at_period_end: model.cancel_at_period_end,
			current_period_start: firebase.firestore.Timestamp.fromDate(
				model.current_period_start
			),
			current_period_end: firebase.firestore.Timestamp.fromDate(
				model.current_period_end
			),
			created: firebase.firestore.Timestamp.fromDate(model.created),
			cancel_at: model.cancel_at
				? firebase.firestore.Timestamp.fromDate(model.cancel_at)
				: null,
			canceled_at: model.canceled_at
				? firebase.firestore.Timestamp.fromDate(model.canceled_at)
				: null,
			ended_at: model.ended_at
				? firebase.firestore.Timestamp.fromDate(model.ended_at)
				: null,
			trial_start: model.trial_start
				? firebase.firestore.Timestamp.fromDate(model.trial_start)
				: null,
			trial_end: model.trial_end
				? firebase.firestore.Timestamp.fromDate(model.trial_end)
				: null,
		});
	}

	public toDomain(): Subscription {
		return new Subscription({
			id: this.id,
			status: this.status,
			product: this.product.toDomain(),
			price: this.price.toDomain(),
			cancel_at_period_end: this.cancel_at_period_end,
			cancel_at: this.cancel_at?.toDate(),
			canceled_at: this.canceled_at?.toDate(),
			current_period_start: this.current_period_start.toDate(),
			current_period_end: this.current_period_end.toDate(),
			created: this.created.toDate(),
			ended_at: this.ended_at?.toDate(),
			trial_start: this.trial_start?.toDate(),
			trial_end: this.trial_end?.toDate(),
		});
	}

	// public static empty(): Subscription {
	// 	return new Subscription({
	// 		id: "",
	// 		status: false,
	// 	});
	// }

	// public isValid(): boolean {
	// 	return this.name !== "" && this.address !== "";
	// }
}
