import React from "react";
import { initialValues, InputFormDispatch } from "./misc";
import reducer from "./reducer";

var InputFormStateContext = React.createContext(initialValues);
var InputFormDispatchContext = React.createContext<InputFormDispatch>(() => null);

function InputFormProvider({ children }: any) {
	var [state, dispatch] = React.useReducer(reducer, initialValues);
	return (
		<InputFormStateContext.Provider value={state}>
			<InputFormDispatchContext.Provider value={dispatch}>
				{children}
			</InputFormDispatchContext.Provider>
		</InputFormStateContext.Provider>
	);
}

function useInputFormState() {
	var context = React.useContext(InputFormStateContext);
	if (context === undefined) {
		throw new Error("useInputFormState must be used within a InputFormProvider");
	}
	return context;
}

function useInputFormDispatch() {
	var context = React.useContext(InputFormDispatchContext);
	if (context === undefined) {
		throw new Error("useInputFormDispatch must be used within a InputFormProvider");
	}
	return context;
}

export { InputFormProvider, useInputFormState, useInputFormDispatch };
