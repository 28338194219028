import Price from "./Price";

type ProductProps = {
	id: string;
	name: string;
	order: string;
	active: boolean;
	description?: string | null;
	clients: string;
	groups: string;
	generator: string;
	prices?: Price[];
};

export default class Product {
	id: string;
	name: string;
	order: string;
	active: boolean;
	description?: string | null;
	clients: string;
	groups: string;
	generator: string;
	prices?: Price[];

	constructor(props: ProductProps) {
		this.id = props.id;
		this.active = props.active;
		this.name = props.name;
		this.order = props.order;
		this.description = props.description;
		this.clients = props.clients;
		this.groups = props.groups;
		this.generator = props.generator;
		this.prices = props.prices;
	}

	// public static empty(): Product {
	// 	return new Product({
	// 		active: false,
	// 		currency: "",
	// 		interval: "",
	// 		interval_count: -1,
	// 		trial_period_days: -1,
	// 		type: "",
	// 		unit_amount: -1,
	// 	});
	// }
}
