import GroupPlan from "../../../domain/group/models/GroupPlan";
import firebase from "firebase";
import { GroupTipDto } from "../../note/dtos/GroupTipDto";

export interface IGroupPlanDtoProps {
  id?: string;
  date: Date;
  groupTips: GroupTipDto[];
}

export class GroupPlanDto {
  id?: string;
  date: Date;
  groupTips: GroupTipDto[];

  constructor(props: IGroupPlanDtoProps) {
    this.id = props.id;
    this.date = props.date;
    this.groupTips = props.groupTips;
  }

  public static fromDomain(model: GroupPlan): GroupPlanDto {
    // console.log(`fromDomain client.goals`, client.goals);
    return new GroupPlanDto({
      id: model.id,
      date: model.date,
      groupTips: model.groupTips.map((item) => GroupTipDto.fromDomain(item)),
    });
  }

  public static fromFirestore(
    doc: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
  ): GroupPlanDto {
    const data = doc.data();
    // console.log(`data`, data);
    return new GroupPlanDto({
      id: doc.id,
      date: data?.date.toDate(),
      groupTips: data?.groupTips.map(
        (item: any) => new GroupTipDto({ ...item })
      ),
    });
  }

  public toJson(): any {
    return {
      date: this.date,
      groupTips: this.groupTips.map((item) => item.toJson()),
    };
  }

  public toDomain(): GroupPlan {
    return new GroupPlan({
      id: this.id,
      date: this.date,
      groupTips: this.groupTips.map((item) => item.toDomain()),
    });
  }
}
