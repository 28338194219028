import {
	Button,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import React from "react";
import { useHistory } from "react-router";
import {
	useClientDispatch,
	useClientState,
} from "../../../../../../application/client";
import ClientActions from "../../../../../../application/client/actions";
import {
	useGroupDispatch,
	useGroupState,
} from "../../../../../../application/group";
import GroupActions from "../../../../../../application/group/actions";
import {
	useUserDispatch,
	useUserState,
} from "../../../../../../application/user";
import UserActions from "../../../../../../application/user/actions";
import { useInjection } from "../../../../../../core/dependency_injection";
import ItemView from "../../../../components/ItemView";
import useStyles from "./styles";

export default function GroupForm() {
	const classes = useStyles();
	const history = useHistory();

	const { user } = useUserState();
	const userDispatch = useUserDispatch();
	const userActions = useInjection<UserActions>("UserActions");

	const { group } = useGroupState();
	const groupDispatch = useGroupDispatch();
	const groupActions = useInjection<GroupActions>("GroupActions");

	const { clients } = useClientState();
	const clientDispatch = useClientDispatch();
	const clientActions = useInjection<ClientActions>("ClientActions");

	const { enqueueSnackbar } = useSnackbar();

	const isEditing = !window.location.pathname.includes("create");

	React.useEffect(() => {
		if (!clients) {
			clientActions.getClients(clientDispatch, enqueueSnackbar);
		} // eslint-disable-next-line
	}, []);

	return (
		<Grid container spacing={2}>
			<ItemView title="Group Name">
				<TextField
					margin="normal"
					autoFocus
					variant="filled"
					className={classes.input}
					label="Enter Group Name"
					value={group.name}
					onChange={(e) => {
						groupActions.setGroupName(groupDispatch, e.target.value as string);
					}}
				/>
			</ItemView>
			<ItemView title="Clients">
				<FormControl variant="filled" className={classes.formControl}>
					<InputLabel id="demo-mutiple-chip-label">Select Clients</InputLabel>
					<Select
						labelId="demo-mutiple-chip-label"
						id="demo-mutiple-chip"
						multiple
						value={group.clients.map((item) => item.name)}
						onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
							const values = event.target.value as string[];
							const selectedClients = clients?.filter(
								(item) => values.indexOf(item.name) !== -1
							);
							groupActions.setGroupClients(
								groupDispatch,
								selectedClients ?? []
							);
						}}
						// input={
						// 	<Input id="select-multiple-chip" className={classes.input} />
						// }
						// renderValue={(selected: any) => (
						// 	<div className={classes.chips}>
						// 		{(selected as string[]).map((value, index) => (
						// 			<Chip key={index} label={value} className={classes.chip} />
						// 		))}
						// 	</div>
						// )}
						MenuProps={{
							PaperProps: {
								style: {
									maxHeight: 48 * 4.5 + 8,
									width: 250,
								},
							},
						}}
					>
						{clients?.map((client, index) => (
							<MenuItem
								key={index}
								value={client.name}
								style={{
									fontWeight:
										group.clients.findIndex(
											(item) => item.name === client.name
										) === -1
											? "normal"
											: "bold",
								}}
							>
								{client.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</ItemView>
			{isEditing && (
				<ItemView title="Group Titles">
					<Button
						color="primary"
						variant="outlined"
						onClick={() => {
							userActions.handleSubscriptionStatus(
								userDispatch,
								user,
								enqueueSnackbar,
								() => {
									history.push(`/app/groups/${group.id}/group-plans`);
								}
							);
						}}
					>
						Edit...
					</Button>
				</ItemView>
			)}
		</Grid>
	);
}
