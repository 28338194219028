import { useEffect } from "react";
import { useGroupDispatch, useGroupState } from "../../../../../application/group";
import GroupActions from "../../../../../application/group/actions";
import { useInjection } from "../../../../../core/dependency_injection";
import SessionGroup from "../../../../../domain/group/models/SessionGroup";

export default function useFindGroupById(id: string) {
	const { group, sessionGroups } = useGroupState();
	const groupDispatch = useGroupDispatch();
	const groupActions = useInjection<GroupActions>("GroupActions");

	useEffect(() => {
		if (group.name === "" && sessionGroups) {
			const currentGroup = sessionGroups.find((item) => item.id === id);
			groupActions.setGroup(groupDispatch, currentGroup ?? SessionGroup.empty());
		} // eslint-disable-next-line
	}, [group, sessionGroups]);
	return;
}
