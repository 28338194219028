import Modal from "@material-ui/core/Modal";
import Card from "@material-ui/core/Card";
import ReactPlayer from "react-player";
import useStyles from "./styles";
import { IconButton, Typography } from "@material-ui/core";
import { useState } from "react";
import { Close } from "@material-ui/icons";

export default function ModalPlayer(props: { isOpen: boolean }) {
	const { isOpen } = props;

	const classes = useStyles();

	const [open, setOpen] = useState(isOpen);

	const handleClose = () => {
		localStorage.setItem("start-video", "viewed");
		setOpen(false);
	};

	return (
		<Modal
			className={classes.root}
			open={open}
			disableEnforceFocus
			disableAutoFocus
		>
			<Card className={classes.card}>
				<div className={classes.cardHeader}>
					<Typography variant="h3">Welcome to PSR Notes</Typography>
					<IconButton onClick={handleClose}>
						<Close />
					</IconButton>
				</div>
				<ReactPlayer
					url="https://youtu.be/feFDTsBxkLo"
					width="100%"
					height="80%"
					controls={true}
				/>
			</Card>
		</Modal>
	);
}
