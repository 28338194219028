import { useClientState } from "../../../../../../../../../../application/client";
import InfoDataItem from "../../../../../../../../components/InfoDataItem";
import useStyles from "./styles";

export default function PersonalInformation() {
	const classes = useStyles();

	// const { user } = useUserState();
	const { selectedClient } = useClientState();

	return (
		<div className={classes.root}>
			<div className={classes.itemContainer}>
				<InfoDataItem
					label={"Service Code"}
					value={selectedClient?.serviceCode ?? ""}
				/>
				<InfoDataItem label={"Case #"} value={selectedClient?.case ?? ""} />
			</div>
			<div className={classes.itemContainer}>
				<InfoDataItem label={"Setting"} value={selectedClient?.setting ?? ""} />
				<InfoDataItem
					label={"Diagnosis"}
					value={selectedClient?.diagnosis ?? ""}
				/>
			</div>
			{/* <div className={classes.itemContainer}>
        {user.contractorProps && (
          <>
            <InfoDataItem
              label={"Provider ID"}
              value={user?.contractorProps?.providerId ?? ""}
            />
            <InfoDataItem
              label={"License Number"}
              value={user?.contractorProps?.licenseNumber ?? ""}
            />
            <InfoDataItem
              label={"Languages"}
              value={languagesFormater(user?.contractorProps?.language ?? [])}
            />
          </>
        )}
      </div> */}
		</div>
	);
}
