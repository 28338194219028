import { makeStyles } from "@material-ui/core";
export default makeStyles((theme) => ({
	root: {
		width: "100%",
		minHeight: "100px",
		backgroundColor: theme.palette.secondary.main,
		padding: "24px",
		display: "flex",
		flexDirection: "column",
		marginTop: "50px",
	},
	logoContainer: {
		display: "flex",
		flex: 1.5,
		flexDirection: "column",
	},
	aboutContainer: {
		marginTop: "20px",
	},
	topContainer: {
		display: "flex",
		flexDirection: "row",
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column",
			justifyContent: "center",
		},
	},
	container: {
		display: "flex",
		flex: 1,
		flexDirection: "column",
		// justifyContent: "center",
		marginTop: "10px",
	},
	white: {
		color: theme.palette.common.white,
	},
	grey: {
		color: theme.palette.grey[400],
	},
	buttonContainer: {
		display: "flex",
		justifyContent: "center",
		marginTop: "40px",
	},
	divider: {
		display: "flex",
		height: "1px",
		backgroundColor: "grey",
		width: "100%",
		marginTop: "30px",
	},
	rigths: {
		display: "flex",
		justifyContent: "center",
		margin: "20px 0px",
	},
	link: {
		textDecoration: "none",
		color: theme.palette.grey[400],
		"&:hover": {
			color: theme.palette.grey[100],
		},
	},
	marginTop: {
		marginTop: "10px",
	},
}));
