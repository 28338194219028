import { Themes } from "../../../domain/core/utils";
import { ThemeAction, ThemeState, SET_DARK_MODE, SET_THEME } from "./misc";

export default function reducer(state: ThemeState, action: ThemeAction) {
	switch (action.type) {
		case SET_THEME:
			var isDark = false;
			if (action.payload === Themes.System) {
				isDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
			} else {
				isDark = action.payload === Themes.Dark;
			}
			localStorage.setItem("theme", action.payload);
			return { ...state, isDark: isDark, selectedTheme: action.payload } as ThemeState;
		case SET_DARK_MODE:
			return { ...state, isDark: action.payload } as ThemeState;
		default: {
			throw new Error(`Unhandled action type: ${action}`);
		}
	}
}
