import { Route, Switch } from "react-router-dom";
import { PublicRoute } from "../core/components/Routes";
import ErrorPage from "../Error";
import SignIn from "./SignIn";
import SignUp from "./SignUp";

export default function Auth() {
	return (
		<div>
			<Switch>
				{/* <PublicRoute path="/auth/signLink" component={SignLink} /> */}
				{/* <PublicRoute path="/auth/sign" component={Sign} /> */}
				<PublicRoute path="/auth/sign-in" component={SignIn} />
				<PublicRoute path="/auth/sign-up" component={SignUp} />
				<Route path="/auth/*" component={ErrorPage} />
			</Switch>
		</div>
	);
}
