import {
	SET_NOTE,
	SET_FETCHING,
	NoteDispatch,
	SetNoteGoalPayload,
	SetNoteGoalObjectivePayload,
	SET_NOTE_GOAL_OBJECTIVE,
	SET_TOTAL_UNITS,
	SET_START_DATE,
	SET_END_DATE,
	INIT_NOTE,
	InitNotePayload,
	SET_EDIT_NOTE,
	SET_NOTE_TREATMENT_ANSWER,
	SetNoteSelectedAnswer,
	SET_NOTE_GROUP_INTERVENTION,
	SET_NOTE_GROUP_PARTICIPATION,
	SetNoteGroupIntervention,
	SET_GROUP_TIPS,
	SET_GOALS,
	SET_NOTE_GOAL_GROUP_TIPS,
	SET_FETCHING_CLIENT_INFO,
	SetNoteCategoryPayload,
	SET_NOTE_GOAL_CATEGORY,
	SET_NOTE_PROGRESS_GOAL,
	SET_NOTE_PROGRESS_OBJECTIVE,
	SET_NOTE_PROGRESS_VALUE,
	SetNoteGroupTipTitle,
	SET_NOTE_GROUP_TIP_CATEGORY,
	SET_NOTE_GROUP_TIP_TITLE,
	SET_NOTE_GOAL_NAME,
	SetNoteGroupTipCategoryPayload,
	SET_USER_GOALS,
	SetNoteProgressValue,
	SET_DATE,
	SetNoteSelectedParticipation,
	SET_NOTE_CLIENT_NAME,
	SET_NOTE_CLIENT_CASE,
	SET_NOTE_CLIENT_SETTING,
	SET_NOTE_CLIENT_DIAGNOSIS,
	SET_NOTE_CLIENT_SERVICE_CODE,
	SET_NOTE_GOALS_END_DATE,
	SET_IS_GOALS_NEW,
	SET_NOTE_CLIENT,
} from "./misc";
import { EnqueueSnackbar } from "../../domain/core/utils";
import { INoteRepository } from "../../domain/note/inote.repository";
import Note from "../../domain/note/models/Note";
import { IGroupRepository } from "../../domain/group/igroup.repository";
import { IClientRepository } from "../../domain/client/iclient.repository";
import Goal from "../../domain/note/models/Goal";
import Treatment from "../../domain/note/models/Treatment";
import GroupTip from "../../domain/note/models/GroupTip";
import Client from "../../domain/client/models/Client";

export default class NoteActions {
	private noteRepository: INoteRepository;
	private groupRepository: IGroupRepository;
	private clientRepository: IClientRepository;

	constructor(
		noteRepository: INoteRepository,
		groupRepository: IGroupRepository,
		clientRepository: IClientRepository
	) {
		this.noteRepository = noteRepository;
		this.groupRepository = groupRepository;
		this.clientRepository = clientRepository;
	}

	setNoteClient(dispatch: NoteDispatch, payload: Client) {
		dispatch({ type: SET_NOTE_CLIENT, payload: payload });
	}

	setNoteClientName(dispatch: NoteDispatch, payload: string) {
		dispatch({ type: SET_NOTE_CLIENT_NAME, payload: payload });
	}

	setNoteClientCase(dispatch: NoteDispatch, payload: string) {
		dispatch({ type: SET_NOTE_CLIENT_CASE, payload: payload });
	}

	setNoteClientSetting(dispatch: NoteDispatch, payload: string) {
		dispatch({ type: SET_NOTE_CLIENT_SETTING, payload: payload });
	}

	setNoteClientDiagnosis(dispatch: NoteDispatch, payload: string) {
		dispatch({ type: SET_NOTE_CLIENT_DIAGNOSIS, payload: payload });
	}

	setNoteClientServiceCode(dispatch: NoteDispatch, payload: string) {
		dispatch({ type: SET_NOTE_CLIENT_SERVICE_CODE, payload: payload });
	}

	setNoteGoalName(dispatch: NoteDispatch, payload: SetNoteGoalPayload) {
		dispatch({ type: SET_NOTE_GOAL_NAME, payload: payload });
	}

	setNoteGoalCategory(dispatch: NoteDispatch, payload: SetNoteCategoryPayload) {
		dispatch({ type: SET_NOTE_GOAL_CATEGORY, payload: payload });
	}

	initNote(dispatch: NoteDispatch, payload: InitNotePayload) {
		dispatch({ type: INIT_NOTE, payload: payload });
	}

	setEditNote(dispatch: NoteDispatch, note: Note) {
		dispatch({ type: SET_EDIT_NOTE, payload: note });
	}

	setNoteTotalUnits(dispatch: NoteDispatch, value: number) {
		dispatch({ type: SET_TOTAL_UNITS, payload: value });
	}

	setNoteDate(dispatch: NoteDispatch, value: Date) {
		dispatch({ type: SET_DATE, payload: value });
	}

	setNoteStartDate(dispatch: NoteDispatch, value: Date) {
		dispatch({ type: SET_START_DATE, payload: value });
	}

	setNoteEndDate(dispatch: NoteDispatch, value: Date) {
		dispatch({ type: SET_END_DATE, payload: value });
	}

	setNoteGoalsEndDate(dispatch: NoteDispatch, value: Date) {
		dispatch({ type: SET_NOTE_GOALS_END_DATE, payload: value });
	}

	setIsNoteGoalsNew(dispatch: NoteDispatch, value: boolean) {
		dispatch({ type: SET_IS_GOALS_NEW, payload: value });
	}

	setNoteTreatmentSelectedAnswer(
		dispatch: NoteDispatch,
		payload: SetNoteSelectedAnswer
	) {
		dispatch({
			type: SET_NOTE_TREATMENT_ANSWER,
			payload: payload,
		});
	}

	setNoteGroupIntervention(
		dispatch: NoteDispatch,
		payload: SetNoteGroupIntervention
	) {
		dispatch({
			type: SET_NOTE_GROUP_INTERVENTION,
			payload: payload,
		});
	}

	setNoteGroupParticipation(
		dispatch: NoteDispatch,
		payload: SetNoteSelectedParticipation
	) {
		dispatch({
			type: SET_NOTE_GROUP_PARTICIPATION,
			payload: payload,
		});
	}

	setNoteGoalObjective(
		dispatch: NoteDispatch,
		payload: SetNoteGoalObjectivePayload
	) {
		dispatch({ type: SET_NOTE_GOAL_OBJECTIVE, payload: payload });
	}

	// setNoteGroupTip(dispatch: NoteDispatch, payload: SetNoteGroupTipTitle) {
	// 	dispatch({ type: SET_NOTE_GROUP_TIP, payload: payload });
	// }

	setNoteGroupTipTitle(dispatch: NoteDispatch, payload: SetNoteGroupTipTitle) {
		dispatch({ type: SET_NOTE_GROUP_TIP_TITLE, payload: payload });
	}

	setNoteGroupTipCategory(
		dispatch: NoteDispatch,
		payload: SetNoteGroupTipCategoryPayload
	) {
		dispatch({ type: SET_NOTE_GROUP_TIP_CATEGORY, payload: payload });
	}

	setNoteProgressGoal(dispatch: NoteDispatch, value: number) {
		dispatch({ type: SET_NOTE_PROGRESS_GOAL, payload: value });
	}

	setNoteProgressObjective(dispatch: NoteDispatch, value: number) {
		dispatch({ type: SET_NOTE_PROGRESS_OBJECTIVE, payload: value });
	}

	setNoteProgressValue(dispatch: NoteDispatch, value: SetNoteProgressValue) {
		dispatch({ type: SET_NOTE_PROGRESS_VALUE, payload: value });
	}

	async getGroupTips(dispatch: NoteDispatch, enqueueSnackbar: EnqueueSnackbar) {
		dispatch({ type: SET_FETCHING, payload: true });
		const groupTipsResult = await this.noteRepository.getGroupTips();
		if (groupTipsResult.isLeft) {
			enqueueSnackbar(groupTipsResult.getError(), { variant: "error" });
			dispatch({ type: SET_FETCHING, payload: false });
			return;
		}
		const userGroupTipsResult = await this.noteRepository.getUserGroupTips();
		if (userGroupTipsResult.isLeft) {
			enqueueSnackbar(userGroupTipsResult.getError(), { variant: "error" });
			dispatch({ type: SET_FETCHING, payload: false });
			return;
		}
		dispatch({
			type: SET_GROUP_TIPS,
			payload: {
				groupTips: groupTipsResult.getValue(),
				userGroupTips: userGroupTipsResult.getValue(),
			},
		});
		dispatch({ type: SET_FETCHING, payload: false });
	}

	async getGoals(dispatch: NoteDispatch, enqueueSnackbar: EnqueueSnackbar) {
		dispatch({ type: SET_FETCHING, payload: true });
		const result = await this.noteRepository.getGoals();
		if (result.isLeft) {
			enqueueSnackbar(result.getError(), { variant: "error" });
			dispatch({ type: SET_FETCHING, payload: false });
			return;
		}
		const userGoalsresult = await this.noteRepository.getUserGoals();
		if (userGoalsresult.isLeft) {
			enqueueSnackbar(userGoalsresult.getError(), { variant: "error" });
			dispatch({ type: SET_FETCHING, payload: false });
			return;
		}
		dispatch({
			type: SET_GOALS,
			payload: {
				goals: result.getValue(),
				userGoals: userGoalsresult.getValue(),
			},
		});
		dispatch({ type: SET_FETCHING, payload: false });
	}

	async setUserGoals(
		dispatch: NoteDispatch,
		goals: Goal[],
		enqueueSnackbar: EnqueueSnackbar
	) {
		// dispatch({ type: SET_SA, payload: true });
		const result = await this.noteRepository.setUserGoals(goals);
		if (result.isLeft) {
			enqueueSnackbar(result.getError(), { variant: "warning" });
			// dispatch({ type: SET_FETCHING, payload: false });
			return;
		}
		dispatch({
			type: SET_USER_GOALS,
			payload: goals,
		});
		dispatch({ type: SET_FETCHING, payload: false });
	}

	async setUserTreatments(
		dispatch: NoteDispatch,
		treatments: Treatment[],
		enqueueSnackbar: EnqueueSnackbar
	) {
		// dispatch({ type: SET_SA, payload: true });
		const result = await this.noteRepository.setUserTreatments(treatments);
		if (result.isLeft) {
			enqueueSnackbar(result.getError(), { variant: "warning" });
			// dispatch({ type: SET_FETCHING, payload: false });
			return;
		}
		// dispatch({
		// 	type: SET_USER_GOALS,
		// 	payload: goals,
		// });
		dispatch({ type: SET_FETCHING, payload: false });
	}

	async setUserGroupTips(
		dispatch: NoteDispatch,
		groupTips: GroupTip[],
		enqueueSnackbar: EnqueueSnackbar
	) {
		// dispatch({ type: SET_SA, payload: true });
		const result = await this.noteRepository.setUserGroupTips(groupTips);
		if (result.isLeft) {
			enqueueSnackbar(result.getError(), { variant: "warning" });
			// dispatch({ type: SET_FETCHING, payload: false });
			return;
		}
		// dispatch({
		// 	type: SET_USER_GOALS,
		// 	payload: goals,
		// });
		dispatch({ type: SET_FETCHING, payload: false });
	}

	async getNoteClientInfo(
		dispatch: NoteDispatch,
		clientId: string,
		date: Date
	) {
		dispatch({ type: SET_FETCHING_CLIENT_INFO, payload: true });
		const groupTipsResult = await this.groupRepository.getGroupPlanByDate(
			clientId,
			date
		);
		const goalPlansResult = await this.clientRepository.getGoalPlanByDate(
			clientId,
			date
		);
		dispatch({
			type: SET_NOTE_GOAL_GROUP_TIPS,
			payload: {
				groupTips: groupTipsResult.isRigth
					? groupTipsResult.getValue().groupTips
					: undefined,
				goals: goalPlansResult.isRigth
					? goalPlansResult.getValue().goals
					: undefined,
			},
		});
		dispatch({ type: SET_FETCHING_CLIENT_INFO, payload: false });
	}

	async getNote(dispatch: NoteDispatch, enqueueSnackbar: EnqueueSnackbar) {
		dispatch({ type: SET_FETCHING, payload: true });
		const goalsResult = await this.noteRepository.getGoals();
		if (goalsResult.isLeft) {
			enqueueSnackbar(goalsResult.getError(), { variant: "error" });
			dispatch({ type: SET_FETCHING, payload: false });
			return;
		}
		const userGoalsResult = await this.noteRepository.getUserGoals();
		if (userGoalsResult.isLeft) {
			enqueueSnackbar(userGoalsResult.getError(), { variant: "error" });
			dispatch({ type: SET_FETCHING, payload: false });
			return;
		}
		const treatmentsResult = await this.noteRepository.getTreatments();
		if (treatmentsResult.isLeft) {
			enqueueSnackbar(treatmentsResult.getError(), { variant: "error" });
			dispatch({ type: SET_FETCHING, payload: false });
			return;
		}
		const userTreatmentsResult = await this.noteRepository.getUserTreatments();
		if (userTreatmentsResult.isLeft) {
			enqueueSnackbar(userTreatmentsResult.getError(), { variant: "error" });
			dispatch({ type: SET_FETCHING, payload: false });
			return;
		}
		const groupTipsResult = await this.noteRepository.getGroupTips();
		if (groupTipsResult.isLeft) {
			enqueueSnackbar(groupTipsResult.getError(), { variant: "error" });
			dispatch({ type: SET_FETCHING, payload: false });
			return;
		}
		const userGroupTipsResult = await this.noteRepository.getUserGroupTips();
		if (userGroupTipsResult.isLeft) {
			enqueueSnackbar(userGroupTipsResult.getError(), { variant: "error" });
			dispatch({ type: SET_FETCHING, payload: false });
			return;
		}
		// console.log(`treatmentsResult.getValue()`, treatmentsResult.getValue());
		dispatch({
			type: SET_NOTE,
			payload: {
				goals: goalsResult.getValue(),
				userGoals: userGoalsResult.getValue(),
				treatments: treatmentsResult.getValue(),
				userTreatments: userTreatmentsResult.getValue(),
				groupTips: groupTipsResult.getValue(),
				userGroupTips: userGroupTipsResult.getValue(),
			},
		});
		dispatch({ type: SET_FETCHING, payload: false });
	}
}
