import { Route, Switch } from "react-router";
import GoalPlanCreate from "./pages/GoalPlanCreate";
import GoalPlanEdit from "./pages/GoalPlanEdit";

export default function GoalPlans() {
	return (
		<div>
			<Switch>
				<Route path="/app/clients/:id/goals-objectives/create" component={GoalPlanCreate} />
				<Route path="/app/clients/:id/goals-objectives/:goalPlanId" component={GoalPlanEdit} />
			</Switch>
		</div>
	);
}
