import React from "react";
import { initialValues, ThemeDispatch } from "./misc";
import reducer from "./reducer";

var ThemeStateContext = React.createContext(initialValues);
var ThemeDispatchContext = React.createContext<ThemeDispatch>(() => null);

function ThemeProvider({ children }: any) {
  var [state, dispatch] = React.useReducer(reducer, initialValues);
  return (
    <ThemeStateContext.Provider value={state}>
      <ThemeDispatchContext.Provider value={dispatch}>
        {children}
      </ThemeDispatchContext.Provider>
    </ThemeStateContext.Provider>
  );
}

function useThemeState() {
  var context = React.useContext(ThemeStateContext);
  if (context === undefined) {
    throw new Error("useThemeState must be used within a ThemeProvider");
  }
  return context;
}

function useThemeDispatch() {
  var context = React.useContext(ThemeDispatchContext);
  if (context === undefined) {
    throw new Error("useThemeDispatch must be used within a ThemeProvider");
  }
  return context;
}

export { ThemeProvider, useThemeState, useThemeDispatch };
