import Client from "../../domain/client/models/Client";
import Goal from "../../domain/note/models/Goal";
import GroupTip, { GroupObject } from "../../domain/note/models/GroupTip";
import Note from "../../domain/note/models/Note";
import UpdateOfProgress from "../../domain/note/models/UpdateOfProgress";
import {
	NoteAction,
	NoteState,
	SET_END_DATE,
	SET_FETCHING,
	SET_NOTE,
	INIT_NOTE,
	SET_START_DATE,
	SET_TOTAL_UNITS,
	SET_EDIT_NOTE,
	SET_NOTE_TREATMENT,
	SET_NOTE_TREATMENT_ANSWER,
	SET_NOTE_GOAL_OBJECTIVE,
	SET_NOTE_GROUP_INTERVENTION,
	SET_NOTE_GROUP_PARTICIPATION,
	SET_GROUP_TIPS,
	SET_GOALS,
	SET_NOTE_GOALS,
	SET_NOTE_GOAL_GROUP_TIPS,
	SET_FETCHING_CLIENT_INFO,
	SET_NOTE_GOAL_CATEGORY,
	SET_NOTE_PROGRESS_GOAL,
	SET_NOTE_PROGRESS_OBJECTIVE,
	SET_NOTE_PROGRESS_VALUE,
	SET_NOTE_GROUP_TIP_CATEGORY,
	SET_NOTE_GROUP_TIP_TITLE,
	SET_NOTE_GOAL_NAME,
	SET_USER_GOALS,
	SET_DATE,
	SET_NOTE_CLIENT_NAME,
	SET_NOTE_CLIENT_CASE,
	SET_NOTE_CLIENT_DIAGNOSIS,
	SET_NOTE_CLIENT_SETTING,
	SET_NOTE_CLIENT_SERVICE_CODE,
	SET_NOTE_GOALS_END_DATE,
	SET_IS_GOALS_NEW,
	SET_NOTE_CLIENT,
} from "./misc";

export default function reducer(state: NoteState, action: NoteAction) {
	// console.log(`action`, action);
	// console.log(`state`, state);
	var noteGoals;
	var goal;
	var treatments;
	var groupTips: GroupTip[];
	var categories: string[];
	var groupTip;

	switch (action.type) {
		case SET_FETCHING:
			return { ...state, isFetching: action.payload };
		case SET_FETCHING_CLIENT_INFO:
			return { ...state, isFetchingClientInfo: action.payload } as NoteState;
		case SET_IS_GOALS_NEW:
			return { ...state, isGoalsNew: action.payload } as NoteState;
		case SET_NOTE_CLIENT:
			return {
				...state,
				note: new Note({
					...state.note,
					client: action.payload,
				}),
			} as NoteState;
		case SET_NOTE:
			treatments =
				state.note.treatments.length === 0
					? action.payload.treatments.map((item) => item.toNoteTreatment())
					: state.note.treatments;
			categories = action.payload.goals
				.filter(
					(v, i, a) => a.findIndex((e) => e.category === v.category) === i
				)
				.map((item) => item.category);
			const groupTipsCategories = action.payload.groupTips
				.filter(
					(v, i, a) => a.findIndex((e) => e.category === v.category) === i
				)
				.map((item) => item.category);
			const userGroupTipCategories = action.payload.userGroupTips
				.filter(
					(v, i, a) => a.findIndex((e) => e.category === v.category) === i
				)
				.map((item) => item.category);
			const userGoalsCategories = action.payload.userGoals
				.filter(
					(v, i, a) => a.findIndex((e) => e.category === v.category) === i
				)
				.map((item) => item.category);
			categories.sort((a, b) => a.localeCompare(b));
			groupTipsCategories.sort((a, b) => a.localeCompare(b));
			userGroupTipCategories.sort((a, b) => a.localeCompare(b));
			userGoalsCategories.sort((a, b) => a.localeCompare(b));
			return {
				...state,
				userGoals: action.payload.userGoals,
				userGoalsCategories: userGoalsCategories,
				goals: action.payload.goals,
				goalsCategories: categories,
				groupTips: action.payload.groupTips,
				groupTipsCategories: groupTipsCategories,
				userGroupTips: action.payload.userGroupTips,
				userGroupTipsCategories: userGroupTipCategories,
				treatments: action.payload.treatments,
				userTreatments: action.payload.userTreatments,
				note: new Note({
					...state.note,
					treatments: treatments,
				}),
			} as NoteState;
		case SET_GROUP_TIPS:
			categories = action.payload.groupTips
				.filter(
					(v, i, a) => a.findIndex((e) => e.category === v.category) === i
				)
				.map((item) => item.category);
			const userGroupTipsCategories = action.payload.userGroupTips
				.filter(
					(v, i, a) => a.findIndex((e) => e.category === v.category) === i
				)
				.map((item) => item.category);
			categories.sort((a, b) => a.localeCompare(b));
			userGroupTipsCategories.sort((a, b) => a.localeCompare(b));
			return {
				...state,
				groupTipsCategories: categories,
				groupTips: [...action.payload.groupTips],
				userGroupTipsCategories: userGroupTipsCategories,
				userGroupTips: [...action.payload.userGroupTips],
			} as NoteState;
		case SET_NOTE_GOAL_GROUP_TIPS:
			groupTips = state.note.groupTips;
			const payloadGroupTips = action.payload.groupTips;
			if (payloadGroupTips) {
				groupTips.forEach((_, index) => {
					const newGroupTip = payloadGroupTips[index];
					groupTips[index] = new GroupTip({
						...groupTips[index],
						category: newGroupTip.category,
						title: newGroupTip.title,
						selectedIntervention: new GroupObject({
							...(newGroupTip.selectedIntervention ?? GroupObject.Empty()),
						}),
					});
				});
			}
			return {
				...state,
				note: new Note({
					...state.note,
					groupTips: !state.isEditingNote
						? payloadGroupTips
							? [...groupTips]
							: Note.empty().groupTips
						: state.note.groupTips,
					goals: !state.isEditingNote
						? action.payload.goals
							? [...action.payload.goals]
							: Note.empty().goals
						: state.note.goals,
				}),
			} as NoteState;
		case SET_GOALS:
			categories = action.payload.goals
				.filter(
					(v, i, a) => a.findIndex((e) => e.category === v.category) === i
				)
				.map((item) => item.category);
			categories.sort((a, b) => a.localeCompare(b));
			const userGoalcategories = action.payload.userGoals
				.filter(
					(v, i, a) => a.findIndex((e) => e.category === v.category) === i
				)
				.map((item) => item.category);
			userGoalcategories.sort((a, b) => a.localeCompare(b));
			return {
				...state,
				userGoals: [...action.payload.userGoals],
				goals: [...action.payload.goals],
				goalsCategories: categories,
				userGoalsCategories: userGoalcategories,
			} as NoteState;
		case SET_USER_GOALS:
			categories = action.payload
				.filter(
					(v, i, a) => a.findIndex((e) => e.category === v.category) === i
				)
				.map((item) => item.category);
			return {
				...state,
				userGoalsCategories: [...state.userGoalsCategories, ...categories],
				userGoals: [...state.userGoals, ...action.payload],
			} as NoteState;
		case SET_NOTE_GOAL_NAME:
			noteGoals = state.note.goals;
			goal = noteGoals[action.payload.index];
			goal.name = action.payload.goalName;
			goal.changeIsNewName(action.payload.isNew);
			goal.objectives = Goal.empty().objectives;
			noteGoals[action.payload.index] = goal;
			return {
				...state,
				note: new Note({
					...state.note,
					goals: noteGoals,
				}),
			};
		case SET_NOTE_GOALS:
			return {
				...state,
				note: new Note({
					...state.note,
					goals: action.payload,
				}),
			};
		case SET_NOTE_GOALS_END_DATE:
			return {
				...state,
				goalsEndDate: action.payload,
			} as NoteState;
		case SET_NOTE_GOAL_CATEGORY:
			noteGoals = state.note.goals;
			const newGoal = action.payload.clean
				? Goal.empty()
				: noteGoals[action.payload.index];
			// const newGoal = Goal.empty();
			newGoal.category = action.payload.value;
			newGoal.changeIsNewCategory(action.payload.isNew);
			noteGoals[action.payload.index] = newGoal;
			return {
				...state,
				note: new Note({
					...state.note,
					goals: noteGoals,
				}),
			};
		case SET_NOTE_GOAL_OBJECTIVE:
			noteGoals = state.note.goals;
			goal = noteGoals[action.payload.goalIndex];
			var objectives = goal.objectives;
			objectives[action.payload.objectiveIndex] = action.payload.objective;
			goal.objectives = objectives;
			noteGoals[action.payload.goalIndex] = goal;
			// console.log(`SET_NOTE_GOAL_OBJECTIVE noteGoals`, noteGoals);
			return {
				...state,
				note: new Note({
					...state.note,
					goals: noteGoals,
				}),
			};
		case SET_NOTE_TREATMENT:
			treatments = state.note.treatments;
			var treatmentIndex = treatments.findIndex(
				(item) => item.question === action.payload.question
			);
			treatments[treatmentIndex] = action.payload.toNoteTreatment();
			// console.log(`SET_NOTE_GOAL_OBJECTIVE noteGoals`, noteGoals);
			return {
				...state,
				note: new Note({
					...state.note,
					treatments: treatments,
				}),
			};
		// case SET_NOTE_GROUP_TIP:
		// 	groupTips = state.note.groupTips;
		// 	groupTip = state.groupTips.find((item) => item.title === action.payload.title);
		// 	// const groupTip = action.payload.title;
		// 	groupTips[action.payload.index] = new GroupTip({
		// 		...groupTips[action.payload.index],
		// 		title: groupTip?.title ?? "",
		// 		// selectedParticipation: "",
		// 		// selectedIntervention: { label: "", value: "" },
		// 		interventions: groupTip?.interventions,
		// 		participations: groupTip?.participations,
		// 	});
		// 	// console.log(`SET_NOTE_GOAL_OBJECTIVE noteGoals`, noteGoals);
		// 	return {
		// 		...state,
		// 		note: new Note({
		// 			...state.note,
		// 			groupTips: groupTips,
		// 		}),
		// 	} as NoteState;
		case SET_NOTE_GROUP_TIP_TITLE:
			groupTips = state.note.groupTips;
			// const groupTip = action.payload.title;
			var nGroupTip: GroupTip = groupTips[action.payload.index];
			if (action.payload.find) {
				groupTip =
					state.groupTips.find((item) => item.title === action.payload.title) ??
					state.userGroupTips.find(
						(item) => item.title === action.payload.title
					);
				nGroupTip = new GroupTip(
					action.payload.clean
						? {
								...nGroupTip,
								title: groupTip?.title ?? "",
								selectedParticipation: "",
								selectedIntervention: new GroupObject({ label: "", value: "" }),
								interventions: groupTip?.interventions
									? [...groupTip.interventions]
									: [],
								participations: groupTip?.participations
									? [...groupTip.participations]
									: [],
						  }
						: {
								...nGroupTip,
								title: groupTip?.title ?? "",
								interventions: groupTip?.interventions
									? [...groupTip.interventions]
									: [],
								participations: groupTip?.participations
									? [...groupTip.participations]
									: [],
						  }
				);
			} else {
				nGroupTip.title = action.payload.title;
			}
			nGroupTip.changeIsNewTitle(action.payload.isNew);
			groupTips[action.payload.index] = new GroupTip({ ...nGroupTip });
			// console.log(`SET_NOTE_GOAL_OBJECTIVE noteGoals`, noteGoals);
			return {
				...state,
				note: new Note({
					...state.note,
					groupTips: [...groupTips],
				}),
			} as NoteState;
		case SET_NOTE_GROUP_TIP_CATEGORY:
			groupTips = state.note.groupTips;
			// goal = noteGoals[action.payload.index];
			const newGroupTip = action.payload.clean
				? GroupTip.empty()
				: groupTips[action.payload.index];
			newGroupTip.category = action.payload.value;
			newGroupTip.changeIsNewCategory(action.payload.isNew);
			groupTips[action.payload.index] = newGroupTip;
			return {
				...state,
				note: new Note({
					...state.note,
					groupTips: groupTips,
				}),
			};
		case SET_NOTE_TREATMENT_ANSWER:
			var data = action.payload;
			treatments = state.note.treatments;
			treatments[data.index].selectedAnswer = data.selectedAnswer;
			treatments[data.index].isNew = data.isNew;
			// console.log(`SET_NOTE_GOAL_OBJECTIVE noteGoals`, noteGoals);
			return {
				...state,
				note: new Note({
					...state.note,
					treatments: treatments,
				}),
			};
		case SET_NOTE_GROUP_INTERVENTION:
			groupTips = state.note.groupTips;
			groupTip = groupTips[action.payload.index];
			groupTip.selectedIntervention = action.payload.value;
			groupTip.changeIsNewIntervention(action.payload.isNew);
			// console.log(`SET_NOTE_GOAL_OBJECTIVE noteGoals`, noteGoals);
			return {
				...state,
				note: new Note({
					...state.note,
					groupTips: groupTips,
				}),
			} as NoteState;
		case SET_NOTE_GROUP_PARTICIPATION:
			groupTips = state.note.groupTips;
			groupTip = groupTips[action.payload.index];
			groupTip.selectedParticipation = action.payload.selectedAnswer;
			groupTip.changeIsNewParticipation(action.payload.isNew);
			// console.log(`SET_NOTE_GOAL_OBJECTIVE noteGoals`, noteGoals);
			return {
				...state,
				note: new Note({
					...state.note,
					groupTips: groupTips,
				}),
			} as NoteState;
		case SET_NOTE_PROGRESS_GOAL:
			return {
				...state,
				note: new Note({
					...state.note,
					updateOfProgress: new UpdateOfProgress({
						...state.note.updateOfProgress,
						goal: action.payload,
						objective: -1,
						progress: { label: "", value: "" },
					}),
				}),
			};
		case SET_NOTE_PROGRESS_OBJECTIVE:
			return {
				...state,
				note: new Note({
					...state.note,
					updateOfProgress: new UpdateOfProgress({
						...state.note.updateOfProgress,
						objective: action.payload,
						progress: { label: "", value: "" },
					}),
				}),
			};
		case SET_NOTE_PROGRESS_VALUE:
			return {
				...state,
				note: new Note({
					...state.note,
					updateOfProgress: new UpdateOfProgress({
						...state.note.updateOfProgress,
						progress: action.payload.progress,
						isNew: action.payload.isNew,
					}),
				}),
			};
		case INIT_NOTE:
			return {
				...state,
				isEditingNote: false,
				note: new Note({
					...Note.empty(),
					client: action.payload.client,
					company: action.payload.company,
					treatments: state.treatments.map((item) => item.toNoteTreatment()),
					startDate: action.payload.date ?? state.note.startDate,
					endDate: action.payload.date
						? new Date(action.payload.date.getTime() + 1000 * 60 * 60)
						: state.note.endDate,
				}),
			};
		case SET_NOTE_CLIENT_NAME:
			return {
				...state,
				note: new Note({
					...state.note,
					client: new Client({
						...state.note.client,
						name: action.payload,
					}),
				}),
			};
		case SET_NOTE_CLIENT_CASE:
			return {
				...state,
				note: new Note({
					...state.note,
					client: new Client({
						...state.note.client,
						case: action.payload,
					}),
				}),
			};
		case SET_NOTE_CLIENT_DIAGNOSIS:
			return {
				...state,
				note: new Note({
					...state.note,
					client: new Client({
						...state.note.client,
						diagnosis: action.payload,
					}),
				}),
			};
		case SET_NOTE_CLIENT_SETTING:
			return {
				...state,
				note: new Note({
					...state.note,
					client: new Client({
						...state.note.client,
						setting: action.payload,
					}),
				}),
			};
		case SET_NOTE_CLIENT_SERVICE_CODE:
			return {
				...state,
				note: new Note({
					...state.note,
					client: new Client({
						...state.note.client,
						serviceCode: action.payload,
					}),
				}),
			};
		case SET_EDIT_NOTE:
			return {
				...state,
				isEditingNote: true,
				note: action.payload,
			};
		case SET_TOTAL_UNITS:
			return {
				...state,
				note: new Note({ ...state.note, totalUnits: action.payload }),
			};
		case SET_DATE:
			return {
				...state,
				note: new Note({
					...state.note,
					startDate: action.payload,
					endDate: new Date(action.payload.getTime() + 1000 * 60 * 60),
				}),
			};
		case SET_START_DATE:
			return {
				...state,
				note: new Note({
					...state.note,
					startDate: action.payload,
					endDate: new Date(action.payload.getTime() + 1000 * 60 * 60),
				}),
			};
		case SET_END_DATE:
			return {
				...state,
				note: new Note({ ...state.note, endDate: action.payload }),
			};
		default: {
			throw new Error(`Unhandled action type: ${action}`);
		}
	}
}
