import Product from "../../../domain/product/models/Product";
import firebase from "firebase/app";
import PriceDto from "./PriceDto";
import { PRICES_COLLECTION } from "../../core/constants";

type ProductDtoProps = {
	id: string;
	active: boolean;
	name: string;
	description?: string | null;
	order: string;
	clients: string;
	groups: string;
	generator: string;
	prices?: PriceDto[];
};

export default class ProductDto {
	id: string;
	active: boolean;
	name: string;
	description?: string | null;
	order: string;
	clients: string;
	groups: string;
	generator: string;
	prices?: PriceDto[];

	constructor(props: ProductDtoProps) {
		this.id = props.id;
		this.active = props.active;
		this.name = props.name;
		this.order = props.order;
		this.clients = props.clients;
		this.groups = props.groups;
		this.generator = props.generator;
		this.prices = props.prices;
	}

	public static fromDomain(model: Product): ProductDto {
		return new ProductDto({
			id: model.id,
			active: model.active,
			name: model.name,
			description: model.description,
			order: model.order,
			clients: model.clients,
			groups: model.groups,
			generator: model.generator,
			prices: model.prices?.map((item) => PriceDto.fromDomain(item)),
		});
	}

	public static async fromFirestore(
		doc: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
	): Promise<ProductDto> {
		const data = doc.data();
		const prices = await doc.ref.collection(PRICES_COLLECTION).get();
		return new ProductDto({
			id: doc.id,
			active: data?.active,
			name: data?.name,
			description: data?.description,
			order: data?.order,
			clients: data?.clients,
			groups: data?.groups,
			generator: data?.generator,
			prices: prices.docs.map((item) => PriceDto.fromFirestore(item)),
		});
	}

	public toDomain(): Product {
		return new Product({
			id: this.id,
			active: this.active,
			name: this.name,
			description: this.description,
			order: this.order,
			clients: this.clients,
			groups: this.groups,
			generator: this.generator,
			prices: this.prices?.map((item) => item.toDomain()),
		});
	}
}
