import Price from "../../../domain/product/models/Price";
import firebase from "firebase/app";

type PriceDtoProps = {
	id: string;
	active: boolean;
	currency: string;
	description?: string | null;
	interval: string;
	interval_count: number;
	trial_period_days: number;
	type: string;
	unit_amount: number;
};

export default class PriceDto {
	id: string;
	active: boolean;
	currency: string;
	description?: string | null;
	interval: string;
	interval_count: number;
	trial_period_days: number;
	type: string;
	unit_amount: number;

	constructor(props: PriceDtoProps) {
		this.id = props.id;
		this.active = props.active;
		this.currency = props.currency;
		this.interval = props.interval;
		this.interval_count = props.interval_count;
		this.trial_period_days = props.trial_period_days;
		this.type = props.type;
		this.unit_amount = props.unit_amount;
	}

	public static fromDomain(model: Price): PriceDto {
		return new PriceDto({
			id: model.id,
			active: model.active,
			currency: model.currency,
			description: model.description,
			interval: model.interval,
			interval_count: model.interval_count,
			trial_period_days: model.trial_period_days,
			type: model.type,
			unit_amount: model.unit_amount,
		});
	}

	public static fromFirestore(
		doc: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
	): PriceDto {
		const data = doc.data();
		return new PriceDto({
			id: doc.id,
			active: data?.active,
			currency: data?.currency,
			description: data?.description,
			interval: data?.interval,
			interval_count: data?.interval_count,
			trial_period_days: data?.trial_period_days,
			type: data?.type,
			unit_amount: data?.unit_amount,
		});
	}

	public toDomain(): Price {
		return new Price({
			id: this.id,
			active: this.active,
			currency: this.currency,
			description: this.description,
			interval: this.interval,
			interval_count: this.interval_count,
			trial_period_days: this.trial_period_days,
			type: this.type,
			unit_amount: this.unit_amount,
		});
	}
}
