import Price from "../../product/models/Price";
import Product from "../../product/models/Product";

export default class Subscription {
	id: string;
	status: string;
	product: Product;
	price: Price;
	cancel_at_period_end: boolean;
	current_period_start: Date;
	current_period_end: Date;
	created: Date;
	cancel_at?: Date;
	canceled_at?: Date;
	ended_at?: Date;
	trial_start?: Date;
	trial_end?: Date;

	constructor(props: {
		id: string;
		status: string;
		product: Product;
		price: Price;
		cancel_at_period_end: boolean;
		current_period_start: Date;
		current_period_end: Date;
		created: Date;
		cancel_at?: Date;
		canceled_at?: Date;
		ended_at?: Date;
		trial_start?: Date;
		trial_end?: Date;
	}) {
		this.id = props.id;
		this.status = props.status;
		this.product = props.product;
		this.price = props.price;
		this.cancel_at_period_end = props.cancel_at_period_end;
		this.cancel_at = props.cancel_at;
		this.canceled_at = props.canceled_at;
		this.current_period_start = props.current_period_start;
		this.current_period_end = props.current_period_end;
		this.created = props.created;
		this.ended_at = props.ended_at;
		this.trial_start = props.trial_start;
		this.trial_end = props.trial_end;
	}

	// public static empty(): Subscription {
	// 	return new Subscription({
	// 		id: "",
	// 		status: false,
	// 	});
	// }

	public isActive(): boolean {
		return this.status === "active" || this.status === "trialing";
	}

	// public canAddClient(currentNumberOfClients: number): boolean {
	// 	if (this.product.name === "Standard" && currentNumberOfClients < 5) {
	// 		return true;
	// 	} else if (this.product.name === "Pro" && currentNumberOfClients < 30) {
	// 		return true;
	// 	} else if (this.product.name === "Unlimited") {
	// 		return true;
	// 	}
	// 	return false;
	// }

	// public checkIfIsActive(): { error: boolean; message?: string } {

	// 	return { error: false };
	// }
}
