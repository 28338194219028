import {
	CLIENTS_COLLECTION,
	CUSTOMERS_COLLECTION,
	GOAL_PLANS_COLLECTION,
	GROUPS_COLLECTION,
	NOTES_COLLECTION,
} from "../../core/constants";
import Firebase from "../../core/firebase";
import { ClientDto } from "../dtos/ClientDto";
import { GoalPlanDto } from "../dtos/GoalPlanDto";
import firebase from "firebase";

export interface IClientRemoteDatasource {
	getClients(): Promise<ClientDto[]>;
	create(client: ClientDto): Promise<ClientDto>;
	delete(id: string): Promise<void>;
	update(client: ClientDto): Promise<void>;
	getGoalPlanByDate(clientId: string, date: Date): Promise<GoalPlanDto>;
	createGoalPlan(
		clientId: string,
		goalPlanDto: GoalPlanDto
	): Promise<GoalPlanDto>;
	updateGoalPlan(clientId: string, goalPlanDto: GoalPlanDto): Promise<void>;
	deleteGoalPlan(clientId: string, goalPlanId: string): Promise<void>;
}

export default class ClientRemoteDatasource implements IClientRemoteDatasource {
	async getGoalPlanByDate(clientId: string, date: Date): Promise<GoalPlanDto> {
		const firebaseInstance = Firebase.GetInstance();
		const timeStamp = firebase.firestore.Timestamp.fromDate(
			new Date(date.getFullYear(), date.getMonth(), date.getDate())
		);
		var docRef = await firebaseInstance.firestore
			.collection(CUSTOMERS_COLLECTION)
			.doc(firebaseInstance.auth.currentUser?.uid)
			.collection(CLIENTS_COLLECTION)
			.doc(clientId)
			.collection(GOAL_PLANS_COLLECTION)
			.where("startDate", "<=", timeStamp)
			.get();
		const goalPlan = docRef.docs.find(
			(item) => timeStamp.seconds < item.data().endDate.seconds
		);
		if (goalPlan) {
			return GoalPlanDto.fromFirestore(goalPlan);
		} else {
			throw new Error("There is no goals for this date");
		}
	}
	async deleteGoalPlan(clientId: string, goalPlanId: string): Promise<void> {
		const firebase = Firebase.GetInstance();
		const docRef = firebase.firestore
			.collection(CUSTOMERS_COLLECTION)
			.doc(firebase.auth.currentUser?.uid)
			.collection(CLIENTS_COLLECTION)
			.doc(clientId)
			.collection(GOAL_PLANS_COLLECTION)
			.doc(goalPlanId);
		await docRef.delete();
	}

	async updateGoalPlan(
		clientId: string,
		goalPlanDto: GoalPlanDto
	): Promise<void> {
		const firebase = Firebase.GetInstance();
		await firebase.firestore
			.collection(CUSTOMERS_COLLECTION)
			.doc(firebase.auth.currentUser?.uid)
			.collection(CLIENTS_COLLECTION)
			.doc(clientId)
			.collection(GOAL_PLANS_COLLECTION)
			.doc(goalPlanDto.id)
			.set(goalPlanDto.toJson(), { merge: true });
	}

	async createGoalPlan(
		clientId: string,
		goalPlanDto: GoalPlanDto
	): Promise<GoalPlanDto> {
		const firebaseInstance = Firebase.GetInstance();
		const docRef = await firebaseInstance.firestore
			.collection(CUSTOMERS_COLLECTION)
			.doc(firebaseInstance.auth.currentUser?.uid)
			.collection(CLIENTS_COLLECTION)
			.doc(clientId)
			.collection(GOAL_PLANS_COLLECTION)
			.add(goalPlanDto.toJson());
		const doc = await docRef.get();
		return GoalPlanDto.fromFirestore(doc);
	}
	async getClients(): Promise<ClientDto[]> {
		const firebase = Firebase.GetInstance();
		const clients = await firebase.firestore
			.collection(CUSTOMERS_COLLECTION)
			.doc(firebase.auth.currentUser?.uid)
			.collection(CLIENTS_COLLECTION)
			.orderBy("name")
			.get();
		return Promise.all(
			clients.docs.map((item) => ClientDto.fromFirestore(item))
		);
	}

	// async createIfIsNew(client: ClientDto): Promise<ClientDto> {
	// 	const firebase = Firebase.GetInstance();
	// 	const collectionRef = await firebase.firestore
	// 		.collection(CUSTOMERS_COLLECTION)
	// 		.doc(firebase.auth.currentUser?.uid)
	// 		.collection(CLIENTS_COLLECTION);
	// 	const existingDocs = await collectionRef
	// 		.where("case", "==", client.case)
	// 		.get();
	// 	if (existingDocs.size === 0) {
	// 		const docRef = await collectionRef.add(client.toJson());
	// 		const doc = await docRef.get();
	// 		return ClientDto.fromFirestore(doc);
	// 	}
	// 	return ClientDto.fromFirestore(existingDocs.docs[0]);
	// }

	async create(client: ClientDto): Promise<ClientDto> {
		const firebase = Firebase.GetInstance();
		const docRef = await firebase.firestore
			.collection(CUSTOMERS_COLLECTION)
			.doc(firebase.auth.currentUser?.uid)
			.collection(CLIENTS_COLLECTION)
			.add(client.toJson());
		const doc = await docRef.get();
		return ClientDto.fromFirestore(doc);
	}

	async delete(id: string): Promise<void> {
		const firebase = Firebase.GetInstance();
		const docRef = firebase.firestore
			.collection(CUSTOMERS_COLLECTION)
			.doc(firebase.auth.currentUser?.uid)
			.collection(CLIENTS_COLLECTION)
			.doc(id);
		const groups = await firebase.firestore
			.collection(CUSTOMERS_COLLECTION)
			.doc(firebase.auth.currentUser?.uid)
			.collection(GROUPS_COLLECTION)
			.where("clients", "array-contains", id)
			.get();
		groups.docs.forEach(async (doc) => {
			const clients = doc.data().clients.filter((item: any) => item !== id);
			await firebase.firestore
				.collection(CUSTOMERS_COLLECTION)
				.doc(firebase.auth.currentUser?.uid)
				.collection(GROUPS_COLLECTION)
				.doc(doc.id)
				.set({ clients: clients }, { merge: true });
		});
		const noteCollectionRef = docRef.collection(NOTES_COLLECTION);
		const goalPlansCollectionRef = docRef.collection(GOAL_PLANS_COLLECTION);
		await firebase.deleteQueryBatch(noteCollectionRef);
		await firebase.deleteQueryBatch(goalPlansCollectionRef);

		await docRef.delete();
	}

	async update(client: ClientDto): Promise<void> {
		const firebase = Firebase.GetInstance();
		await firebase.firestore
			.collection(CUSTOMERS_COLLECTION)
			.doc(firebase.auth.currentUser?.uid)
			.collection(CLIENTS_COLLECTION)
			.doc(client.id)
			.set(client.toJson(), { merge: true });
	}
}
