import Client from "../../client/models/Client";
import GroupPlan from "./GroupPlan";

type SessionGroupProps = {
  id?: string;
  name: string;
  clients: Client[];
  groupPlans: GroupPlan[];
};

export default class SessionGroup {
  id?: string;
  name: string;
  clients: Client[];
  groupPlans: GroupPlan[];

  constructor(props: SessionGroupProps) {
    this.id = props.id;
    this.name = props.name;
    this.clients = props.clients;
    this.groupPlans = props.groupPlans;
  }

  public static empty(): SessionGroup {
    return new SessionGroup({
      name: "",
      clients: [],
      groupPlans: [],
    });
  }

  public isValid(): boolean {
    return this.name !== "" && this.clients.length !== 0;
  }
}
