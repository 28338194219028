import { useEffect } from "react";
import { useClientState } from "../../../../../application/client";
import { useNoteDispatch, useNoteState } from "../../../../../application/note";
import NoteActions from "../../../../../application/note/actions";
import { useInjection } from "../../../../../core/dependency_injection";

export default function useFindNoteById(id: string) {
	const { note } = useNoteState();
	const noteDispatch = useNoteDispatch();
	const noteActions = useInjection<NoteActions>("NoteActions");

	const { selectedClient } = useClientState();

	useEffect(() => {
		if (!note.id) {
			const currentNote = selectedClient.notes?.find((item) => item.id === id);
			if (currentNote) {
				noteActions.setEditNote(noteDispatch, currentNote);
			}
		} // eslint-disable-next-line
	}, [note, selectedClient]);
	return;
}
