import React from "react";
import { useUserState } from "../../../../../../../../application/user";
import InfoDataItem from "../../../../../../components/InfoDataItem";
import useStyles from "./styles";

export default function PersonalInformation() {
	const classes = useStyles();

	const { user } = useUserState();

	return (
		<div className={classes.root}>
			<div className={classes.itemContainer}>
				<InfoDataItem label={"Company"} value={user.company?.getName()} />
			</div>
			<div className={classes.itemContainer}>
				<InfoDataItem label={"Address"} value={user.company?.getAddress()} />
			</div>
		</div>
	);
}
