import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Grid,
	Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useParams } from "react-router";
import { useClientState } from "../../../../../../../../application/client";
import {
	useNoteDispatch,
	useNoteState,
} from "../../../../../../../../application/note";
import NoteActions from "../../../../../../../../application/note/actions";
import { useUserState } from "../../../../../../../../application/user";
import { useInjection } from "../../../../../../../../core/dependency_injection";
import Client from "../../../../../../../../domain/client/models/Client";
import Company from "../../../../../../../../domain/user/models/Company";
import { PanelCircularProgress } from "../../../../../../../core/components/Progress";
import ClientInfoAccordionContent from "./components/ClientInfoAccordionContent";
import GoalsAccordionContent from "./components/GoalsAccordionContent";
import GroupTipAccordionContent from "./components/GroupTipAccordionContent";
import TreatmentAccordionContent from "./components/TreatmentAccordionContent";
import useStyles from "../../styles";
import useFindClientById from "../../../../hooks/useFindClientById";
import SessionInfoAccordionContent from "./components/SessionInfoAccordionContent";
import ProgressAccordionContent from "./components/ProgressAccordionContent";
import useFindNoteById from "../../../../hooks/useFindNoteById";

type NoteAccordionProps = {
	defaultExpanded?: boolean;
	boxShadow?: boolean;
	title?: string;
	children: React.ReactNode;
};

function NoteAccordion(props: NoteAccordionProps) {
	const { defaultExpanded, title, children, boxShadow } = props;

	const getAccordionStyles = (boxShadow: boolean) => {
		const defaultStyle = { width: "100%", backgroundColor: "transparent" };
		if (!boxShadow) {
			return { ...defaultStyle, boxShadow: "none" };
		} else {
			return defaultStyle;
		}
	};

	return (
		<Grid item container lg={12} xs={12} style={{ margin: 0, padding: 0 }}>
			<Accordion
				defaultExpanded={defaultExpanded ?? true}
				style={getAccordionStyles(boxShadow ?? false)}
			>
				<AccordionSummary
					expandIcon={<ExpandMore />}
					aria-controls="panel1a-content"
					id="panel1a-header"
				>
					{title && (
						<Typography style={{ fontWeight: "bold" }}>{title}</Typography>
					)}
				</AccordionSummary>
				<AccordionDetails
				// style={boxShadow ?? true ? {} : { margin: 0, padding: 0 }}
				>
					{children}
				</AccordionDetails>
			</Accordion>
		</Grid>
	);
}

export default function NoteForm() {
	const classes = useStyles();

	const { id, noteId } = useParams<{ id: string; noteId: string }>();

	const { user } = useUserState();

	const { note, isEditingNote, isFetching, isFetchingClientInfo } =
		useNoteState();
	const noteDispatch = useNoteDispatch();
	const noteActions = useInjection<NoteActions>("NoteActions");

	const { selectedClient } = useClientState();

	const { enqueueSnackbar } = useSnackbar();

	useFindClientById(id);
	useFindNoteById(noteId);

	useEffect(() => {
		if (selectedClient.id && !isEditingNote) {
			noteActions.getNoteClientInfo(
				noteDispatch,
				selectedClient.id,
				note.startDate
			);
		} // eslint-disable-next-line
	}, [selectedClient.id, note.startDate.getDate(), note.startDate.getMonth()]);

	useEffect(() => {
		if (!isEditingNote) {
			noteActions.initNote(noteDispatch, {
				client: selectedClient ?? Client.empty(),
				company: user.company ?? Company.empty(),
			});
		} // eslint-disable-next-line
	}, [selectedClient, isEditingNote]);

	useEffect(() => {
		noteActions.getNote(noteDispatch, enqueueSnackbar); // eslint-disable-next-line
	}, []);

	return !isFetching && !isFetchingClientInfo ? (
		<>
			<div className={classes.root}>
				<Grid container spacing={3} alignItems="center">
					<NoteAccordion
						defaultExpanded={isEditingNote || note.client.isEmpty()}
						title="Client's Information:"
					>
						<ClientInfoAccordionContent />
					</NoteAccordion>
					<NoteAccordion title="Session:">
						<SessionInfoAccordionContent />
					</NoteAccordion>
					<NoteAccordion
						defaultExpanded={!note.isGoalsValid() || isEditingNote}
						title="Goals and Objectives:"
					>
						<GoalsAccordionContent />
					</NoteAccordion>
					<NoteAccordion title="Goals/Objetives Addressed Client’s Treatment Plan:">
						<TreatmentAccordionContent />
					</NoteAccordion>
					<NoteAccordion title="Group Titles:">
						<GroupTipAccordionContent />
					</NoteAccordion>
					<NoteAccordion title="Update of progress towards meeting goal and objective on the treatment plan:">
						<ProgressAccordionContent />
					</NoteAccordion>
				</Grid>
			</div>
		</>
	) : (
		<PanelCircularProgress />
	);
}
