import { Button, CircularProgress } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import {
	useProductDispatch,
	useProductState,
} from "../../../../application/product";
import ProductActions from "../../../../application/product/actions";
import { useInjection } from "../../../../core/dependency_injection";
import Price from "../../../../domain/product/models/Price";
import PlanItem from "./components/PlanItem";
import useStyles from "./styles";

export default function Plans(props: {
	onButtonClick: (price: Price) => void;
	isLoading: boolean;
	buttonLabel: string;
}) {
	const classes = useStyles();

	const { onButtonClick, isLoading, buttonLabel } = props;

	const [interval, setInterval] = useState("month");

	const { products, isFetchingProducts } = useProductState();
	const productDispatch = useProductDispatch();
	const productActions = useInjection<ProductActions>("ProductActions");

	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		productActions.getProducts(productDispatch, enqueueSnackbar); // eslint-disable-next-line
	}, []);

	return !isFetchingProducts && !isLoading ? (
		<div>
			<div className={classes.buttonsContainer}>
				<div>
					<Button
						color={interval === "year" ? "primary" : "default"}
						onClick={() => {
							setInterval(() => "year");
						}}
					>
						Yearly save 18%
					</Button>
				</div>
				<div className={classes.divider} />
				<div>
					<Button
						color={interval === "month" ? "primary" : "default"}
						onClick={() => {
							setInterval(() => "month");
						}}
					>
						Monthly
					</Button>
				</div>
			</div>
			<div className={classes.plansContainer}>
				{products.map((item, index) => (
					<PlanItem
						key={index}
						buttonLabel={buttonLabel}
						product={item}
						interval={interval}
						onButtonClick={onButtonClick}
					/>
				))}
			</div>
		</div>
	) : (
		<div className={classes.progress}>
			<CircularProgress />
		</div>
	);
}
