import Client from "../../../domain/client/models/Client";
import firebase from "firebase";
import { NoteDto } from "../../note/dtos/NoteDto";
import { GOAL_PLANS_COLLECTION, NOTES_COLLECTION } from "../../core/constants";
import { GoalPlanDto } from "./GoalPlanDto";

export interface IClientDtoProps {
	id?: string;
	name: string;
	serviceCode: string;
	case: string;
	setting: string;
	diagnosis: string;
	goalPlans?: GoalPlanDto[];
	notes?: any[];
}

export class ClientDto {
	id?: string;
	name: string;
	serviceCode: string;
	case: string;
	setting: string;
	diagnosis: string;
	goalPlans?: GoalPlanDto[];
	notes?: NoteDto[];

	constructor(props: IClientDtoProps) {
		this.id = props.id;
		this.name = props.name;
		this.serviceCode = props.serviceCode;
		this.case = props.case;
		this.setting = props.setting;
		this.diagnosis = props.diagnosis;
		this.goalPlans = props.goalPlans;
		this.notes = props.notes?.map((item) => new NoteDto({ ...item }));
	}

	public static fromDomain(client: Client): ClientDto {
		// console.log(`fromDomain client.goals`, client.goals);
		return new ClientDto({
			id: client.id,
			name: client.name,
			serviceCode: client.serviceCode,
			case: client.case,
			setting: client.setting,
			diagnosis: client.diagnosis,
			goalPlans: client.goalPlans?.map((item) => GoalPlanDto.fromDomain(item)),
			notes: client.notes,
		});
	}

	public static async fromFirestore(
		doc: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
	): Promise<ClientDto> {
		const data = doc.data();
		// console.log(`data`, data);
		const notes = await doc.ref
			.collection(NOTES_COLLECTION)
			.orderBy("startDate", "desc")
			.get();
		const goalPlans = await doc.ref
			.collection(GOAL_PLANS_COLLECTION)
			.orderBy("startDate", "desc")
			.get();
		return new ClientDto({
			id: doc.id,
			case: data?.case,
			name: data?.name,
			serviceCode: data?.serviceCode,
			setting: data?.setting,
			diagnosis: data?.diagnosis,
			goalPlans: goalPlans.docs.map((item: any) =>
				GoalPlanDto.fromFirestore(item)
			),
			notes: notes.docs.map((item) => NoteDto.fromFirestore(item)),
		});
	}

	public toJson(): any {
		return {
			name: this.name,
			serviceCode: this.serviceCode,
			case: this.case,
			setting: this.setting,
			diagnosis: this.diagnosis,
		};
	}

	public toDomain(): Client {
		return new Client({
			id: this.id,
			name: this.name,
			serviceCode: this.serviceCode,
			case: this.case,
			setting: this.setting,
			diagnosis: this.diagnosis,
			goalPlans: this.goalPlans?.map((item) => item.toDomain()),
			notes: this.notes?.map((item) => item.toDomain()),
		});
	}
}
