import {
	Card,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography,
} from "@material-ui/core";
import { useState } from "react";
import { useClientState } from "../../../../../../../../../../application/client";
import {
	useNoteDispatch,
	useNoteState,
} from "../../../../../../../../../../application/note";
import NoteActions from "../../../../../../../../../../application/note/actions";
import { useInjection } from "../../../../../../../../../../core/dependency_injection";
import Client from "../../../../../../../../../../domain/client/models/Client";
import useStyles from "../../styles";

const UnmodifyItem = (props: { label: string; value: string }) => (
	<div style={{ padding: "10px 0px" }}>
		<Typography variant="caption">{props.label}</Typography>
		<Typography variant="body1">{props.value}</Typography>
	</div>
);

export default function ClientInfoAccordionContent() {
	const classes = useStyles();

	const { note } = useNoteState();
	const noteDispatch = useNoteDispatch();
	const noteActions = useInjection<NoteActions>("NoteActions");

	const { selectedClient, clients } = useClientState();

	const [selectedClientName, setSelectedClientName] = useState("new");

	return (
		<Grid container>
			{!selectedClient.id && clients?.length !== 0 && (
				<Card className={classes.card}>
					<Grid item container alignItems="center" lg={12} xs={12}>
						<Grid item container lg={6} xs={12}>
							Client:
						</Grid>
						<Grid item container lg={6} xs={12}>
							<FormControl variant="filled" className={classes.item}>
								<InputLabel id={`note-client-select`}>Select</InputLabel>
								<Select
									labelId={`note-client-select`}
									id={`note-client-select`}
									value={selectedClientName}
									onChange={(e) => {
										const value = e.target.value as string;
										const client = clients?.find((item) => item.name === value);
										noteActions.setNoteClient(
											noteDispatch,
											client ?? Client.empty()
										);
										setSelectedClientName(value);
									}}
								>
									<MenuItem value={"new"}>New Client</MenuItem>
									{clients?.map((item: any, index: number) => (
										<MenuItem key={index} value={item.name}>
											{item.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
					</Grid>
				</Card>
			)}
			<Card className={classes.card}>
				<Grid container>
					<Grid item container justifyContent="flex-start" lg={6} xs={12}>
						{note.client.id ? (
							<UnmodifyItem label="Client's name" value={note.client.name} />
						) : (
							<TextField
								margin="normal"
								variant="filled"
								className={classes.item}
								disabled={!!note.client.id}
								label="Client's name"
								value={note.client.name}
								onChange={(e) => {
									noteActions.setNoteClientName(
										noteDispatch,
										e.target.value as string
									);
								}}
							/>
						)}
					</Grid>
					<Grid item container justifyContent="flex-start" lg={6} xs={12}>
						{note.client.id ? (
							<UnmodifyItem
								label="Service Code"
								value={note.client.serviceCode}
							/>
						) : (
							<TextField
								margin="normal"
								variant="filled"
								className={classes.item}
								disabled={!!note.client.id}
								label="Service Code"
								value={note.client.serviceCode}
								onChange={(e) => {
									noteActions.setNoteClientServiceCode(
										noteDispatch,
										e.target.value as string
									);
								}}
							/>
						)}
					</Grid>
					<Grid item container justifyContent="flex-start" lg={6} xs={12}>
						{note.client.id ? (
							<UnmodifyItem label="Setting" value={note.client.setting} />
						) : (
							<TextField
								margin="normal"
								variant="filled"
								className={classes.item}
								disabled={!!note.client.id}
								label="Setting"
								value={note.client.setting}
								onChange={(e) => {
									noteActions.setNoteClientSetting(
										noteDispatch,
										e.target.value as string
									);
								}}
							/>
						)}
					</Grid>
					<Grid item container justifyContent="flex-start" lg={6} xs={12}>
						{note.client.id ? (
							<UnmodifyItem label="Case" value={note.client.case} />
						) : (
							<TextField
								margin="normal"
								variant="filled"
								className={classes.item}
								disabled={!!note.client.id}
								label="Case #"
								value={note.client.case}
								onChange={(e) => {
									noteActions.setNoteClientCase(
										noteDispatch,
										e.target.value as string
									);
								}}
							/>
						)}
					</Grid>
					<Grid item container justifyContent="flex-start" lg={6} xs={12}>
						{note.client.id ? (
							<UnmodifyItem label="Diagnosis" value={note.client.diagnosis} />
						) : (
							<TextField
								margin="normal"
								variant="filled"
								className={classes.item}
								disabled={!!note.client.id}
								label="Diagnosis"
								value={note.client.diagnosis}
								onChange={(e) => {
									noteActions.setNoteClientDiagnosis(
										noteDispatch,
										e.target.value as string
									);
								}}
							/>
						)}
					</Grid>
				</Grid>
			</Card>
		</Grid>
	);
}
