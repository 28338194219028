import { Dispatch } from "react";

export const INIT_GOALS_STATE = "INIT_GOALS_STATE";
export const SET_SHOW_CATEGORY_INPUT = "SET_SHOW_CATEGORY_INPUT";
export const SET_SHOW_GOAL_INPUT = "SET_SHOW_GOAL_INPUT";
export const SET_SHOW_OBJECTIVES_INPUT = "SET_SHOW_OBJECTIVES_INPUT";
export const INIT_TREATMENT_STATE = "INIT_TREATMENT_STATE";
export const SET_SHOW_TREATMENT_INPUT = "SET_SHOW_TREATMENT_INPUT";
export const INIT_GROUP_TIP_STATE = "INIT_GROUP_TIP_STATE";
export const SET_SHOW_GROUP_TIP_CATEGORY = "SET_SHOW_GROUP_TIP_CATEGORY";
export const SET_SHOW_GROUP_TIP_TITLE = "SET_SHOW_GROUP_TIP_TITLE";
export const SET_SHOW_GROUP_TIP_INTERVENTION = "SET_SHOW_GROUP_TIP_INTERVENTION";
export const SET_SHOW_GROUP_TIP_PARTICIPATION = "SET_SHOW_GROUP_TIP_PARTICIPATION";

export const initialValues: InputFormState = {
	showCategoryInput: [],
	showGoalInput: [],
	showObjectivesInput: [],
	showTreatmentInput: [],
	showGroupTipCategory: [],
	showGroupTipTitle: [],
	showGroupTipIntervention: [],
	showGroupTipParticipation: [],
};

export type InputFormDispatch = Dispatch<InputFormAction>;

export type InputFormState = {
	showCategoryInput: boolean[];
	showGoalInput: boolean[];
	showObjectivesInput: boolean[][];
	showTreatmentInput: boolean[];
	showGroupTipCategory: boolean[];
	showGroupTipTitle: boolean[];
	showGroupTipIntervention: boolean[];
	showGroupTipParticipation: boolean[];
};

export type InitGoalsStatePayload = {
	showCategoryInput: boolean[];
	showGoalInput: boolean[];
	showObjectivesInput: boolean[][];
};

export type InitGroupTipStatePayload = {
	showGroupTipCategory: boolean[];
	showGroupTipTitle: boolean[];
	showGroupTipIntervention: boolean[];
	showGroupTipParticipation: boolean[];
};

export type SetValuePayload = {
	index: number;
};

export type SetObjectivesValuePayload = {
	i: number;
	j: number;
};

export type InputFormAction =
	| {
			type: typeof INIT_GROUP_TIP_STATE;
			payload: InitGroupTipStatePayload;
	  }
	| { type: typeof SET_SHOW_GROUP_TIP_CATEGORY; payload: SetValuePayload }
	| { type: typeof SET_SHOW_GROUP_TIP_TITLE; payload: SetValuePayload }
	| { type: typeof SET_SHOW_GROUP_TIP_INTERVENTION; payload: SetValuePayload }
	| { type: typeof SET_SHOW_GROUP_TIP_PARTICIPATION; payload: SetValuePayload }
	| {
			type: typeof INIT_GOALS_STATE;
			payload: InitGoalsStatePayload;
	  }
	| { type: typeof SET_SHOW_CATEGORY_INPUT; payload: SetValuePayload }
	| { type: typeof SET_SHOW_GOAL_INPUT; payload: SetValuePayload }
	| { type: typeof SET_SHOW_OBJECTIVES_INPUT; payload: SetObjectivesValuePayload }
	| {
			type: typeof INIT_TREATMENT_STATE;
			payload: boolean[];
	  }
	| { type: typeof SET_SHOW_TREATMENT_INPUT; payload: SetValuePayload };
