import { useUserState } from "../../../../../../../../application/user";
import InfoDataItem from "../../../../../../components/InfoDataItem";
import { getDaysBetweenDates } from "../../../../../../core/helpers";
import useStyles from "./styles";

export default function Subscription() {
	const classes = useStyles();

	const { user } = useUserState();

	return (
		<>
			<div className={classes.root}>
				{/* {user.subscription ? ( */}
				<>
					<div className={classes.itemContainer}>
						<InfoDataItem
							label={"Subscribed to:"}
							value={`${
								user.subscription ? user.subscription.product.name : "Free"
							} plan`}
						/>
						{user.subscription && (
							<InfoDataItem
								label={"Created:"}
								value={user.subscription.created.toLocaleDateString("en")}
							/>
						)}
						{user.subscription?.cancel_at_period_end &&
							!!user.subscription?.cancel_at && (
								<InfoDataItem
									label={"Cancel at:"}
									value={user.subscription.cancel_at.toLocaleDateString("en")}
								/>
							)}
						<InfoDataItem
							label={"Clients:"}
							value={
								user.subscription
									? user.subscription.product.clients
									: "Up to 1 client"
							}
						/>
					</div>
					<div className={classes.itemContainer}>
						<InfoDataItem
							label={"Status:"}
							value={user.subscription ? user.subscription.status : "active"}
						/>
						{user.subscription ? (
							user.subscription.status === "active" ? (
								<InfoDataItem
									label={"Price:"}
									value={`$${user.subscription.price.unit_amount / 100} / ${
										user.subscription.price.interval
									}`}
								/>
							) : user.subscription.status === "trialing" ? (
								<InfoDataItem
									label={"Trial end:"}
									value={`${getDaysBetweenDates(
										new Date(),
										user.subscription.trial_end ?? new Date()
									)} days`}
								/>
							) : (
								<></>
							)
						) : (
							<></>
						)}
						<InfoDataItem label={"Groups:"} value={"Unlimited groups"} />
						{/* <InfoDataItem
								label={
									(user.subscription && user.subscription.status === "active") || !user.subscription
										? "Price:"
										: user.subscription.status === "trialing" ? "Trial end:" : 
								}
								value={
									user.subscription.status === "active"
										? `$${user.subscription.price.unit_amount / 100} / ${
												user.subscription.price.interval
										  }`
										: `${getDaysBetweenDates(
												new Date(),
												user.subscription.trial_end ?? new Date()
										  )} days`
								}
							/> */}
					</div>
				</>
			</div>
		</>
	);
}
