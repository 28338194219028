import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
	table: {
		width: "100%",
		borderRadius: "10px",
	},
	tableContainer: {
		maxHeight: "60vh",
		borderRadius: "10px",
	},
	toolbar: {
		backgroundColor: theme.palette.background.paper,
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(1),
		borderRadius: "10px",
	},
	title: {
		fontWeight: "bold",
	},
}));
