import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
} from "@material-ui/core";
import { Close, Print } from "@material-ui/icons";

type DialogProps = {
	open: boolean;
	handleClose: () => void;
	title?: string;
	content: any;
	onSuccessCallback?: () => void;
};

// type ScrollableDialogProps = {
// 	open: boolean;
// 	handleClose: () => void;
// 	title?: string;
// 	content?: string;
// 	onSuccessCallback?: () => void;
// };

export function NoteDialog(props: DialogProps) {
	const { open, handleClose, content } = props;

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			scroll={"paper"}
			aria-labelledby="scroll-dialog-title"
			aria-describedby="scroll-dialog-description"
			maxWidth="md"
		>
			<DialogTitle style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
				<IconButton>
					<Print />
				</IconButton>
				<IconButton onClick={handleClose}>
					<Close />
				</IconButton>
			</DialogTitle>
			<DialogContent dividers>{content}</DialogContent>
			{/* <DialogActions>
				<Button onClick={handleClose} color="primary">
					Back
				</Button>
			</DialogActions> */}
		</Dialog>
	);
}

export function ConfirmationDialog(props: DialogProps) {
	const { open, handleClose, title, content, onSuccessCallback } = props;

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">{title}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">{content}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="primary">
					No
				</Button>
				<Button onClick={onSuccessCallback} color="primary" autoFocus>
					Yes
				</Button>
			</DialogActions>
		</Dialog>
	);
}
