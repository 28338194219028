import { hexToRgb } from "./helpers";

export type RouteParams = {
	id: string;
};

export const drawerWidth = 260;

export const transition = {
	transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)",
};

export const container = {
	paddingRight: "15px",
	paddingLeft: "15px",
	marginRight: "auto",
	marginLeft: "auto",
};

export const boxShadow = {
	boxShadow:
		"0 10px 30px -12px rgba(" +
		hexToRgb("#000") +
		", 0.42), 0 4px 25px 0px rgba(" +
		hexToRgb("#000") +
		", 0.12), 0 8px 10px -5px rgba(" +
		hexToRgb("#000") +
		", 0.2)",
};

export const disabledMenuItem = {
	textDecoration: "underline",
};

export const card = {
	minHeight: "100px",
	width: "100%",
	padding: "10px 30px",
	marginBottom: "10px",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
};

export const YouTubeLink =
	"https://www.youtube.com/channel/UCA_9uPIRe_ZgCP-7cE0tjfw/featured";
export const FaceBookLink = "https://www.facebook.com/psrnotes";
export const InstagramLink = "https://www.instagram.com/psrnotes/";
