type CompanyProps = {
	name: string;
	address: string;
};

export default class Company {
	name: string;
	address: string;

	constructor(props: CompanyProps) {
		this.name = props.name;
		this.address = props.address;
	}

	public static empty(): Company {
		return new Company({
			name: "",
			address: "",
		});
	}

	public getName(): string {
		return this.name !== "" ? this.name : "-";
	}
	public getAddress(): string {
		return this.address !== "" ? this.address : "-";
	}

	public isValid(): boolean {
		return this.name !== "" && this.address !== "";
	}
}
