import { container } from "tsyringe";
import { IClientRepository } from "../../domain/client/iclient.repository";
import ClientRepository from "../../infrastructure/client/client.repository";
import ClientRemoteDatasource, {
  IClientRemoteDatasource,
} from "../../infrastructure/client/datasources/remote";
import { sl } from "./register_dependencies";

export function registerClientDependencies() {
  container.register<IClientRemoteDatasource>("IClientRemoteDatasource", {
    useClass: ClientRemoteDatasource,
  });

  container.register<IClientRepository>("IClientRepository", {
    useValue: new ClientRepository(
      sl<IClientRemoteDatasource>("IClientRemoteDatasource")
    ),
  });
}
