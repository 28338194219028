import { Delete, Edit, Group } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import React from "react";
import { useHistory, useParams } from "react-router";
import { useGroupDispatch, useGroupState } from "../../../../../../../application/group";
import GroupActions from "../../../../../../../application/group/actions";
import { useNoteDispatch } from "../../../../../../../application/note";
import NoteActions from "../../../../../../../application/note/actions";
import { useInjection } from "../../../../../../../core/dependency_injection";
import GroupPlan from "../../../../../../../domain/group/models/GroupPlan";
import { ConfirmationDialog } from "../../../../../../core/components/Dialog";
import useOpenable from "../../../../../../core/hooks/useOpenable";
import PanelHeader, {
	PanelHeaderAction,
	PanelHeaderBreadcrumbsRoute,
} from "../../../../../components/PanelHeader";
import GroupPlanForm from "../../../components/GroupPlanForm";

export default function GroupPlanEdit() {
	const history = useHistory();
	const { id, planId } = useParams<{ id: string; planId: string }>();

	const { group, groupPlan, sessionGroups, isDeleting, isSubmiting } = useGroupState();
	const groupDispatch = useGroupDispatch();
	const groupActions = useInjection<GroupActions>("GroupActions");

	const noteDispatch = useNoteDispatch();
	const noteActions = useInjection<NoteActions>("NoteActions");

	const { enqueueSnackbar } = useSnackbar();

	const [open, handleOpen, handleClose] = useOpenable();

	React.useEffect(() => {
		if (group.id === undefined && sessionGroups) {
			const currentGroup = sessionGroups.find((item) => item.id === id);
			groupActions.setGroup(groupDispatch, currentGroup);
			const currentGroupPlan = currentGroup?.groupPlans.find((item) => item.id === planId);
			groupActions.setGroupPlan(groupDispatch, currentGroupPlan ?? GroupPlan.empty());
		} // eslint-disable-next-line
	}, [sessionGroups]);

	const breadcrumbsRoutes: PanelHeaderBreadcrumbsRoute[] = [
		{
			label: "Groups",
			icon: Group,
			to: "/app/groups",
		},
		{
			label: `${group.name}`,
			to: `/app/groups/${group.id}`,
			// icon: People,
		},
		{
			label: `Group Titles`,
			to: `/app/groups/${group.id}/group-plans`,
		},
		{
			label: `${groupPlan.date.toLocaleDateString("en")}`,
		},
	];

	const panelActions: PanelHeaderAction[] = [
		{
			label: "Update",
			icon: Edit,
			isLoading: isSubmiting,
			onClick: () => {
				if (group.id) {
					if (groupPlan.isValid()) {
						noteActions.setUserGroupTips(
							noteDispatch,
							groupPlan.getNewGroupTips(),
							enqueueSnackbar
						);
						groupActions.updateGroupPlan(
							groupDispatch,
							group.id,
							groupPlan,
							history,
							enqueueSnackbar
						);
					} else {
						enqueueSnackbar("Form invalid", { variant: "error" });
					}
				}
			},
		},
		{
			label: "Delete...",
			color: "danger",
			isLoading: isDeleting,
			icon: Delete,
			onClick: () => {
				handleOpen();
			},
		},
	];
	return (
		<div>
			<PanelHeader breadcrumbsRoutes={breadcrumbsRoutes} actions={panelActions} />
			<GroupPlanForm />
			<ConfirmationDialog
				open={open}
				handleClose={handleClose}
				title="Delete this group title?"
				content="Are you sure do you want to delete this group title."
				onSuccessCallback={async () => {
					handleClose();
					groupActions.deleteGroupPlan(
						groupDispatch,
						group.id ?? "",
						groupPlan.id ?? "",
						history,
						enqueueSnackbar
					);
				}}
			/>
		</div>
	);
}
