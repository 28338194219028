import GoalPlan from "../../../domain/client/models/GoalPlan";
import firebase from "firebase";
import { GoalDto } from "../../note/dtos/GoalDto";

export interface IGoalPlanDtoProps {
  id?: string;
  startDate: Date;
  endDate: Date;
  goals: GoalDto[];
}

export class GoalPlanDto {
  id?: string;
  startDate: Date;
  endDate: Date;
  goals: GoalDto[];

  constructor(props: IGoalPlanDtoProps) {
    this.id = props.id;
    this.startDate = props.startDate;
    this.endDate = props.endDate;
    this.goals = props.goals;
  }

  public static fromDomain(model: GoalPlan): GoalPlanDto {
    // console.log(`fromDomain client.goals`, client.goals);
    return new GoalPlanDto({
      id: model.id,
      startDate: model.startDate,
      endDate: model.endDate,
      goals: model.goals.map((item) => GoalDto.fromDomain(item)),
    });
  }

  public static fromFirestore(
    doc: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
  ): GoalPlanDto {
    const data = doc.data();
    // console.log(`data`, data);
    return new GoalPlanDto({
      id: doc.id,
      startDate: data?.startDate.toDate(),
      endDate: data?.endDate.toDate(),
      goals: data?.goals.map((item: any) => new GoalDto({ ...item })),
    });
  }

  public toJson(): any {
    return {
      startDate: this.startDate,
      endDate: this.endDate,
      goals: this.goals.map((item) => item.toJson()),
    };
  }

  public toDomain(): GoalPlan {
    return new GoalPlan({
      id: this.id,
      startDate: this.startDate,
      endDate: this.endDate,
      goals: this.goals.map((item) => item.toDomain()),
    });
  }
}
