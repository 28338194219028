import { User } from "../../../domain/user/models/User";
import { CompanyDto } from "./CompanyDto";
import Company from "../../../domain/user/models/Company";
import SubscriptionDto from "./SubscriptionDto";

export interface IUserProps {
	id?: string;
	name: string;
	email: string;
	stripeId?: string;
	emailVerified?: boolean;
	freeTrial?: boolean;
	company?: CompanyDto;
	subscription?: SubscriptionDto;
}

export class UserDto {
	id?: string;
	name: string;
	email: string;
	stripeId?: string;
	emailVerified?: boolean;
	freeTrial?: boolean;
	company?: CompanyDto;
	subscription?: SubscriptionDto;

	constructor(props: IUserProps) {
		this.id = props.id;
		this.name = props.name;
		this.email = props.email;
		this.stripeId = props.stripeId;
		this.emailVerified = props.emailVerified;
		this.freeTrial = props.freeTrial;
		this.company = props.company
			? new CompanyDto({ ...props.company })
			: undefined;
		this.subscription = props.subscription
			? new SubscriptionDto({ ...props.subscription })
			: undefined;
	}

	public static fromDomain(user: User): UserDto {
		return new UserDto({
			id: user.id,
			name: user.name,
			email: user.email,
			stripeId: user.stripeId,
			emailVerified: user.emailVerified,
			freeTrial: user.freeTrial,
			company: CompanyDto.fromDomain(user.company ?? Company.empty()),
			subscription: user.subscription
				? SubscriptionDto.fromDomain(user.subscription)
				: undefined,
		});
	}

	public toDomain(): User {
		return new User({
			id: this.id,
			name: this.name,
			email: this.email,
			stripeId: this.stripeId,
			emailVerified: this.emailVerified,
			freeTrial: this.freeTrial,
			company: this.company?.toDomain(),
			subscription: this.subscription?.toDomain(),
		});
	}
}
