import { Button, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import TryBanner from "../../components/TryBanner";
import useStyles from "./styles";
import image from "../../../core/assets/images/release_m.png";
import flat from "../../../core/assets/images/flat.png";
import cloud from "../../../core/assets/images/cloud_lock.png";
import VerticalItem from "../../components/VerticalItem";
import { CloudDownload, Security } from "@material-ui/icons";
import HorizontalItem from "../../components/HorizontalItem";

export default function HomeSection() {
	const classes = useStyles();

	return (
		<div id="home-section" className={classes.root}>
			<div className={classes.marginTop + " " + classes.flexCenter}>
				<Typography
					variant="h1"
					color="secondary"
					style={{ fontWeight: "bold" }}
				>
					Powerful, Robust, Secure,
				</Typography>
			</div>
			<div className={classes.flexCenter}>
				<Typography variant="h4" color="primary">
					yet quick and simple to use therapy notes software.
				</Typography>
			</div>
			<div className={classes.flexCenter + " " + classes.marginTop}>
				<Button
					variant="contained"
					component={Link}
					to="/auth/sign-up"
					color="secondary"
					size="large"
				>
					Get Started
				</Button>
			</div>
			<div className={classes.flexCenter} style={{ minHeight: "60vh" }}>
				<img src={image} alt="Center" width="60%" height="100%" />
			</div>
			<div className={classes.items}>
				<VerticalItem
					title="Secure Document Library"
					icon={Security}
					content="Securely store and share documents with your organization—from a blank Notice of 
									Privacy Practices and forms to staff resources and handbooks. All of your
									internal documents are stored in a central location for easy access."
				/>
				<VerticalItem
					title="Downloadable Notes and Files"
					icon={CloudDownload}
					content="Any document, note, or file stored in TherapyNotesTM is available to download for easy 
						printing or faxing from wherever you are. Download individual files or save multiple notes 
						in a single PDF. The logo for your practice is automatically included on every note."
				/>
			</div>
			<TryBanner />
			<div className={classes.marginTop}>
				<HorizontalItem
					imgLeft
					title="Progress Notes"
					content="Powerful and robust, yet quick and simple to use, notes are the cornerstone of 
									PSRnotesTM. Our powerful, click based note generating software is available for treatment 
									plans and progress notes, so you can complete and organize your notes in a breeze."
					img={flat}
				/>
				<HorizontalItem
					title="Secure Online Access"
					content="Everything you need to manage your practice is securely accessible 
									right from your preferred web browser. A strong TLS encryption ensures a secure 
									connection between our servers and your web browser, and our extended validation 
									certificate gives you the confidence that you’re in the right place."
					img={cloud}
				/>
			</div>
		</div>
	);
}
