import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
	root: {
		// position: "absolute",
		// top: 0,
		// left: 0,
		minHeight: "100vh",
		width: "100vw",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: theme.palette.secondary.main,
	},
	card: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		minWidth: "30vw",
		[theme.breakpoints.down("md")]: {
			minWidth: "50vw",
		},
		[theme.breakpoints.down("sm")]: {
			minWidth: "80vw",
			marginTop: "20px",
		},
	},
	cardContent: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		maxWidth: "30vw",
		width: "80%",
		[theme.breakpoints.down("sm")]: {
			width: "100%",
			maxWidth: "80vw",
		},
	},
	form: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		width: "100%",
	},
	subtitle: {
		margin: "10px 0px",
		textAlign: "center",
	},
	margin: {
		marginTop: "10px",
	},
	button: {
		marginTop: "40px",
	},
	link: {
		marginLeft: "10px",
		textDecoration: "none",
		color: theme.palette.primary.main,
	},
	textLink: {
		textDecoration: "none",
		color: theme.palette.primary.main,
	},
	logolink: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		textDecoration: "none",
		color: theme.palette.primary.main,
	},
	logoContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
	},
	logo: {
		cursor: "pointer",
		width: "70%",
	},
}));
