import { loadStripe } from "@stripe/stripe-js";
import { CUSTOMERS_COLLECTION } from "../../core/constants";
import Firebase from "../../core/firebase";
import { UserDto } from "../dtos/UserDto";

export interface IUserRemoteDatasource {
	signIn(email: string, password: string): Promise<UserDto>;
	signUp(user: UserDto, password: string): Promise<UserDto>;
	update(user: UserDto): Promise<void>;
	sendValidateEmail(): Promise<void>;
	getAuthenticatedUser(): Promise<UserDto>;
	sendResetPasswordEmail(email: string): Promise<void>;
	goToCheckout(user: UserDto, priceId: string): Promise<void>;
	goToCustomerPortal(customerId: string): Promise<void>;
	signOut(): Promise<void>;
}

export default class UserRemoteDatasource implements IUserRemoteDatasource {
	// async signInWithLink(email: string): Promise<UserDto> {
	// 	const firebase = Firebase.GetInstance();
	// 	const emailLink = window.location.href;
	// 	if (firebase.auth.isSignInWithEmailLink(emailLink)) {
	// 		const credential = await firebase.auth.signInWithEmailLink(
	// 			email,
	// 			emailLink
	// 		);
	// 		if (credential.user) {
	// 			const userCreated = credential.user;
	// 			const doc = await firebase.firestore
	// 				.collection(CUSTOMERS_COLLECTION)
	// 				.doc(userCreated.uid)
	// 				.get();
	// 			return new UserDto({
	// 				id: userCreated.uid,
	// 				email: userCreated.email ?? "",
	// 				emailVerified: userCreated.emailVerified,
	// 				name: doc.data()?.name,
	// 				freeTrial: doc.data()?.freeTrial,
	// 				company: doc.data()?.company,
	// 				subscription: doc.data()?.subscription,
	// 				stripeId: doc.data()?.stripeId,
	// 			});
	// 		} else {
	// 			throw new Error("An error ocurred creating your account");
	// 		}
	// 	} else {
	// 		throw new Error("An error ocurred creating your account");
	// 	}
	// }
	// async sendSignWithLink(email: string): Promise<void> {
	// 	const firebase = Firebase.GetInstance();
	// 	await firebase.auth.sendSignInLinkToEmail(email, {
	// 		url: Config.redirectLinkUrl,
	// 		handleCodeInApp: true,
	// 	});
	// }
	async goToCheckout(user: UserDto, priceId: string): Promise<void> {
		const firebase = Firebase.GetInstance();
		const stripe = await loadStripe(
			process.env.REACT_APP_STRIPE_PUBLIC_KEY ?? ""
		);
		var createCheckoutSessionRef = firebase.functions.httpsCallable(
			"createCheckoutSession"
		);
		const response = await createCheckoutSessionRef({
			customerId: user.stripeId,
			priceId: priceId,
			freeTrial: user.freeTrial,
			successUrl: window.location.href,
			cancelUrl: window.location.href,
		});
		await stripe?.redirectToCheckout({
			sessionId: response.data.sessionId,
		});
	}

	async goToCustomerPortal(customerId: string): Promise<void> {
		const firebase = Firebase.GetInstance();
		// const stripe = await StripeJs.loadStripe(STRIPE_PUBLIC_KEY);
		var createCustomerPortalRef = firebase.functions.httpsCallable(
			"crateCustomerPortal"
		);
		const response = await createCustomerPortalRef({
			customerId: customerId,
			returnUrl: window.location.href,
		});
		window.location = response.data.url;
		// stripe?.redirectToCheckout({
		// 	sessionId: response.data.sessionId,
		// });
	}

	async signIn(email: string, password: string): Promise<UserDto> {
		const firebase = Firebase.GetInstance();
		const userCredential = await firebase.auth.signInWithEmailAndPassword(
			email,
			password
		);
		if (userCredential.user) {
			const user = userCredential.user;
			const doc = await firebase.firestore
				.collection(CUSTOMERS_COLLECTION)
				.doc(user.uid)
				.get();
			return new UserDto({
				id: user.uid,
				email: user.email ?? "",
				emailVerified: user.emailVerified,
				name: doc.data()?.name,
				freeTrial: doc.data()?.freeTrial,
				company: doc.data()?.company,
				subscription: doc.data()?.subscription,
				stripeId: doc.data()?.stripeId,
			});
		} else {
			throw new Error("An error ocurred loging into your account");
		}
	}

	async signUp(user: UserDto, password: string): Promise<UserDto> {
		const firebase = Firebase.GetInstance();
		const credential = await firebase.auth.createUserWithEmailAndPassword(
			user.email,
			password
		);
		if (credential.user) {
			const userCreated = credential.user;
			await firebase.firestore
				.collection(CUSTOMERS_COLLECTION)
				.doc(userCreated.uid)
				.set(
					{
						name: user.name,
						freeTrial: false,
						company: user.company?.toJson(),
					},
					{ merge: true }
				);
			var createStripeCustomerFuncRef =
				firebase.functions.httpsCallable("createCustomer");
			const stripeCustomer = await createStripeCustomerFuncRef({
				uid: credential.user.uid,
				name: user.name,
				email: user.email.toLocaleLowerCase(),
			});
			return new UserDto({
				id: userCreated.uid,
				email: user.email,
				freeTrial: true,
				emailVerified: userCreated.emailVerified,
				name: user.name,
				company: user.company,
				stripeId: stripeCustomer.data.stripeId,
			});
		} else {
			throw new Error("An error ocurred creating your account");
		}
	}

	// async register(user: UserDto, password: string): Promise<UserDto> {
	// 	// const isValidEmail = await EmailServices.validateEmail(email);
	// 	// if (isValidEmail) {
	// 	const firebase = Firebase.GetInstance();
	// 	// const credential = await firebase.auth.createUserWithEmailAndPassword(
	// 	// 	user.email,
	// 	// 	password
	// 	// );
	// 	// if (credential.user) {
	// 	// const userCreated = credential.user;
	// 	await firebase.firestore.collection(CUSTOMERS_COLLECTION).doc(user.id).set(
	// 		{
	// 			name: user.name,
	// 			freeTrial: true,
	// 			company: user.company?.toJson(),
	// 		},
	// 		{ merge: true }
	// 	);
	// 	var createStripeCustomerFuncRef =
	// 		firebase.functions.httpsCallable("createCustomer");
	// 	const stripeCustomer = await createStripeCustomerFuncRef({
	// 		uid: user.id,
	// 		name: user.name,
	// 		email: user.email.toLocaleLowerCase(),
	// 	});
	// 	return new UserDto({
	// 		id: user.id,
	// 		email: user.email,
	// 		emailVerified: user.emailVerified,
	// 		freeTrial: true,
	// 		name: user.name,
	// 		company: user.company,
	// 		stripeId: stripeCustomer.data.stripeId,
	// 	});
	// 	// } else {
	// 	// 	throw new Error("An error ocurred creating your account");
	// 	// }
	// }
	async update(user: UserDto): Promise<void> {
		const firebase = Firebase.GetInstance();
		await firebase.firestore
			.collection(CUSTOMERS_COLLECTION)
			.doc(firebase.auth.currentUser?.uid)
			.set(
				{
					name: user.name,
					company: user.company?.toJson(),
				},
				{ merge: true }
			);
	}

	async sendValidateEmail(): Promise<void> {
		const user = Firebase.GetInstance().auth.currentUser;
		if (user) {
			return await user.sendEmailVerification();
		} else {
			throw new Error(
				"An error ocurred sending you an email to validate your account"
			);
		}
	}

	async getAuthenticatedUser(): Promise<UserDto> {
		const firebase = Firebase.GetInstance();
		const user = firebase.auth.currentUser;
		// console.log(`user`, user);
		if (user !== null) {
			const doc = await firebase.firestore
				.collection(CUSTOMERS_COLLECTION)
				.doc(user.uid)
				.get();
			const docData = doc.data();
			return new UserDto({
				id: user.uid,
				email: user.email ?? "",
				emailVerified: user.emailVerified,
				name: docData?.name,
				freeTrial: docData?.freeTrial,
				company: docData?.company,
				stripeId: docData?.stripeId,
				subscription: doc.data()?.subscription,
			});
		} else {
			throw new Error("There is not logged user");
		}
	}

	async sendResetPasswordEmail(email: string): Promise<void> {
		return await Firebase.GetInstance().auth.sendPasswordResetEmail(email);
	}

	async signOut(): Promise<void> {
		return await Firebase.GetInstance().auth.signOut();
	}
}
