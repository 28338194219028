import { container } from "tsyringe";
import { sl } from "./register_dependencies";
import GroupRemoteDatasource, {
  IGroupRemoteDatasource,
} from "../../infrastructure/group/datasources/remote";
import GroupRepository from "../../infrastructure/group/group.repository";
import { IGroupRepository } from "../../domain/group/igroup.repository";

export function registerGroupDependencies() {
  container.register<IGroupRemoteDatasource>("IGroupRemoteDatasource", {
    useClass: GroupRemoteDatasource,
  });

  container.register<IGroupRepository>("IGroupRepository", {
    useValue: new GroupRepository(
      sl<IGroupRemoteDatasource>("IGroupRemoteDatasource")
    ),
  });
}
