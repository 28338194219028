import {
	Card,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Tooltip,
	Typography,
} from "@material-ui/core";
import { Add, Edit, List } from "@material-ui/icons";
import { useEffect, useState } from "react";
import {
	useNoteDispatch,
	useNoteState,
} from "../../../../../../../../../../application/note";
import NoteActions from "../../../../../../../../../../application/note/actions";
import { useInjection } from "../../../../../../../../../../core/dependency_injection";
import Goal from "../../../../../../../../../../domain/note/models/Goal";
import Objective, {
	Progress,
} from "../../../../../../../../../../domain/note/models/Objective";
import useStyles from "../../styles";

export default function ProgressAccordionContent() {
	const classes = useStyles();

	const { note, goals } = useNoteState();
	const noteDispatch = useNoteDispatch();
	const noteActions = useInjection<NoteActions>("NoteActions");

	const [objectives, setObjectives] = useState<Objective[]>([]);
	const [progress, setProgress] = useState<Progress[]>([]);
	const [showInput, setShowInput] = useState(false);

	useEffect(() => {
		if (note.updateOfProgress.goal !== -1) {
			const objs = note.goals[note.updateOfProgress.goal].objectives;
			setObjectives(objs);
		}
	}, [note.updateOfProgress.goal, note.goals]);

	useEffect(() => {
		if (
			note.updateOfProgress.goal !== -1 &&
			note.updateOfProgress.objective !== -1
		) {
			const noteGoal = note.goals[note.updateOfProgress.goal];
			const g = goals.find((item) => item.name === noteGoal.name);
			const o = g?.objectives.find(
				(obj) =>
					obj.label ===
					noteGoal.objectives[note.updateOfProgress.objective].label
			);
			const objProgress = o?.progress ?? [];
			setProgress(objProgress);
			setShowInput(objProgress.length === 0 ?? true);
		} // eslint-disable-next-line
	}, [note.updateOfProgress.objective, note.goals, goals]);

	useEffect(() => {
		if (
			progress.length !== 0 &&
			note.updateOfProgress.progress.label !== "" &&
			!showInput
		) {
			const exist =
				progress.findIndex(
					(p) => p.label === note.updateOfProgress.progress.label
				) !== -1;
			if (!exist) {
				setProgress([...progress, note.updateOfProgress.progress]);
			}
		} // eslint-disable-next-line
	}, [note.updateOfProgress.progress, progress]);

	// useEffect(() => {
	// 	setShowInput(progress.length === 0);
	// }, [progress]);

	return (
		<Card className={classes.card}>
			<Grid container alignItems="center">
				<Grid item container justifyContent="flex-start" lg={6} xs={12}>
					<Typography>Goal</Typography>
				</Grid>
				<Grid item container justifyContent="flex-start" lg={5} xs={12}>
					<FormControl
						variant="filled"
						fullWidth
						className={classes.formControl}
					>
						<InputLabel id={`demo-simple-select${1}`}>Select</InputLabel>
						<Select
							labelId={`demo-simple-select${1}`}
							id={`demo-simple-select${1}`}
							disabled={!note.isGoalsValid()}
							renderValue={(value: any) => (
								<Typography>
									{value === -1
										? ""
										: `Goal ${value + 1} - ${
												note.goals[note.updateOfProgress.goal].name
										  }`}
								</Typography>
							)}
							value={
								note.updateOfProgress.goal === -1
									? ""
									: note.updateOfProgress.goal
							}
							onChange={(e) => {
								const index = e.target.value as number;
								noteActions.setNoteProgressGoal(noteDispatch, index);
							}}
						>
							{note.goals.map((item: Goal, index: number) => (
								<MenuItem key={index} value={index}>
									{`Goal ${index + 1} - ${item.name}`}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
				<Grid item container justifyContent="flex-start" lg={6} xs={12}>
					<Typography>Objective</Typography>
				</Grid>
				<Grid item container justifyContent="flex-start" lg={5} xs={12}>
					<FormControl
						variant="filled"
						fullWidth
						className={classes.formControl}
					>
						<InputLabel id={`demo-simple-select${1}`}>
							{note.updateOfProgress.goal === -1 ? "Select a goal" : "Select"}
						</InputLabel>
						<Select
							labelId={`demo-simple-select${1}`}
							id={`demo-simple-select${1}`}
							disabled={note.updateOfProgress.goal === -1}
							renderValue={(value: any) => (
								<Typography>
									{value === -1
										? ""
										: `Objective ${
												note.updateOfProgress.goal + 1
										  }${String.fromCharCode(65 + value)} - ${
												note.goals[note.updateOfProgress.goal].objectives[
													note.updateOfProgress.objective
												].label
										  }`}
								</Typography>
							)}
							value={
								note.updateOfProgress.objective === -1
									? ""
									: note.updateOfProgress.objective
							}
							onChange={(e) => {
								const index = e.target.value as string;
								noteActions.setNoteProgressObjective(
									noteDispatch,
									parseInt(index)
								);
							}}
						>
							{objectives.map((item: any, index: number) => (
								<MenuItem key={index} value={index}>
									{`Objective ${
										note.updateOfProgress.goal + 1
									}${String.fromCharCode(65 + index)} - ${item.label}`}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
				<Grid item container justifyContent="flex-start" lg={6} xs={12}>
					<Typography>Update of progress</Typography>
				</Grid>
				<Grid item container justifyContent="flex-start" lg={5} xs={10}>
					{!showInput ? (
						<FormControl
							variant="filled"
							fullWidth
							className={classes.formControl}
						>
							<InputLabel id={`demo-simple-select${1}`}>
								{note.updateOfProgress.goal === -1
									? "Select a objective"
									: "Select"}
							</InputLabel>
							<Select
								labelId={`demo-simple-select${1}`}
								id={`demo-simple-select${1}`}
								disabled={note.updateOfProgress.objective === -1}
								value={note.updateOfProgress?.progress?.label}
								onChange={(e) => {
									const label = e.target.value as string;
									const selectedProgress = progress.find(
										(item) => item.label === label
									);
									noteActions.setNoteProgressValue(noteDispatch, {
										progress: selectedProgress ?? { label: "", value: "" },
										isNew: false,
									});
								}}
							>
								{progress.map((item: any, index: number) => (
									<MenuItem key={index} value={item.label}>
										{item.label}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					) : (
						<TextField
							className={classes.formControl}
							fullWidth
							variant="filled"
							// disabled={note.updateOfProgress.objective === -1}
							label="Update of Progress"
							multiline
							value={note.updateOfProgress?.progress?.label}
							onChange={(e) => {
								const label = e.target.value as string;
								// const selectedProgress = progress.find((item) => item.label === label);
								noteActions.setNoteProgressValue(noteDispatch, {
									progress: { label: label, value: label },
									isNew: true,
								});
							}}
						/>
					)}
				</Grid>
				<Grid
					item
					container
					justifyContent="center"
					alignItems="center"
					lg={1}
					xs={1}
				>
					<Tooltip
						title={
							!showInput
								? note.updateOfProgress?.progress?.label === ""
									? "Add new progress"
									: "Edit progress"
								: "Show all progress"
						}
					>
						<IconButton
							color="primary"
							disabled={progress.length === 0}
							onClick={() => {
								setShowInput(!showInput);
							}}
						>
							{!showInput ? (
								note.updateOfProgress?.progress?.label === "" ? (
									<Add />
								) : (
									<Edit />
								)
							) : (
								<List />
							)}
						</IconButton>
					</Tooltip>
				</Grid>
			</Grid>
		</Card>
	);
}
