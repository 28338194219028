import { Typography } from "@material-ui/core";
import { forwardRef } from "react";
import { useParams } from "react-router";
import { useNoteState } from "../../../../../../../../application/note";
import useFindClientById from "../../../../hooks/useFindClientById";
import useFindNoteById from "../../../../hooks/useFindNoteById";
import useStyles from "./styles";

function LabelValueItem(props: { label: string; value: string }) {
	const { label, value } = props;
	return (
		<div>
			<Typography style={{ display: "inline", fontWeight: "bold" }}>
				{label}
			</Typography>{" "}
			<Typography style={{ display: "inline" }}>{value}</Typography>
		</div>
	);
}

const NotePreview = forwardRef(function NotePreview(props, ref) {
	const classes = useStyles();

	const { id, noteId } = useParams<{ id: string; noteId: string }>();

	const { note } = useNoteState();

	useFindClientById(id);

	useFindNoteById(noteId);

	return (
		<div className={classes.root}>
			<div className={classes.topSection}>
				<Typography variant="h4">{note.company.name}</Typography>
				<Typography>{note.company.address}</Typography>
				<Typography>Psychosocial Rehabilitation Progress Note</Typography>
			</div>
			<br />
			<br />
			<div className={classes.clientSection}>
				<LabelValueItem label="Client's Name:" value={note.client.name} />

				<div className={classes.clientSectionRow}>
					<LabelValueItem
						label="Service Code:"
						value={note.client.serviceCode}
					/>
					<LabelValueItem label="Diagnosis:" value={note.client.diagnosis} />
				</div>
				<div className={classes.clientSectionRow}>
					<LabelValueItem label="Case #:" value={note.client.case} />
					<LabelValueItem
						label="Service Date:"
						value={note.startDate.toLocaleDateString()}
					/>
					<LabelValueItem
						label="Total Units:"
						value={`${note.totalUnits} units`}
					/>
				</div>
				<div className={classes.clientSectionRow}>
					<LabelValueItem
						label="Begin-End:"
						value={`${note.startDate.toLocaleTimeString()}-
						${note.endDate.toLocaleTimeString()}`}
					/>
					<LabelValueItem label="Setting:" value={note.client.setting} />
				</div>
			</div>
			<br />
			<div className={classes.goalSection}>
				{note.goals.map((goal, i) => (
					<div key={i}>
						<div>
							<LabelValueItem label={`Goal ${i + 1}:`} value={goal.name} />
						</div>
						{goal.objectives.map((obj, j) => (
							<div>
								<LabelValueItem
									key={j}
									label={`Objective ${i + 1}
									${String.fromCharCode(65 + j)}:`}
									value={obj.value}
								/>
							</div>
						))}
					</div>
				))}
			</div>
			<br />
			<div>
				<Typography style={{ textDecoration: "underline", fontWeight: "bold" }}>
					GOALS/OBJETIVES ADDRESSED CLIENT’S TREATMENT PLAN:
				</Typography>
				<ul>
					{note.treatments.map((treatment, index) => (
						<li key={index}>
							<LabelValueItem
								label={`${treatment.question}:`}
								value={`${treatment.selectedAnswer?.toString()}`}
							/>
						</li>
					))}
				</ul>
			</div>
			<br />
			<div>
				{note.groupTips.map((item, index) => (
					<div key={index}>
						<div>
							<Typography style={{ fontWeight: "bold" }}>
								Group Title {index + 1}
							</Typography>
							<LabelValueItem label={`${item.category}:`} value={item.title} />
						</div>
						{/* <Typography>{item.title}</Typography> */}
						<div>
							<LabelValueItem
								label="Group facilitator intervention:"
								value={item.selectedIntervention?.value ?? ""}
							/>
						</div>
						<div>
							<LabelValueItem
								label="Participation:"
								value={item.selectedParticipation ?? ""}
							/>
						</div>
						<br />
					</div>
				))}
			</div>
			<br />
			<div>
				<LabelValueItem
					label="Update of progress towards meeting goal and objective on the treatment plan:"
					value={note.updateOfProgress.progress.value}
				/>
				<br />
				<Typography>
					Goal {note.updateOfProgress.goal + 1}, Objective{" "}
					{note.updateOfProgress.goal + 1}
					{String.fromCharCode(65 + note.updateOfProgress.objective)}
				</Typography>
				<br />
				<Typography>Client's Name: {note.client.name}</Typography>
			</div>
			{/* <br />
			<br />
			<br />
			<div className={classes.signSection}>
				<div className={classes.signItem}>
					<div className={classes.signDivider} />
					<Typography>Facilitator’s Signature/Credentials</Typography>
				</div>
				<div className={classes.signItem}>
					<Typography>{user.name}</Typography>
					<div className={classes.signDivider} />
					<Typography>Printed Name/Credentials</Typography>
				</div>
				<div className={classes.signItem}>
					<Typography>{note.startDate.toLocaleDateString()}</Typography>
					<div className={classes.signDivider} />
					<Typography>Date</Typography>
				</div>
			</div> */}
		</div>
	);
});

export default NotePreview;
