import { Typography } from "@material-ui/core";
import useStyles from "./styles";
import profile from "../../../../../../../core/assets/images/profile.png";
import { useClientState } from "../../../../../../../../application/client";
import ProfileAvatar from "../../../../../../components/ProfileAvatar";

export default function ClientHighlighs() {
	const classes = useStyles();

	const { selectedClient } = useClientState();

	// const clientDispatch = useClientDispatch();

	// const clientActions = useInjection<ClientActions>("ClientActions");

	// const { enqueueSnackbar } = useSnackbar();

	return (
		<div className={classes.root}>
			<ProfileAvatar imageSrc={profile} />
			<div className={classes.infoContainer}>
				<Typography className={classes.name}>{selectedClient.name}</Typography>
				{/* <Typography>{selectedClient.phone || "(---) --- -- --"}</Typography>
				<Typography>{selectedClient.email || "No email provided"}</Typography> */}
			</div>
		</div>
	);
}
