import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
	root: {
		display: "flex",
		justifyContent: "flex-start",
		alignItems: "center",
	},
	avatarContainer: {
		position: "relative",
		top: -10,
		zIndex: 3,
		width: "120px",
		height: "120px",
		backgroundColor: "grey",
		borderRadius: "60px",
		marginLeft: "20px",
		[theme.breakpoints.down("sm")]: {
			marginLeft: "5px",
		},
	},
	// image: {
	// 	borderRadius: "60px",
	// },
	infoContainer: {
		display: "flex",
		// flex: 1,
		width: "10vw",
		flexDirection: "row",
		justifyContent: "center",
		marginLeft: "30px",
		alignItems: "center",
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column",
			width: "50vw",
		},
	},
	name: {},
	// email: {
	// 	display: "flex",
	// 	flexDirection: "row",
	// 	[theme.breakpoints.down("sm")]: {
	// 		flexDirection: "column",
	// 		width: "50vw",
	// 	},
	// },
	// verified: {
	// 	display: "flex",
	// 	flexDirection: "row",
	// 	marginLeft: "20px",
	// },
	// emailVerifiedText: {
	// 	marginLeft: "5px",
	// },
}));
