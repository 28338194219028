import { makeStyles } from "@material-ui/core";
import { container } from "../../../core/constants";

export default makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.background.paper,
    // position: "absolute",
    margin: 0,
    padding: 0,
    width: "100%",
    zIndex: 1029,
    color: theme.palette.text.primary,
    border: "0",
    borderRadius: "3px",
    transition: "all 150ms ease 0s",
    maxHeight: "90px",
    display: "block",
    // flex: 1,
  },
  container: {
    ...container,
    // position: "fixed",
    // // width: "80%",
    // zIndex: 1030,
    // backgroundColor: theme.palette.background.paper,
    // color: theme.palette.text.primary,
    // display: "flex",
    // flex: 1,
    // border: "0",
    // borderRadius: "3px",
    // transition: "all 150ms ease 0s",
    // maxHeight: "90px",
  },
  flex: {
    flex: 1,
  },
}));
