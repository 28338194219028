import { makeStyles } from "@material-ui/core";
export default makeStyles((theme) => ({
	root: {
		width: "100%",
		minHeight: "300px",
		backgroundColor: theme.palette.secondary.main,
		padding: "24px 0px",
		marginTop: "30px",
	},
	container: {
		display: "flex",
		justifyContent: "center",
		marginTop: "10px",
		textAlign: "center",
	},
	white: {
		color: theme.palette.common.white,
	},
	buttonContainer: {
		display: "flex",
		justifyContent: "center",
		marginTop: "40px",
	},
}));
