import {
	Button,
	Drawer,
	Hidden,
	Icon,
	List,
	ListItem,
	ListItemText,
	Typography,
	useMediaQuery,
	useTheme,
} from "@material-ui/core";
import useStyles from "./styles";
import { NavLink, useHistory } from "react-router-dom";
import classNames from "classnames";
import { ExitToApp } from "@material-ui/icons";
import { useUserDispatch, useUserState } from "../../../../application/user";
import logo from "../../../core/assets/images/logo-white.png";
// import { getRoutes } from "../../../../core/helpers";
import { useInjection } from "../../../../core/dependency_injection/ioc.react";
import UserActions from "../../../../application/user/actions";
import routes from "../../core/routes";
import { activeRoute } from "../../core/helpers";
import { useSnackbar } from "notistack";

export default function Sidebar(props: any) {
	const classes = useStyles();
	const history = useHistory();
	const theme = useTheme();
	const onMobile = useMediaQuery(theme.breakpoints.down("sm"));

	const { user } = useUserState();
	const userDispatch = useUserDispatch();
	const userActions = useInjection<UserActions>("UserActions");

	const { enqueueSnackbar } = useSnackbar();

	// const routes = getRoutes(user.role);

	const sidebarRoutes = onMobile
		? routes
		: routes.filter((item) => item.onSidebar);

	const links = (
		<List className={classes.list}>
			{sidebarRoutes.map((prop, key) => {
				const listClasses = classNames({
					[classes.activeColor]: activeRoute(prop.layout + prop.path),
				});
				// const listItemClasses = classNames({
				// 	[classes.activeItemColor]: activeRoute(prop.layout + prop.path),
				// });
				// const whiteFontClasses = classNames({
				//   [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path),
				// });
				return (
					<NavLink
						to={prop.layout + prop.path}
						className={classes.item}
						activeClassName="active"
						key={key}
						onClick={(e) => {
							if (window.innerWidth < 959) {
								props.handleDrawerToggle();
							}
						}}
					>
						<ListItem
							button
							className={classNames(classes.itemLink, listClasses)}
						>
							<prop.icon className={classNames(classes.itemIcon)} />
							<ListItemText
								primary={prop.name}
								className={classNames(classes.itemText)}
								disableTypography={true}
							/>
						</ListItem>
					</NavLink>
				);
			})}
			<Hidden mdUp>
				<ListItem
					button
					className={classes.item}
					onClick={() => {
						userActions.signOut(userDispatch, history);
					}}
				>
					<Icon className={classes.itemIcon}>
						<ExitToApp />
					</Icon>
					<ListItemText
						primary="Sign Out"
						className={classes.itemText}
						disableTypography={true}
					/>
				</ListItem>
			</Hidden>
		</List>
	);

	const brand = (
		<div className={classes.brand}>
			{/* <div className={classes.logoImage}>
        <img src={logo} alt="logo" className={classes.img} />
      </div> */}
			<div className={classes.logo}>
				{/* <Typography component="span" size="sm">
          <Box color="white" fontSize={24}>
            PSR Notes
          </Box>
        </Typography> */}
				<img src={logo} alt="" width="80%" />
			</div>
		</div>
	);

	const bottom = (
		<div className={classes.bottomContainer}>
			<Typography style={{ color: "white" }}>
				{user.subscription
					? `${user.subscription.product.name} plan`
					: "Free plan"}
			</Typography>
			<Button
				variant="contained"
				onClick={() => {
					if (user.emailVerified) {
						if (user.subscription) {
							userActions.goToCustomerPortal(
								userDispatch,
								user.stripeId ?? "",
								enqueueSnackbar
							);
						} else {
							userActions.setShowPlans(userDispatch, true);
						}
					} else {
						enqueueSnackbar(
							"You need to activate your account. Please check your email inbox.",
							{
								variant: "error",
							}
						);
					}
				}}
			>
				Modify
			</Button>
		</div>
	);

	return (
		<>
			<Hidden mdUp implementation="css">
				<Drawer
					variant="temporary"
					anchor={"right"}
					open={props.open}
					classes={{
						paper: classNames(classes.drawerPaper),
					}}
					onClose={props.handleDrawerToggle}
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
					}}
				>
					{brand}
					<div className={classes.sidebarWrapper}>
						{links}
						{bottom}
					</div>
				</Drawer>
			</Hidden>
			<Hidden smDown implementation="css">
				<Drawer
					anchor={"left"}
					variant="permanent"
					open
					classes={{
						paper: classNames(classes.drawerPaper),
					}}
				>
					{brand}
					<div className={classes.sidebarWrapper}>
						{links}
						{bottom}
					</div>
				</Drawer>
			</Hidden>
		</>
	);
}
