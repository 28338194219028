import { Group, Save } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import React from "react";
import { useHistory } from "react-router";
import { useGroupDispatch, useGroupState } from "../../../../../../../application/group";
import GroupActions from "../../../../../../../application/group/actions";
import { useInjection } from "../../../../../../..//core/dependency_injection";
import PanelHeader, { PanelHeaderAction, PanelHeaderBreadcrumbsRoute } from "../../../../..//components/PanelHeader";
import GroupForm from "../../../components/GroupForm";

export default function GroupCreate() {
  const history = useHistory();

  const { group, isSubmiting } = useGroupState();
  const groupDispatch = useGroupDispatch();
  const groupActions = useInjection<GroupActions>("GroupActions");

  const { enqueueSnackbar } = useSnackbar();

  const breadcrumbsRoutes: PanelHeaderBreadcrumbsRoute[] = [
    {
      label: "Groups",
      icon: Group,
      to: "/app/groups",
    },
    {
      label: "Create",
      // icon: People,
    },
  ];

  const panelActions: PanelHeaderAction[] = [
    {
      label: "Save",
      isLoading: isSubmiting,
      icon: Save,
      onClick: async () => {
        await groupActions.createGroup(groupDispatch, group, history, enqueueSnackbar);
      },
    },
  ];

  return (
    <div>
      <PanelHeader breadcrumbsRoutes={breadcrumbsRoutes} actions={panelActions} />
      <GroupForm />
    </div>
  );
}
