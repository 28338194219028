import {
	Card,
	CircularProgress,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Tooltip,
	Typography,
} from "@material-ui/core";
import { Add, Edit, List } from "@material-ui/icons";
import { useEffect } from "react";
import {
	useInputFormDispatch,
	useInputFormState,
} from "../../../../../../../../../../application/core/inputForm";
import InputFormActions from "../../../../../../../../../../application/core/inputForm/actions";
import { useNoteDispatch, useNoteState } from "../../../../../../../../../../application/note";
import NoteActions from "../../../../../../../../../../application/note/actions";
import { useInjection } from "../../../../../../../../../../core/dependency_injection";
import { GroupObject } from "../../../../../../../../../../domain/note/models/GroupTip";
import useStyles from "../../styles";

export default function GroupTipAccordionContent() {
	const classes = useStyles();

	const {
		note,
		groupTips,
		groupTipsCategories,
		userGroupTips,
		userGroupTipsCategories,
		isFetchingClientInfo,
	} = useNoteState();
	const noteDispatch = useNoteDispatch();
	const noteActions = useInjection<NoteActions>("NoteActions");

	const {
		showGroupTipCategory,
		showGroupTipTitle,
		showGroupTipIntervention,
		showGroupTipParticipation,
	} = useInputFormState();
	const inputFormDispatch = useInputFormDispatch();
	const inputFormActions = useInjection<InputFormActions>("InputFormActions");

	useEffect(() => {
		if (groupTips.length !== 0) {
			note.groupTips?.forEach((item, index) => {
				if (item.title !== "") {
					// const groupTip = groupTips.find((groupTip) => groupTip.title === item.title);
					noteActions.setNoteGroupTipTitle(noteDispatch, {
						index: index,
						title: item.title,
						clean: false,
						find: true,
						isNew: false,
					});
				}
			});
		}
		// eslint-disable-next-line
	}, [groupTips, ...note.groupTips.map((item) => item.title)]);

	useEffect(() => {
		if (note.groupTips.length !== 0) {
			const initialShowInputValues = note.groupTips.map((_) => false);
			inputFormActions.initGroupTipState(inputFormDispatch, {
				showGroupTipCategory: [...initialShowInputValues],
				showGroupTipTitle: [...initialShowInputValues],
				showGroupTipIntervention: [...initialShowInputValues],
				showGroupTipParticipation: note.groupTips.map(
					(item) => !item.participations || item.participations.length === 0
				),
			});
		} // eslint-disable-next-line
	}, [note.groupTips]);

	// useEffect(() => {
	// 	if (note.groupTips.length !== 0) {
	// 		note.groupTips.forEach((item, index) => {
	// 			if (item.participations?.length === 0) {
	// 				inputFormActions.setGroupTipParticipationInput(inputFormDispatch, {
	// 					index: index,
	// 				});
	// 			}
	// 		});
	// 	}
	// }, [note.groupTips]);

	return (
		<Grid container>
			{note.groupTips?.map((item, index) => (
				<Card key={index} className={classes.card}>
					{!isFetchingClientInfo ? (
						<Grid container justifyContent="flex-start" alignItems="center">
							<Grid item container lg={6} xs={12}>
								<Typography>Category:</Typography>
							</Grid>
							<Grid item container lg={5} xs={10}>
								{!showGroupTipCategory[index] ? (
									<FormControl variant="filled" fullWidth className={classes.formControl}>
										<InputLabel id={`demo-simple-select${index}`}>Select</InputLabel>
										<Select
											labelId={`demo-simple-select${index}`}
											id={`demo-simple-select${index}`}
											value={item.category}
											onChange={(e) => {
												const value = e.target.value as string;
												noteActions.setNoteGroupTipCategory(noteDispatch, {
													index: index,
													value: value,
													clean: true,
													isNew: false,
												});
											}}
										>
											<MenuItem className={classes.disabledMenuItem} disabled>
												Recent Categories:
											</MenuItem>
											{userGroupTipsCategories.length !== 0 ? (
												userGroupTipsCategories.map((item, index) => (
													<MenuItem key={index} value={item}>
														{item}
													</MenuItem>
												))
											) : (
												<MenuItem disabled>There are no recents Categories</MenuItem>
											)}
											<MenuItem className={classes.disabledMenuItem} disabled>
												Categories:
											</MenuItem>
											{groupTipsCategories.length !== 0 ? (
												groupTipsCategories.map((item, index) => (
													<MenuItem key={index} value={item}>
														{item}
													</MenuItem>
												))
											) : (
												<MenuItem value={item.category}>{item.category}</MenuItem>
											)}
										</Select>
									</FormControl>
								) : (
									<TextField
										className={classes.formControl}
										fullWidth
										variant="filled"
										label="Enter new category"
										multiline
										value={item.category}
										onChange={(e) => {
											const value = e.target.value as string;
											noteActions.setNoteGroupTipCategory(noteDispatch, {
												index: index,
												value: value,
												clean: false,
												isNew: true,
											});
										}}
									/>
								)}
							</Grid>
							<Grid item container justifyContent="center" alignItems="center" lg={1} xs={2}>
								<Tooltip
									title={
										!showGroupTipCategory[index]
											? item.category === ""
												? "Add new category"
												: "Edit category"
											: "Show all categories"
									}
								>
									<IconButton
										color="primary"
										onClick={() => {
											inputFormActions.setGroupTipCategoryInput(inputFormDispatch, {
												index: index,
											});
											if (!showGroupTipCategory[index]) {
												noteActions.setNoteGroupTipCategory(noteDispatch, {
													value: "",
													index: index,
													clean: true,
													isNew: false,
												});
											}
										}}
									>
										{!showGroupTipCategory[index] ? (
											item.category === "" ? (
												<Add />
											) : (
												<Edit />
											)
										) : (
											<List />
										)}
									</IconButton>
								</Tooltip>
							</Grid>
							<Grid item container lg={6} xs={12}>
								<Typography>Group Title:</Typography>
							</Grid>
							<Grid item container lg={5} xs={10}>
								{!showGroupTipTitle[index] ? (
									<FormControl variant="filled" fullWidth className={classes.formControl}>
										<InputLabel id={`demo-simple-select${index}`}>
											{!item.category ? "Select a category" : "Select"}
										</InputLabel>
										<Select
											labelId={`demo-simple-select${index}`}
											id={`demo-simple-select${index}`}
											disabled={!item.category}
											value={item.title}
											onChange={(e) => {
												const value = e.target.value as string;
												// const groupTip = groupTips.find((item) => item.title === value);
												noteActions.setNoteGroupTipTitle(noteDispatch, {
													index: index,
													title: value,
													clean: true,
													find: true,
													isNew: false,
													// groupTip: groupTip ?? GroupTip.empty(),
												});
											}}
										>
											{/* <MenuItem value={""}>Select a goal</MenuItem> */}
											<MenuItem className={classes.disabledMenuItem} disabled>
												Recent Titles:
											</MenuItem>
											{userGroupTips.filter((e) => e.category === item.category).length !== 0 ? (
												userGroupTips
													.filter((e) => e.category === item.category)
													.map((item, index) => (
														<MenuItem key={index} value={item.title}>
															{item.title}
														</MenuItem>
													))
											) : (
												<MenuItem disabled>
													There are no recent goals with selected category
												</MenuItem>
											)}
											<MenuItem className={classes.disabledMenuItem} disabled>
												Titles:
											</MenuItem>
											{groupTips.length !== 0 ? (
												groupTips
													.filter((e) => e.category === item.category)
													.map((item, index) => (
														<MenuItem key={index} value={item.title}>
															{item.title}
														</MenuItem>
													))
											) : (
												<MenuItem value={item.title}>{item.title}</MenuItem>
											)}
										</Select>
									</FormControl>
								) : (
									<TextField
										className={classes.formControl}
										fullWidth
										variant="filled"
										label="Enter new title"
										multiline
										value={item.title}
										onChange={(e) => {
											const value = e.target.value as string;
											noteActions.setNoteGroupTipTitle(noteDispatch, {
												index: index,
												title: value,
												clean: true,
												find: false,
												isNew: true,
												// groupTip: groupTip ?? GroupTip.empty(),
											});
										}}
									/>
								)}
							</Grid>
							<Grid item container justifyContent="center" alignItems="center" lg={1} xs={2}>
								<Tooltip
									title={
										!showGroupTipTitle[index]
											? item.title === ""
												? "Add new title"
												: "Edit title"
											: "Show all title"
									}
								>
									<IconButton
										color="primary"
										disabled={!item.category || showGroupTipCategory[index]}
										onClick={() => {
											inputFormActions.setGroupTipTitleInput(inputFormDispatch, {
												index: index,
											});
										}}
									>
										{!showGroupTipTitle[index] ? item.title === "" ? <Add /> : <Edit /> : <List />}
									</IconButton>
								</Tooltip>
							</Grid>
							<Grid item container lg={6} xs={12}>
								<Typography>Group facilitator intervention:</Typography>
							</Grid>
							<Grid item container lg={5} xs={10}>
								{!showGroupTipIntervention[index] ? (
									<FormControl variant="filled" fullWidth className={classes.formControl}>
										<InputLabel id={`demo-simple-select${index}`}>
											{item.interventions?.length === 0 ? "Select a title" : "Select"}
										</InputLabel>
										<Select
											labelId={`demo-simple-select${index}`}
											disabled={!item.title}
											id={`demo-simple-select${index}`}
											value={item.selectedIntervention?.label ?? ""}
											onChange={(e) => {
												const value = e.target.value as string;
												const intervention = item.interventions?.find(
													(item) => item.label === value
												);
												noteActions.setNoteGroupIntervention(noteDispatch, {
													index: index,
													value: new GroupObject(
														intervention ? { ...intervention } : { label: "", value: "" }
													),
													isNew: false,
												});
											}}
										>
											{!!item.interventions && item.interventions?.length !== 0 ? (
												item.interventions?.map((item, index) => (
													<MenuItem key={index} value={item.label}>
														{item.label}
													</MenuItem>
												))
											) : (
												<MenuItem value={item.selectedIntervention?.label ?? ""}>
													{item.selectedIntervention?.label ?? ""}
												</MenuItem>
											)}
										</Select>
									</FormControl>
								) : (
									<TextField
										className={classes.formControl}
										fullWidth
										variant="filled"
										label="Enter new intervention"
										multiline
										value={item.selectedIntervention?.label}
										onChange={(e) => {
											const value = e.target.value as string;
											noteActions.setNoteGroupIntervention(noteDispatch, {
												index: index,
												value: new GroupObject({ label: value, value: value }),
												isNew: true,
											});
										}}
									/>
								)}
							</Grid>
							<Grid item container justifyContent="center" alignItems="center" lg={1} xs={2}>
								<Tooltip
									title={
										!showGroupTipIntervention[index]
											? item.title === ""
												? "Add new intervention"
												: "Edit intervention"
											: "Show all intervention"
									}
								>
									<IconButton
										color="primary"
										disabled={
											!item.title || showGroupTipCategory[index] || showGroupTipTitle[index]
										}
										onClick={() => {
											inputFormActions.setGroupTipInterventionInput(inputFormDispatch, {
												index: index,
											});
										}}
									>
										{!showGroupTipIntervention[index] ? (
											item.selectedIntervention?.label === "" ? (
												<Add />
											) : (
												<Edit />
											)
										) : (
											<List />
										)}
									</IconButton>
								</Tooltip>
							</Grid>
							<Grid item container lg={6} xs={12}>
								<Typography>Participation:</Typography>
							</Grid>
							<Grid item container lg={5} xs={10}>
								{!showGroupTipParticipation[index] ? (
									<FormControl variant="filled" fullWidth className={classes.formControl}>
										<InputLabel id={`demo-simple-select${index}`}>
											{item.interventions?.length === 0 ? "Select a title" : "Select"}
										</InputLabel>
										<Select
											labelId={`demo-simple-select${index}`}
											id={`demo-simple-select${index}`}
											disabled={!item.title}
											value={item.selectedParticipation ?? ""}
											onChange={(e) => {
												const value = e.target.value as string;
												noteActions.setNoteGroupParticipation(noteDispatch, {
													index: index,
													selectedAnswer: value,
													isNew: false,
												});
											}}
										>
											{!!item.participations && item.participations?.length !== 0 ? (
												item.participations?.map((item, index) => (
													<MenuItem key={index} value={item}>
														{item}
													</MenuItem>
												))
											) : (
												<MenuItem value={item.selectedParticipation ?? ""}>
													{item.selectedParticipation ?? ""}
												</MenuItem>
											)}
										</Select>
									</FormControl>
								) : (
									<TextField
										className={classes.formControl}
										fullWidth
										variant="filled"
										label="Enter new participation"
										multiline
										value={item.selectedParticipation}
										onChange={(e) => {
											const value = e.target.value as string;
											noteActions.setNoteGroupParticipation(noteDispatch, {
												index: index,
												selectedAnswer: value,
												isNew: true,
											});
										}}
									/>
								)}
							</Grid>
							<Grid item container justifyContent="center" alignItems="center" lg={1} xs={2}>
								<Tooltip
									title={
										!showGroupTipParticipation[index]
											? item.title === ""
												? "Add new participation"
												: "Edit participation"
											: "Show all participation"
									}
								>
									<IconButton
										color="primary"
										disabled={
											!item.title ||
											showGroupTipCategory[index] ||
											showGroupTipTitle[index] ||
											!item.participations ||
											item.participations.length === 0
										}
										onClick={() => {
											inputFormActions.setGroupTipParticipationInput(inputFormDispatch, {
												index: index,
											});
										}}
									>
										{!showGroupTipParticipation[index] ? (
											item.selectedParticipation === "" ? (
												<Add />
											) : (
												<Edit />
											)
										) : (
											<List />
										)}
									</IconButton>
								</Tooltip>
							</Grid>
						</Grid>
					) : (
						<CircularProgress />
					)}
				</Card>
			))}
		</Grid>
	);
}
