import React from "react";
import { initialValues, GroupDispatch } from "./misc";
import reducer from "./reducer";

var GroupStateContext = React.createContext(initialValues);
var GroupDispatchContext = React.createContext<GroupDispatch>(() => null);

function GroupProvider({ children }: any) {
  var [state, dispatch] = React.useReducer(reducer, initialValues);
  return (
    <GroupStateContext.Provider value={state}>
      <GroupDispatchContext.Provider value={dispatch}>
        {children}
      </GroupDispatchContext.Provider>
    </GroupStateContext.Provider>
  );
}

function useGroupState() {
  var context = React.useContext(GroupStateContext);
  if (context === undefined) {
    throw new Error("useGroupState must be used within a GroupProvider");
  }
  return context;
}

function useGroupDispatch() {
  var context = React.useContext(GroupDispatchContext);
  if (context === undefined) {
    throw new Error("useGroupDispatch must be used within a GroupProvider");
  }
  return context;
}

export { GroupProvider, useGroupState, useGroupDispatch };
