import {
	InputFormAction,
	InputFormState,
	SET_SHOW_CATEGORY_INPUT,
	SET_SHOW_GOAL_INPUT,
	SET_SHOW_OBJECTIVES_INPUT,
	INIT_GOALS_STATE,
	INIT_TREATMENT_STATE,
	SET_SHOW_TREATMENT_INPUT,
	INIT_GROUP_TIP_STATE,
	SET_SHOW_GROUP_TIP_CATEGORY,
	SET_SHOW_GROUP_TIP_TITLE,
	SET_SHOW_GROUP_TIP_INTERVENTION,
	SET_SHOW_GROUP_TIP_PARTICIPATION,
} from "./misc";

export default function reducer(state: InputFormState, action: InputFormAction) {
	var showCategoryInput: boolean[];
	var showGoalInput: boolean[];
	var showObjectivesInput: boolean[][];
	var showGroupTipCategory: boolean[];
	var showGroupTipTitle: boolean[];
	var showGroupTipIntervention: boolean[];
	var showGroupTipParticipation: boolean[];
	// var showTreatmentInput: boolean[];
	var value: boolean;

	switch (action.type) {
		case INIT_GROUP_TIP_STATE:
			return {
				...state,
				showGroupTipCategory: action.payload.showGroupTipCategory,
				showGroupTipTitle: action.payload.showGroupTipTitle,
				showGroupTipIntervention: action.payload.showGroupTipIntervention,
				showGroupTipParticipation: action.payload.showGroupTipParticipation,
			} as InputFormState;
		case SET_SHOW_GROUP_TIP_CATEGORY:
			showGroupTipCategory = state.showGroupTipCategory;
			value = !showGroupTipCategory[action.payload.index];
			showGroupTipCategory[action.payload.index] = value;
			showGroupTipTitle = state.showGroupTipTitle;
			showGroupTipTitle[action.payload.index] = value;
			showGroupTipIntervention = state.showGroupTipIntervention;
			showGroupTipIntervention[action.payload.index] = value;
			showGroupTipParticipation = state.showGroupTipParticipation;
			showGroupTipParticipation[action.payload.index] = value;
			return {
				...state,
				showGroupTipCategory: [...showGroupTipCategory],
				showGroupTipTitle: [...showGroupTipTitle],
				showGroupTipIntervention: [...showGroupTipIntervention],
				showGroupTipParticipation: [...showGroupTipParticipation],
			} as InputFormState;
		case SET_SHOW_GROUP_TIP_TITLE:
			showGroupTipTitle = state.showGroupTipTitle;
			value = !showGroupTipTitle[action.payload.index];
			showGroupTipTitle[action.payload.index] = value;
			showGroupTipIntervention = state.showGroupTipIntervention;
			showGroupTipIntervention[action.payload.index] = value;
			showGroupTipParticipation = state.showGroupTipParticipation;
			showGroupTipParticipation[action.payload.index] = value;
			return {
				...state,
				showGroupTipTitle: [...showGroupTipTitle],
				showGroupTipIntervention: [...showGroupTipIntervention],
				showGroupTipParticipation: [...showGroupTipParticipation],
			} as InputFormState;
		case SET_SHOW_GROUP_TIP_INTERVENTION:
			showGroupTipIntervention = state.showGroupTipIntervention;
			value = !showGroupTipIntervention[action.payload.index];
			showGroupTipIntervention[action.payload.index] = value;
			return {
				...state,
				showGroupTipIntervention: [...showGroupTipIntervention],
			} as InputFormState;
		case SET_SHOW_GROUP_TIP_PARTICIPATION:
			showGroupTipParticipation = state.showGroupTipParticipation;
			value = !showGroupTipParticipation[action.payload.index];
			showGroupTipParticipation[action.payload.index] = value;
			return {
				...state,
				showGroupTipParticipation: [...showGroupTipParticipation],
			} as InputFormState;
		case INIT_GOALS_STATE:
			return {
				...state,
				showCategoryInput: action.payload.showCategoryInput,
				showGoalInput: action.payload.showGoalInput,
				showObjectivesInput: action.payload.showObjectivesInput,
			} as InputFormState;
		case SET_SHOW_CATEGORY_INPUT:
			showCategoryInput = state.showCategoryInput;
			value = !showCategoryInput[action.payload.index];
			showCategoryInput[action.payload.index] = value;
			showGoalInput = state.showGoalInput;
			showGoalInput[action.payload.index] = value;
			showObjectivesInput = state.showObjectivesInput;
			showObjectivesInput[action.payload.index] = showObjectivesInput[action.payload.index].map(
				(_) => value
			);
			return {
				...state,
				showCategoryInput: [...showCategoryInput],
				showGoalInput: [...showGoalInput],
				showObjectivesInput: [...showObjectivesInput],
			} as InputFormState;
		case SET_SHOW_GOAL_INPUT:
			showGoalInput = state.showGoalInput;
			value = !showGoalInput[action.payload.index];
			showGoalInput[action.payload.index] = value;
			showObjectivesInput = state.showObjectivesInput;
			showObjectivesInput[action.payload.index] = showObjectivesInput[action.payload.index].map(
				(_) => value
			);
			return {
				...state,
				showGoalInput: [...showGoalInput],
				showObjectivesInput: [...showObjectivesInput],
			} as InputFormState;
		case SET_SHOW_OBJECTIVES_INPUT:
			showObjectivesInput = state.showObjectivesInput;
			showObjectivesInput[action.payload.i][action.payload.j] =
				!showObjectivesInput[action.payload.i][action.payload.j];
			return {
				...state,
				showObjectivesInput: [...showObjectivesInput],
			} as InputFormState;
		case INIT_TREATMENT_STATE:
			return {
				...state,
				showTreatmentInput: action.payload,
			} as InputFormState;
		case SET_SHOW_TREATMENT_INPUT:
			const showTreatmentInput = state.showTreatmentInput;
			showTreatmentInput[action.payload.index] = !showTreatmentInput[action.payload.index];
			return {
				...state,
				showTreatmentInput: showTreatmentInput,
			} as InputFormState;
		default: {
			throw new Error(`Unhandled action type: ${action}`);
		}
	}
}
