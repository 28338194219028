import Footer from "./components/Footer";
import Header from "./components/Header";
import HeaderLinks from "./components/Header/HeaderLinks";
import HomeSection from "./sections/HomeSection";
// import PricingSection from "./sections/PricingSection";

export default function HomePage() {
	document.title = "PSR Notes";

	// const { user } = useUserState();

	// console.log(`user`, user);

	// const userDispatch = useUserDispatch();

	// const userActions = useInjection<UserActions>("UserActions");

	// useEffect(() => {
	//   // TODO: Check if the user is authenticated and if it's fetch his data form backend
	//   // checkAuthenticated(userDispatch);
	//   userActions.checkAuthenticated(userDispatch);
	//   // eslint-disable-next-line
	// }, []);

	return (
		<div
			style={{
				position: "relative",
				display: "flex",
				flexDirection: "column",
				// justifyContent: "center",
				// alignItems: "center",
				// height: "100vh",
			}}
		>
			<Header
				color="transparent"
				rightLinks={<HeaderLinks />}
				fixed
				changeColorOnScroll={{
					height: 100,
					color: "white",
				}}
			/>
			<HomeSection />
			{/* <PricingSection /> */}
			<Footer />
		</div>
	);
}
