import useStyles from "./styles";
import logo from "../../../core/assets/images/logo-white.png";
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import {
	FaceBookLink,
	InstagramLink,
	YouTubeLink,
} from "../../../core/constants";

export default function Footer() {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<div className={classes.topContainer}>
				<div className={classes.logoContainer}>
					<img src={logo} width="200px" alt="Logo" />
					<div className={classes.aboutContainer}>
						<Typography className={classes.grey}>
							Powerful, Robust, Secure,
						</Typography>
						<Typography className={classes.grey}>
							yet quick and simple to use therapy notes software.
						</Typography>
					</div>
				</div>
				<div className={classes.container}>
					<Typography className={classes.white}>Contact Us</Typography>
					<Typography className={classes.grey + " " + classes.marginTop}>
						support@psrnotes.com
					</Typography>
				</div>
				<div className={classes.container}>
					<Typography className={classes.white}>Follow</Typography>
					<a
						href={YouTubeLink}
						target="_blank"
						rel="noreferrer"
						className={classes.link + " " + classes.marginTop}
					>
						<Typography>YouTube</Typography>
					</a>
					<a
						href={FaceBookLink}
						target="_blank"
						rel="noreferrer"
						className={classes.link}
					>
						<Typography>Facebook</Typography>
					</a>
					{/* <a
						href=""
						target="_blank"
						rel="noreferrer"
						className={classes.link}
					>
						<Typography>Twitter</Typography>
					</a> */}
					<a
						href={InstagramLink}
						target="_blank"
						rel="noreferrer"
						className={classes.link}
					>
						<Typography>Instagram</Typography>
					</a>
				</div>
				<div className={classes.container}>
					<Typography className={classes.white}>Legal</Typography>
					<Link
						to="/terms"
						target="_blank"
						className={classes.link + " " + classes.marginTop}
					>
						<Typography>Terms</Typography>
					</Link>
					<Link to="/privacy" target="_blank" className={classes.link}>
						<Typography>Privacy</Typography>
					</Link>
				</div>
			</div>
			<div className={classes.divider} />
			<div className={classes.rigths}>
				<Typography className={classes.grey}>
					Copyright &copy; {new Date().getFullYear()} PSRnotes, All Rights
					Reserved.
				</Typography>
			</div>
		</div>
	);
}
