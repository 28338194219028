import Client from "../../domain/client/models/Client";
import { EnqueueSnackbar } from "../../domain/core/utils";
import { IGroupRepository } from "../../domain/group/igroup.repository";
import GroupPlan from "../../domain/group/models/GroupPlan";
import SessionGroup from "../../domain/group/models/SessionGroup";
import { SET_FETCHING } from "../client/misc";
import {
	ADD_GROUP,
	ADD_GROUP_PLAN,
	DELETE_GROUP,
	DELETE_GROUP_PLAN,
	GroupDispatch,
	SetGroupPlanIntervention,
	SetGroupPlanTipCategory,
	SetGroupPlanTipTitle,
	SET_DELETING,
	SET_GROUP,
	SET_GROUPS,
	SET_GROUP_CLIENTS,
	SET_GROUP_NAME,
	SET_GROUP_PLAN,
	SET_GROUP_PLAN_DATE,
	SET_GROUP_PLAN_INTERVENTION,
	SET_GROUP_PLAN_TIP_CATEGORY,
	SET_GROUP_PLAN_TIP_TITLE,
	SET_SUBMITING,
	UPDATE_GROUP,
	UPDATE_GROUP_PLAN,
} from "./misc";

export default class GroupActions {
	private groupRepository: IGroupRepository;

	constructor(groupRepository: IGroupRepository) {
		this.groupRepository = groupRepository;
	}

	public async getAllSessionGroups(dispatch: GroupDispatch, enqueueSnackbar: EnqueueSnackbar) {
		dispatch({ type: SET_FETCHING, payload: true });
		const result = await this.groupRepository.getAllSessionGroups();
		if (result.isLeft) {
			enqueueSnackbar(result.getError(), { variant: "error" });
			dispatch({ type: SET_FETCHING, payload: false });
			return;
		}
		dispatch({ type: SET_GROUPS, payload: result.getValue() });
		dispatch({ type: SET_FETCHING, payload: false });
	}

	public async createGroup(
		dispatch: GroupDispatch,
		group: SessionGroup,
		history: any,
		enqueueSnackbar: EnqueueSnackbar
	) {
		if (group.isValid()) {
			dispatch({ type: SET_SUBMITING, payload: true });
			const result = await this.groupRepository.create(group);
			if (result.isLeft) {
				enqueueSnackbar(result.getError(), { variant: "error" });
				dispatch({ type: SET_SUBMITING, payload: false });
				return;
			}
			dispatch({ type: ADD_GROUP, payload: result.getValue() });
			dispatch({ type: SET_SUBMITING, payload: false });
			enqueueSnackbar("Group created successfully", { variant: "success" });
			history.replace("/app/groups");
		} else {
			enqueueSnackbar("Invalid Form", { variant: "error" });
		}
	}

	public async updateGroup(
		dispatch: GroupDispatch,
		group: SessionGroup,
		history: any,
		enqueueSnackbar: EnqueueSnackbar
	) {
		if (group.isValid()) {
			dispatch({ type: SET_SUBMITING, payload: true });
			const result = await this.groupRepository.update(group);
			if (result.isLeft) {
				enqueueSnackbar(result.getError(), { variant: "error" });
				dispatch({ type: SET_SUBMITING, payload: false });
				return;
			}
			dispatch({ type: UPDATE_GROUP, payload: group });
			dispatch({ type: SET_SUBMITING, payload: false });
			enqueueSnackbar("Group updated successfully", { variant: "success" });
			history.replace("/app/groups");
		} else {
			enqueueSnackbar("Invalid Form", { variant: "error" });
		}
	}

	public async deleteGroup(
		dispatch: GroupDispatch,
		id: string,
		history: any,
		enqueueSnackbar: EnqueueSnackbar
	) {
		dispatch({ type: SET_DELETING, payload: true });
		const result = await this.groupRepository.delete(id);
		if (result.isLeft) {
			enqueueSnackbar(result.getError(), { variant: "error" });
			dispatch({ type: SET_DELETING, payload: false });
			return;
		}
		dispatch({ type: DELETE_GROUP, payload: id });
		dispatch({ type: SET_DELETING, payload: false });
		enqueueSnackbar("Group removed successfully", { variant: "success" });
		history.replace("/app/groups");
	}

	public async createGroupPlan(
		dispatch: GroupDispatch,
		groupId: string,
		groupPlan: GroupPlan,
		history: any,
		enqueueSnackbar: EnqueueSnackbar
	) {
		if (groupPlan.isValid()) {
			dispatch({ type: SET_SUBMITING, payload: true });
			const result = await this.groupRepository.createGroupPlan(groupId, groupPlan);
			if (result.isLeft) {
				enqueueSnackbar(result.getError(), { variant: "error" });
				dispatch({ type: SET_SUBMITING, payload: false });
				return;
			}
			dispatch({ type: ADD_GROUP_PLAN, payload: result.getValue() });
			dispatch({ type: SET_SUBMITING, payload: false });
			enqueueSnackbar("Group created successfully", { variant: "success" });
			history.goBack();
		} else {
			enqueueSnackbar("Form invalid", { variant: "error" });
		}
	}
	public async updateGroupPlan(
		dispatch: GroupDispatch,
		groupId: string,
		groupPlan: GroupPlan,
		history: any,
		enqueueSnackbar: EnqueueSnackbar
	) {
		if (groupPlan.isValid()) {
			dispatch({ type: SET_SUBMITING, payload: true });
			const result = await this.groupRepository.updateGroupPlan(groupId, groupPlan);
			if (result.isLeft) {
				enqueueSnackbar(result.getError(), { variant: "error" });
				dispatch({ type: SET_SUBMITING, payload: false });
				return;
			}
			dispatch({ type: UPDATE_GROUP_PLAN, payload: groupPlan });
			dispatch({ type: SET_SUBMITING, payload: false });
			enqueueSnackbar("Group created successfully", { variant: "success" });
			history.goBack();
		} else {
			enqueueSnackbar("Form invalid", { variant: "error" });
		}
	}

	public async deleteGroupPlan(
		dispatch: GroupDispatch,
		groupId: string,
		planId: string,
		history: any,
		enqueueSnackbar: EnqueueSnackbar
	) {
		dispatch({ type: SET_DELETING, payload: true });
		const result = await this.groupRepository.deleteGroupPlan(groupId, planId);
		if (result.isLeft) {
			enqueueSnackbar(result.getError(), { variant: "error" });
			dispatch({ type: SET_DELETING, payload: false });
			return;
		}
		dispatch({
			type: DELETE_GROUP_PLAN,
			payload: planId,
		});
		dispatch({ type: SET_DELETING, payload: false });
		enqueueSnackbar("Group removed successfully", { variant: "success" });
		history.goBack();
	}

	public async setGroup(dispatch: GroupDispatch, group?: SessionGroup) {
		dispatch({ type: SET_GROUP, payload: group ?? SessionGroup.empty() });
	}

	public async setGroupName(dispatch: GroupDispatch, value: string) {
		dispatch({ type: SET_GROUP_NAME, payload: value });
	}

	public async setGroupClients(dispatch: GroupDispatch, value: Client[]) {
		dispatch({ type: SET_GROUP_CLIENTS, payload: value });
	}

	public async setGroupPlanDate(dispatch: GroupDispatch, value: Date) {
		dispatch({ type: SET_GROUP_PLAN_DATE, payload: value });
	}

	public async setGroupPlanTip(dispatch: GroupDispatch, value: SetGroupPlanTipTitle) {
		dispatch({ type: SET_GROUP_PLAN_TIP_TITLE, payload: value });
	}

	public async setGroupPlanTipCategory(dispatch: GroupDispatch, value: SetGroupPlanTipCategory) {
		dispatch({ type: SET_GROUP_PLAN_TIP_CATEGORY, payload: value });
	}

	public async setGroupPlanIntervention(dispatch: GroupDispatch, value: SetGroupPlanIntervention) {
		dispatch({ type: SET_GROUP_PLAN_INTERVENTION, payload: value });
	}

	public async setGroupPlan(dispatch: GroupDispatch, value: GroupPlan) {
		dispatch({ type: SET_GROUP_PLAN, payload: value });
	}
}
