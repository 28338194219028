import { container } from "tsyringe";
import { IUserRepository } from "../../domain/user/iuser.repository";
import UserRemoteDatasource, {
	IUserRemoteDatasource,
} from "../../infrastructure/user/datasources/remote";
import UserRepository from "../../infrastructure/user/user.repository";
import { sl } from "./register_dependencies";

export function registerUserDependencies() {
	container.register<IUserRemoteDatasource>("IUserRemoteDatasource", {
		useClass: UserRemoteDatasource,
	});

	container.register<IUserRepository>("IUserRepository", {
		useValue: new UserRepository(sl<IUserRemoteDatasource>("IUserRemoteDatasource")),
	});
}
