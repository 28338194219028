import React from "react";
import { initialValues, ClientDispatch } from "./misc";
import reducer from "./reducer";

var ClientStateContext = React.createContext(initialValues);
var ClientDispatchContext = React.createContext<ClientDispatch>(() => null);

function ClientProvider({ children }: any) {
  var [state, dispatch] = React.useReducer(reducer, initialValues);
  return (
    <ClientStateContext.Provider value={state}>
      <ClientDispatchContext.Provider value={dispatch}>
        {children}
      </ClientDispatchContext.Provider>
    </ClientStateContext.Provider>
  );
}

function useClientState() {
  var context = React.useContext(ClientStateContext);
  if (context === undefined) {
    throw new Error("useClientState must be used within a ClientProvider");
  }
  return context;
}

function useClientDispatch() {
  var context = React.useContext(ClientDispatchContext);
  if (context === undefined) {
    throw new Error("useClientDispatch must be used within a ClientProvider");
  }
  return context;
}

export { ClientProvider, useClientState, useClientDispatch };
