import GroupTip, { GroupObject } from "../../../domain/note/models/GroupTip";
import firebase from "firebase";

export interface IGroupTipDtoProps {
	id?: string;
	title: string;
	category: string;
	interventions?: GroupObjectDto[];
	selectedIntervention?: GroupObjectDto;
	participations?: string[];
	selectedParticipation?: string;
}

export class GroupObjectDto {
	label: string;
	value: string;

	constructor(props: any) {
		this.label = props.label;
		this.value = props.value;
	}

	public toDomain(): GroupObject {
		return new GroupObject({
			label: this.label,
			value: this.value,
		});
	}

	public toJson(): any {
		return {
			label: this.label,
			value: this.value,
		};
	}
}

export class GroupTipDto {
	id?: string;
	title: string;
	category: string;
	interventions?: GroupObjectDto[];
	selectedIntervention?: GroupObjectDto;
	participations?: string[];
	selectedParticipation?: string;

	constructor(props: IGroupTipDtoProps) {
		this.id = props.id;
		this.title = props.title;
		this.category = props.category;
		this.interventions = props.interventions?.map((item) => new GroupObjectDto({ ...item }));
		this.selectedIntervention = new GroupObjectDto({ ...props.selectedIntervention });
		this.participations = props.participations;
		this.selectedParticipation = props.selectedParticipation;
	}

	public static fromDomain(model: GroupTip): GroupTipDto {
		return new GroupTipDto({
			id: model.id,
			title: model.title,
			category: model.category,
			interventions: model.interventions?.map((item) => new GroupObjectDto({ ...item })),
			selectedIntervention: new GroupObjectDto({ ...model.selectedIntervention }),
			participations: model.participations,
			selectedParticipation: model.selectedParticipation,
		});
	}

	public static fromFirestore(
		doc: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
	): GroupTipDto {
		const data = doc.data();
		// console.log(`data`, data);
		return new GroupTipDto({
			id: doc.id,
			title: data?.title,
			category: data?.category,
			interventions: data?.interventions.map((item: any) => new GroupObjectDto({ ...item })),
			selectedIntervention: new GroupObjectDto({ ...data?.selectedIntervention }),
			participations: data?.participations,
			selectedParticipation: data?.selectedParticipation,
		});
	}

	public toJson(): any {
		return {
			title: this.title,
			category: this.category,
			selectedIntervention: this.selectedIntervention?.toJson(),
			selectedParticipation: this.selectedParticipation,
		};
	}

	public toRecentJson(): any {
		return {
			title: this.title,
			category: this.category,
			interventions: this.interventions?.map((item) => item.toJson()),
			participations: this.participations,
		};
	}

	public toDomain(): GroupTip {
		return new GroupTip({
			id: this.id,
			title: this.title,
			category: this.category,
			interventions: this.interventions?.map((item) => item.toDomain()),
			selectedIntervention: this.selectedIntervention?.toDomain(),
			participations: this.participations,
			selectedParticipation: this.selectedParticipation,
		});
	}
}
