import { Dispatch } from "react";
import { newDate } from "../../core/helpers";
import Client from "../../domain/client/models/Client";
import Goal from "../../domain/note/models/Goal";
import GroupTip, { GroupObject } from "../../domain/note/models/GroupTip";
import Note from "../../domain/note/models/Note";
import Objective, { Progress } from "../../domain/note/models/Objective";
import Treatment from "../../domain/note/models/Treatment";
import Company from "../../domain/user/models/Company";

export const SET_FETCHING = "SET_FETCHING";
export const SET_FETCHING_CLIENT_INFO = "SET_FETCHING_CLIENT_INFO";
export const SET_NOTE = "SET_NOTE";
export const SET_NOTE_CLIENT = "SET_NOTE_CLIENT";
export const SET_NOTE_CLIENT_NAME = "SET_NOTE_CLIENT_NAME";
export const SET_NOTE_CLIENT_SETTING = "SET_NOTE_CLIENT_SETTING";
export const SET_NOTE_CLIENT_SERVICE_CODE = "SET_NOTE_CLIENT_SERVICE_CODE";
export const SET_NOTE_CLIENT_CASE = "SET_NOTE_CLIENT_CASE";
export const SET_NOTE_CLIENT_DIAGNOSIS = "SET_NOTE_CLIENT_DIAGNOSIS";
export const SET_GOALS = "SET_GOALS";
export const SET_NOTE_GOALS = "SET_NOTE_GOALS";
export const SET_NOTE_GOALS_END_DATE = "SET_NOTE_GOALS_END_DATE";
export const SET_IS_GOALS_NEW = "SET_IS_GOALS_NEW";
export const SET_NOTE_GOAL_NAME = "SET_NOTE_GOAL_NAME";
export const SET_NOTE_GOAL_CATEGORY = "SET_NOTE_GOAL_CATEGORY";
export const SET_NOTE_GOAL_OBJECTIVE = "SET_NOTE_GOAL_OBJECTIVE";
export const SET_NOTE_TREATMENT = "SET_NOTE_TREATMENT";
export const SET_NOTE_TREATMENT_ANSWER = "SET_NOTE_TREATMENT_ANSWER";
export const INIT_NOTE = "INIT_NOTE";
export const ADD_NOTE = "ADD_NOTE";
export const SET_TOTAL_UNITS = "SET_TOTAL_UNITS";
export const SET_DATE = "SET_DATE";
export const SET_START_DATE = "SET_START_DATE";
export const SET_END_DATE = "SET_END_DATE";
export const SET_EDIT_NOTE = "SET_EDIT_NOTE";
export const SET_GROUP_TIPS = "SET_GROUP_TIPS";
export const SET_NOTE_GOAL_GROUP_TIPS = "SET_NOTE_GOAL_GROUP_TIPS";
// export const SET_NOTE_GROUP_TIP = "SET_NOTE_GROUP_TIP";
export const SET_NOTE_GROUP_TIP_TITLE = "SET_NOTE_GROUP_TIP_TITLE";
export const SET_NOTE_GROUP_TIP_CATEGORY = "SET_NOTE_GROUP_TIP_CATEGORY";
export const SET_NOTE_GROUP_INTERVENTION = "SET_NOTE_GROUP_INTERVENTION";
export const SET_NOTE_GROUP_PARTICIPATION = "SET_NOTE_GROUP_PARTICIPATION";
export const SET_NOTE_PROGRESS_GOAL = "SET_NOTE_PROGRESS_GOAL";
export const SET_NOTE_PROGRESS_OBJECTIVE = "SET_NOTE_PROGRESS_OBJECTIVE";
export const SET_NOTE_PROGRESS_VALUE = "SET_NOTE_PROGRESS_VALUE";
export const SET_USER_GOALS = "SET_USER_GOALS";

export const initialValues: NoteState = {
	note: Note.empty(),
	userGoals: [],
	goals: [],
	goalsEndDate: newDate(),
	isGoalsNew: false,
	goalsCategories: [],
	userGoalsCategories: [],
	treatments: [],
	userTreatments: [],
	groupTips: [],
	groupTipsCategories: [],
	userGroupTips: [],
	userGroupTipsCategories: [],
	isFetching: false,
	isFetchingClientInfo: false,
	isSaving: false,
	isEditingNote: false,
};

export type NoteDispatch = Dispatch<NoteAction>;

export type NoteState = {
	userGoals: Goal[];
	goals: Goal[];
	goalsEndDate: Date;
	isGoalsNew: boolean;
	goalsCategories: string[];
	userGoalsCategories: string[];
	treatments: Treatment[];
	userTreatments: Treatment[];
	groupTips: GroupTip[];
	groupTipsCategories: string[];
	userGroupTips: GroupTip[];
	userGroupTipsCategories: string[];
	isFetching: boolean;
	isFetchingClientInfo: boolean;
	isSaving: boolean;
	isEditingNote: boolean;
	note: Note;
};

type SetNoteActionPayload = {
	goals: Goal[];
	userGoals: Goal[];
	treatments: Treatment[];
	userTreatments: Treatment[];
	groupTips: GroupTip[];
	userGroupTips: GroupTip[];
};

type SetNoteGoalGroupTipPayload = {
	goals?: Goal[];
	groupTips?: GroupTip[];
};

export type SetNoteGoalPayload = {
	index: number;
	goalName: string;
	isNew: boolean;
};

export type SetNoteGoalObjectivePayload = {
	goalIndex: number;
	objectiveIndex: number;
	objective: Objective;
};

export type SetNoteSelectedAnswer = {
	index: number;
	selectedAnswer: string[];
	isNew: boolean;
};
export type SetNoteSelectedParticipation = {
	index: number;
	selectedAnswer: string;
	isNew: boolean;
};

export type InitNotePayload = {
	client: Client;
	company: Company;
	date?: Date;
};

export type SetNoteGroupTipTitle = {
	index: number;
	title: string;
	clean: boolean;
	find: boolean;
	isNew: boolean;
};

export type SetNoteGroupIntervention = {
	index: number;
	value: GroupObject;
	isNew: boolean;
};

export type SetNoteCategoryPayload = {
	index: number;
	value: string;
	clean: boolean;
	isNew: boolean;
};

export type SetNoteGroupTipCategoryPayload = {
	index: number;
	value: string;
	clean: boolean;
	isNew: boolean;
};

export type SetNoteProgressValue = {
	progress: Progress;
	isNew: boolean;
};

export type NoteAction =
	| {
			type: typeof SET_USER_GOALS;
			payload: Goal[];
	  }
	| {
			type: typeof SET_NOTE_GROUP_TIP_CATEGORY;
			payload: SetNoteGroupTipCategoryPayload;
	  }
	| {
			type: typeof SET_NOTE_PROGRESS_VALUE;
			payload: SetNoteProgressValue;
	  }
	| {
			type: typeof SET_NOTE_PROGRESS_OBJECTIVE;
			payload: number;
	  }
	| {
			type: typeof SET_NOTE_PROGRESS_GOAL;
			payload: number;
	  }
	| {
			type: typeof SET_NOTE_GOAL_CATEGORY;
			payload: SetNoteCategoryPayload;
	  }
	| {
			type: typeof SET_NOTE_GOALS;
			payload: Goal[];
	  }
	| {
			type: typeof SET_NOTE_GOALS_END_DATE;
			payload: Date;
	  }
	| {
			type: typeof SET_GOALS;
			payload: {
				userGoals: Goal[];
				goals: Goal[];
			};
	  }
	| {
			type: typeof SET_GROUP_TIPS;
			payload: {
				groupTips: GroupTip[];
				userGroupTips: GroupTip[];
			};
	  }
	| {
			type: typeof SET_NOTE_GOAL_GROUP_TIPS;
			payload: SetNoteGoalGroupTipPayload;
	  }
	| {
			type: typeof SET_NOTE_GROUP_INTERVENTION;
			payload: SetNoteGroupIntervention;
	  }
	| {
			type: typeof SET_NOTE_GROUP_PARTICIPATION;
			payload: SetNoteSelectedParticipation;
	  }
	// | {
	// 		type: typeof SET_NOTE_GROUP_TIP;
	// 		payload: SetNoteGroupTipTitle;
	//   }
	| {
			type: typeof SET_NOTE_GROUP_TIP_TITLE;
			payload: SetNoteGroupTipTitle;
	  }
	| {
			type: typeof SET_NOTE_TREATMENT_ANSWER;
			payload: SetNoteSelectedAnswer;
	  }
	| {
			type: typeof SET_EDIT_NOTE;
			payload: Note;
	  }
	| {
			type: typeof SET_NOTE_CLIENT;
			payload: Client;
	  }
	| {
			type: typeof SET_DATE;
			payload: Date;
	  }
	| {
			type: typeof SET_START_DATE;
			payload: Date;
	  }
	| {
			type: typeof SET_END_DATE;
			payload: Date;
	  }
	| {
			type: typeof SET_TOTAL_UNITS;
			payload: number;
	  }
	| {
			type: typeof SET_NOTE_TREATMENT;
			payload: Treatment;
	  }
	| {
			type: typeof SET_NOTE_GOAL_OBJECTIVE;
			payload: SetNoteGoalObjectivePayload;
	  }
	// | {
	// 		type: typeof SET_NOTE_GOAL;
	// 		payload: SetNoteGoalPayload;
	//   }
	| {
			type: typeof SET_NOTE_GOAL_NAME;
			payload: SetNoteGoalPayload;
	  }
	| {
			type: typeof INIT_NOTE;
			payload: InitNotePayload;
	  }
	| {
			type: typeof SET_NOTE;
			payload: SetNoteActionPayload;
	  }
	| {
			type: typeof SET_FETCHING;
			payload: boolean;
	  }
	| {
			type: typeof SET_NOTE_CLIENT_NAME;
			payload: string;
	  }
	| {
			type: typeof SET_NOTE_CLIENT_CASE;
			payload: string;
	  }
	| {
			type: typeof SET_NOTE_CLIENT_DIAGNOSIS;
			payload: string;
	  }
	| {
			type: typeof SET_NOTE_CLIENT_SERVICE_CODE;
			payload: string;
	  }
	| {
			type: typeof SET_NOTE_CLIENT_SETTING;
			payload: string;
	  }
	| {
			type: typeof SET_FETCHING_CLIENT_INFO;
			payload: boolean;
	  }
	| {
			type: typeof SET_IS_GOALS_NEW;
			payload: boolean;
	  };
