import Note from "../../note/models/Note";
import GoalPlan from "./GoalPlan";

type ClientProps = {
	id?: string;
	name: string;
	serviceCode: string;
	case: string;
	setting: string;
	diagnosis: string;
	goalPlans?: GoalPlan[];
	notes?: Note[];
};

export default class Client {
	id?: string;
	name: string;
	serviceCode: string;
	case: string;
	setting: string;
	diagnosis: string;
	goalPlans?: GoalPlan[];
	notes?: Note[];

	constructor(props: ClientProps) {
		this.id = props.id;
		this.name = props.name;
		this.serviceCode = props.serviceCode;
		this.case = props.case;
		this.setting = props.setting;
		this.diagnosis = props.diagnosis;
		this.goalPlans = props.goalPlans;
		this.notes = props.notes;
	}

	public static empty(): Client {
		return new Client({
			name: "",
			serviceCode: "",
			case: "",
			setting: "",
			diagnosis: "",
		});
	}

	public isValid(): boolean {
		return (
			this.name !== "" &&
			this.serviceCode !== "" &&
			this.case !== "" &&
			this.diagnosis !== "" &&
			this.setting !== ""
		);
	}
	public isEmpty(): boolean {
		return (
			this.name === "" ||
			this.serviceCode === "" ||
			this.case === "" ||
			this.diagnosis === "" ||
			this.setting === ""
		);
	}
}
