import { makeStyles } from "@material-ui/core";
export default makeStyles((theme) => ({
	root: {
		display: "flex",
		flexDirection: "row",
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column",
		},
	},
	leftContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		padding: "30px",
		flex: 1,
	},
	rightContainer: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		marginBottom: "16px",
		flex: 1,
	},
	titleContainer: {
		display: "flex",
		justifyContent: "flex-start",
		alignItems: "center",
	},
	title: {
		marginBottom: "10px",
		textAlign: "start",
	},
	content: {
		display: "flex",
		maxWidth: "30vw",
		marginTop: "10px",
		textAlign: "center",
		[theme.breakpoints.down("sm")]: {
			maxWidth: "90%",
		},
	},
}));
