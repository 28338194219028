import { makeStyles } from "@material-ui/core";
export default makeStyles((theme) => ({
	root: {
		display: "flex",
		flexDirection: "column",
		padding: "20px",
	},
	topSection: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
	},
	clientSection: {
		// marginTop: "20px",
	},
	clientSectionRow: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	goalSection: {},
	signSection: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "flex-end",
	},
	signItem: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
	},
	signDivider: {
		width: "100%",
		height: "1px",
		backgroundColor: "black",
	},
	signTopLabel: {
		borderBottom: "1px solid black",
	},
	signBottomLabel: {
		borderTop: "1px solid black",
	},
}));
