import {
	Button,
	Card,
	CardContent,
	TextField,
	Typography,
	FormControl,
	Input,
	InputLabel,
	InputAdornment,
	IconButton,
} from "@material-ui/core";
import { useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useUserDispatch, useUserState } from "../../../application/user";
import useStyles from "./styles";
import logoBlue from "../../core/assets/images/logo.png";
// import logoWhite from "../../core/assets/images/logo-white1.png";
import { useSnackbar } from "notistack";
import { useInjection } from "../../../core/dependency_injection/ioc.react";
import UserActions from "../../../application/user/actions";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { ButtonCircularProgress } from "../../core/components/Progress";

export default function SignIn() {
	document.title = "PSR Notes | Sign In";

	const classes = useStyles();
	const history = useHistory();

	// const { isDark } = useThemeState();

	const { isSubmiting } = useUserState();
	const userDispatch = useUserDispatch();
	const userActions = useInjection<UserActions>("UserActions");

	const { enqueueSnackbar } = useSnackbar();

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [showPassword, setShowPassword] = useState(false);

	// const logo = isDark ? logoWhite : logoBlue;
	const logo = logoBlue;

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleMouseDownPassword = (event: any) => {
		event.preventDefault();
	};

	// console.log(`isSubmiting`, isSubmiting);

	return (
		<div className={classes.root}>
			<Card className={classes.card}>
				<CardContent className={classes.cardContent}>
					{/* <Typography variant="h4"> */}
					<div className={classes.logoContainer}>
						<Link className={classes.logolink} to="/">
							<img
								src={logo}
								alt="PSR Notes"
								className={classes.logo}
								onClick={() => {
									history.replace("/");
								}}
							/>
						</Link>
					</div>
					{/* </Typography> */}
					<Typography className={classes.subtitle} variant="h6">
						Welcome back
					</Typography>
					<form
						className={classes.form}
						onSubmit={(e) => {
							e.preventDefault();
							userActions.signIn(
								userDispatch,
								email.replace(" ", ""),
								password,
								history,
								enqueueSnackbar
							);
						}}
					>
						<TextField
							className={classes.margin}
							fullWidth
							label="Email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
						<FormControl fullWidth className={classes.margin}>
							<InputLabel htmlFor="standard-adornment-password">
								Password
							</InputLabel>
							<Input
								id="standard-adornment-password"
								type={showPassword ? "text" : "password"}
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								endAdornment={
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={handleClickShowPassword}
											onMouseDown={handleMouseDownPassword}
										>
											{showPassword ? <Visibility /> : <VisibilityOff />}
										</IconButton>
									</InputAdornment>
								}
							/>
						</FormControl>
						<Button
							type="submit"
							fullWidth
							className={classes.button}
							variant="contained"
							disabled={isSubmiting}
							color="primary"
						>
							Sign In
							{isSubmiting && <ButtonCircularProgress />}
						</Button>
						<Typography className={classes.margin} variant="body1">
							<Link
								className={classes.link}
								to="#"
								onClick={() => {
									if (email !== "") {
										userActions.sendResetPasswordEmail(
											userDispatch,
											email.replace(" ", ""),
											enqueueSnackbar
										);
									} else {
										enqueueSnackbar("Enter your email", { variant: "error" });
									}
								}}
							>
								Forgot password?
							</Link>
						</Typography>
						<Typography className={classes.margin} variant="body1">
							New to PSR Notes?{" "}
							<Link className={classes.link} to="/auth/sign-up">
								Join now
							</Link>
						</Typography>
					</form>
				</CardContent>
			</Card>
		</div>
	);
}
