import { Add, Group } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import {
	useGroupDispatch,
	useGroupState,
} from "../../../../../../application/group";
import GroupActions from "../../../../../../application/group/actions";
import {
	useUserDispatch,
	useUserState,
} from "../../../../../../application/user";
import UserActions from "../../../../../../application/user/actions";
import { useInjection } from "../../../../../../core/dependency_injection";
import { EnhancedTable } from "../../../../../core/components/Table";
import PanelHeader, {
	PanelHeaderAction,
} from "../../../../components/PanelHeader";

export default function GroupsPage() {
	document.title = "PSR Notes | Groups";

	const history = useHistory();

	const { user } = useUserState();
	const userDispatch = useUserDispatch();
	const userActions = useInjection<UserActions>("UserActions");

	const { sessionGroups } = useGroupState();
	const groupDispatch = useGroupDispatch();
	const groupActions = useInjection<GroupActions>("GroupActions");

	const { enqueueSnackbar } = useSnackbar();

	const columns = [
		{ id: "name", label: "Name", minWidth: 170, align: "left" },
		{
			id: "clients",
			label: "Clients",
			minWidth: 170,
			align: "left",
			format: (value: any) => value.length,
		},
	];

	const panelActions: PanelHeaderAction[] = [
		{
			label: "Add...",
			icon: Add,
			onClick: () => {
				userActions.handleSubscriptionStatus(
					userDispatch,
					user,
					enqueueSnackbar,
					() => {
						groupActions.setGroup(groupDispatch);
						history.push("/app/groups/create");
					}
				);
			},
		},
	];

	return (
		<div>
			<PanelHeader title="Groups" icon={<Group />} actions={panelActions} />
			{!sessionGroups || sessionGroups.length === 0 ? (
				<div>No registered groups</div>
			) : (
				<EnhancedTable
					data={sessionGroups ?? []}
					columns={columns}
					onClickCallback={(group: any) => {
						userActions.handleSubscriptionStatus(
							userDispatch,
							user,
							enqueueSnackbar,
							() => {
								groupActions.setGroup(groupDispatch, group);
								history.push(`/app/groups/${group.id}`);
							}
						);
					}}
				/>
			)}
		</div>
	);
}
