import { OptionsObject, SnackbarKey, SnackbarMessage } from "notistack";

export type EnqueueSnackbar = (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey;



export enum Themes {
	Light = "Light",
	Dark = "Dark",
	System = "System",
}
