import { makeStyles } from "@material-ui/core";
import { hexToRgb } from "../../../core/helpers";

export default makeStyles((theme) => ({
  button: {
    // marginLeft: "10px",
    color: theme.palette.common.white,
  },
  primary: {
    backgroundColor: theme.palette.primary.main,
    "&:hover,&:focus": {
      backgroundColor: `rgba(${hexToRgb(theme.palette.primary.main)}, 0.8)`,
    },
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main,
    "&:hover,&:focus": {
      backgroundColor: `rgba(${hexToRgb(theme.palette.secondary.main)}, 0.8)`,
    },
  },
  info: {
    backgroundColor: theme.palette.info.main,
    "&:hover,&:focus": {
      backgroundColor: `rgba(${hexToRgb(theme.palette.info.main)}, 0.8)`,
    },
  },
  success: {
    backgroundColor: theme.palette.success.main,
    "&:hover,&:focus": {
      backgroundColor: `rgba(${hexToRgb(theme.palette.success.main)}, 0.8)`,
    },
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    "&:hover,&:focus": {
      backgroundColor: `rgba(${hexToRgb(theme.palette.warning.main)}, 0.8)`,
    },
  },
  danger: {
    backgroundColor: theme.palette.error.main,
    "&:hover,&:focus": {
      backgroundColor: `rgba(${hexToRgb(theme.palette.error.main)}, 0.8)`,
    },
  },
  white: {
    backgroundColor: theme.palette.common.white,
    "&:hover,&:focus": {
      backgroundColor: `rgba(${hexToRgb(theme.palette.common.white)}, 0.8)`,
    },
  },
  black: {
    backgroundColor: theme.palette.common.black,
    "&:hover,&:focus": {
      backgroundColor: `rgba(${hexToRgb(theme.palette.common.black)}, 0.8)`,
    },
  },
  transparent: {
    backgroundColor: "transparent",
    "&:hover,&:focus": {
      backgroundColor: `rgba(${hexToRgb("#000")}, 0.7)`,
    },
  },
}));
