import { container } from "tsyringe";
import { IProductRepository } from "../../domain/product/iproduct.repository";
import ProductRemoteDatasource, {
	IProductRemoteDatasource,
} from "../../infrastructure/product/datasources/remote";
import ProductRepository from "../../infrastructure/product/product.repository";
import { sl } from "./register_dependencies";

export function registerProductDependencies() {
	container.register<IProductRemoteDatasource>("IProductRemoteDatasource", {
		useClass: ProductRemoteDatasource,
	});

	container.register<IProductRepository>("IProductRepository", {
		useValue: new ProductRepository(
			sl<IProductRemoteDatasource>("IProductRemoteDatasource")
		),
	});
}
