import "reflect-metadata";
import { CssBaseline, MuiThemeProvider } from "@material-ui/core";
import { SnackbarProvider } from "notistack";
import ReactDOM from "react-dom";
import App from "./presentation";
import reportWebVitals from "./reportWebVitals";
import {
	DependencyProvider,
	registerDependencies,
} from "./core/dependency_injection";
import { container } from "tsyringe";
import { ContextProvider } from "./application";
import { themes } from "./presentation/core/themes";

registerDependencies();

ReactDOM.render(
	<ContextProvider>
		<DependencyProvider container={container}>
			<SnackbarProvider
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
			>
				<MuiThemeProvider theme={themes.default}>
					<CssBaseline />
					<App />
				</MuiThemeProvider>
			</SnackbarProvider>
		</DependencyProvider>
	</ContextProvider>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
