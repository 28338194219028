import { User } from "../../domain/user/models/User";
import {
	SET_FILL_PROFILE,
	SET_LOADING,
	SET_SENDING_PASSWORD,
	SET_SENDING_VERIFY,
	SET_SHOW_PLAN,
	SET_SIGN_EMAIL_SENDED,
	SET_SUBMITING,
	SET_UPDATING,
	SET_USER,
	SIGN_FAILURE,
	SIGN_OUT_SUCCESS,
	UPDATE_USER,
	UserAction,
	UserState,
} from "./misc";

export default function reducer(state: UserState, action: UserAction) {
	switch (action.type) {
		case SET_FILL_PROFILE:
			return {
				...state,
				isFillingProfile: action.payload.value,
				user: new User({ ...state.user, id: action.payload.id }),
			} as UserState;
		case SET_SIGN_EMAIL_SENDED:
			return { ...state, signEmailSended: action.payload } as UserState;
		case SET_SENDING_VERIFY:
			return { ...state, isSendingVerify: action.payload } as UserState;
		case SET_SENDING_PASSWORD:
			return { ...state, isSendingPassword: action.payload } as UserState;
		case SET_SHOW_PLAN:
			return { ...state, showPlans: action.payload } as UserState;
		case SET_LOADING:
			return { ...state, isLoading: action.payload };
		case SET_UPDATING:
			return { ...state, isUpdating: action.payload };
		case SET_SUBMITING:
			return { ...state, isSubmiting: action.payload };
		case SIGN_OUT_SUCCESS:
			return { ...state, isAuthenticated: false, isLoading: false };
		case SIGN_FAILURE:
			return { ...state, isAuthenticated: false, isLoading: false };
		case SET_USER:
			return {
				...state,
				user: new User({ ...action.payload }),
				isAuthenticated: !!action.payload.name,
			};
		case UPDATE_USER:
			const user = action.payload;
			return {
				...state,
				user: new User({
					...state.user,
					name: user.name,
					company: user.company,
				}),
			};
		default: {
			throw new Error(`Unhandled action type: ${action}`);
		}
	}
}
