import { Progress } from "./Objective";

type UpdateOfProgressProps = {
	goal: number;
	objective: number;
	progress: Progress;
	isNew?: boolean;
};

export default class UpdateOfProgress {
	goal: number;
	objective: number;
	progress: Progress;
	isNew: boolean;

	constructor(props: UpdateOfProgressProps) {
		this.goal = props.goal;
		this.objective = props.objective;
		this.progress = props.progress;
		this.isNew = props.isNew ?? false;
	}

	public static empty(): UpdateOfProgress {
		return new UpdateOfProgress({
			goal: -1,
			objective: -1,
			progress: { label: "", value: "" },
		});
	}

	public isValid(): boolean {
		return this.goal !== -1 && this.objective !== -1 && this.progress && this.progress.label !== "";
	}

	public changeIsNew(value: boolean): void {
		this.isNew = value;
	}
}
