export default class Config {
	static isInDev = process.env.NODE_ENV === "development";
	static redirectLinkUrl =
		process.env.NODE_ENV === "development"
			? "http://localhost:3000/auth/signLink"
			: "http://www.psrnotes.com/auth/signLink";
	// static successUrl =
	// 	process.env.NODE_ENV === "development"
	// 		? "http://localhost:3000/app/profile"
	// 		: "";
	// static cancelUrl =
	// 	process.env.NODE_ENV === "development"
	// 		? "http://localhost:3000/app/profile"
	// 		: "http://localhost:3000/app/dashboard";
	// static returnUrl =
	// 	process.env.NODE_ENV === "development"
	// 		? "http://localhost:3000/app/profile"
	// 		: "http://localhost:3000/app/dashboard";
}
