import { Fragment, useEffect, useState } from "react";
import {
	Card,
	CircularProgress,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Tooltip,
	Typography,
} from "@material-ui/core";
import Objective from "../../../../../../../../../../domain/note/models/Objective";
import useStyles from "../../styles";
import {
	useNoteDispatch,
	useNoteState,
} from "../../../../../../../../../../application/note";
import { useInjection } from "../../../../../../../../../../core/dependency_injection";
import NoteActions from "../../../../../../../../../../application/note/actions";
import { Add, Edit, List } from "@material-ui/icons";
import {
	useInputFormDispatch,
	useInputFormState,
} from "../../../../../../../../../../application/core/inputForm";
import InputFormActions from "../../../../../../../../../../application/core/inputForm/actions";
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

export default function GoalsAccordionContent() {
	const classes = useStyles();

	const {
		note,
		goals,
		goalsCategories,
		userGoals,
		userGoalsCategories,
		isFetchingClientInfo,
		goalsEndDate,
		isGoalsNew,
	} = useNoteState();
	const noteDispatch = useNoteDispatch();
	const noteActions = useInjection<NoteActions>("NoteActions");

	const { showCategoryInput, showGoalInput, showObjectivesInput } =
		useInputFormState();
	const inputFormDispatch = useInputFormDispatch();
	const inputFormActions = useInjection<InputFormActions>("InputFormActions");

	const [objectives, setObjectives] = useState<Objective[][]>([]);

	useEffect(() => {
		noteActions.setIsNoteGoalsNew(
			noteDispatch,
			!note.isGoalsValid() && !note.client.id
		); //eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (objectives.length === 0 && note.isGoalsValid()) {
			note.goals.forEach((item, index) => {
				if (item.name) {
					var goal = goals.find((g) => g.name === item.name);
					if (!goal) {
						goal = userGoals.find((g) => g.name === item.name);
					}
					const objs = objectives;
					objs[index] = goal?.objectives ?? [];
					setObjectives(objs);
				}
			});
		} // eslint-disable-next-line
	}, [objectives, note.goals, goals]);

	useEffect(() => {
		if (note.goals.length !== 0) {
			const initialShowInputValues = note.goals.map((_) => false);
			inputFormActions.initGoalsState(inputFormDispatch, {
				showCategoryInput: [...initialShowInputValues],
				showGoalInput: [...initialShowInputValues],
				showObjectivesInput: note.goals.map((item) =>
					item.objectives.map((_) => false)
				),
			});
		} // eslint-disable-next-line
	}, [note.goals]);

	return (
		<Grid container>
			{note.goals.map((item, index) => {
				return (
					<Card key={index} className={classes.card}>
						{!isFetchingClientInfo &&
						showCategoryInput.length !== 0 &&
						showGoalInput.length !== 0 &&
						showObjectivesInput.length !== 0 ? (
							<Grid container alignItems="center" justifyContent="center">
								<Grid item container lg={12} xs={12}>
									<Grid item container alignItems="center" lg={6} xs={12}>
										<Typography>Category:</Typography>
									</Grid>
									<Grid
										item
										container
										alignItems="center"
										direction="row"
										lg={5}
										xs={10}
									>
										{!showCategoryInput[index] ? (
											<FormControl
												variant="filled"
												fullWidth
												className={classes.formControl}
											>
												<InputLabel id={`demo-simple-select${index}`}>
													Select
												</InputLabel>
												<Select
													labelId={`demo-simple-select${index}`}
													id={`demo-simple-select${index}`}
													value={item.category}
													onChange={(e) => {
														const value = e.target.value as string;
														noteActions.setNoteGoalCategory(noteDispatch, {
															value: value,
															index: index,
															clean: true,
															isNew: false,
														});
													}}
												>
													<MenuItem
														className={classes.disabledMenuItem}
														disabled
													>
														Recent Categories:
													</MenuItem>
													{userGoalsCategories.length !== 0 ? (
														userGoalsCategories.map((item, index) => (
															<MenuItem key={index} value={item}>
																{item}
															</MenuItem>
														))
													) : (
														<MenuItem disabled>
															There are no recent categories
														</MenuItem>
													)}
													<MenuItem
														className={classes.disabledMenuItem}
														disabled
													>
														Categories
													</MenuItem>
													{goalsCategories.length !== 0 ? (
														goalsCategories.map((item: any, index: number) => (
															<MenuItem key={index} value={item}>
																{item}
															</MenuItem>
														))
													) : (
														<MenuItem value={item.category}>
															{item.category}
														</MenuItem>
													)}
												</Select>
											</FormControl>
										) : (
											<TextField
												className={classes.formControl}
												fullWidth
												variant="filled"
												label="Enter new category"
												multiline
												value={item.category}
												onChange={(e) => {
													const value = e.target.value as string;
													noteActions.setNoteGoalCategory(noteDispatch, {
														value: value,
														index: index,
														clean: false,
														isNew: true,
													});
												}}
											/>
										)}
									</Grid>
									<Grid
										item
										container
										justifyContent="center"
										alignItems="center"
										lg={1}
										xs={2}
									>
										<Tooltip
											title={
												!showCategoryInput[index]
													? item.category === ""
														? "Add new category"
														: "Edit category"
													: "Show all categories"
											}
										>
											<IconButton
												color="primary"
												onClick={() => {
													inputFormActions.setCategoryInput(inputFormDispatch, {
														index: index,
													});
													if (!showCategoryInput[index]) {
														noteActions.setNoteGoalCategory(noteDispatch, {
															value: "",
															index: index,
															clean: true,
															isNew: false,
														});
													}
												}}
											>
												{!showCategoryInput[index] ? (
													item.category === "" ? (
														<Add />
													) : (
														<Edit />
													)
												) : (
													<List />
												)}
											</IconButton>
										</Tooltip>
									</Grid>
								</Grid>
								<Grid item container lg={12} xs={12}>
									<Grid item container alignItems="center" lg={6} xs={12}>
										<Typography>Goal {index + 1}:</Typography>
									</Grid>
									<Grid item container alignItems="center" lg={5} xs={10}>
										{!showGoalInput[index] ? (
											<FormControl
												variant="filled"
												fullWidth
												className={classes.formControl}
											>
												<InputLabel id={`demo-simple-select${index}`}>
													{!item.category ? "Select a category" : "Select"}
												</InputLabel>
												<Select
													labelId={`demo-simple-select${index}`}
													disabled={!item.category}
													id={`demo-simple-select${index}`}
													value={item.name}
													onChange={(e) => {
														const goalName = e.target.value as string;
														var selectedGoal = goals.find(
															(goal) => goal.name === goalName
														);
														if (!selectedGoal) {
															selectedGoal = userGoals.find(
																(goal) => goal.name === goalName
															);
														}
														noteActions.setNoteGoalName(noteDispatch, {
															goalName: goalName,
															index: index,
															isNew: false,
														});
														var tempObjectives = objectives;
														tempObjectives[index] =
															selectedGoal?.objectives ?? [];
														setObjectives(tempObjectives);
													}}
												>
													{/* {item.name !== "" && <MenuItem value={item.name}>{item.name}</MenuItem>} */}
													<MenuItem
														className={classes.disabledMenuItem}
														disabled
													>
														Recent Goals:
													</MenuItem>
													{userGoals.filter((e) => e.category === item.category)
														.length !== 0 ? (
														userGoals
															.filter((e) => e.category === item.category)
															.map((item, index) => (
																<MenuItem key={index} value={item.name}>
																	{item.name}
																</MenuItem>
															))
													) : (
														<MenuItem disabled>
															There are no recent goals with selected category
														</MenuItem>
													)}
													<MenuItem
														className={classes.disabledMenuItem}
														disabled
													>
														Goals:
													</MenuItem>
													{goals.length !== 0 &&
													goals.filter((e) => e.category === item.category)
														.length !== 0 ? (
														goals
															.filter((e) => e.category === item.category)
															.map((item: any, index: number) => (
																<MenuItem key={index} value={item.name}>
																	{item.name}
																</MenuItem>
															))
													) : (
														<MenuItem disabled>
															There are no goals with selected category
														</MenuItem>
													)}
												</Select>
											</FormControl>
										) : (
											<TextField
												className={classes.formControl}
												fullWidth
												disabled={!item.category}
												multiline
												variant="filled"
												label="Enter new goal"
												value={item.name}
												onChange={(e) => {
													const value = e.target.value as string;
													noteActions.setNoteGoalName(noteDispatch, {
														goalName: value,
														index: index,
														isNew: true,
													});
												}}
											/>
										)}
									</Grid>
									<Grid
										item
										container
										justifyContent="center"
										alignItems="center"
										lg={1}
										xs={2}
									>
										<Tooltip
											title={
												!showGoalInput[index]
													? item.name === ""
														? "Add new goal"
														: "Edit goal"
													: "Show all goal"
											}
										>
											<IconButton
												color="primary"
												disabled={!item.category || showCategoryInput[index]}
												onClick={() => {
													inputFormActions.setGoalInput(inputFormDispatch, {
														index: index,
													});
												}}
											>
												{!showGoalInput[index] ? (
													item.name === "" ? (
														<Add />
													) : (
														<Edit />
													)
												) : (
													<List />
												)}
											</IconButton>
										</Tooltip>
									</Grid>
								</Grid>
								<Grid item container lg={12} xs={12}>
									{item.objectives.map((obj: Objective, i: any) => {
										return (
											<Fragment key={i}>
												<Grid item container alignItems="center" lg={6} xs={12}>
													<Typography>
														Objective {index + 1}
														{String.fromCharCode(65 + i)}:
													</Typography>
												</Grid>
												<Grid item container alignItems="center" lg={5} xs={10}>
													{!showObjectivesInput[index][i] ? (
														<FormControl
															variant="filled"
															fullWidth
															className={classes.formControl}
														>
															<InputLabel id="demo-simple-select-label">
																{!note.goals[index].name
																	? "Select a goal"
																	: "Select"}
															</InputLabel>
															<Select
																labelId="demo-simple-select-label"
																id="demo-simple-select"
																disabled={!note.goals[index].name}
																value={obj.label}
																onChange={(e) => {
																	const objectiveLabel = e.target
																		.value as string;
																	const selectedObejctive =
																		objectives[index]?.find(
																			(item) => item.label === objectiveLabel
																		) ?? Objective.empty();
																	selectedObejctive.isNew = false;
																	noteActions.setNoteGoalObjective(
																		noteDispatch,
																		{
																			goalIndex: index,
																			objectiveIndex: i,
																			objective: selectedObejctive,
																		}
																	);
																}}
															>
																{objectives[index]?.length !== 0 ? (
																	objectives[index]?.map(
																		(item: any, index: number) => (
																			<MenuItem key={index} value={item.label}>
																				{item.label}
																			</MenuItem>
																		)
																	)
																) : (
																	<MenuItem value={obj.label}>
																		{obj.label}
																	</MenuItem>
																)}
															</Select>
														</FormControl>
													) : (
														<TextField
															className={classes.formControl}
															fullWidth
															multiline
															variant="filled"
															disabled={!item.category || !item.name}
															label="Enter new objective"
															value={obj.label}
															onChange={(e) => {
																const value = e.target.value as string;
																noteActions.setNoteGoalObjective(noteDispatch, {
																	goalIndex: index,
																	objectiveIndex: i,
																	objective: new Objective({
																		label: value,
																		value: value,
																		isNew: true,
																	}),
																});
															}}
														/>
													)}
												</Grid>
												<Grid
													item
													container
													justifyContent="center"
													alignItems="center"
													lg={1}
													xs={2}
												>
													<Tooltip
														title={
															!showObjectivesInput[index][i]
																? obj.label === ""
																	? "Add new objective"
																	: "Edit objective"
																: "Show all objective"
														}
													>
														<IconButton
															color="primary"
															disabled={
																!item.category ||
																!item.name ||
																showGoalInput[index]
															}
															onClick={() => {
																inputFormActions.setObjectivesInput(
																	inputFormDispatch,
																	{
																		i: index,
																		j: i,
																	}
																);
																if (!showObjectivesInput[index][i]) {
																	noteActions.setNoteGoalObjective(
																		noteDispatch,
																		{
																			goalIndex: index,
																			objectiveIndex: i,
																			objective: new Objective({
																				label: "",
																				value: "",
																				isNew: false,
																			}),
																		}
																	);
																}
															}}
														>
															{!showObjectivesInput[index][i] ? (
																obj.label === "" ? (
																	<Add />
																) : (
																	<Edit />
																)
															) : (
																<List />
															)}
														</IconButton>
													</Tooltip>
												</Grid>
											</Fragment>
										);
									})}
								</Grid>
							</Grid>
						) : (
							<CircularProgress />
						)}
					</Card>
				);
			})}
			{isGoalsNew && (
				<Card className={classes.card}>
					<Grid container alignItems="center">
						<Grid item lg={6} xs={6}>
							End Date
						</Grid>
						<Grid item lg={6} xs={6}>
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<KeyboardDatePicker
									variant="inline"
									inputVariant="filled"
									className={classes.item}
									format="MM/dd/yyyy"
									// margin="normal"
									id="date-picker-inline"
									label="Select a Date"
									value={goalsEndDate}
									onChange={(date) => {
										noteActions.setNoteGoalsEndDate(noteDispatch, date as Date);
									}}
									KeyboardButtonProps={{
										"aria-label": "change date",
									}}
								/>
							</MuiPickersUtilsProvider>
						</Grid>
					</Grid>
				</Card>
			)}
		</Grid>
	);
}
