import { useEffect } from "react";
import {
	useClientDispatch,
	useClientState,
} from "../../../../../application/client";
import ClientActions from "../../../../../application/client/actions";
import { useInjection } from "../../../../../core/dependency_injection";

export default function useFindClientById(id: string) {
	const { clients, selectedClient } = useClientState();
	const clientDispatch = useClientDispatch();
	const clientActions = useInjection<ClientActions>("ClientActions");

	useEffect(() => {
		if (!selectedClient.id && clients) {
			const client = clients.find((item) => item.id === id);
			if (client) {
				clientActions.setSelectedClient(clientDispatch, client);
			}
		} // eslint-disable-next-line
	}, [clients, selectedClient]);
	return;
}
