import DateFnsUtils from "@date-io/date-fns";
import {
	Card,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Tooltip,
	Typography,
} from "@material-ui/core";
import { Add, Edit, List } from "@material-ui/icons";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import {
	useInputFormDispatch,
	useInputFormState,
} from "../../../../../../application/core/inputForm";
import InputFormActions from "../../../../../../application/core/inputForm/actions";
import { useGroupDispatch, useGroupState } from "../../../../../../application/group";
import GroupActions from "../../../../../../application/group/actions";
import { useNoteDispatch, useNoteState } from "../../../../../../application/note";
import NoteActions from "../../../../../../application/note/actions";
import { useInjection } from "../../../../../../core/dependency_injection";
import { GroupObject } from "../../../../../../domain/note/models/GroupTip";
import useStyles from "./styles";

export default function GroupPlanForm() {
	const classes = useStyles();

	const { groupTips, groupTipsCategories, userGroupTips, userGroupTipsCategories } = useNoteState();
	const noteDispatch = useNoteDispatch();
	const noteActions = useInjection<NoteActions>("NoteActions");

	const { groupPlan } = useGroupState();
	const groupDispatch = useGroupDispatch();
	const groupActions = useInjection<GroupActions>("GroupActions");

	const { showGroupTipCategory, showGroupTipTitle, showGroupTipIntervention } = useInputFormState();
	const inputFormDispatch = useInputFormDispatch();
	const inputFormActions = useInjection<InputFormActions>("InputFormActions");

	const { enqueueSnackbar } = useSnackbar();

	// const [groupPlan, setGroupPlan] = useState<GroupPlan>(GroupPlan.empty());

	useEffect(() => {
		if (groupTips.length !== 0) {
			groupPlan.groupTips.forEach((item, index) => {
				if (item.title !== "") {
					const groupTip =
						groupTips.find((groupTip) => groupTip.title === item.title) ??
						userGroupTips.find((groupTip) => groupTip.title === item.title);
					groupActions.setGroupPlanTip(groupDispatch, {
						index: index,
						title: item.title,
						interventions: groupTip?.interventions ?? [],
						clean: false,
						isNew: false,
					});
				}
			});
		}
		// eslint-disable-next-line
	}, [groupTips, groupPlan.groupTips]);

	useEffect(() => {
		noteActions.getGroupTips(noteDispatch, enqueueSnackbar); // eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (groupPlan.groupTips.length !== 0) {
			const initialShowInputValues = groupPlan.groupTips.map((_) => false);
			inputFormActions.initGroupTipState(inputFormDispatch, {
				showGroupTipCategory: [...initialShowInputValues],
				showGroupTipTitle: [...initialShowInputValues],
				showGroupTipIntervention: [...initialShowInputValues],
				showGroupTipParticipation: [...initialShowInputValues],
			});
		} // eslint-disable-next-line
	}, [groupPlan.groupTips]);

	return (
		<Grid container>
			<Grid item container justifyContent="center" lg={12} xs={12}>
				<Card className={classes.card}>
					<Grid item lg={6}>
						Date
					</Grid>
					<Grid item lg={6}>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<KeyboardDatePicker
								variant="inline"
								inputVariant="filled"
								className={classes.item}
								format="MM/dd/yyyy"
								// margin="normal"
								id="date-picker-inline"
								label="Select a Date"
								value={groupPlan.date}
								onChange={(date) => {
									groupActions.setGroupPlanDate(groupDispatch, date as Date);
									// setGroupPlan({ ...groupPlan, date: date as Date });
								}}
								KeyboardButtonProps={{
									"aria-label": "change date",
								}}
							/>
						</MuiPickersUtilsProvider>
					</Grid>
				</Card>
				{groupPlan.groupTips.map((item, index) => (
					<Card key={index} className={classes.card}>
						<Grid container justifyContent="flex-start" alignItems="center">
							<Grid item container lg={6} xs={12}>
								<Typography>Category:</Typography>
							</Grid>
							<Grid item container lg={5} xs={10}>
								{!showGroupTipCategory[index] ? (
									<FormControl variant="filled" fullWidth className={classes.formControl}>
										<InputLabel id={`demo-simple-select${index}`}>Select</InputLabel>
										<Select
											labelId={`demo-simple-select${index}`}
											id={`demo-simple-select${index}`}
											value={item.category}
											onChange={(e) => {
												const value = e.target.value as string;
												// const groupTip = groupTips.find((item) => item.title === value);
												groupActions.setGroupPlanTipCategory(groupDispatch, {
													index: index,
													value: value,
													clean: true,
													isNew: false,
												});
											}}
										>
											{/* <option value=""></option> */}
											<MenuItem className={classes.disabledMenuItem} disabled>Recent Categories:</MenuItem>
											{userGroupTipsCategories.length !== 0 ? (
												userGroupTipsCategories.map((item, index) => (
													<MenuItem key={index} value={item}>
														{item}
													</MenuItem>
												))
											) : (
												<MenuItem disabled>There are no recents Categories</MenuItem>
											)}
											<MenuItem className={classes.disabledMenuItem} disabled>Categories:</MenuItem>
											{groupTipsCategories.length !== 0 ? (
												groupTipsCategories.map((item, index) => (
													<MenuItem key={index} value={item}>
														{item}
													</MenuItem>
												))
											) : (
												<MenuItem value={item.category}>{item.category}</MenuItem>
											)}
										</Select>
									</FormControl>
								) : (
									<TextField
										className={classes.formControl}
										fullWidth
										variant="filled"
										label="Enter new category"
										multiline
										value={item.category}
										onChange={(e) => {
											const value = e.target.value as string;
											groupActions.setGroupPlanTipCategory(groupDispatch, {
												index: index,
												value: value,
												clean: false,
												isNew: true,
											});
										}}
									/>
								)}
							</Grid>
							<Grid item container justifyContent="center" alignItems="center" lg={1} xs={2}>
								<Tooltip
									title={
										!showGroupTipCategory[index]
											? item.category === ""
												? "Add new category"
												: "Edit category"
											: "Show all categories"
									}
								>
									<IconButton
										color="primary"
										onClick={() => {
											inputFormActions.setGroupTipCategoryInput(inputFormDispatch, {
												index: index,
											});
											if (!showGroupTipCategory[index]) {
												groupActions.setGroupPlanTipCategory(groupDispatch, {
													index: index,
													value: "",
													clean: true,
													isNew: false,
												});
											}
										}}
									>
										{!showGroupTipCategory[index] ? (
											item.category === "" ? (
												<Add />
											) : (
												<Edit />
											)
										) : (
											<List />
										)}
									</IconButton>
								</Tooltip>
							</Grid>
							<Grid item container lg={6} xs={12}>
								<Typography>Group Title:</Typography>
							</Grid>
							<Grid item container lg={5} xs={10}>
								{!showGroupTipTitle[index] ? (
									<FormControl variant="filled" fullWidth className={classes.formControl}>
										<InputLabel id={`demo-simple-select${index}`}>Select</InputLabel>
										<Select
											labelId={`demo-simple-select${index}`}
											id={`demo-simple-select${index}`}
											disabled={!item.category}
											value={item.title}
											onChange={(e) => {
												const value = e.target.value as string;
												const groupTip =
													groupTips.find((item) => item.title === value) ??
													userGroupTips.find((item) => item.title === value);
												groupActions.setGroupPlanTip(groupDispatch, {
													index: index,
													title: groupTip?.title ?? "",
													interventions: groupTip?.interventions ?? [],
													clean: true,
													isNew: false,
												});
											}}
										>
											<MenuItem className={classes.disabledMenuItem} disabled>Recent Titles:</MenuItem>
											{userGroupTips.filter((e) => e.category === item.category).length !== 0 ? (
												userGroupTips
													.filter((e) => e.category === item.category)
													.map((item, index) => (
														<MenuItem key={index} value={item.title}>
															{item.title}
														</MenuItem>
													))
											) : (
												<MenuItem disabled>
													There are no recent goals with selected category
												</MenuItem>
											)}
											<MenuItem className={classes.disabledMenuItem} disabled>Titles:</MenuItem>
											{groupTips.length !== 0 ? (
												groupTips
													.filter((e) => e.category === item.category)
													.map((item, index) => (
														<MenuItem key={index} value={item.title}>
															{item.title}
														</MenuItem>
													))
											) : (
												<MenuItem value={item.title}>{item.title}</MenuItem>
											)}
										</Select>
									</FormControl>
								) : (
									<TextField
										className={classes.formControl}
										fullWidth
										variant="filled"
										label="Enter new title"
										multiline
										value={item.title}
										onChange={(e) => {
											const value = e.target.value as string;
											groupActions.setGroupPlanTip(groupDispatch, {
												index: index,
												title: value,
												interventions: [],
												clean: false,
												isNew: true,
											});
										}}
									/>
								)}
							</Grid>
							<Grid item container justifyContent="center" alignItems="center" lg={1} xs={2}>
								<Tooltip
									title={
										!showGroupTipTitle[index]
											? item.title === ""
												? "Add new title"
												: "Edit title"
											: "Show all title"
									}
								>
									<IconButton
										color="primary"
										disabled={!item.category || showGroupTipCategory[index]}
										onClick={() => {
											inputFormActions.setGroupTipTitleInput(inputFormDispatch, {
												index: index,
											});
										}}
									>
										{!showGroupTipTitle[index] ? item.title === "" ? <Add /> : <Edit /> : <List />}
									</IconButton>
								</Tooltip>
							</Grid>
							<Grid item container lg={6} xs={12}>
								<Typography>Group facilitator intervention:</Typography>
							</Grid>
							<Grid item container lg={5} xs={10}>
								{!showGroupTipIntervention[index] ? (
									<FormControl variant="filled" fullWidth className={classes.formControl}>
										<InputLabel id={`demo-simple-select${index}`}>
											{item.interventions?.length === 0 ? "Select a title" : "Select"}
										</InputLabel>
										<Select
											labelId={`demo-simple-select${index}`}
											disabled={!item.title}
											id={`demo-simple-select${index}`}
											value={item.selectedIntervention?.label ?? ""}
											onChange={(e) => {
												const value = e.target.value as string;
												const intervention = item.interventions?.find(
													(item) => item.label === value
												);
												groupActions.setGroupPlanIntervention(groupDispatch, {
													index: index,
													value: intervention ?? new GroupObject({ label: "", value: "" }),
													isNew: false,
												});
											}}
										>
											{!!item.interventions && item.interventions?.length !== 0 ? (
												item.interventions?.map((item, index) => (
													<MenuItem key={index} value={item.label}>
														{item.label}
													</MenuItem>
												))
											) : (
												<MenuItem value={item.selectedIntervention?.label ?? ""}>
													{item.selectedIntervention?.label ?? ""}
												</MenuItem>
											)}
										</Select>
									</FormControl>
								) : (
									<TextField
										className={classes.formControl}
										fullWidth
										variant="filled"
										label="Enter new intervention"
										multiline
										value={item.selectedIntervention?.label}
										onChange={(e) => {
											const value = e.target.value as string;
											groupActions.setGroupPlanIntervention(groupDispatch, {
												index: index,
												value: new GroupObject({ label: value, value: value }),
												isNew: true,
											});
										}}
									/>
								)}
							</Grid>
							<Grid item container justifyContent="center" alignItems="center" lg={1} xs={2}>
								<Tooltip
									title={
										!showGroupTipIntervention[index]
											? item.title === ""
												? "Add new intervention"
												: "Edit intervention"
											: "Show all intervention"
									}
								>
									<IconButton
										color="primary"
										disabled={
											!item.title || showGroupTipCategory[index] || showGroupTipTitle[index]
										}
										onClick={() => {
											inputFormActions.setGroupTipInterventionInput(inputFormDispatch, {
												index: index,
											});
										}}
									>
										{!showGroupTipIntervention[index] ? (
											item.selectedIntervention?.label === "" ? (
												<Add />
											) : (
												<Edit />
											)
										) : (
											<List />
										)}
									</IconButton>
								</Tooltip>
							</Grid>
						</Grid>
					</Card>
				))}
			</Grid>
		</Grid>
	);
}
