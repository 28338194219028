import { Result } from "../../domain/core/Result";
import { IProductRepository } from "../../domain/product/iproduct.repository";
import Product from "../../domain/product/models/Product";
import { IProductRemoteDatasource } from "./datasources/remote";

export default class ProductRepository implements IProductRepository {
	private remoteDatasource: IProductRemoteDatasource;

	public constructor(remoteDatasource: IProductRemoteDatasource) {
		this.remoteDatasource = remoteDatasource;
	}

	async getProducts(): Promise<Result<Product[]>> {
		try {
			const dtos = await this.remoteDatasource.getProducts();
			return Result.rigth(dtos.map((item) => item.toDomain()));
		} catch (error: any) {
			console.log(`error`, error);
			return Result.left(error.message);
		}
	}
}
