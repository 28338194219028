import { ClientProvider } from "./client";
import { InputFormProvider } from "./core/inputForm";
import { ThemeProvider } from "./core/theme";
import { GroupProvider } from "./group";
import { NoteProvider } from "./note";
import { ProductProvider } from "./product";
import { UserProvider } from "./user";

export function ContextProvider({ children }: any) {
	return (
		<ThemeProvider>
			<ProductProvider>
				<UserProvider>{children}</UserProvider>
			</ProductProvider>
		</ThemeProvider>
	);
}

export function AppContextProvider({ children }: any) {
	return (
		<GroupProvider>
			<NoteProvider>
				<ClientProvider>
					<InputFormProvider>{children}</InputFormProvider>
				</ClientProvider>
			</NoteProvider>
		</GroupProvider>
	);
}
