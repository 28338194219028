import { Button, Typography, useTheme } from "@material-ui/core";
import { useEffect, useState } from "react";
import Price from "../../../../../../domain/product/models/Price";
import Product from "../../../../../../domain/product/models/Product";
import useStyles from "./styles";

export default function PlanItem(props: {
	product: Product;
	interval: string;
	onButtonClick: (price: Price) => void;
	buttonLabel: string;
}) {
	const { product, interval, onButtonClick, buttonLabel } = props;

	const classes = useStyles();
	const theme = useTheme();

	const [price, setPrice] = useState<Price>(Price.Empty());

	const getColor = () => {
		return product.name === "Standard"
			? "rgb(64,134,244)"
			: product.name === "Pro"
			? theme.palette.primary.main
			: theme.palette.secondary.main;
	};

	useEffect(() => {
		const currentPrice = product.prices?.find(
			(item) => item.interval === interval
		);
		setPrice(currentPrice ?? Price.Empty());
	}, [interval, product]);

	return (
		<div className={classes.root}>
			<div
				className={classes.banner}
				style={{
					backgroundColor: getColor(),
				}}
			/>
			<div className={classes.content}>
				<div className={classes.topContent}>
					<div className={classes.nameContainer}>
						{/* {product.name === "Pro" ? ( */}
						{/* // <PlanTooltip
							// 	open={true}
							// 	arrow
							// 	title="Most Popular"
							// 	placement="right"
							// >
							<> */}
						<Typography variant="h4" className={classes.name}>
							{product.name}
						</Typography>
						{product.name === "Pro" && (
							<div className={classes.tooltip}>Most Popular</div>
						)}
						{/* </> */}
						{/* ) : ( */}
						{/* // </PlanTooltip> */}
						{/* <Typography variant="h4" className={classes.name}>
								{product.name}
							</Typography> */}
						{/* )} */}
					</div>
					<div className={classes.priceContainer}>
						<Typography
							variant="h3"
							className={classes.price}
							style={{ color: getColor() }}
						>
							$
							{interval === "month"
								? (price?.unit_amount ?? 0) / 100
								: (price?.unit_amount ?? 0) / 100 / 12}
						</Typography>
						<div className={classes.interval}>
							<Typography>/month</Typography>
						</div>
					</div>
					<div className={classes.billed}>
						<Typography>
							Billed{" "}
							{interval === "month"
								? "monthly"
								: `annualy $${(price?.unit_amount ?? 0) / 100}`}
						</Typography>
					</div>
				</div>
				<div className={classes.divider} />
				<div className={classes.bottomContent}>
					<div className={classes.features}>
						<li>{product.clients}</li>
						<li>{product.groups}</li>
						<li>{product.generator}</li>
					</div>
					<div className={classes.buttonContainer}>
						<Button
							variant="outlined"
							color="primary"
							onClick={() => {
								onButtonClick(price);
							}}
						>
							{buttonLabel}
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
}
