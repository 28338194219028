import { ControlPoint, Note, People, Person } from "@material-ui/icons";
import { useUserDispatch, useUserState } from "../../../../application/user";
import DashCard from "./components/DashCard";
import { Grid } from "@material-ui/core";
import { EnhancedTable } from "../../../core/components/Table";
import {
	useClientDispatch,
	useClientState,
} from "../../../../application/client";
import ClientActions from "../../../../application/client/actions";
import { useInjection } from "../../../../core/dependency_injection";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useGroupDispatch, useGroupState } from "../../../../application/group";
import GroupActions from "../../../../application/group/actions";
import UserActions from "../../../../application/user/actions";
import { useHistory } from "react-router";
import { useNoteDispatch } from "../../../../application/note";
import NoteActions from "../../../../application/note/actions";
import Company from "../../../../domain/user/models/Company";
import Client from "../../../../domain/client/models/Client";
import { newDate, newNoteDate } from "../../../../core/helpers";
import SessionGroup from "../../../../domain/group/models/SessionGroup";

type DashboardClientNote = {
	id: string;
	name: string;
	date: Date;
	group: string;
};

export default function Dashboard() {
	document.title = "PSR Notes | Dashboard";

	const history = useHistory();

	const { user } = useUserState();
	const userDispatch = useUserDispatch();
	const userActions = useInjection<UserActions>("UserActions");

	const { clients } = useClientState();
	const clientDispatch = useClientDispatch();
	const clientActions = useInjection<ClientActions>("ClientActions");

	const { sessionGroups } = useGroupState();
	const groupDispatch = useGroupDispatch();
	const groupActions = useInjection<GroupActions>("GroupActions");

	const noteDispatch = useNoteDispatch();
	const noteActions = useInjection<NoteActions>("NoteActions");

	const { enqueueSnackbar } = useSnackbar();

	const [todayClients, setTodayClients] = useState<DashboardClientNote[]>([]);

	const columns = [
		{ id: "name", label: "Name", minWidth: 170, align: "left" },
		{
			id: "date",
			label: "Date",
			minWidth: 170,
			align: "center",
			format: (value: Date) => value?.toLocaleDateString("en-US"),
		},
		{
			id: "group",
			label: "Group",
			minWidth: 100,
			align: "left",
		},
		{
			label: "",
			minWidth: 10,
			align: "center",
			icon: <ControlPoint />,
			onButtonClick: (client: DashboardClientNote) => {
				const selectedClient =
					clients?.find((c) => c.id === client.id) ?? Client.empty();
				userActions.handleSubscriptionStatus(
					userDispatch,
					user,
					enqueueSnackbar,
					() => {
						clientActions.setSelectedClient(clientDispatch, selectedClient);
						noteActions.initNote(noteDispatch, {
							client: selectedClient,
							company: user.company ?? Company.empty(),
							date: new Date(
								client.date.getFullYear(),
								client.date.getMonth(),
								client.date.getDate(),
								8
							),
						});
						history.push(`/app/clients/${client.id}/progress-note/create`);
					}
				);
			},
		},
	];

	const groupColumns = [
		{ id: "name", label: "Name", minWidth: 170, align: "left" },
		{
			id: "clients",
			label: "Clients",
			minWidth: 170,
			align: "center",
			format: (value: any) => value.length,
		},
		{
			label: "",
			minWidth: 10,
			align: "center",
			icon: <ControlPoint />,
			onButtonClick: (group: SessionGroup) => {
				userActions.handleSubscriptionStatus(
					userDispatch,
					user,
					enqueueSnackbar,
					() => {
						groupActions.setGroup(groupDispatch, group);
						history.push(`/app/groups/${group.id}/group-plans/create`);
					}
				);
			},
		},
	];

	// const userDispatch = useUserDispatch();
	// const userActions = useInjection<UserActions>("UserActions");

	useEffect(() => {
		if (!clients) {
			clientActions.getClients(clientDispatch, enqueueSnackbar);
		} // eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (!sessionGroups) {
			groupActions.getAllSessionGroups(groupDispatch, enqueueSnackbar);
		} // eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (clients && sessionGroups) {
			var output: DashboardClientNote[] = [];
			sessionGroups.forEach((group) => {
				group.groupPlans.forEach((plan) => {
					group.clients.forEach((client) => {
						// const date = newDate();
						const hasNote = client.notes?.some(
							(note) =>
								note.startDate.toLocaleDateString() ===
								plan.date.toLocaleDateString()
						);
						if (!hasNote && plan.date.getTime() <= newDate().getTime()) {
							// const _clients = group.clients.filter(
							// 	(client) =>
							// 		!client.notes?.some(
							// 			(note) =>
							// 				note.startDate.toLocaleDateString() ===
							// 				date.toLocaleDateString()
							// 		)
							// );

							output = [
								...output,
								{
									id: client.id,
									name: client.name,
									date: plan.date,
									group: group.name,
								} as DashboardClientNote,
							];
						}
					});
				});
			});
			output.sort((a, b) => a.date.getTime() - b.date.getTime());
			setTodayClients(output);
		}
	}, [clients, sessionGroups]);

	return (
		<Grid container spacing={2}>
			<Grid
				item
				lg={3}
				md={6}
				xs={12}
				onClick={() => {
					history.push(`/app/profile`);
				}}
			>
				<DashCard title="Welcome" value={user.name} icon={Person} />
			</Grid>
			<Grid
				item
				lg={3}
				md={6}
				xs={12}
				onClick={() => {
					clientActions.setSelectedClient(clientDispatch, Client.empty());
					noteActions.initNote(noteDispatch, {
						client: Client.empty(),
						company: user.company ?? Company.empty(),
						date: newNoteDate(),
					});
					history.push(`/app/clients/new/progress-note/create`);
				}}
			>
				<DashCard title="Progress Note" value="Quick Note" icon={Note} />
			</Grid>
			<Grid
				item
				lg={3}
				md={6}
				xs={12}
				onClick={() => {
					history.push(`/app/clients`);
				}}
			>
				<DashCard
					title="Clients"
					value={clients ? clients.length.toString() : "-"}
					icon={People}
				/>
			</Grid>
			<Grid
				item
				lg={3}
				md={6}
				xs={12}
				onClick={() => {
					history.push(`/app/groups`);
				}}
			>
				<DashCard
					title="Groups"
					value={sessionGroups ? sessionGroups.length.toString() : "-"}
					icon={People}
				/>
			</Grid>
			<Grid item container lg={6} xs={12}>
				<EnhancedTable
					data={todayClients}
					dense={true}
					columns={columns}
					rowsPerPage={[5, 10, 50]}
					title="Pending notes"
					// onClickCallback={(client: DashboardClientNote) => {
					// 	const selectedClient =
					// 		clients?.find((c) => c.id === client.id) ?? Client.empty();
					// 	userActions.handleSubscriptionStatus(
					// 		userDispatch,
					// 		user,
					// 		enqueueSnackbar,
					// 		() => {
					// 			clientActions.setSelectedClient(clientDispatch, selectedClient);
					// 			noteActions.initNote(noteDispatch, {
					// 				client: selectedClient,
					// 				company: user.company ?? Company.empty(),
					// 				date: client.date,
					// 			});
					// 			history.push(`/app/clients/${client.id}/progress-note/create`);
					// 		}
					// 	);
					// }}
				/>
			</Grid>
			<Grid item container lg={6} xs={12}>
				<EnhancedTable
					data={sessionGroups ?? []}
					columns={groupColumns}
					dense={true}
					rowsPerPage={[5, 10, 50]}
					title="Add Group Titles"
					// onClickCallback={(group: SessionGroup) => {
					// 	userActions.handleSubscriptionStatus(
					// 		userDispatch,
					// 		user,
					// 		enqueueSnackbar,
					// 		() => {
					// 			groupActions.setGroup(groupDispatch, group);
					// 			history.push(`/app/groups/${group.id}/group-plans/create`);
					// 		}
					// 	);
					// }}
				/>
			</Grid>
		</Grid>
	);
}
