import React from "react";
import { DependencyContainer, InjectionToken } from "tsyringe";

const DependencyContext = React.createContext<{
	container: DependencyContainer | null;
}>({
	container: null,
});

type Props = {
	container: DependencyContainer;
};

export const DependencyProvider: React.FC<Props> = (props) => {
	return (
		<DependencyContext.Provider value={{ container: props.container }}>
			{props.children}
		</DependencyContext.Provider>
	);
};

export function useInjection<T>(dependency: InjectionToken<T>) {
	const { container } = React.useContext(DependencyContext);
	if (!container) {
		throw new Error();
	}
	return container.resolve<T>(dependency);
}
