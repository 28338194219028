import { SvgIconTypeMap } from "@material-ui/core";
import { AccountCircle, Group, Home, People } from "@material-ui/icons";
import Dashboard from "../pages/Dashboard";
import Clients from "../pages/Clients";
import Profile from "../pages/Profile";
import Groups from "../pages/Groups";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";

export type Route = {
	path: string;
	name: string;
	icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
	component: () => JSX.Element;
	layout: string;
	onSidebar: boolean;
	onNavbar: boolean;
};

const routes: Route[] = [
	{
		path: "/dashboard",
		name: "Dashboard",
		icon: Home,
		component: Dashboard,
		layout: "/app",
		onSidebar: true,
		onNavbar: false,
	},
	{
		path: "/clients",
		name: "Clients",
		icon: People,
		component: Clients,
		layout: "/app",
		onSidebar: true,
		onNavbar: false,
	},
	{
		path: "/groups",
		name: "Groups",
		icon: Group,
		component: Groups,
		layout: "/app",
		onSidebar: true,
		onNavbar: false,
	},
	{
		path: "/profile",
		name: "Profile",
		icon: AccountCircle,
		component: Profile,
		layout: "/app",
		onSidebar: false,
		onNavbar: true,
	},
	// {
	//   path: "/settings",
	//   name: "Settings",
	//   icon: Settings,
	//   component: SettingsPage,
	//   layout: "/app",
	//   onSidebar: false,
	//   onNavbar: true,
	// },
];
export default routes;
// export const contractorRoutes = [
//   {
//     path: "/dashboard",
//     name: "Dashboard",
//     icon: Home,
//     component: Dashboard,
//     layout: "/app",
//     onSidebar: true,
//     onNavbar: false,
//   },
//   {
//     path: "/profile",
//     name: "Profile",
//     icon: AccountCircle,
//     component: Profile,
//     layout: "/app",
//     onNavbar: true,
//     onSidebar: true,
//   },
//   {
//     path: "/settings",
//     name: "Settings",
//     icon: Settings,
//     component: SettingsPage,
//     layout: "/app",
//     onSidebar: false,
//     onNavbar: true,
//   },
// ];
