import { Add, People } from "@material-ui/icons";
import useOpenable from "../../../../../core/hooks/useOpenable";
import WrappedModal from "../../../../../core/components/Modal";
import EditClientModalContent from "../../components/EditClientModalContent";
import PanelHeader, {
	PanelHeaderAction,
} from "../../../../components/PanelHeader";
import { EnhancedTable } from "../../../../../core/components/Table";
import ClientActions from "../../../../../../application/client/actions";
import { useInjection } from "../../../../../../core/dependency_injection";
import {
	useClientDispatch,
	useClientState,
} from "../../../../../../application/client";
import { useSnackbar } from "notistack";
import Client from "../../../../../../domain/client/models/Client";
import { useHistory } from "react-router";
import {
	useUserDispatch,
	useUserState,
} from "../../../../../../application/user";
import UserActions from "../../../../../../application/user/actions";

export default function ClientsPage() {
	const history = useHistory();

	const { user } = useUserState();
	const userDispatch = useUserDispatch();
	const userActions = useInjection<UserActions>("UserActions");

	const { clients } = useClientState();
	const clientDispatch = useClientDispatch();
	const clientActions = useInjection<ClientActions>("ClientActions");

	const [open, handleOpen, handleClose] = useOpenable();

	const { enqueueSnackbar } = useSnackbar();

	// console.log(`contractors`, contractors);

	const columns = [
		{ id: "name", label: "Name", minWidth: 170, align: "left" },
		{
			id: "diagnosis",
			label: "Diagnosis",
			minWidth: 100,
			align: "left",
		},
		{
			id: "serviceCode",
			label: "Service Code",
			minWidth: 100,
			align: "left",
		},
		{ id: "case", label: "Case #", minWidth: 100, align: "left" },
		{ id: "setting", label: "Setting", minWidth: 100, align: "left" },
	];

	const panelActions: PanelHeaderAction[] = [
		{
			label: "Add...",
			icon: Add,
			onClick: () => {
				userActions.handleSubscriptionStatus(
					userDispatch,
					user,
					enqueueSnackbar,
					() => {
						handleOpen();
					}
				);
			},
		},
	];

	// console.log(`clients`, clients);

	return (
		<>
			<PanelHeader title="Clients" icon={<People />} actions={panelActions} />
			{!clients || clients.length === 0 ? (
				<div>No registered clients</div>
			) : (
				<EnhancedTable
					data={clients}
					columns={columns}
					onClickCallback={(client: any) => {
						userActions.handleSubscriptionStatus(
							userDispatch,
							user,
							enqueueSnackbar,
							() => {
								clientActions.setSelectedClient(clientDispatch, client);
								history.push(`/app/clients/${client.id}`);
							}
						);
					}}
				/>
			)}
			<WrappedModal open={open} handleClose={handleClose}>
				<EditClientModalContent
					onSuccessCallback={(client: Client) => {
						userActions.handleSubscriptionStatus(
							userDispatch,
							user,
							enqueueSnackbar,
							() => {
								if (!!clients && user.canAddClient(clients.length)) {
									clientActions.create(
										clientDispatch,
										client,
										enqueueSnackbar,
										handleClose
									);
								} else {
									handleClose();
									userActions.setShowPlans(userDispatch, true);
									enqueueSnackbar(
										"You reached the limit of clients. Upgrade your subscrition in order to add more clients",
										{
											variant: "error",
										}
									);
								}
							}
						);
					}}
				/>
			</WrappedModal>
		</>
		// ) : (
		//   <PanelCircularProgress />
	);
}
