/*eslint-disable*/
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";

// core components
// import CustomDropdown from "../CustomDropdown/CustomDropdown.js";

import useStyles from "./styles";
import { Button, useMediaQuery, Theme } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import Scroll from "react-scroll";

const ScrollLink = Scroll.Link;

export default function HeaderLinks(props: any) {
	const classes = useStyles();
	const theme = useTheme<Theme>();

	const onMobile = useMediaQuery(theme.breakpoints?.down("sm"));

	return (
		<List className={classes.list}>
			{/* <ListItem className={classes.listItem}>
				<ScrollLink
					to="home-section"
					spy={true}
					smooth={true}
					duration={500}
					offset={-100}
					className={classes.navLink}
					activeClass={classes.navLinkActive}
				>
					Home
				</ScrollLink>
			</ListItem> */}
			{/* <ListItem className={classes.listItem}>
				<ScrollLink
					to="pricing-section"
					spy={true}
					smooth={true}
					offset={-100}
					duration={500}
					className={classes.navLink}
					activeClass={classes.navLinkActive}
				>
					Pricing
				</ScrollLink>
			</ListItem> */}
			{/* <ListItem className={classes.listItem}>
				<Link to="" className={classes.navLink}>
					Contact Us
				</Link>
			</ListItem> */}
			<ListItem className={classes.listItem}>
				<Link to="/auth/sign-in" className={classes.navLink}>
					Sign In
				</Link>
			</ListItem>
			<ListItem className={classes.listItem}>
				{onMobile ? (
					<Link to="/auth/sign-up" className={classes.navLink}>
						Get Started
					</Link>
				) : (
					<Button
						variant="contained"
						component={Link}
						color="secondary"
						to="/auth/sign-up"
					>
						Get Started
					</Button>
				)}
			</ListItem>
		</List>
	);
}
