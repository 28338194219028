import { makeStyles } from "@material-ui/core";
import { disabledMenuItem } from "../../../../../core/constants";
export default makeStyles((theme) => ({
	card: {
		minHeight: "100px",
		width: "100%",
		padding: "10px 30px",
		marginBottom: "10px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	item: {
		width: "300px",
		[theme.breakpoints.down("sm")]: {
			width: "100%",
		},
	},
	formControl: {
		margin: `${theme.spacing(1)}px 0px`,
		minWidth: 120,
		// maxWidth: 300,
	},
	disabledMenuItem: {
		...disabledMenuItem,
	},
	// chips: {
	//   display: "flex",
	//   flexWrap: "wrap",
	// },
	// chip: {
	//   margin: 2,
	// },
	// input: {
	//   minWidth: "300px",
	//   [theme.breakpoints.down("sm")]: {
	//     width: "100%",
	//   },
	// },
}));
