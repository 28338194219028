import { makeStyles } from "@material-ui/core";
import { card } from "../../../../../core/constants";
export default makeStyles((theme) => ({
	card: {
		...card,
	},
	formControl: {
		minWidth: 300,
	},
	chips: {
		display: "flex",
		flexWrap: "wrap",
	},
	chip: {
		margin: 2,
	},
	input: {
		minWidth: "300px",
		[theme.breakpoints.down("sm")]: {
			width: "100%",
		},
	},
}));
