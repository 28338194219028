import {
  Typography,
  Hidden,
  Breadcrumbs,
  useMediaQuery,
  useTheme,
  SvgIconTypeMap,
} from "@material-ui/core";
import useStyles from "./styles";
import MobileMenu from "./components/MobileMenu";
import WrappedButton from "../../../core/components/Button";
import { Link } from "react-router-dom";
import { ButtonCircularProgress } from "../../../core/components/Progress";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";

export type PanelHeaderAction = {
  color?: string;
  onClick: () => void;
  label: string;
  isLoading?: boolean;
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
};

export type PanelHeaderBreadcrumbsRoute = {
  label: string;
  icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  to?: string;
};

export type PanelHeaderProps = {
  title?: string;
  icon?: any;
  breadcrumbsRoutes?: PanelHeaderBreadcrumbsRoute[];
  actions?: PanelHeaderAction[];
};

export default function PanelHeader(props: PanelHeaderProps) {
  const { title, icon, breadcrumbsRoutes, actions } = props;
  const theme = useTheme();
  const onMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const classes = useStyles();

  // console.log(`breadCrumbsRoutes`, breadcrumbsRoutes);

  return (
    <div className={classes.root}>
      {breadcrumbsRoutes ? (
        <Breadcrumbs maxItems={onMobile ? 2 : undefined} aria-label="breadcrumb">
          {breadcrumbsRoutes.map((item: any, index: any) => {
            return item.to ? (
              <Link
                key={index}
                color="inherit"
                to={item.to}
                // onClick={handleClick}
                className={classes.link}
              >
                {/* {item.icon && <item.icon className={classes.icon} />} */}
                {item.icon && (
                  <span className={classes.icon}>
                    <item.icon />
                  </span>
                )}
                {item.label}
              </Link>
            ) : (
              <Typography key={index} color="textPrimary" className={classes.text}>
                {/* {item.icon && <item.icon className={classes.icon} />} */}
                {item.icon && (
                  <span className={classes.icon}>
                    <item.icon />
                  </span>
                )}
                {item.label}
              </Typography>
            );
          })}
        </Breadcrumbs>
      ) : (
        <Typography color="textPrimary" className={classes.text}>
          {/* {item.icon && <item.icon className={classes.icon} />} */}
          {icon && <span className={classes.icon}>{icon}</span>}
          {title}
        </Typography>
      )}
      {actions && (
        <>
          <Hidden smDown>
            <div>
              {actions.map((item: any, index: number) => (
                <WrappedButton
                  key={index}
                  size="small"
                  color={item.color}
                  disabled={item.isLoading}
                  startIcon={<item.icon />}
                  onClick={item.onClick}
                  style={{ marginLeft: "10px" }}
                >
                  {item.label}
                  {item.isLoading && <ButtonCircularProgress />}
                </WrappedButton>
              ))}
            </div>
          </Hidden>
          <Hidden mdUp>
            <MobileMenu actions={actions} />
          </Hidden>
        </>
      )}
    </div>
  );
}

// PanelHeader.propTypes = {
//   title: PropTypes.string,
//   icon: PropTypes.any,
//   breadcrumbsRoutes: PropTypes.arrayOf(
//     PropTypes.shape({
//       icon: PropTypes.any,
//       label: PropTypes.string,
//       to: PropTypes.string,
//     })
//   ),
//   actions: PropTypes.arrayOf(
//     PropTypes.shape({
//       label: PropTypes.string,
//       icon: PropTypes.any,
//       onClick: PropTypes.func,
//     })
//   ),
// };
