import { Delete, Edit, Group } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { useHistory, useParams } from "react-router";
import { useGroupDispatch, useGroupState } from "../../../../../../../application/group";
import GroupActions from "../../../../../../../application/group/actions";
import { useInjection } from "../../../../../../../core/dependency_injection";
import { ConfirmationDialog } from "../../../../../../core/components/Dialog";
import { RouteParams } from "../../../../../../core/constants";
import useOpenable from "../../../../../../core/hooks/useOpenable";
import PanelHeader, {
	PanelHeaderAction,
	PanelHeaderBreadcrumbsRoute,
} from "../../../../../components/PanelHeader";
import GroupForm from "../../../components/GroupForm";
import useFindGroupById from "../../../hooks/useFindGroupById";

export default function GroupDetails() {
	const history = useHistory();
	const { id } = useParams<RouteParams>();

	const { group, isSubmiting, isDeleting } = useGroupState();
	const groupDispatch = useGroupDispatch();
	const groupActions = useInjection<GroupActions>("GroupActions");

	const [open, handleOpen, handleClose] = useOpenable();

	const { enqueueSnackbar } = useSnackbar();

	useFindGroupById(id);

	// React.useEffect(() => {
	//   if (group.name === "") {
	//     const currentGroup = sessionGroups.find((item) => item.id === id);
	//     groupActions.setGroup(
	//       groupDispatch,
	//       currentGroup ?? SessionGroup.empty()
	//     );
	//   } // eslint-disable-next-line
	// }, [group, sessionGroups]);

	const breadcrumbsRoutes: PanelHeaderBreadcrumbsRoute[] = [
		{
			label: "Groups",
			icon: Group,
			to: "/app/groups",
		},
		{
			label: `${group.name}`,
			// icon: People,
		},
	];

	const panelActions: PanelHeaderAction[] = [
		{
			label: "Update",
			isLoading: isSubmiting,
			icon: Edit,
			onClick: () => {
				groupActions.updateGroup(groupDispatch, group, history, enqueueSnackbar);
			},
		},
		{
			label: "Delete...",
			isLoading: isDeleting,
			color: "danger",
			icon: Delete,
			onClick: () => {
				handleOpen();
			},
		},
	];

	return (
		<div>
			<PanelHeader breadcrumbsRoutes={breadcrumbsRoutes} actions={panelActions} />
			<GroupForm />
			<ConfirmationDialog
				open={open}
				handleClose={handleClose}
				title="Delete this group?"
				content="Are you sure do you want to delete this group."
				onSuccessCallback={async () => {
					handleClose();
					groupActions.deleteGroup(groupDispatch, group.id ?? "", history, enqueueSnackbar);
				}}
			/>
		</div>
	);
}
