import Goal from "../../domain/note/models/Goal";
import GroupTip, { GroupObject } from "../../domain/note/models/GroupTip";
import Objective from "../../domain/note/models/Objective";
import Treatment from "../../domain/note/models/Treatment";

export const CUSTOMERS_COLLECTION = "customers";
export const PRODUCTS_COLLECTION = "products";
export const PRICES_COLLECTION = "prices";
export const CLIENTS_COLLECTION = "clients";
export const GOALS_COLLECTION = "goals";
export const TREATMENTS_COLLECTION = "treatments";
export const NOTES_COLLECTION = "notes";
export const GROUPS_COLLECTION = "groups";
export const GROUPSTIPS_COLLECTION = "groupsTips";
export const GROUP_PLANS_COLLECTION = "groupPlans";
export const GOAL_PLANS_COLLECTION = "goalPlans";

export const goals: Goal[] = [
	new Goal({
		name: "goal 1",
		category: "Category 1",
		objectives: [
			new Objective({
				label: "Objective 1.1",
				value: "This is objective 1.1",
				progress: [
					{
						value: "This is update of progress Objective 1.1 a",
						label: "Update of progress Objective 1.1 a",
					},
					{
						value: "This is update of progress Objective 1.1 b",
						label: "Update of progress Objective 1.1 b",
					},
					{
						value: "This is update of progress Objective 1.1 c",
						label: "Update of progress Objective 1.1 c",
					},
				],
			}),
			new Objective({
				label: "Objective 1.2",
				value: "This is objective 1.2",
				progress: [
					{
						value: "This is update of progress Objective 1.2 a",
						label: "Update of progress Objective 1.2 a",
					},
					{
						value: "This is update of progress Objective 1.2 b",
						label: "Update of progress Objective 1.2 b",
					},
					{
						value: "This is update of progress Objective 1.2 c",
						label: "Update of progress Objective 1.2 c",
					},
				],
			}),
			new Objective({
				label: "Objective 1.3",
				value: "This is objective 1.3",
				progress: [
					{
						value: "This is update of progress Objective 1.3 a",
						label: "Update of progress Objective 1.3 a",
					},
					{
						value: "This is update of progress Objective 1.3 b",
						label: "Update of progress Objective 1.3 b",
					},
					{
						value: "This is update of progress Objective 1.3 c",
						label: "Update of progress Objective 1.3 c",
					},
				],
			}),
		],
	}),
	new Goal({
		name: "goal 2",
		category: "Category 2",
		objectives: [
			new Objective({
				label: "Objective 2.1",
				value: "This is objective 2.1",
				progress: [
					{
						value: "This is update of progress Objective 2.1 a",
						label: "Update of progress Objective 2.1 a",
					},
					{
						value: "This is update of progress Objective 2.1 b",
						label: "Update of progress Objective 2.1 b",
					},
					{
						value: "This is update of progress Objective 2.1 c",
						label: "Update of progress Objective 2.1 c",
					},
				],
			}),
			new Objective({
				label: "Objective 2.2",
				value: "This is objective 2.2",
				progress: [
					{
						value: "This is update of progress Objective 2.2 a",
						label: "Update of progress Objective 2.2 a",
					},
					{
						value: "This is update of progress Objective 2.2 b",
						label: "Update of progress Objective 2.2 b",
					},
					{
						value: "This is update of progress Objective 2.2 c",
						label: "Update of progress Objective 2.2 c",
					},
				],
			}),
			new Objective({
				label: "Objective 2.3",
				value: "This is objective 2.3",
				progress: [
					{
						value: "This is update of progress Objective 2.3 a",
						label: "Update of progress Objective 2.3 a",
					},
					{
						value: "This is update of progress Objective 2.3 b",
						label: "Update of progress Objective 2.3 b",
					},
					{
						value: "This is update of progress Objective 2.3 c",
						label: "Update of progress Objective 2.3 c",
					},
				],
			}),
		],
	}),
	new Goal({
		name: "goal 3",
		category: "Category 1",
		objectives: [
			new Objective({
				label: "Objective 3.1",
				value: "This is objective 3.1",
				progress: [
					{
						value: "This is update of progress Objective 3.1 a",
						label: "Update of progress Objective 3.1 a",
					},
					{
						value: "This is update of progress Objective 3.1 b",
						label: "Update of progress Objective 3.1 b",
					},
					{
						value: "This is update of progress Objective 3.1 c",
						label: "Update of progress Objective 3.1 c",
					},
				],
			}),
			new Objective({
				label: "Objective 3.2",
				value: "This is objective 3.2",
				progress: [
					{
						value: "This is update of progress Objective 3.2 a",
						label: "Update of progress Objective 3.2 a",
					},
					{
						value: "This is update of progress Objective 3.2 b",
						label: "Update of progress Objective 3.2 b",
					},
					{
						value: "This is update of progress Objective 3.2 c",
						label: "Update of progress Objective 3.2 c",
					},
				],
			}),
			new Objective({
				label: "Objective 3.3",
				value: "This is objective 3.3",
				progress: [
					{
						value: "This is update of progress Objective 3.3 a",
						label: "Update of progress Objective 3.3 a",
					},
					{
						value: "This is update of progress Objective 3.3 b",
						label: "Update of progress Objective 3.3 b",
					},
					{
						value: "This is update of progress Objective 3.3 c",
						label: "Update of progress Objective 3.3 c",
					},
				],
			}),
		],
	}),
];

export const treatments: Treatment[] = [
	new Treatment({
		question: "Attitude/Cooperation/Motivation",
		answers: ["The client was motivated."],
		order: 1,
	}),
	new Treatment({
		question: "Concentration/Focus",
		answers: ["The client was focused."],
		order: 2,
	}),
	new Treatment({
		question: "Ability to Follow Rules/Directions",
		answers: ["The client followed the rules."],
		order: 3,
	}),
	new Treatment({
		question: "Peer Interaction",
		answers: ["The client interacted with the group."],
		order: 4,
	}),
];

export const groupTips: GroupTip[] = [
	new GroupTip({
		category: "Category 1",
		title: "Group title 1",
		interventions: [
			new GroupObject({
				label: "Group intervention 1.1",
				value: "This is group intervention 1.1",
			}),
			new GroupObject({
				label: "Group intervention 1.2",
				value: "This is group intervention 1.2",
			}),
		],
		participations: [
			"The client gave tips how to manage time properly.",
			"The client shared some problems solving tips.",
			"The client spoke in the topic.",
		],
	}),
	new GroupTip({
		category: "Category 1",
		title: "Group title 2",
		interventions: [
			new GroupObject({
				label: "Group intervention 2.1",
				value: "This is group intervention 2.1",
			}),
			new GroupObject({
				label: "Group intervention 2.2",
				value: "This is group intervention 2.2",
			}),
		],
		participations: [
			"The client gave tips how to manage time properly.",
			"The client shared some problems solving tips.",
			"The client spoke in the topic.",
		],
	}),
	new GroupTip({
		category: "Category 2",
		title: "Group title 3",
		interventions: [
			new GroupObject({
				label: "Group intervention 3.1",
				value: "This is group intervention 3.1",
			}),
			new GroupObject({
				label: "Group intervention 3.2",
				value: "This is group intervention 3.2",
			}),
		],
		participations: [
			"The client gave tips how to manage time properly.",
			"The client shared some problems solving tips.",
			"The client spoke in the topic.",
		],
	}),
];
