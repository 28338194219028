import { Progress } from "../../../domain/note/models/Objective";
import UpdateOfProgress from "../../../domain/note/models/UpdateOfProgress";

export interface IUpdateOfProgressDtoProps {
  goal: number;
  objective: number;
  progress: Progress;
}

export class UpdateOfProgressDto {
  goal: number;
  objective: number;
  progress: Progress;

  constructor(props: IUpdateOfProgressDtoProps) {
    this.goal = props.goal;
    this.objective = props.objective;
    this.progress = props.progress;
  }

  public static fromDomain(model: UpdateOfProgress): UpdateOfProgressDto {
    return new UpdateOfProgressDto({
      goal: model.goal,
      objective: model.objective,
      progress: model.progress,
    });
  }

  // public static fromFirestore(
  //   doc: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
  // ): UpdateOfProgressDto {
  //   const data = doc.data();
  //   return new UpdateOfProgressDto({
  //     id: doc.id,
  //     value: data?.value,
  //     label: data?.label,
  //   });
  // }

  public static fromJson(item: any): UpdateOfProgressDto {
    return new UpdateOfProgressDto({
      goal: item?.goal,
      objective: item?.objective,
      progress: item?.progress,
    });
  }

  public toJson(): any {
    return {
      goal: this.goal,
      objective: this.objective,
      progress: this.progress,
    };
  }

  public toDomain(): UpdateOfProgress {
    return new UpdateOfProgress({
      goal: this.goal,
      objective: this.objective,
      progress: this.progress,
    });
  }
}
