import { makeStyles } from "@material-ui/core";
import {
	drawerWidth,
	boxShadow,
	transition,
} from "../../../../presentation/core/constants";

export default makeStyles((theme) => ({
	drawerPaper: {
		border: "none",
		position: "fixed",
		// backgroundColor: "#22273d",
		backgroundColor: theme.palette.secondary.main,
		// backgroundColor:
		//   theme.palette.type === "dark" ? theme.palette.background.paper : theme.palette.secondary.main,
		top: "0",
		bottom: "0",
		left: "0",
		zIndex: 1,
		...boxShadow,
		width: drawerWidth,
		[theme.breakpoints.up("md")]: {
			width: drawerWidth,
			position: "fixed",
			height: "100%",
		},
		[theme.breakpoints.down("sm")]: {
			width: drawerWidth,
			...boxShadow,
			position: "fixed",
			display: "block",
			top: "0",
			height: "100vh",
			right: "0",
			left: "auto",
			zIndex: 1032,
			visibility: "visible",
			overflowY: "visible",
			borderTop: "none",
			textAlign: "left",
			paddingRight: "0px",
			paddingLeft: "0",
			transform: `translate3d(${drawerWidth}px, 0, 0)`,
			...transition,
		},
	},
	list: {
		marginTop: "20px",
		paddingLeft: "0",
		paddingTop: "0",
		paddingBottom: "0",
		marginBottom: "0",
		listStyle: "none",
		position: "unset",
		display: "flex",
		flexDirection: "column",
	},
	item: {
		position: "relative",
		display: "block",
		textDecoration: "none",
		color: "#fff",
	},
	itemLink: {
		width: "auto",
		transition: "all 300ms linear",
		position: "relative",
		display: "block",
		padding: "10px 15px",
	},
	itemIcon: {
		width: "24px",
		height: "30px",
		fontSize: "24px",
		lineHeight: "30px",
		float: "left",
		marginRight: "15px",
		textAlign: "center",
		verticalAlign: "middle",
	},
	itemText: {
		margin: "0",
		lineHeight: "30px",
		fontSize: "15px",
	},
	activeColor: {
		color: theme.palette.primary.main,
	},
	sidebarWrapper: {
		position: "relative",
		height: "calc(100vh - 75px)",
		overflow: "auto",
		width: "260px",
		zIndex: 4,
		overflowScrolling: "touch",
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
	},
	brand: {
		display: "flex",
		position: "relative",
		width: "100%",
		padding: "15px 15px",
		maxHeight: "65px",
		...boxShadow,
		zIndex: 4,
	},
	logo: {
		display: "flex",
		// justifyContent: "center",
		alignItems: "center",
		// maxHeight: "90px",
	},
	bottomContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		padding: "10px",
		marginBottom: "30px",
		borderTop: `1px solid ${theme.palette.grey[700]}`,
		borderBottom: `1px solid ${theme.palette.grey[700]}`,
	},
}));
