import {
	ADD_CLIENT,
	ADD_GOAL_PLAN,
	ADD_NOTE,
	ClientDispatch,
	DELETE_CLIENT,
	DELETE_GOAL_PLAN,
	DELETE_NOTE,
	SetGoalPlanCategoryPayload,
	SetGoalPlanNamePayload,
	SetGoalPlanObjectivePayload,
	SET_CLIENTS,
	SET_CREATING,
	SET_DELETE_NOTE,
	SET_DELETING,
	SET_FETCHING,
	SET_GOAL_PLAN,
	SET_GOAL_PLAN_CATEGORY,
	SET_GOAL_PLAN_END_DATE,
	SET_GOAL_PLAN_NAME,
	SET_GOAL_PLAN_OBJECTIVE,
	SET_GOAL_PLAN_START_DATE,
	SET_SAVING_NOTE,
	SET_SELECTED_CLIENT,
	UPDATE_CLIENT,
	UPDATE_GOAL_PLAN,
	UPDATE_NOTE,
} from "./misc";
import Client from "../../domain/client/models/Client";
import { EnqueueSnackbar } from "../../domain/core/utils";
import { IClientRepository } from "../../domain/client/iclient.repository";
import Note from "../../domain/note/models/Note";
import { INoteRepository } from "../../domain/note/inote.repository";
import GoalPlan from "../../domain/client/models/GoalPlan";
import { DELETE_GROUP_CLIENT, GroupDispatch, SET_GROUPS } from "../group/misc";
import { newDate } from "../../core/helpers";

export default class ClientActions {
	private clientRepository: IClientRepository;
	private noteRepository: INoteRepository;

	constructor(
		clientRepository: IClientRepository,
		noteRepository: INoteRepository
	) {
		this.clientRepository = clientRepository;
		this.noteRepository = noteRepository;
	}

	async saveNote(
		dispatch: ClientDispatch,
		groupDispatch: GroupDispatch,
		note: Note,
		isGoalsNew: boolean,
		goalsEndDate: Date,
		history: any,
		enqueueSnackbar: EnqueueSnackbar
	) {
		if (note.isValid()) {
			dispatch({ type: SET_SAVING_NOTE, payload: true });
			if (!note.client.id) {
				const clientResult = await this.clientRepository.create(note.client);
				if (clientResult.isLeft) {
					enqueueSnackbar(clientResult.getError(), { variant: "error" });
					dispatch({ type: SET_SAVING_NOTE, payload: false });
					return;
				}
				enqueueSnackbar("Client created successfully", { variant: "success" });
				const client = clientResult.getValue() as Client;
				dispatch({ type: ADD_CLIENT, payload: client });
				note.client.id = client.id;
				if (isGoalsNew && client.id) {
					const goalPlan = new GoalPlan({
						goals: note.goals,
						startDate: newDate(),
						endDate: goalsEndDate,
					});
					if (goalPlan.isValid()) {
						const result = await this.clientRepository.createGoalPlan(
							client.id,
							goalPlan
						);
						if (result.isLeft) {
							enqueueSnackbar(result.getError(), { variant: "error" });
							dispatch({ type: SET_SAVING_NOTE, payload: false });
							return;
						}
						const value: GoalPlan = result.getValue();
						// console.log(`value`, value);
						dispatch({
							type: ADD_GOAL_PLAN,
							payload: { goalPlan: value, clientId: client.id },
						});
						enqueueSnackbar("Treatment plan added successfully", {
							variant: "success",
						});
					}
				}
			}
			const createNoteResult = await this.noteRepository.create(note);
			if (createNoteResult.isLeft) {
				enqueueSnackbar(createNoteResult.getError(), { variant: "error" });
				dispatch({ type: SET_SAVING_NOTE, payload: false });
				return;
			}
			dispatch({ type: ADD_NOTE, payload: createNoteResult.getValue() });
			groupDispatch({ type: SET_GROUPS, payload: undefined });
			enqueueSnackbar("Note saved successfully", { variant: "success" });
			dispatch({ type: SET_SAVING_NOTE, payload: false });
			history.goBack();
		} else {
			enqueueSnackbar("The note is invalid", { variant: "error" });
		}
	}

	async updateNote(
		dispatch: ClientDispatch,
		groupDispatch: GroupDispatch,
		note: Note,
		history: any,
		enqueueSnackbar: EnqueueSnackbar
	) {
		if (note.isValid()) {
			dispatch({ type: SET_SAVING_NOTE, payload: true });
			const result = await this.noteRepository.update(note);
			if (result.isLeft) {
				enqueueSnackbar(result.getError(), { variant: "error" });
				dispatch({ type: SET_SAVING_NOTE, payload: false });
				return;
			}
			dispatch({ type: UPDATE_NOTE, payload: note });
			groupDispatch({ type: SET_GROUPS, payload: undefined });
			enqueueSnackbar("Note updated successfully", { variant: "success" });
			dispatch({ type: SET_SAVING_NOTE, payload: false });
			history.goBack();
		} else {
			enqueueSnackbar("The note is invalid", { variant: "error" });
		}
	}

	async deleteNote(
		dispatch: ClientDispatch,
		groupDispatch: GroupDispatch,
		clientId: string,
		noteId: string,
		history: any,
		enqueueSnackbar: EnqueueSnackbar
	) {
		dispatch({ type: SET_DELETE_NOTE, payload: true });
		const result = await this.noteRepository.delete(clientId, noteId);
		if (result.isLeft) {
			enqueueSnackbar(result.getError(), { variant: "error" });
			dispatch({ type: SET_DELETE_NOTE, payload: false });
			return;
		}
		dispatch({ type: DELETE_NOTE, payload: { clientId, noteId } });
		groupDispatch({ type: SET_GROUPS, payload: undefined });
		enqueueSnackbar("Note deleted successfully", { variant: "success" });
		dispatch({ type: SET_DELETE_NOTE, payload: false });
		history.goBack();
	}

	async getClients(dispatch: ClientDispatch, enqueueSnackbar: EnqueueSnackbar) {
		dispatch({ type: SET_FETCHING, payload: true });
		const result = await this.clientRepository.getClients();
		if (result.isLeft) {
			enqueueSnackbar(result.getError(), { variant: "error" });
			dispatch({ type: SET_FETCHING, payload: false });
			return;
		}
		dispatch({ type: SET_CLIENTS, payload: result.getValue() });
		dispatch({ type: SET_FETCHING, payload: false });
	}

	public async createGoalPlan(
		dispatch: ClientDispatch,
		clientId: string,
		goalPlan: GoalPlan,
		history: any,
		enqueueSnackbar: EnqueueSnackbar
	) {
		if (goalPlan.isValid()) {
			dispatch({ type: SET_CREATING, payload: true });
			const result = await this.clientRepository.createGoalPlan(
				clientId,
				goalPlan
			);
			// console.log(`result`, result);
			if (result.isLeft) {
				enqueueSnackbar(result.getError(), { variant: "error" });
				dispatch({ type: SET_CREATING, payload: false });
				return;
			}
			const value: GoalPlan = result.getValue();
			// console.log(`value`, value);
			dispatch({
				type: ADD_GOAL_PLAN,
				payload: { goalPlan: value, clientId: clientId },
			});
			dispatch({ type: SET_CREATING, payload: false });
			enqueueSnackbar("Treatment plan added successfully", {
				variant: "success",
			});
			history.goBack();
		} else {
			enqueueSnackbar("Form invalid", { variant: "error" });
		}
	}

	public async updateGoalPlan(
		dispatch: ClientDispatch,
		clientId: string,
		goalPlan: GoalPlan,
		history: any,
		enqueueSnackbar: EnqueueSnackbar
	) {
		if (goalPlan.isValid()) {
			dispatch({ type: SET_CREATING, payload: true });
			const result = await this.clientRepository.updateGoalPlan(
				clientId,
				goalPlan
			);
			// console.log(`result`, result);
			if (result.isLeft) {
				enqueueSnackbar(result.getError(), { variant: "error" });
				dispatch({ type: SET_CREATING, payload: false });
				return;
			}
			// console.log(`value`, value);
			dispatch({ type: UPDATE_GOAL_PLAN, payload: goalPlan });
			dispatch({ type: SET_CREATING, payload: false });
			enqueueSnackbar("Treatment plan edited successfully", {
				variant: "success",
			});
			history.goBack();
		} else {
			enqueueSnackbar("Form invalid", { variant: "error" });
		}
	}
	public async deleteGoalPlan(
		dispatch: ClientDispatch,
		clientId: string,
		goalPlanId: string,
		history: any,
		enqueueSnackbar: EnqueueSnackbar
	) {
		dispatch({ type: SET_DELETING, payload: true });
		const result = await this.clientRepository.deleteGoalPlan(
			clientId,
			goalPlanId
		);
		// console.log(`result`, result);
		if (result.isLeft) {
			enqueueSnackbar(result.getError(), { variant: "error" });
			dispatch({ type: SET_DELETING, payload: false });
			return;
		}
		// console.log(`value`, value);
		dispatch({ type: DELETE_GOAL_PLAN, payload: goalPlanId });
		dispatch({ type: SET_DELETING, payload: false });
		enqueueSnackbar("Treatment plan edited successfully", {
			variant: "success",
		});
		history.goBack();
	}

	public async create(
		dispatch: ClientDispatch,
		client: Client,
		enqueueSnackbar: EnqueueSnackbar,
		handleClose: any
	) {
		dispatch({ type: SET_CREATING, payload: true });
		const result = await this.clientRepository.create(client);
		// console.log(`result`, result);
		if (result.isLeft) {
			enqueueSnackbar(result.getError(), { variant: "error" });
			dispatch({ type: SET_CREATING, payload: false });
			return;
		}
		const value: Client = result.getValue();
		// console.log(`value`, value);
		dispatch({ type: ADD_CLIENT, payload: value });
		dispatch({ type: SET_CREATING, payload: false });
		enqueueSnackbar("Client added successfully", { variant: "success" });
		handleClose();
	}

	public async update(
		dispatch: ClientDispatch,
		client: Client,
		enqueueSnackbar: EnqueueSnackbar,
		handleClose: any
	) {
		dispatch({ type: SET_CREATING, payload: true });
		const result = await this.clientRepository.update(client);
		// console.log(`result`, result);
		if (result.isLeft) {
			enqueueSnackbar(result.getError(), { variant: "error" });
			dispatch({ type: SET_CREATING, payload: false });
			return;
		}
		// const value: Client = result.getValue();
		// console.log(`value`, value);
		dispatch({ type: UPDATE_CLIENT, payload: client });
		dispatch({ type: SET_CREATING, payload: false });
		enqueueSnackbar("Client updated successfully", { variant: "success" });
		handleClose();
	}

	public async delete(
		dispatch: ClientDispatch,
		groupDispatch: GroupDispatch,
		id: string,
		enqueueSnackbar: EnqueueSnackbar,
		history: any
		// handleClose: any
	) {
		dispatch({ type: SET_DELETING, payload: true });
		const result = await this.clientRepository.delete(id);
		// console.log(`result`, result);
		if (result.isLeft) {
			enqueueSnackbar(result.getError(), { variant: "error" });
			dispatch({ type: SET_DELETING, payload: false });
			return;
		}
		// const value: Client = result.getValue();
		// console.log(`value`, value);
		dispatch({ type: DELETE_CLIENT, payload: id });
		groupDispatch({ type: DELETE_GROUP_CLIENT, payload: id });
		dispatch({ type: SET_DELETING, payload: false });
		enqueueSnackbar("Client deleted successfully", { variant: "success" });
		history.goBack();
	}

	setSelectedClient(dispatch: ClientDispatch, client: Client) {
		dispatch({ type: SET_SELECTED_CLIENT, payload: client });
	}

	setGoalPlan(dispatch: ClientDispatch, payload: GoalPlan) {
		dispatch({ type: SET_GOAL_PLAN, payload: payload });
	}

	setGoalPlanStartDate(dispatch: ClientDispatch, payload: Date) {
		dispatch({ type: SET_GOAL_PLAN_START_DATE, payload: payload });
	}

	setGoalPlanEndDate(dispatch: ClientDispatch, payload: Date) {
		dispatch({ type: SET_GOAL_PLAN_END_DATE, payload: payload });
	}

	setGoalPlanName(dispatch: ClientDispatch, payload: SetGoalPlanNamePayload) {
		dispatch({ type: SET_GOAL_PLAN_NAME, payload: payload });
	}

	setGoalPlanCategory(
		dispatch: ClientDispatch,
		payload: SetGoalPlanCategoryPayload
	) {
		dispatch({ type: SET_GOAL_PLAN_CATEGORY, payload: payload });
	}

	setGoalPlanObjectives(
		dispatch: ClientDispatch,
		payload: SetGoalPlanObjectivePayload
	) {
		dispatch({ type: SET_GOAL_PLAN_OBJECTIVE, payload: payload });
	}
}
