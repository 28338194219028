import DateFnsUtils from "@date-io/date-fns";
import {
	Card,
	CircularProgress,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Tooltip,
	Typography,
} from "@material-ui/core";
import { Add, Edit, List } from "@material-ui/icons";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useSnackbar } from "notistack";
import { Fragment, useEffect, useState } from "react";
import { useClientDispatch, useClientState } from "../../../../../../../../application/client";
import ClientActions from "../../../../../../../../application/client/actions";
import {
	useInputFormDispatch,
	useInputFormState,
} from "../../../../../../../../application/core/inputForm";

import InputFormActions from "../../../../../../../../application/core/inputForm/actions";
import { useNoteDispatch, useNoteState } from "../../../../../../../../application/note";
import NoteActions from "../../../../../../../../application/note/actions";
import { useInjection } from "../../../../../../../../core/dependency_injection";
import Objective from "../../../../../../../../domain/note/models/Objective";
import { PanelCircularProgress } from "../../../../../../../core/components/Progress";
import ItemView from "../../../../../../components/ItemView";
import useStyles from "./styles";

export default function GoalPlanForm() {
	const classes = useStyles();

	const [selectedObjectives, setSelectedObjectives] = useState<Objective[][]>([]);

	const { goalPlan } = useClientState();
	const clientDispatch = useClientDispatch();
	const clientActions = useInjection<ClientActions>("ClientActions");

	const { goals, goalsCategories, userGoals, userGoalsCategories, isFetching } = useNoteState();
	const noteDispatch = useNoteDispatch();
	const noteActions = useInjection<NoteActions>("NoteActions");

	const { showCategoryInput, showGoalInput, showObjectivesInput } = useInputFormState();
	const inputFormDispatch = useInputFormDispatch();
	const inputFormActions = useInjection<InputFormActions>("InputFormActions");

	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		// if (goals.length === 0) {
		noteActions.getGoals(noteDispatch, enqueueSnackbar);
		// }
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (goals.length !== 0) {
			goalPlan.goals.forEach((item, index) => {
				var selectedGoal = goals.find((goal) => goal.name === item.name);
				if (!selectedGoal) {
					selectedGoal = userGoals.find((goal) => goal.name === item.name);
				}
				const tempSelectedObjectives = selectedObjectives;
				tempSelectedObjectives[index] = selectedGoal?.objectives ?? [];
				setSelectedObjectives([...tempSelectedObjectives]);
			});
		} // eslint-disable-next-line
	}, [goals, goalPlan.goals]);

	useEffect(() => {
		if (goalPlan.goals.length !== 0) {
			const initialShowInputValues = goalPlan.goals.map((_) => false);
			inputFormActions.initGoalsState(inputFormDispatch, {
				showCategoryInput: [...initialShowInputValues],
				showGoalInput: [...initialShowInputValues],
				showObjectivesInput: goalPlan.goals.map((item) => item.objectives.map((_) => false)),
			});
		} // eslint-disable-next-line
	}, [goalPlan.goals]);

	return !isFetching ? (
		<Grid container>
			<MuiPickersUtilsProvider utils={DateFnsUtils}>
				<ItemView title="Start Date">
					<KeyboardDatePicker
						variant="inline"
						inputVariant="filled"
						className={classes.item}
						format="MM/dd/yyyy"
						// margin="normal"
						id="date-picker-inline"
						label="Select a Date"
						value={goalPlan.startDate}
						onChange={(date) => {
							clientActions.setGoalPlanStartDate(clientDispatch, date as Date);
							// setGroupPlan({ ...groupPlan, date: date as Date });
						}}
						KeyboardButtonProps={{
							"aria-label": "change date",
						}}
					/>
				</ItemView>
				<ItemView title="End Date">
					<KeyboardDatePicker
						variant="inline"
						inputVariant="filled"
						className={classes.item}
						format="MM/dd/yyyy"
						// margin="normal"
						id="date-picker-inline"
						label="Select a Date"
						value={goalPlan.endDate}
						onChange={(date) => {
							clientActions.setGoalPlanEndDate(clientDispatch, date as Date);
							// groupActions.setGroupPlanDate(groupDispatch, date as Date);
							// setGroupPlan({ ...groupPlan, date: date as Date });
						}}
						KeyboardButtonProps={{
							"aria-label": "change date",
						}}
					/>
				</ItemView>
			</MuiPickersUtilsProvider>
			<Grid item container>
				{goalPlan.goals.map((item, index) => (
					<Card key={index} className={classes.card}>
						{showCategoryInput.length !== 0 &&
						showGoalInput.length !== 0 &&
						showObjectivesInput.length !== 0 ? (
							<Grid item container alignItems="center">
								<Grid item container lg={12} xs={12}>
									<Grid item container alignItems="center" lg={6} xs={12}>
										<Typography>Category:</Typography>
									</Grid>
									<Grid item lg={5} xs={10}>
										{!showCategoryInput[index] ? (
											<FormControl variant="filled" fullWidth className={classes.formControl}>
												<InputLabel id={`demo-simple-select${index}`}>Select</InputLabel>
												<Select
													labelId={`demo-simple-select${index}`}
													id={`demo-simple-select${index}`}
													value={item.category}
													onChange={(e) => {
														clientActions.setGoalPlanCategory(clientDispatch, {
															value: e.target.value as string,
															index: index,
															isNew: false,
														});
													}}
												>
													<MenuItem className={classes.disabledMenuItem} disabled>Recent Categories:</MenuItem>
													{userGoalsCategories.length !== 0 ? (
														userGoalsCategories.map((item, index) => (
															<MenuItem key={index} value={item}>
																{item}
															</MenuItem>
														))
													) : (
														<MenuItem disabled>There are no recent categories</MenuItem>
													)}
													<MenuItem className={classes.disabledMenuItem} disabled>Categories</MenuItem>
													{goalsCategories.length !== 0 ? (
														goalsCategories.map((item: any, index: number) => (
															<MenuItem key={index} value={item}>
																{item}
															</MenuItem>
														))
													) : (
														<MenuItem value={item.category}>{item.category}</MenuItem>
													)}
												</Select>
											</FormControl>
										) : (
											<TextField
												className={classes.formControl}
												fullWidth
												variant="filled"
												label="Enter new category"
												multiline
												value={item.category}
												onChange={(e) => {
													const value = e.target.value as string;
													clientActions.setGoalPlanCategory(clientDispatch, {
														value: value,
														index: index,
														isNew: true,
													});
												}}
											/>
										)}
									</Grid>
									<Grid item container justifyContent="center" alignItems="center" lg={1} xs={2}>
										<Tooltip
											title={
												!showGoalInput[index]
													? item.category === ""
														? "Add new category"
														: "Edit category"
													: "Show all categories"
											}
										>
											<IconButton
												color="primary"
												onClick={() => {
													inputFormActions.setCategoryInput(inputFormDispatch, { index: index });
												}}
											>
												{!showGoalInput[index] ? (
													item.category === "" ? (
														<Add />
													) : (
														<Edit />
													)
												) : (
													<List />
												)}
											</IconButton>
										</Tooltip>
									</Grid>
								</Grid>
								<Grid item container lg={12} xs={12}>
									<Grid item container alignItems="center" lg={6} xs={12}>
										<Typography>Goal {index + 1}:</Typography>
									</Grid>
									<Grid item lg={5} xs={10}>
										{!showGoalInput[index] ? (
											<FormControl variant="filled" fullWidth className={classes.formControl}>
												<InputLabel id={`demo-simple-select${index}`}>
													{!item.category ? "Select a category" : "Select"}
												</InputLabel>
												<Select
													labelId={`demo-simple-select${index}`}
													disabled={!item.category}
													id={`demo-simple-select${index}`}
													value={item.name}
													onChange={(e) => {
														const goalName = e.target.value as string;
														const selectedGoal =
															goals.find((goal) => goal.name === goalName) ??
															userGoals.find((goal) => goal.name === goalName);
														clientActions.setGoalPlanName(clientDispatch, {
															name: selectedGoal?.name ?? "",
															index: index,
															isNew: false,
														});
														var tempObjectives = selectedObjectives;
														tempObjectives[index] = selectedGoal?.objectives ?? [];
														setSelectedObjectives([...tempObjectives]);
													}}
												>
													<MenuItem className={classes.disabledMenuItem} disabled>Recent Goals:</MenuItem>
													{userGoals.filter((e) => e.category === item.category).length !== 0 ? (
														userGoals
															.filter((e) => e.category === item.category)
															.map((item, index) => (
																<MenuItem key={index} value={item.name}>
																	{item.name}
																</MenuItem>
															))
													) : (
														<MenuItem disabled>
															There are no recent goals with selected category
														</MenuItem>
													)}
													<MenuItem className={classes.disabledMenuItem} disabled>Goals:</MenuItem>
													{goals.length !== 0 &&
													goals.filter((e) => e.category === item.category).length !== 0 ? (
														goals
															.filter((e) => e.category === item.category)
															.map((item: any, index: number) => (
																<MenuItem key={index} value={item.name}>
																	{item.name}
																</MenuItem>
															))
													) : (
														<MenuItem disabled>There are no goals with selected category</MenuItem>
													)}
												</Select>
											</FormControl>
										) : (
											<TextField
												className={classes.formControl}
												fullWidth
												disabled={!item.category}
												multiline
												variant="filled"
												label="Enter new goal"
												value={item.name}
												onChange={(e) => {
													const value = e.target.value as string;
													clientActions.setGoalPlanName(clientDispatch, {
														name: value,
														index: index,
														isNew: true,
													});
												}}
											/>
										)}
									</Grid>
									<Grid item container justifyContent="center" alignItems="center" lg={1} xs={2}>
										<Tooltip
											title={
												!showGoalInput[index]
													? item.name === ""
														? "Add new goal"
														: "Edit goal"
													: "Show all goals"
											}
										>
											<IconButton
												color="primary"
												disabled={!item.category || showCategoryInput[index]}
												onClick={() => {
													inputFormActions.setGoalInput(inputFormDispatch, { index: index });
												}}
											>
												{!showGoalInput[index] ? item.name === "" ? <Add /> : <Edit /> : <List />}
											</IconButton>
										</Tooltip>
									</Grid>
								</Grid>
								<Grid item container lg={12} xs={12}>
									{item.objectives.map((obj: Objective, i) => (
										<Fragment key={i}>
											<Grid item container alignItems="center" lg={6} xs={12}>
												<Typography>
													Objective {index + 1}
													{String.fromCharCode(65 + i)}:
												</Typography>
											</Grid>
											<Grid item lg={5} xs={10}>
												{!showObjectivesInput[index][i] ? (
													<FormControl variant="filled" fullWidth className={classes.formControl}>
														<InputLabel id="demo-simple-select-label">
															{!item.name ? "Select a goal" : "Select"}
														</InputLabel>
														<Select
															labelId="demo-simple-select-label"
															id="demo-simple-select"
															disabled={!item.name}
															value={obj.label}
															onChange={(e) => {
																const objectiveLabel = e.target.value as string;
																const selectedObejctive =
																	selectedObjectives[index]?.find(
																		(item) => item.label === objectiveLabel
																	) ?? Objective.empty();
																selectedObejctive.isNew = false;
																clientActions.setGoalPlanObjectives(clientDispatch, {
																	goalIndex: index,
																	objectiveIndex: i,
																	objective: selectedObejctive,
																});
															}}
														>
															{selectedObjectives.length !== 0 ? (
																selectedObjectives[index]?.map((item: any, index: number) => (
																	<MenuItem key={index} value={item.label}>
																		{item.label}
																	</MenuItem>
																))
															) : (
																<MenuItem value={obj.label}>{obj.label}</MenuItem>
															)}
														</Select>
													</FormControl>
												) : (
													<TextField
														className={classes.formControl}
														fullWidth
														multiline
														variant="filled"
														disabled={!item.category || !item.name}
														label="Enter new objective"
														value={obj.label}
														onChange={(e) => {
															const value = e.target.value as string;
															clientActions.setGoalPlanObjectives(clientDispatch, {
																goalIndex: index,
																objectiveIndex: i,
																objective: new Objective({
																	label: value,
																	value: value,
																	isNew: true,
																}),
															});
														}}
													/>
												)}
											</Grid>
											<Grid
												item
												container
												justifyContent="center"
												alignItems="center"
												lg={1}
												xs={2}
											>
												<Tooltip
													title={
														!showObjectivesInput[index][i]
															? obj.label === ""
																? "Add new objective"
																: "Edit objective"
															: "Show all objective"
													}
												>
													<IconButton
														color="primary"
														disabled={!item.category || !item.name || showGoalInput[index]}
														onClick={() => {
															inputFormActions.setObjectivesInput(inputFormDispatch, {
																i: index,
																j: i,
															});
															if (!showObjectivesInput[index][i]) {
																clientActions.setGoalPlanObjectives(clientDispatch, {
																	goalIndex: index,
																	objectiveIndex: i,
																	objective: new Objective({ label: "", value: "" }),
																});
																// noteActions.setNoteGoalObjective(noteDispatch, {
																// 	goalIndex: index,
																// 	objectiveIndex: i,
																// 	objective: new Objective({ label: "", value: "" }),
																// 	isNew: false,
																// });
															}
														}}
													>
														{!showObjectivesInput[index][i] ? (
															obj.label === "" ? (
																<Add />
															) : (
																<Edit />
															)
														) : (
															<List />
														)}
													</IconButton>
												</Tooltip>
											</Grid>
										</Fragment>
									))}
								</Grid>
								{/* <ObjectivesContent objectives={objectives[index]} index={index} /> */}
							</Grid>
						) : (
							<CircularProgress />
						)}
					</Card>
				))}
			</Grid>
		</Grid>
	) : (
		<PanelCircularProgress />
	);
}
