import { makeStyles } from "@material-ui/core";
export default makeStyles((theme) => ({
	root: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		height: "100%",
		width: "100%",
	},
	card: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		// alignItems: "center",
		padding: "20px",
		height: "75%",
		width: "60%",
		[theme.breakpoints.down("sm")]: {
			width: "90%",
		},
	},
	cardHeader: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
}));
