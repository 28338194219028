import {
	ProductAction,
	ProductState,
	SET_FETCHING,
	SET_PRODUCTS,
} from "./misc";

export default function reducer(state: ProductState, action: ProductAction) {
	switch (action.type) {
		case SET_FETCHING:
			return { ...state, isFetchingProducts: action.payload } as ProductState;
		case SET_PRODUCTS:
			return { ...state, products: action.payload } as ProductState;
		default: {
			throw new Error(`Unhandled action type: ${action}`);
		}
	}
}
