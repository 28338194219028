import { useEffect, useState } from "react";
import {
	BrowserRouter as Router,
	Redirect,
	Route,
	Switch,
} from "react-router-dom";
import { useUserDispatch } from "../application/user";
import UserActions from "../application/user/actions";
import { useInjection } from "../core/dependency_injection";
import Firebase from "../infrastructure/core/firebase";
import App from "./App";
import Auth from "./Auth";
import { MainCircularProgress } from "./core/components/Progress";
import { PrivateRoute, PublicRoute } from "./core/components/Routes";
import ErrorPage from "./Error";
import HomePage from "./Home";
import Privacy from "./Privacy";
import Terms from "./Terms";

function Root() {
	const [firebaseInitailized, setFirebaseInitailized] = useState(false);

	const userDispatch = useUserDispatch();
	const userActions = useInjection<UserActions>("UserActions");

	useEffect(() => {
		const firebase = Firebase.GetInstance();
		firebase.auth.onAuthStateChanged(async function (user) {
			if (user) {
				await userActions.checkAuthenticated(userDispatch);
			}
			setFirebaseInitailized(true);
		}); // eslint-disable-next-line
	}, []);

	return firebaseInitailized ? (
		<Router>
			<Switch>
				{/* <Route exact path="/" render={() => <Redirect to="/app/dashboard" />} />*/}
				<Route
					exact
					path="/app"
					render={() => <Redirect to="/app/dashboard" />}
				/>
				<PublicRoute exact path="/" component={HomePage} />
				<PrivateRoute path="/app" component={App} />
				<Route path="/auth" component={Auth} />
				<Route path="/terms" component={Terms} />
				<Route path="/privacy" component={Privacy} />
				<Route path="*" component={ErrorPage} />
			</Switch>
		</Router>
	) : (
		<MainCircularProgress />
	);
}

export default Root;
