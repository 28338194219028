// import tinycolor from "tinycolor2";

import { ThemeOptions } from "@material-ui/core";
import { primary, secondary } from "./misc";

// const warning = warningColor[0];
// const success = successColor[0];
// const info = infoColor[0];

// const lightenRate = 7.5;
// const darkenRate = 15;

const defaultTheme: ThemeOptions = {
	palette: {
		// type: "dark",
		primary: {
			main: primary,
			// light: tinycolor(primary).lighten(lightenRate).toHexString(),
			// dark: tinycolor(primary).darken(darkenRate).toHexString(),
			contrastText: "#FFFFFF",
		},
		secondary: {
			main: secondary,
			// light: tinycolor(secondary).lighten(lightenRate).toHexString(),
			// dark: tinycolor(secondary).darken(darkenRate).toHexString(),
			// contrastText: "#FFFFFF",
		},
		// warning: {
		//   main: warning,
		//   light: tinycolor(warning).lighten(lightenRate).toHexString(),
		//   dark: tinycolor(warning).darken(darkenRate).toHexString(),
		// },
		// success: {
		//   main: success,
		//   light: tinycolor(success).lighten(lightenRate).toHexString(),
		//   dark: tinycolor(success).darken(darkenRate).toHexString(),
		// },
		// info: {
		//   main: info,
		//   light: tinycolor(info).lighten(lightenRate).toHexString(),
		//   dark: tinycolor(info).darken(darkenRate).toHexString(),
		// },
		// text: {
		//   primary: "#4A4A4A",
		//   secondary: "#6E6E6E",
		//   hint: "#B9B9B9",
		// },
		// background: {
		//   default: "#F0ffFF",
		//   paper: "#FFFFFF",
		// },
	},
};

export default defaultTheme;
