import { makeStyles } from "@material-ui/core";
import { boxShadow, drawerWidth, transition } from "../../../core/constants";

export default makeStyles((theme) => ({
	appBar: {
		display: "flex",
		border: "0",
		borderRadius: "3px",
		padding: "0.625rem 0",
		marginBottom: "20px",
		color: "#555",
		width: "100%",
		backgroundColor: "#fff",
		boxShadow:
			"0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)",
		transition: "all 150ms ease 0s",
		alignItems: "center",
		flexFlow: "row nowrap",
		justifyContent: "flex-start",
		position: "relative",
		zIndex: 100,
	},
	absolute: {
		position: "absolute",
		zIndex: 1100,
	},
	fixed: {
		position: "fixed",
		zIndex: 1100,
	},
	container: {
		// ...landingContainer,
		padding: "0 5vw",
		minHeight: "50px",
		flex: "1",
		alignItems: "center",
		justifyContent: "space-between",
		display: "flex",
		flexWrap: "nowrap",
	},
	flex: {
		flex: 1,
	},
	brandContent: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	title: {
		lineHeight: "30px",
		fontSize: "18px",
		borderRadius: "3px",
		textTransform: "none",
		color: "inherit",
		padding: "8px 16px",
		letterSpacing: "unset",
		"&:hover,&:focus": {
			color: "inherit",
			background: "transparent",
		},
	},
	appResponsive: {
		margin: "20px 10px",
	},
	primary: {
		backgroundColor: theme.palette.primary.main,
		color: "#FFFFFF",
		boxShadow:
			"0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(156, 39, 176, 0.46)",
	},
	info: {
		backgroundColor: theme.palette.info.main,
		color: "#FFFFFF",
		boxShadow:
			"0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(0, 188, 212, 0.46)",
	},
	success: {
		backgroundColor: theme.palette.success.main,
		color: "#FFFFFF",
		boxShadow:
			"0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(76, 175, 80, 0.46)",
	},
	warning: {
		backgroundColor: theme.palette.warning.main,
		color: "#FFFFFF",
		boxShadow:
			"0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(255, 152, 0, 0.46)",
	},
	danger: {
		backgroundColor: theme.palette.error.main,
		color: "#FFFFFF",
		boxShadow:
			"0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(244, 67, 54, 0.46)",
	},
	transparent: {
		backgroundColor: "transparent !important",
		boxShadow: "none",
		paddingTop: "25px",
		color: "#FFFFFF",
	},
	dark: {
		color: "#FFFFFF",
		backgroundColor: "#212121 !important",
		boxShadow:
			"0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(33, 33, 33, 0.46)",
	},
	white: {
		border: "0",
		padding: "0.625rem 0",
		marginBottom: "20px",
		color: "#555",
		backgroundColor: "#fff !important",
		boxShadow:
			"0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)",
	},
	drawerPaper: {
		border: "none",
		bottom: "0",
		transitionProperty: "top, bottom, width",
		transitionDuration: ".2s, .2s, .35s",
		transitionTimingFunction: "linear, linear, ease",
		width: drawerWidth,
		...boxShadow,
		position: "fixed",
		display: "block",
		top: "0",
		height: "100vh",
		right: "0",
		left: "auto",
		visibility: "visible",
		overflowY: "visible",
		borderTop: "none",
		textAlign: "left",
		paddingRight: "0px",
		paddingLeft: "0",
		...transition,
	},
	// HeaderLinks Styles
	list: {
		fontSize: "14px",
		margin: 0,
		paddingLeft: "0",
		listStyle: "none",
		paddingTop: "0",
		paddingBottom: "0",
		display: "flex",
		alignItems: "center",
		flexDirection: "row",
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column",
		},
		// color: "inherit",
	},
	listItem: {
		float: "left",
		// color: "inherit",
		position: "relative",
		display: "block",
		width: "auto",
		marginLeft: "10px",
		padding: "0",
		[theme.breakpoints.down("sm")]: {
			width: "100%",
			"&:after": {
				width: "calc(100% - 30px)",
				content: '""',
				display: "block",
				height: "1px",
				marginLeft: "15px",
				backgroundColor: "#e5e5e5",
			},
		},
	},
	listItemText: {
		padding: "0 !important",
	},
	navLink: {
		color: "black",
		position: "relative",
		// padding: "0.9375rem",
		fontWeight: "bold",
		fontSize: "16px",
		// textTransform: "uppercase",
		borderRadius: "3px",
		lineHeight: "20px",
		textDecoration: "none",
		margin: "0px 20px",
		cursor: "pointer",
		display: "inline-flex",
		"&:before": {
			content: '""',
			position: "absolute",
			//   textDecoration: "none",
			width: "80%",
			height: "2px",
			bottom: "0",
			left: "7%",
			background: theme.palette.primary.main,
			//   visibility: "hidden",
			borderRadius: "5px",
			transform: "scaleX(0)",
			transition: ".25s linear",
		},
		"&:hover": {
			"&:before": {
				visibility: "visible",
				transform: "scaleX(1)",
				[theme.breakpoints.down("sm")]: {
					visibility: "hidden",
					transform: "scaleX(0)",
				},
			},
		},
		// "&:active": {
		//   color: theme.palette.primary.main,
		// },
		[theme.breakpoints.down("sm")]: {
			width: "calc(100% - 30px)",
			marginLeft: "15px",
			marginBottom: "8px",
			marginTop: "8px",
			textAlign: "left",
			"& > span:first-child": {
				justifyContent: "flex-start",
			},
		},
	},
	// notificationNavLink: {
	// 	// color: "inherit",
	// 	padding: "0.9375rem",
	// 	// fontWeight: "400",
	// 	fontSize: "12px",
	// 	textTransform: "uppercase",
	// 	lineHeight: "20px",
	// 	textDecoration: "none",
	// 	margin: "0px",
	// 	display: "inline-flex",
	// 	top: "4px",
	// },
	// registerNavLink: {
	// 	top: "3px",
	// 	position: "relative",
	// 	// fontWeight: "400",
	// 	fontSize: "12px",
	// 	textTransform: "uppercase",
	// 	lineHeight: "20px",
	// 	textDecoration: "none",
	// 	margin: "0px",
	// 	display: "inline-flex",
	// },
	navLinkActive: {
		color: theme.palette.primary.main,
		// backgroundColor: "rgba(255, 255, 255, 0.1)",
		// borderBottom: "1px solid grey",
		"&:before": {
			content: '""',
			position: "absolute",
			//   textDecoration: "none",
			width: "80%",
			height: "2px",
			bottom: "0",
			left: "7%",
			background: theme.palette.primary.main,
			//   visibility: "hidden",
			borderRadius: "5px",
			transform: "scaleX(1)",
			transition: ".25s linear",
			[theme.breakpoints.down("sm")]: {
				visibility: "hidden",
				transform: "scaleX(0)",
			},
		},
	},
	// icons: {
	// 	width: "20px",
	// 	height: "20px",
	// 	marginRight: "3px",
	// },
	// socialIcons: {
	// 	position: "relative",
	// 	fontSize: "20px !important",
	// 	marginRight: "4px",
	// },
	// dropdownLink: {
	// 	"&,&:hover,&:focus": {
	// 		color: "inherit",
	// 		textDecoration: "none",
	// 		display: "block",
	// 		padding: "10px 20px",
	// 	},
	// },
	// ...tooltip,
	// marginRight5: {
	// 	marginRight: "5px",
	// },
}));
