import { newDate } from "../../../core/helpers";
import Goal from "../../note/models/Goal";

type GoalPlanProps = {
	id?: string;
	startDate: Date;
	endDate: Date;
	goals: Goal[];
};

export default class GoalPlan {
	id?: string;
	startDate: Date;
	endDate: Date;
	goals: Goal[];

	constructor(props: GoalPlanProps) {
		this.id = props.id;
		this.startDate = props.startDate;
		this.endDate = props.endDate;
		this.goals = props.goals;
	}

	public static empty(): GoalPlan {
		return new GoalPlan({
			startDate: newDate(),
			endDate: newDate(),
			goals: [Goal.empty(), Goal.empty(), Goal.empty()],
		});
	}

	public isValid(): boolean {
		return this.goals.every((item) => item.isValid());
	}

	public getNewGoals(): Goal[] {
		return this.goals.filter((item) => item.checkIsNew());
	}
}
