import { Grid } from "@material-ui/core";
import { Delete, Edit, People } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import {
	useClientDispatch,
	useClientState,
} from "../../../../../../../../application/client";
import ClientActions from "../../../../../../../../application/client/actions";
import { useGroupDispatch } from "../../../../../../../../application/group";
import {
	useNoteDispatch,
	useNoteState,
} from "../../../../../../../../application/note";
import NoteActions from "../../../../../../../../application/note/actions";
import { useInjection } from "../../../../../../../../core/dependency_injection";
import WrappedButton from "../../../../../../../core/components/Button";
import { ConfirmationDialog } from "../../../../../../../core/components/Dialog";
import { ButtonCircularProgress } from "../../../../../../../core/components/Progress";
import useOpenable from "../../../../../../../core/hooks/useOpenable";
import PanelHeader, {
	PanelHeaderAction,
	PanelHeaderBreadcrumbsRoute,
} from "../../../../../../components/PanelHeader";
import NoteForm from "../../components/NoteForm";

export default function NoteEdit() {
	const history = useHistory();

	const { note, isFetching, isFetchingClientInfo } = useNoteState();

	const { isSaving, isDeleting } = useClientState();
	const clientDispatch = useClientDispatch();
	const clientActions = useInjection<ClientActions>("ClientActions");

	const noteDispatch = useNoteDispatch();
	const noteActions = useInjection<NoteActions>("NoteActions");

	const groupDispatch = useGroupDispatch();

	const [open, handleOpen, handleClose] = useOpenable();

	const { enqueueSnackbar } = useSnackbar();

	const onUpdate = async () => {
		if (note.isValid()) {
			noteActions.setUserGoals(
				noteDispatch,
				note.getNewGoals(),
				enqueueSnackbar
			);
			noteActions.setUserTreatments(
				noteDispatch,
				note.getNewTreatments(),
				enqueueSnackbar
			);
			noteActions.setUserGroupTips(
				noteDispatch,
				note.getNewGroupTips(),
				enqueueSnackbar
			);
			await clientActions.updateNote(
				clientDispatch,
				groupDispatch,
				note,
				history,
				enqueueSnackbar
			);
		} else {
			enqueueSnackbar("The note is invalid", { variant: "error" });
		}
	};

	const panelActions: PanelHeaderAction[] = [
		{
			label: "Update",
			icon: Edit,
			isLoading: isSaving,
			onClick: onUpdate,
		},
		{
			label: "Delete",
			icon: Delete,
			isLoading: isDeleting,
			color: "danger",
			onClick: async () => {
				handleOpen();
			},
		},
	];
	const breadcrumbsRoutes: PanelHeaderBreadcrumbsRoute[] = [
		{
			label: "Clients",
			icon: People,
			to: "/app/clients",
		},
		{
			label: `${note.client.name}`,
			to: `/app/clients/${note.client.id}`,
			// icon: People,
		},
		{
			label: `Progress Note ${note.startDate.toLocaleDateString("en")}`,
		},
	];

	return (
		<>
			<PanelHeader
				breadcrumbsRoutes={breadcrumbsRoutes}
				actions={panelActions}
			/>
			<NoteForm />

			{!isFetching && !isFetchingClientInfo && (
				<Grid container justifyContent="flex-end">
					<WrappedButton
						size="small"
						disabled={isSaving}
						startIcon={<Edit />}
						onClick={onUpdate}
						style={{ marginLeft: "10px" }}
					>
						Update
						{isSaving && <ButtonCircularProgress />}
					</WrappedButton>
				</Grid>
			)}
			<ConfirmationDialog
				open={open}
				handleClose={handleClose}
				title="Delete this note?"
				content="Are you sure do you want to delete this note."
				onSuccessCallback={async () => {
					handleClose();
					if (note.client.id && note.id) {
						await clientActions.deleteNote(
							clientDispatch,
							groupDispatch,
							note.client.id,
							note.id,
							history,
							enqueueSnackbar
						);
					}
				}}
			/>
		</>
	);
}
