import { Card, SvgIconTypeMap, Typography } from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import useStyles from "./styles";

export default function DashCard(props: {
	title: string;
	value: string;
	icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
}) {
	const { title, value } = props;
	const classes = useStyles();

	return (
		<Card className={classes.card}>
			<div className={classes.topContainer}>
				<div className={classes.textContainer}>
					<div>
						<Typography className={classes.value}>{value}</Typography>
					</div>
					<div>
						<Typography className={classes.title}>{title}</Typography>
					</div>
				</div>
				<div className={classes.iconContainer}>
					<div className={classes.icon}>
						<props.icon style={{ fontSize: "40px" }} />
					</div>
				</div>
			</div>
			{/* <div className={classes.divider} />
			<div className={classes.bottomContainer}>
				<Link className={classes.link} to="#">
					See details
				</Link>
			</div> */}
		</Card>
	);
}
