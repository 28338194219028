import {
	SET_SHOW_CATEGORY_INPUT,
	SET_SHOW_GOAL_INPUT,
	SET_SHOW_OBJECTIVES_INPUT,
	InputFormDispatch,
	InitGoalsStatePayload,
	SetObjectivesValuePayload,
	SetValuePayload,
	INIT_GOALS_STATE,
	INIT_TREATMENT_STATE,
	SET_SHOW_TREATMENT_INPUT,
	INIT_GROUP_TIP_STATE,
	InitGroupTipStatePayload,
	SET_SHOW_GROUP_TIP_CATEGORY,
	SET_SHOW_GROUP_TIP_TITLE,
	SET_SHOW_GROUP_TIP_INTERVENTION,
	SET_SHOW_GROUP_TIP_PARTICIPATION,
} from "./misc";

export default class InputFormActions {
	// private groupRepository: IInputFormRepository;

	// constructor(groupRepository: IInputFormRepository) {
	// 	this.groupRepository = groupRepository;
	// }

	public async initGroupTipState(dispatch: InputFormDispatch, value: InitGroupTipStatePayload) {
		dispatch({ type: INIT_GROUP_TIP_STATE, payload: value });
	}

	public async setGroupTipCategoryInput(dispatch: InputFormDispatch, value: SetValuePayload) {
		dispatch({ type: SET_SHOW_GROUP_TIP_CATEGORY, payload: value });
	}

	public async setGroupTipTitleInput(dispatch: InputFormDispatch, value: SetValuePayload) {
		dispatch({ type: SET_SHOW_GROUP_TIP_TITLE, payload: value });
	}

	public async setGroupTipInterventionInput(dispatch: InputFormDispatch, value: SetValuePayload) {
		dispatch({ type: SET_SHOW_GROUP_TIP_INTERVENTION, payload: value });
	}

	public async setGroupTipParticipationInput(dispatch: InputFormDispatch, value: SetValuePayload) {
		dispatch({ type: SET_SHOW_GROUP_TIP_PARTICIPATION, payload: value });
	}

	public async initGoalsState(dispatch: InputFormDispatch, value: InitGoalsStatePayload) {
		dispatch({ type: INIT_GOALS_STATE, payload: value });
	}

	public async setCategoryInput(dispatch: InputFormDispatch, value: SetValuePayload) {
		dispatch({ type: SET_SHOW_CATEGORY_INPUT, payload: value });
	}

	public async setGoalInput(dispatch: InputFormDispatch, value: SetValuePayload) {
		dispatch({ type: SET_SHOW_GOAL_INPUT, payload: value });
	}

	public async setObjectivesInput(dispatch: InputFormDispatch, value: SetObjectivesValuePayload) {
		dispatch({ type: SET_SHOW_OBJECTIVES_INPUT, payload: value });
	}

	public async initTreatmentState(dispatch: InputFormDispatch, value: boolean[]) {
		dispatch({ type: INIT_TREATMENT_STATE, payload: value });
	}

	public async setTreatmentInput(dispatch: InputFormDispatch, value: SetValuePayload) {
		dispatch({ type: SET_SHOW_TREATMENT_INPUT, payload: value });
	}
}
