import { ReactNode, useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
// @material-ui/core components
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
// core components
import useStyles from "./styles";
import colorlogo from "../../../core/assets/images/logo.png";

export default function Header(props: {
	color: "primary" | "info" | "success" | "warning" | "danger" | "transparent";
	rightLinks?: ReactNode;
	leftLinks?: ReactNode;
	fixed?: boolean;
	absolute?: boolean;
	changeColorOnScroll: {
		height: number;
		color:
			| "primary"
			| "info"
			| "success"
			| "warning"
			| "danger"
			| "transparent"
			| "white";
	};
}) {
	const classes = useStyles();

	const [mobileOpen, setMobileOpen] = useState(false);

	useEffect(() => {
		if (props.changeColorOnScroll) {
			window.addEventListener("scroll", headerColorChange);
		}
		return function cleanup() {
			if (props.changeColorOnScroll) {
				window.removeEventListener("scroll", headerColorChange);
			}
		};
	});

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const headerColorChange = () => {
		const { color, changeColorOnScroll } = props;
		const windowsScrollTop = window.pageYOffset;
		if (windowsScrollTop > changeColorOnScroll.height) {
			document.body
				.getElementsByTagName("header")[0]
				?.classList.remove(classes[color]);
			document.body
				.getElementsByTagName("header")[0]
				?.classList.add(classes[changeColorOnScroll.color]);
			// document.body.getElementsByTagName("img")[0].src = colorlogo;
		} else {
			document.body
				.getElementsByTagName("header")[0]
				?.classList.add(classes[color]);
			document.body
				.getElementsByTagName("header")[0]
				?.classList.remove(classes[changeColorOnScroll.color]);
			// document.body.getElementsByTagName("img")[0].src = whitelogo;
		}
	};

	const { color, rightLinks, leftLinks, fixed, absolute } = props;

	var brandComponent = (
		// <Button className={classes.title}>
		<div id="brand_div" className={classes.title} style={{ cursor: "pointer" }}>
			<img
				src={colorlogo}
				alt="Logo"
				width="200px"
				style={{ marginRight: 10 }}
			/>
			{/* {brand} */}
		</div>
	);

	const appBarClasses = classNames({
		[classes.appBar]: true,
		[classes[color]]: color,
		[classes.absolute]: absolute ?? false,
		[classes.fixed]: fixed ?? false,
	});

	return (
		<AppBar className={appBarClasses}>
			<Toolbar className={classes.container}>
				{leftLinks !== undefined ? brandComponent : null}
				<div className={classes.flex}>
					{leftLinks !== undefined ? (
						<Hidden smDown implementation="css">
							{leftLinks}
						</Hidden>
					) : (
						brandComponent
					)}
				</div>
				<Hidden mdDown implementation="css">
					{rightLinks}
				</Hidden>
				<Hidden lgUp>
					<IconButton
						color="primary"
						aria-label="open drawer"
						onClick={handleDrawerToggle}
					>
						<Menu />
					</IconButton>
				</Hidden>
			</Toolbar>
			<Hidden mdUp implementation="js">
				<Drawer
					variant="temporary"
					anchor={"right"}
					open={mobileOpen}
					classes={{
						paper: classes.drawerPaper,
					}}
					onClose={handleDrawerToggle}
				>
					<div className={classes.appResponsive}>
						{leftLinks}
						{rightLinks}
					</div>
				</Drawer>
			</Hidden>
		</AppBar>
	);
}
