import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firebase-firestore";
import "firebase/firebase-functions";
import "firebase/analytics";
import "firebase/storage";
import Config from "./config";
// import { isInDev } from "../tools/env";
// import EmailServices from "./email";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: "AIzaSyAnzxKMlwIBnkjUQ7S7i7wuthnhgq-V8pg",
	authDomain: "mhnotes-35b1f.firebaseapp.com",
	projectId: "mhnotes-35b1f",
	storageBucket: "mhnotes-35b1f.appspot.com",
	messagingSenderId: "289559166635",
	appId: "1:289559166635:web:c9031d46d23e5444e581ee",
	measurementId: "G-TJZT858M7J",
};

class Firebase {
	public auth: firebase.auth.Auth;
	public analytics: firebase.analytics.Analytics;
	public firestore: firebase.firestore.Firestore;
	public functions: firebase.functions.Functions;
	public storage: firebase.storage.Storage;
	private static instance: Firebase;

	private constructor() {
		// Initialize Firebase
		firebase.initializeApp(firebaseConfig);
		this.analytics = firebase.analytics();
		this.auth = firebase.auth();
		this.firestore = firebase.firestore();
		this.functions = firebase.functions();
		this.storage = firebase.storage();
		if (Config.isInDev) {
			this.functions.useEmulator("localhost", 5001);
			this.auth.useEmulator("http://localhost:9099");
			this.storage.useEmulator("localhost", 9190);
			this.firestore.settings({
				host: "localhost:8080",
				ssl: false,
			});
		}
	}

	public static GetInstance() {
		if (!this.instance) {
			this.instance = new Firebase();
		}
		return this.instance;
	}

	public async deleteQueryBatch(query: firebase.firestore.CollectionReference) {
		const snapshot = await query.get();

		const batchSize = snapshot.size;
		if (batchSize === 0) {
			// When there are no documents left, we are done
			return;
		}

		// Delete documents in a batch
		const batch = this.firestore.batch();
		snapshot.docs.forEach((doc) => {
			batch.delete(doc.ref);
		});
		await batch.commit();

		// Recurse on the next process tick, to avoid
		// exploding the stack.
		process.nextTick(() => {
			this.deleteQueryBatch(query);
		});
	}
}

export default Firebase;
