import {
	AlignmentType,
	Document,
	HeadingLevel,
	Packer,
	Paragraph,
	TabStopPosition,
	TabStopType,
	TextRun,
} from "docx";
import Note from "../../../domain/note/models/Note";
import { saveAs } from "file-saver";
import { User } from "../../../domain/user/models/User";

export const activeRoute = (routeName: any) => {
	return window.location.href.indexOf(routeName) > -1 ? true : false;
};

export const getDaysBetweenDates = (a: Date, b: Date) => {
	// To calculate the time difference of two dates
	var difference_In_Time = b.getTime() - a.getTime();

	// To calculate the no. of days between two dates
	var difference_In_Days = difference_In_Time / (1000 * 3600 * 24);

	//To display the final no. of days (result)
	return Math.round(difference_In_Days);
};

export const getTotalUnits = (startDate: Date, endDate: Date) => {
	// To calculate the time difference of two dates
	var difference_In_Time = endDate.getTime() - startDate.getTime();

	// To calculate the no. of days between two dates
	var difference_In_Min = difference_In_Time / 1000 / 60;

	//To display the final no. of days (result)
	return Math.round(difference_In_Min / 15);
};

// export const handleSubscriptionError = (
// 	user: User,
// 	enqueueSnackbar: EnqueueSnackbar,
// 	successCallback: () => void,
// 	errorCallback?: () => void
// ): void => {
// 	const { error, message } = user.isActiveSubscription();
// 	if (error) {
// 		enqueueSnackbar(message, {
// 			variant: "error",
// 		});
// 		errorCallback?.();
// 	} else {
// 		successCallback();
// 	}
// };

export const getDocument = (user: User, note: Note): void => {
	const doc = new Document({
		styles: {
			default: {
				document: {
					run: {
						font: "Calibri",
						size: 24,
					},
				},
			},
			paragraphStyles: [
				{
					id: "Title",
					name: "My Wonky Style",
					basedOn: "Normal",
					next: "Normal",
					quickFormat: true,
					run: {
						size: 36,
						font: "Calibri",
						// color: "999999",
					},
				},
			],
		},
		sections: [
			{
				children: [
					new Paragraph({
						text: note.company.name,
						heading: HeadingLevel.TITLE,
						alignment: AlignmentType.CENTER,
					}),
					new Paragraph({
						text: note.company.address,
						alignment: AlignmentType.CENTER,
					}),
					new Paragraph({
						alignment: AlignmentType.CENTER,
						text: "Psychosocial Rehabilitation Progress Note",
						spacing: {
							after: 300,
						},
					}),
					new Paragraph({
						children: [
							new TextRun({
								text: `Client's Name: `,
								bold: true,
							}),
							new TextRun({
								text: `${note.client.name}`,
							}),
						],
					}),
					// new Paragraph({
					// 	children: [
					// 		new TextRun({
					// 			text: `Service Code: `,
					// 			bold: true,
					// 		}),
					// 		new TextRun({
					// 			text: `${note.client.serviceCode}`,
					// 		}),
					// 	],
					// }),
					new Paragraph({
						children: [
							new TextRun({
								text: `Service Code: `,
								bold: true,
							}),
							new TextRun({
								text: `${note.client.serviceCode}`,
							}),
							new TextRun({
								text: `\tDiagnosis: `,
								bold: true,
							}),
							new TextRun({
								text: `${note.client.diagnosis}`,
							}),
						],
						tabStops: [
							{
								type: TabStopType.RIGHT,
								position: TabStopPosition.MAX,
							},
						],
					}),
					new Paragraph({
						children: [
							new TextRun({
								text: `Case #: `,
								bold: true,
							}),
							new TextRun({
								text: `${note.client.case}\t`,
							}),
							new TextRun({
								text: `Service Date: `,
								bold: true,
							}),
							new TextRun({
								text: `${note.startDate.toLocaleDateString("en")}`,
							}),
							new TextRun({
								text: `\tTotal Units: `,
								bold: true,
							}),
							new TextRun({
								text: `${note.totalUnits} units`,
							}),
						],
						tabStops: [
							{
								type: TabStopType.LEFT,
								position: 3000,
							},
							{
								type: TabStopType.RIGHT,
								position: TabStopPosition.MAX,
							},
						],
					}),
					new Paragraph({
						children: [
							new TextRun({
								text: `Begin-End: `,
								bold: true,
							}),
							new TextRun({
								text: `${note.startDate.toLocaleTimeString(
									"en"
								)}-${note.endDate.toLocaleTimeString("en")}`,
							}),
							new TextRun({
								text: `\tSetting: `,
								bold: true,
							}),
							new TextRun({
								text: `${note.client.setting}`,
							}),
							new TextRun({ text: "", break: 1 }),
						],
						tabStops: [
							{
								type: TabStopType.RIGHT,
								position: TabStopPosition.MAX,
							},
						],
					}),
					...note.goals.map(
						(goal, i) =>
							new Paragraph({
								children: [
									new TextRun({
										text: `Goal ${i + 1}: `,
										bold: true,
									}),
									new TextRun({
										text: `${goal.name}`,
									}),
									new TextRun({
										text: "",
										break: 1,
									}),
									...goal.objectives.map(
										(obj, j) =>
											new Paragraph({
												children: [
													new TextRun({
														text: `Objective ${i + 1}${String.fromCharCode(
															65 + j
														)}: `,
														bold: true,
													}),
													new TextRun({
														text: `${obj.value}`,
													}),
												],
											})
									),
								],
							})
					),
					new Paragraph({
						children: [
							new TextRun({
								text: "GOALS/OBJETIVES ADDRESSED CLIENT’S TREATMENT PLAN:",
								allCaps: true,
								underline: {},
								bold: true,
							}),
							new TextRun({ text: "", break: 1 }),
						],
					}),
					...note.treatments.map(
						(item) =>
							new Paragraph({
								children: [
									new TextRun({
										text: `${item.question}: `,
										bold: true,
									}),
									new TextRun({
										text: `${item.selectedAnswer}`,
									}),
								],
								// text: `${item.question}: ${item.selectedAnswer}`,
								bullet: {
									level: 0,
								},
							})
					),
					...note.groupTips.map(
						(item, i) =>
							new Paragraph({
								children: [
									new TextRun({ text: "", break: 1 }),
									new TextRun({
										text: `Group Title ${i + 1}`,
										bold: true,
									}),
									new TextRun({
										text: `${item.category}: `,
										bold: true,
										break: 1,
									}),
									new TextRun({
										text: `${item.title}`,
									}),
									new TextRun({
										text: `Group facilitator intervention: `,
										bold: true,
										break: 1,
									}),
									new TextRun({
										text: `${item.selectedIntervention?.value}`,
									}),
									new TextRun({
										text: `Participation: `,
										bold: true,
										break: 1,
									}),
									new TextRun({
										text: `${item.selectedParticipation}`,
									}),
								],
							})
					),
					new Paragraph({
						// spacing: {
						// 	before: 200,
						// },
						children: [
							new TextRun({ text: "", break: 1 }),
							new TextRun({
								text: `Update of progress towards meeting goal and objective on the treatment plan: `,
								bold: true,
							}),
							new TextRun({
								text: `${note.updateOfProgress.progress.value}`,
							}),
						],
						// text: `Update of progress towards meeting goal and objective on the treatment plan: ${note.updateOfProgress.progress.value}`,
					}),
					new Paragraph({
						spacing: {
							before: 200,
						},
						text: `Goal ${note.updateOfProgress.goal + 1}, Objective ${
							note.updateOfProgress.goal + 1
						} ${String.fromCharCode(65 + note.updateOfProgress.objective)}`,
					}),
					new Paragraph({
						spacing: {
							before: 200,
							after: 400,
						},
						text: `Client's Name: ${note.client.name}`,
					}),
					new Paragraph({
						children: [
							new TextRun({
								text: `Facilitator’s Signature/Credentials: `,
								break: 2,
							}),
							new TextRun({
								text: `Printed Name/Credentials: ${user.name}`,
								break: 2,
							}),
							new TextRun({
								text: `Date: ${note.startDate.toLocaleDateString("en")}`,
								break: 2,
							}),
						],
						// tabStops: [
						// 	{
						// 		type: TabStopType.LEFT,
						// 		position: 10,
						// 	},
						// 	{
						// 		type: TabStopType.RIGHT,
						// 		position: TabStopPosition.MAX,
						// 	},
						// ],
					}),
				],
			},
		],
	});

	// console.log(`doc`, doc);

	Packer.toBlob(doc).then((blob) => {
		saveAs(
			blob,
			`${note.client.name} ${note.startDate.toLocaleDateString("en")}.docx`
		);
	});
};
