import { newDate } from "../../../core/helpers";
import GroupTip from "../../note/models/GroupTip";

type GroupPlanProps = {
	id?: string;
	date: Date;
	groupTips: GroupTip[];
};

export default class GroupPlan {
	id?: string;
	date: Date;
	groupTips: GroupTip[];

	constructor(props: GroupPlanProps) {
		this.id = props.id;
		this.date = props.date;
		this.groupTips = props.groupTips;
	}

	public static empty(): GroupPlan {
		return new GroupPlan({
			date: newDate(),
			groupTips: [
				GroupTip.empty(),
				GroupTip.empty(),
				GroupTip.empty(),
				GroupTip.empty(),
			],
		});
	}

	public isValid(): boolean {
		return this.groupTips.every((item) => item.isValidPlan());
	}

	public getNewGroupTips(): GroupTip[] {
		return this.groupTips.filter((item) => item.checkIsNew());
	}
}
