import Client from "../../client/models/Client";
import { newDate, newNoteDate } from "../../../core/helpers";
import Company from "../../user/models/Company";
import Goal from "./Goal";
import GroupTip from "./GroupTip";
import Treatment from "./Treatment";
import UpdateOfProgress from "./UpdateOfProgress";

type NoteProps = {
	id?: string;
	client: Client;
	totalUnits: number;
	startDate: Date;
	endDate: Date;
	company: any;
	goals: Goal[];
	treatments: Treatment[];
	groupTips: GroupTip[];
	updateOfProgress: UpdateOfProgress;
};

export default class Note {
	id?: string;
	client: Client;
	totalUnits: number;
	startDate: Date;
	endDate: Date;
	company: Company;
	goals: Goal[];
	treatments: Treatment[];
	groupTips: GroupTip[];
	updateOfProgress: UpdateOfProgress;

	constructor(props: NoteProps) {
		this.id = props.id;
		this.client = props.client;
		this.totalUnits = props.totalUnits;
		this.startDate = props.startDate;
		this.endDate = props.endDate;
		this.company = new Company({ ...props.company });
		this.goals = props.goals;
		this.treatments = props.treatments;
		this.groupTips = props.groupTips;
		this.updateOfProgress = props.updateOfProgress;
	}

	public static empty(): Note {
		return new Note({
			client: Client.empty(),
			totalUnits: 0,
			startDate: newNoteDate(),
			endDate: new Date(newNoteDate().getTime() + 1000 * 60 * 60),
			company: Company.empty(),
			goals: [Goal.empty(), Goal.empty(), Goal.empty()],
			treatments: [],
			groupTips: [
				GroupTip.empty(),
				GroupTip.empty(),
				GroupTip.empty(),
				GroupTip.empty(),
			],
			updateOfProgress: UpdateOfProgress.empty(),
		});
	}

	public getNewGoals(): Goal[] {
		return this.goals.filter((item) => item.checkIsNew());
	}

	// public hasNewTreatments(): boolean {
	// 	return this.treatments.some((item) => item.isNew);
	// }

	public getNewTreatments(): Treatment[] {
		return this.treatments.filter((item) => item.isNew);
	}

	// public hasNewGroupTip(): boolean {
	// 	return this.groupTips.some((item) => item.checkIsNew());
	// }

	public getNewGroupTips(): GroupTip[] {
		return this.groupTips.filter((item) => item.checkIsNew());
	}

	public isGoalsValid(): boolean {
		return this.goals.every((item) => item.isValid());
	}

	// public isUpdateOfProgressValid(): boolean {
	// 	return this.;
	// }

	// private isTreatmentsValid(): boolean {
	// 	return this.treatments.every((item) => item.isValid());
	// }

	// private isGroupTipsValid(): boolean {
	// 	return this.groupTips.every((item) => item.isValid());
	// }

	public isValid(): boolean {
		return (
			this.isGoalsValid() &&
			this.treatments.every((item) => item.isValid()) &&
			this.groupTips.every((item) => item.isValid()) &&
			this.updateOfProgress.isValid() &&
			this.totalUnits !== 0
		);
	}

	public toString(): string {
		return `
		 ${this.client.name}
		 ${this.client.name}
		`;
	}
}
