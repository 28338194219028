import { makeStyles, Typography } from "@material-ui/core";
import { ExitToApp } from "@material-ui/icons";
import classNames from "classnames";
import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useUserDispatch } from "../../../../application/user";
import UserActions from "../../../../application/user/actions";
import { useInjection } from "../../../../core/dependency_injection/ioc.react";
import { activeRoute } from "../../core/helpers";
import routes from "../../core/routes";
// import { getRoutes } from "../../../../core/helpers";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  item: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "25px",
    textDecoration: "none",
    color: theme.palette.text.secondary,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  activeItem: {
    color: theme.palette.primary.main,
  },
  icon: {
    // color: theme.palette.text.secondary,
    marginRight: "2px",
  },
  label: {},
}));

export default function NavbarLinks() {
  const classes = useStyles();

  const history = useHistory();

  // const { user } = useUserState();
  const userDispatch = useUserDispatch();

  const userActions = useInjection<UserActions>("UserActions");

  // const routes = getRoutes(user.role);

  return (
    <div className={classes.container}>
      {routes
        .filter((item) => item.onNavbar)
        .map((item, index) => {
          const itemClasses = classNames({
            [classes.item]: true,
            [classes.activeItem]: activeRoute(item.layout + item.path),
          });
          return (
            <NavLink
              key={index}
              to={item.layout + item.path}
              className={itemClasses}
            >
              <item.icon className={classes.icon} />
              <Typography variant="body2" className={classes.label}>
                {item.name}
              </Typography>
            </NavLink>
          );
        })}
      <div
        // to="#"
        className={classes.item}
        onClick={() => {
          userActions.signOut(userDispatch, history);
        }}
      >
        <ExitToApp className={classes.icon} />
        <Typography variant="body2" className={classes.label}>
          Sign Out
        </Typography>
      </div>
    </div>
  );
}
