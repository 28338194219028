import {
	Card,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Tooltip,
	Typography,
} from "@material-ui/core";
import { Add, Edit, List } from "@material-ui/icons";
import { Fragment, useEffect } from "react";
import {
	useInputFormDispatch,
	useInputFormState,
} from "../../../../../../../../../../application/core/inputForm";
import InputFormActions from "../../../../../../../../../../application/core/inputForm/actions";
import {
	useNoteDispatch,
	useNoteState,
} from "../../../../../../../../../../application/note";
import NoteActions from "../../../../../../../../../../application/note/actions";
import { useInjection } from "../../../../../../../../../../core/dependency_injection";
import useStyles from "../../styles";

export default function TreatmentAccordionContent() {
	const classes = useStyles();

	const { treatments, userTreatments, note } = useNoteState();
	const noteDispatch = useNoteDispatch();
	const noteActions = useInjection<NoteActions>("NoteActions");

	const { showTreatmentInput } = useInputFormState();
	const inputFormDispatch = useInputFormDispatch();
	const inputFormActions = useInjection<InputFormActions>("InputFormActions");

	// const [showTreatmentInput, setShowInput] = useState<boolean[]>([]);

	// const [selectedAnswer, setSelectedAnswer] = useState("");

	// console.log(`treatments`, treatments);

	useEffect(() => {
		if (note.treatments.length !== 0) {
			// setShowInput(note.treatments.map((_) => false));
			inputFormActions.initTreatmentState(
				inputFormDispatch,
				note.treatments.map((_) => false)
			);
		} // eslint-disable-next-line
	}, [note.treatments]);

	return (
		<Grid container alignItems="center">
			<Card className={classes.card}>
				<Grid item container lg={12}>
					{note.treatments.map((item, index) => {
						const treatment = treatments.find(
							(treatment) => treatment.question === item.question
						);
						const userTreatment = userTreatments.find(
							(t) => t.question === item.question
						);
						return (
							<Fragment key={index}>
								<Grid item container alignItems="center" lg={6} xs={12}>
									<Typography>{item.question}:</Typography>
								</Grid>
								<Grid item lg={5} xs={10}>
									{!showTreatmentInput[index] ? (
										<FormControl
											variant="filled"
											fullWidth
											className={classes.formControl}
										>
											<InputLabel id={`treatment-select${index}`}>
												Select
											</InputLabel>
											<Select
												multiple
												labelId={`treatment-select${index}`}
												id={`treatment-select${index}`}
												value={item.selectedAnswer?.map((item) => item)}
												onChange={(e) => {
													noteActions.setNoteTreatmentSelectedAnswer(
														noteDispatch,
														{
															index: index,
															selectedAnswer: e.target.value as string[],

															isNew: false,
														}
													);
												}}
											>
												<MenuItem className={classes.disabledMenuItem} disabled>
													Recent Values:
												</MenuItem>
												{!!treatment?.answers &&
													treatment?.answers?.length !== 0 &&
													userTreatment?.answers?.map((userTreat, index) => (
														<MenuItem
															key={index}
															value={userTreat}
															style={{
																fontWeight:
																	item.selectedAnswer?.findIndex(
																		(answer) => answer === userTreat
																	) === -1
																		? "normal"
																		: "bold",
															}}
														>
															{userTreat}
														</MenuItem>
													))}
												<MenuItem className={classes.disabledMenuItem} disabled>
													Values:
												</MenuItem>
												{!!treatment?.answers &&
												treatment?.answers?.length !== 0 ? (
													treatment?.answers?.map(
														(treat: any, index: number) => (
															<MenuItem
																key={index}
																value={treat}
																style={{
																	fontWeight:
																		item.selectedAnswer?.findIndex(
																			(answer) => answer === treat
																		) === -1
																			? "normal"
																			: "bold",
																}}
															>
																{treat}
															</MenuItem>
														)
													)
												) : (
													<MenuItem value={item.selectedAnswer}>
														{item.selectedAnswer}
													</MenuItem>
												)}
											</Select>
										</FormControl>
									) : (
										<TextField
											className={classes.formControl}
											fullWidth
											variant="filled"
											// disabled={note.updateOfProgress.objective === -1}
											label="Type Here"
											multiline
											value={item.selectedAnswer}
											onChange={(e) => {
												// const selectedProgress = progress.find((item) => item.label === label);
												const values = (e.target.value as string).split(",");
												// temp.push(e.target.value as string);
												noteActions.setNoteTreatmentSelectedAnswer(
													noteDispatch,
													{
														index: index,
														selectedAnswer: values,
														isNew: true,
													}
												);
											}}
										/>
									)}
								</Grid>
								<Grid
									item
									container
									justifyContent="center"
									alignItems="center"
									lg={1}
									xs={1}
								>
									<Tooltip
										title={
											!showTreatmentInput[index]
												? item.selectedAnswer?.length === 0
													? "Add new value"
													: "Edit value"
												: "Show all values"
										}
									>
										<IconButton
											color="primary"
											onClick={() => {
												inputFormActions.setTreatmentInput(inputFormDispatch, {
													index: index,
												});
												if (!showTreatmentInput[index]) {
													noteActions.setNoteTreatmentSelectedAnswer(
														noteDispatch,
														{
															index: index,
															selectedAnswer: [],
															isNew: false,
														}
													);
												}
											}}
										>
											{!showTreatmentInput[index] ? (
												item.selectedAnswer?.length === 0 ? (
													<Add />
												) : (
													<Edit />
												)
											) : (
												<List />
											)}
										</IconButton>
									</Tooltip>
								</Grid>
							</Fragment>
						);
					})}
				</Grid>
			</Card>
		</Grid>
	);
}
