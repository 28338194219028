import React from "react";
import { User } from "../../domain/user/models/User";

export const SET_LOADING = "SET_LOADING";
export const SET_SUBMITING = "SET_SUBMITING";
export const SET_USER = "SET_USER";
export const SIGN_FAILURE = "SIGN_FAILURE";
export const SIGN_OUT_SUCCESS = "SIGN_OUT_SUCCESS";
export const UPDATE_USER = "UPDATE_USER";
export const SET_UPDATING = "SET_UPDATING";
export const SET_SIGN_EMAIL_SENDED = "SET_SIGN_EMAIL_SENDED";
export const SET_SENDING_VERIFY = "SET_SENDING_VERIFY";
export const SET_SENDING_PASSWORD = "SET_SENDING_PASSWORD";
export const SET_FILL_PROFILE = "SET_FILL_PROFILE";
export const SET_SHOW_PLAN = "SET_SHOW_PLAN";

export const initialValues: UserState = {
	isAuthenticated: false,
	isLoading: true,
	isSubmiting: false,
	isUpdating: false,
	signEmailSended: false,
	isSendingVerify: false,
	isSendingPassword: false,
	showPlans: false,
	isFillingProfile: false,
	user: User.empty(),
};

export type UserDispatch = React.Dispatch<UserAction>;

export type UserState = {
	isAuthenticated: boolean;
	isLoading: boolean;
	isSubmiting: boolean;
	isUpdating: boolean;
	signEmailSended: boolean;
	isSendingVerify: boolean;
	isSendingPassword: boolean;
	showPlans: boolean;
	isFillingProfile: boolean;
	user: User;
};

export type UserAction =
	| {
			type: typeof SET_FILL_PROFILE;
			payload: {
				id: string;
				emailVerified: boolean;
				value: boolean;
			};
	  }
	| {
			type: typeof SET_SENDING_VERIFY;
			payload: boolean;
	  }
	| {
			type: typeof SET_SENDING_PASSWORD;
			payload: boolean;
	  }
	| {
			type: typeof SET_SIGN_EMAIL_SENDED;
			payload: boolean;
	  }
	| {
			type: typeof SET_LOADING;
			payload: boolean;
	  }
	| {
			type: typeof SET_SHOW_PLAN;
			payload: boolean;
	  }
	| {
			type: typeof SET_SUBMITING;
			payload: boolean;
	  }
	| {
			type: typeof SIGN_FAILURE;
	  }
	| {
			type: typeof SIGN_OUT_SUCCESS;
	  }
	| {
			type: typeof SET_USER;
			payload: User;
	  }
	| {
			type: typeof SET_UPDATING;
			payload: boolean;
	  }
	| {
			type: typeof UPDATE_USER;
			payload: User;
	  };
