import { Themes } from "../../../domain/core/utils";
import { SET_DARK_MODE, SET_THEME, ThemeDispatch } from "./misc";

export default class ThemeActions {
	setTheme(dispatch: ThemeDispatch, value: Themes) {
		dispatch({ type: SET_THEME, payload: value });
	}

	setDarkMode(dispatch: ThemeDispatch, value: boolean) {
		dispatch({ type: SET_DARK_MODE, payload: value });
	}
}
