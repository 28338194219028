export type Progress = {
	label: string;
	value: string;
};

type ObjectiveProps = {
	label: string;
	value: string;
	progress?: Progress[];
	isNew?: boolean;
};

export default class Objective {
	label: string;
	value: string;
	progress?: Progress[];
	isNew: boolean;

	constructor(props: ObjectiveProps) {
		this.label = props.label;
		this.value = props.value;
		this.progress = props.progress;
		this.isNew = props.isNew ?? false;
	}

	public static empty(): Objective {
		return new Objective({
			label: "",
			value: "",
		});
	}

	public isValid(): boolean {
		return this.label !== "" && this.value !== "";
	}
}
