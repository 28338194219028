import Objective, { Progress } from "../../../domain/note/models/Objective";

export interface IObjectiveDtoProps {
  label: string;
  value: string;
  progress?: Progress[];
}

export class ObjectiveDto {
  label: string;
  value: string;
  progress?: Progress[];

  constructor(props: IObjectiveDtoProps) {
    this.label = props.label;
    this.value = props.value;
    this.progress = props.progress;
  }

  public static fromDomain(user: Objective): ObjectiveDto {
    return new ObjectiveDto({
      value: user.value,
      label: user.label,
      progress: user.progress,
    });
  }

  // public static fromFirestore(
  //   doc: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
  // ): ObjectiveDto {
  //   const data = doc.data();
  //   return new ObjectiveDto({
  //     id: doc.id,
  //     value: data?.value,
  //     label: data?.label,
  //   });
  // }

  public static fromJson(item: any): ObjectiveDto {
    return new ObjectiveDto({
      value: item.value,
      label: item.label,
      progress: item.progress,
    });
  }

  public toJson(): any {
    return {
      label: this.label,
      value: this.value,
    };
  }

  public toDomain(): Objective {
    return new Objective({
      label: this.label,
      value: this.value,
      progress: this.progress,
    });
  }
}
