import { Result } from "../../domain/core/Result";
import { IGroupRepository } from "../../domain/group/igroup.repository";
import GroupPlan from "../../domain/group/models/GroupPlan";
import SessionGroup from "../../domain/group/models/SessionGroup";
import { IGroupRemoteDatasource } from "./datasources/remote";
import { GroupPlanDto } from "./dtos/GroupPlanDto";
import { SessionGroupDto } from "./dtos/SessionGroupDto";

export default class GroupRepository implements IGroupRepository {
	private remoteDatasource: IGroupRemoteDatasource;

	public constructor(remoteDatasource: IGroupRemoteDatasource) {
		this.remoteDatasource = remoteDatasource;
	}

	async getGroupPlanByDate(clientId: string, date: Date): Promise<Result<GroupPlan>> {
		try {
			const groupPlanDto = await this.remoteDatasource.getGroupPlanByDate(clientId, date);
			// console.log(`clientDto`, clientDtos);
			return Result.rigth(groupPlanDto.toDomain());
		} catch (error: any) {
			// console.log(`error`, error);
			return Result.left(error.message);
		}
	}

	async createGroupPlan(groupId: string, groupPlan: GroupPlan): Promise<Result<GroupPlan>> {
		try {
			const groupPlanDto = await this.remoteDatasource.createGroupPlan(
				groupId,
				GroupPlanDto.fromDomain(groupPlan)
			);
			// console.log(`clientDto`, clientDtos);
			return Result.rigth(groupPlanDto.toDomain());
		} catch (error: any) {
			console.log(`error`, error);
			return Result.left(error.message);
		}
	}
	async updateGroupPlan(groupId: string, groupPlan: GroupPlan): Promise<Result<void>> {
		try {
			await this.remoteDatasource.updateGroupPlan(groupId, GroupPlanDto.fromDomain(groupPlan));
			// console.log(`clientDto`, clientDtos);
			return Result.rigth("Group plan updated successfully");
		} catch (error: any) {
			console.log(`error`, error);
			return Result.left(error.message);
		}
	}
	async deleteGroupPlan(groupId: string, planId: string): Promise<Result<void>> {
		try {
			await this.remoteDatasource.deleteGroupPlan(groupId, planId);
			// console.log(`clientDto`, clientDtos);
			return Result.rigth("Group plan removed successfully");
		} catch (error: any) {
			console.log(`error`, error);
			return Result.left(error.message);
		}
	}

	async getAllSessionGroups(): Promise<Result<SessionGroup[]>> {
		try {
			const groupsDtos = await this.remoteDatasource.getAllSessionGroups();
			// console.log(`clientDto`, clientDtos);
			return Result.rigth<SessionGroup[]>(groupsDtos.map((item) => item.toDomain()));
		} catch (error: any) {
			console.log(`error`, error);
			return Result.left(error.message);
		}
	}

	async create(sessionGroup: SessionGroup): Promise<Result<SessionGroup>> {
		try {
			const groupDto = await this.remoteDatasource.create(SessionGroupDto.fromDomain(sessionGroup));
			// console.log(`clientDto`, clientDtos);
			return Result.rigth<SessionGroup>(groupDto.toDomain());
		} catch (error: any) {
			console.log(`error`, error);
			return Result.left(error.message);
		}
	}

	async update(sessionGroup: SessionGroup): Promise<Result<void>> {
		try {
			await this.remoteDatasource.update(SessionGroupDto.fromDomain(sessionGroup));
			// console.log(`clientDto`, clientDtos);
			return Result.rigth("Group updated successfully");
		} catch (error: any) {
			console.log(`error`, error);
			return Result.left(error.message);
		}
	}

	async delete(id: string): Promise<Result<void>> {
		try {
			await this.remoteDatasource.delete(id);
			// console.log(`clientDto`, clientDtos);
			return Result.rigth("Group deleted successfully");
		} catch (error: any) {
			console.log(`error`, error);
			return Result.left(error.message);
		}
	}
}
