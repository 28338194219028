import { Typography } from "@material-ui/core";
import useStyles from "./styles";

export default function VerticalItem(props: {
	title: string;
	icon: any;
	content: string;
}) {
	const { title, content } = props;
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<div className={classes.titleContainer}>
				<props.icon className={classes.icon} />
				<Typography>{title}</Typography>
			</div>
			<div>
				<Typography>{content}</Typography>
			</div>
		</div>
	);
}
