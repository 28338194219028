import { Card, Grid, Typography } from "@material-ui/core";
import useStyles from "./styles";

export default function ItemView(props: any) {
  const classes = useStyles();
  return (
    <Grid item container lg={12}>
      <Card className={classes.card}>
        <Grid item container justifyContent="center" alignItems="center" direction="row">
          <Grid item container lg={6}>
            <Typography>{props.title}</Typography>
          </Grid>
          <Grid item container lg={6}>
            {props.children}
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
}
