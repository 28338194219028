import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import {
	useClientDispatch,
	useClientState,
} from "../../../../application/client";
import ClientActions from "../../../../application/client/actions";
import { useInjection } from "../../../../core/dependency_injection";
import { PanelCircularProgress } from "../../../core/components/Progress";
import ClientDetails from "./pages/ClientDetails";
import ClientsPage from "./pages/ClientsPage";
import GoalPlans from "./pages/GoalPlans";
import Note from "./pages/Note";

export default function Clients() {
	document.title = "PSR Notes | Clients";

	// const { user } = useUserState();

	// console.log(`user`, user);

	const { clients, isFetching } = useClientState();
	const clientDispatch = useClientDispatch();
	const clientActions = useInjection<ClientActions>("ClientActions");

	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		if (!clients) {
			clientActions.getClients(clientDispatch, enqueueSnackbar);
		} // eslint-disable-next-line
	}, []);

	return !isFetching ? (
		<div>
			<Switch>
				<Route path="/app/clients/:id/goals-objectives" component={GoalPlans} />
				<Route path="/app/clients/:id/progress-note" component={Note} />
				<Route path="/app/clients/:id" component={ClientDetails} />
				<Route path="/app/clients" component={ClientsPage} />
			</Switch>
		</div>
	) : (
		<PanelCircularProgress />
	);
}
