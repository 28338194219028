import { makeStyles } from "@material-ui/core";
export default makeStyles((theme) => ({
	root: {
		border: "1px solid grey",
		borderRadius: "5px",
		minWidth: "300px",
		minHeight: "400px",
		display: "flex",
		flexDirection: "column",
		marginTop: "20px",
		zIndex: 1,
	},
	banner: {
		height: "18px",
		backgroundColor: "red",
		position: "relative",
		top: 0,
		left: 0,
		zIndex: 100,
		borderRadius: "0px 0px 5px 5px",
	},
	content: {
		padding: "20px",
	},
	topContent: {
		padding: "0px 20px",
	},
	nameContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	name: {
		fontWeight: "bold",
	},
	tooltip: {
		display: "flex",
		position: "relative",
		marginLeft: "20px",
		padding: "5px 10px",
		backgroundColor: theme.palette.primary.main,
		borderRadius: "5px",
		color: theme.palette.common.white,
		"&:before": {
			content: '""',
			position: "absolute",
			//   textDecoration: "none",
			width: 0,
			height: 0,
			borderTop: "10px solid transparent",
			borderBottom: "10px solid transparent",
			borderRight: "10px solid " + theme.palette.primary.main,
			// width: "80%",
			// height: "2px",
			// bottom: "0",
			right: "98%",
			// background: theme.palette.primary.main,
			//   visibility: "hidden",
			// borderRadius: "5px",
			// transform: "scaleX(1)",
			// transition: ".25s linear",
		},
	},
	priceContainer: {
		marginTop: "40px",
		display: "flex",
		flexDirection: "row",
	},
	price: {
		fontWeight: "bold",
	},
	interval: {
		marginLeft: "5px",
	},
	billed: {
		marginTop: "40px",
	},
	divider: {
		height: "1px",
		backgroundColor: "grey",
		margin: "10px 0px",
	},
	bottomContent: {
		padding: "0px 20px",
	},
	features: {
		marginTop: "30px",
	},
	buttonContainer: {
		margin: "60px 0px",
		display: "flex",
		justifyContent: "center",
	},
}));
