import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
	card: {
		// display: "flex",
		// justifyContent: "center",
		// alignItems: "center",
		padding: "10px",
		borderRadius: "10px",
		color: theme.palette.common.black,
		// minHeight: "100px",
		"&:hover": {
			cursor: "pointer",
			backgroundColor: theme.palette.secondary.main,
			color: theme.palette.common.white,
		},
	},
	topContainer: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		padding: "10px 16px",
	},
	textContainer: {
		display: "flex",
		flexDirection: "column",
		flex: 3,
	},
	title: {
		color: theme.palette.grey[500],
		// margin: "10px 0px",
	},
	value: {
		fontWeight: "bold",
		fontSize: "1.7rem",
		// "&:hover": {
		// 	color: theme.palette.common.white,
		// },
	},
	iconContainer: {
		// backgroundColor: "red",
		// padding: "10px 14px",
		display: "flex",
		flex: 1,
		justifyContent: "flex-end",
		alignItems: "center",
	},
	icon: {
		// background: `linear-gradient(135deg,rgb(${hexToRgb(
		// 	theme.palette.primary.main
		// )}) 0%, rgb(${hexToRgb(theme.palette.primary.main)}) 25%, rgb(${hexToRgb(
		// 	theme.palette.secondary.main
		// )}) 100%)`,
		// padding: "10px",
		color: theme.palette.primary.main,
		justifyContent: "center",
		// backgroundColor: theme.palette.primary.main,
		// borderRadius: "10px",
	},
	divider: {
		height: "1px",
		backgroundColor: theme.palette.grey[300],
		margin: "10px 0px",
	},
	bottomContainer: {},
	link: {
		textDecoration: "none",
	},
}));
