import Company from "../../../domain/user/models/Company";

export interface ICompanyProps {
	name: string;
	address: string;
}

export class CompanyDto {
	name: string;
	address: string;

	constructor(props: ICompanyProps) {
		this.name = props.name;
		this.address = props.address;
	}

	public static fromDomain(user: Company): CompanyDto {
		return new CompanyDto({
			name: user.name,
			address: user.address,
		});
	}

	public toJson(): any {
		return {
			name: this.name,
			address: this.address,
		};
	}

	public toDomain(): Company {
		return new Company({
			name: this.name,
			address: this.address,
		});
	}
}
