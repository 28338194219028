import Client from "../../domain/client/models/Client";
import GoalPlan from "../../domain/client/models/GoalPlan";
import Goal from "../../domain/note/models/Goal";
import Note from "../../domain/note/models/Note";
import { ADD_NOTE } from "../note/misc";
import {
	ADD_CLIENT,
	ADD_GOAL_PLAN,
	ClientAction,
	ClientState,
	DELETE_CLIENT,
	DELETE_GOAL_PLAN,
	DELETE_NOTE,
	SET_CLIENTS,
	SET_CREATING,
	SET_DELETE_NOTE,
	SET_DELETING,
	SET_FETCHING,
	SET_GOAL_PLAN,
	SET_GOAL_PLAN_CATEGORY,
	SET_GOAL_PLAN_END_DATE,
	SET_GOAL_PLAN_NAME,
	SET_GOAL_PLAN_OBJECTIVE,
	SET_GOAL_PLAN_START_DATE,
	SET_SAVING_NOTE,
	SET_SELECTED_CLIENT,
	UPDATE_CLIENT,
	UPDATE_GOAL_PLAN,
	UPDATE_NOTE,
} from "./misc";

export default function reducer(state: ClientState, action: ClientAction) {
	var tempClients: any[];
	var note: Note;
	var client: Client;
	var clientIndex: number;
	var notes: Note[];
	var goals: Goal[];
	var goal: Goal;
	var clients: Client[];
	var goalPlans: GoalPlan[];

	switch (action.type) {
		case SET_CREATING:
			return { ...state, isCreating: action.payload };
		case SET_DELETE_NOTE:
			return { ...state, isDeleting: action.payload };
		case SET_SAVING_NOTE:
			return { ...state, isSaving: action.payload };
		case SET_DELETING:
			return { ...state, isDeleting: action.payload };
		case ADD_CLIENT:
			clients = state.clients ?? [];
			clients.push(action.payload);
			return {
				...state,
				clients: clients,
			};
		case ADD_GOAL_PLAN:
			clients = state.clients ?? [];
			clientIndex = clients.findIndex(
				(item: any) => item.id === action.payload.clientId
			);
			goalPlans = state.selectedClient.goalPlans ?? [];
			clients[clientIndex].goalPlans = [...goalPlans, action.payload.goalPlan];
			return {
				...state,
				clients: clients,
				selectedClient: new Client({
					...state.selectedClient,
					goalPlans: [...goalPlans, action.payload.goalPlan],
				}),
			} as ClientState;
		case UPDATE_GOAL_PLAN:
			clients = state.clients ?? [];
			clientIndex = clients.findIndex(
				(item: any) => item.id === state.selectedClient.id
			);
			goalPlans = state.selectedClient.goalPlans ?? [];
			const goalPlanIndex = goalPlans.findIndex(
				(item) => item.id === action.payload.id
			);
			goalPlans[goalPlanIndex] = action.payload;
			clients[clientIndex].goalPlans = goalPlans;
			return {
				...state,
				clients: clients,
				selectedClient: new Client({
					...state.selectedClient,
					goalPlans: goalPlans,
				}),
			} as ClientState;
		case DELETE_GOAL_PLAN:
			clients = state.clients ?? [];
			clientIndex = clients.findIndex(
				(item: any) => item.id === state.selectedClient.id
			);
			goalPlans =
				state.selectedClient.goalPlans?.filter(
					(item) => item.id !== action.payload
				) ?? [];
			clients[clientIndex].goalPlans = goalPlans;
			return {
				...state,
				clients: clients,
				selectedClient: new Client({
					...state.selectedClient,
					goalPlans: goalPlans,
				}),
			} as ClientState;
		case SET_FETCHING:
			return { ...state, isFetching: action.payload };
		case SET_GOAL_PLAN:
			return { ...state, goalPlan: action.payload };
		case SET_GOAL_PLAN_START_DATE:
			return {
				...state,
				goalPlan: new GoalPlan({
					...state.goalPlan,
					startDate: action.payload,
				}),
			} as ClientState;
		case SET_GOAL_PLAN_END_DATE:
			return {
				...state,
				goalPlan: new GoalPlan({ ...state.goalPlan, endDate: action.payload }),
			} as ClientState;
		case SET_GOAL_PLAN_NAME:
			goals = state.goalPlan.goals;
			goal = goals[action.payload.index];
			goal.name = action.payload.name;
			goal.changeIsNewName(action.payload.isNew);
			goal.objectives = Goal.empty().objectives;
			goals[action.payload.index] = goal;
			return {
				...state,
				goalPlan: new GoalPlan({ ...state.goalPlan, goals: goals }),
			} as ClientState;
		case SET_GOAL_PLAN_CATEGORY:
			goals = state.goalPlan.goals;
			// goal = goals[action.payload.index];
			const newGoal = Goal.empty();
			newGoal.category = action.payload.value;
			newGoal.changeIsNewCategory(action.payload.isNew);
			goals[action.payload.index] = newGoal;
			return {
				...state,
				goalPlan: new GoalPlan({ ...state.goalPlan, goals: goals }),
			} as ClientState;
		case SET_GOAL_PLAN_OBJECTIVE:
			goals = state.goalPlan.goals;
			goal = goals[action.payload.goalIndex];
			var objectives = goal.objectives;
			objectives[action.payload.objectiveIndex] = action.payload.objective;
			goal.objectives = objectives;
			goals[action.payload.goalIndex] = goal;
			return {
				...state,
				goalPlan: new GoalPlan({ ...state.goalPlan, goals: goals }),
			} as ClientState;
		case SET_CLIENTS:
			return {
				...state,
				clients: action.payload,
			};
		case SET_SELECTED_CLIENT:
			return {
				...state,
				selectedClient: action.payload,
			};
		case UPDATE_CLIENT:
			client = action.payload;
			tempClients = state.clients ?? [];
			clientIndex = tempClients.findIndex((item: any) => item.id === client.id);
			tempClients[clientIndex] = client;
			return {
				...state,
				clients: tempClients,
				selectedClient: action.payload,
			};
		case ADD_NOTE:
			note = action.payload;
			// console.log(`ADD_NOTE note`, note);
			tempClients = state.clients ?? [];
			clientIndex = tempClients.findIndex(
				(item: any) => item.id === note.client.id
			);
			if (clientIndex === -1) {
				tempClients = [...tempClients, note.client];
				client = note.client;
			} else {
				client = tempClients[clientIndex];
			}
			notes = client.notes ?? [];
			notes.push(note);
			notes.sort((a, b) => b.startDate.getTime() - a.startDate.getTime());
			client.notes = notes;
			tempClients[clientIndex] = client;
			return {
				...state,
				clients: [...tempClients],
				selectedClient: client,
			};
		case UPDATE_NOTE:
			note = action.payload;
			// console.log(`UPDATE_NOTE note`, note);
			tempClients = state.clients ?? [];
			clientIndex = tempClients.findIndex(
				(item: any) => item.id === note.client.id
			);
			client = tempClients[clientIndex];
			notes = client.notes ?? [];
			const noteIndex = notes?.findIndex((item) => item.id === note.id);
			notes[noteIndex] = note;
			// notes?.push(note);
			notes?.sort((a, b) => b.startDate.getTime() - a.startDate.getTime());
			client.notes = notes;
			tempClients[clientIndex] = client;
			return {
				...state,
				clients: [...tempClients],
				selectedClient: client,
			};
		case DELETE_NOTE:
			// console.log(`UPDATE_NOTE note`, note);
			tempClients = state.clients ?? [];
			clientIndex = tempClients.findIndex(
				(item: any) => item.id === action.payload.clientId
			);
			client = tempClients[clientIndex];
			notes =
				client.notes?.filter((item) => item.id !== action.payload.noteId) ?? [];
			// notes?.push(note);
			client.notes = notes;
			tempClients[clientIndex] = client;
			return {
				...state,
				clients: [...tempClients],
				selectedClient: client,
			};
		case DELETE_CLIENT:
			return {
				...state,
				clients: state.clients?.filter(
					(item: any) => item.id !== action.payload
				),
			};
		default: {
			throw new Error(`Unhandled action type: ${action}`);
		}
	}
}
