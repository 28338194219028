type TreatmentProps = {
	id?: string;
	question: string;
	selectedAnswer?: string[];
	order?: number;
	answers?: string[];
	isNew?: boolean;
};

export default class Treatment {
	id?: string;
	question: string;
	selectedAnswer?: string[];
	order?: number;
	answers?: string[];
	isNew?: boolean;

	constructor(props: TreatmentProps) {
		this.id = props.id;
		this.question = props.question;
		this.answers = props.answers;
		this.selectedAnswer = props.selectedAnswer;
		this.order = props.order;
		this.isNew = props.isNew ?? false;
	}

	public toNoteTreatment(): Treatment {
		return new Treatment({
			question: this.question,
			selectedAnswer: this.selectedAnswer ?? [],
		});
	}

	public static empty(): Treatment {
		return new Treatment({
			question: "",
			answers: [],
			order: 0,
		});
	}

	public isValid(): boolean {
		return !!this.selectedAnswer && this.selectedAnswer.length !== 0;
	}
}
